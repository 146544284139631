import * as React from "react";
import {ReactNode} from "react";
import {
    InternalTransactionStep,
    LightrailTransactionStep,
    StripeTransactionStep,
    Transaction,
    TransactionStep
} from "lightrail-client/dist/model";
import {WithRouterProps} from "../../router/WithRouterProps";
import {capitalizeFirstLetter} from "../../../util/stringUtils";
import {dateTimeSecondsFormat, formatDate} from "../../../util/dateUtils";
import {Alert, Avatar, Col, List, Row} from "antd";
import {JsonViewer} from "../../ui/data/JsonViewer";
import {Link} from "react-router-dom";
import {decodeUrlMatchParam, formatRoute, Paths} from "../../../util/routes/routes";
import {DetailLayout} from "../../layout/details/DetailLayout";
import {ContentSection} from "../../layout/ContentSection";
import {FormattedCurrency} from "../../ui/currency/FormattedCurrencyConnected";
import {TransactionChainTimelineConnected} from "../TransactionChainTimelineConnected";
import {TransactionActionButtonGroupConnected} from "../transactionActions/TransactionActionButtonGroupConnected";
import {TransactionAmountDisplay} from "../TransactionAmountDisplay";
import {StringWithCopyButton} from "../../ui/StringWithCopyButton";
import {PendingOrSuccessTag} from "../PendingOrSuccessTag";
import {ReduxProps} from "./TransactionDetailPageConnected";
import {UserOutlined} from "@ant-design/icons";

interface State {
    transactionId: string;
}

export interface Props extends WithRouterProps<{ transactionId: string }>, ReduxProps {
}

export class TransactionDetailPage extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {transactionId: decodeUrlMatchParam(this.props.match.params.transactionId)};

        this.getTransaction = this.getTransaction.bind(this);
        this.renderStep = this.renderStep.bind(this);
    }

    componentDidMount(): void {
        if (!this.getTransaction()) {
            this.props.getTransaction(this.state.transactionId);
        }
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.match.params.transactionId != this.props.match.params.transactionId) {
            const decoded = decodeUrlMatchParam(this.props.match.params.transactionId);
            this.setState({transactionId: decoded});
            this.props.getTransaction(decoded);
        }
    }

    getTransaction(): Transaction {
        return this.props.transactionsServerState.list.find(t => t.id == this.state.transactionId);
    }

    ///////////////////
    //[ RENDER ]
    renderLightailStep(transaction: Transaction, step: LightrailTransactionStep): ReactNode {
        return (
            <List.Item>
                <List.Item.Meta
                    avatar={
                        <Avatar>{this.getStepNumber(transaction, step)}</Avatar>
                    }
                    title={<FormattedCurrency code={transaction.currency} value={step.balanceChange}/>}
                    description={
                        <span>
                            Lightrail Value <Link to={formatRoute(Paths.VALUE_DETAIL, {valueId: step.valueId})}>
                                {step.valueId}
                            </Link>
                        </span>
                    }
                />
                {
                    (!!step.contactId) &&
                    <List.Item.Meta
                        avatar={<Avatar><UserOutlined/></Avatar>}
                        title={"Attached Contact"}
                        description={
                            <span>
                            <Link to={formatRoute(Paths.CONTACT_DETAIL, {contactId: step.contactId})}>
                                {step.contactId}
                            </Link>
                        </span>
                        }
                    />
                }
            </List.Item>
        );
    }

    renderStripeStep(transaction: Transaction, step: StripeTransactionStep): ReactNode {
        return (
            <List.Item>
                <List.Item.Meta
                    avatar={
                        <Avatar>{this.getStepNumber(transaction, step)}</Avatar>
                    }
                    title={<FormattedCurrency code={transaction.currency} value={step.amount}/>}
                    description="Stripe Charge"
                />
            </List.Item>
        );
    }

    renderInternalStep(transaction: Transaction, step: InternalTransactionStep): ReactNode {
        return (
            <List.Item>
                <List.Item.Meta
                    avatar={<Avatar>{this.getStepNumber(transaction, step)}</Avatar>}
                    title={<FormattedCurrency code={transaction.currency} value={step.balanceChange}/>}
                    description="Internal Rail"
                />
            </List.Item>
        );
    }

    getStepNumber(transaction: Transaction, step: LightrailTransactionStep | StripeTransactionStep | InternalTransactionStep): number {
        return transaction.steps.indexOf(step) + 1;
    }

    renderStep(step: TransactionStep): ReactNode {
        const transaction = this.getTransaction();

        if (!!transaction) {
            switch (step.rail) {
                case "lightrail":
                    return this.renderLightailStep(transaction, step as LightrailTransactionStep);
                case "stripe":
                    return this.renderStripeStep(transaction, step as StripeTransactionStep);
                case "internal":
                    return this.renderInternalStep(transaction, step as InternalTransactionStep);
            }
        }

        return null;
    }

    render(): JSX.Element {
        const transaction = this.getTransaction();

        if (this.props.transactionsServerState.status === 404) {
            return <div>
                <Alert
                    message="Transaction Not Found"
                    description={`Transaction Id '${this.state.transactionId}' not found`}
                    showIcon
                    type="warning"
                    className="detailsNotFoundAlert"
                />
            </div>;
        }

        if (!transaction) {
            return null;
        }

        return (
            <DetailLayout
                headerProps={{
                    avatar: "T",
                    title: (
                        <Row gutter={24}>
                            <Col>
                                <TransactionAmountDisplay transaction={transaction}/>
                            </Col>
                            <Col>
                            <span className="lr-title-postfix">
                                {capitalizeFirstLetter(transaction.transactionType) + " "}
                                <PendingOrSuccessTag pending={transaction.pending}/>
                            </span>
                            </Col>
                        </Row>
                    ),
                    details: [
                        <span key={transaction.id}>Id: <StringWithCopyButton stringToBeCopied={transaction.id}/></span>,
                        <span
                            key={transaction.createdDate}>{formatDate(transaction.createdDate, dateTimeSecondsFormat)}</span>,
                    ],
                    actionButton: (<TransactionActionButtonGroupConnected transaction={transaction}/>)
                }}
            >
                {
                    (transaction.steps.length > 0) &&
                    <ContentSection
                        title="Transaction Steps"
                    >
                        <List
                            dataSource={transaction.steps}
                            renderItem={this.renderStep}
                        />
                    </ContentSection>
                }
                <ContentSection
                    title="Transaction Chain"
                >
                    <TransactionChainTimelineConnected transactionId={transaction.id}/>
                </ContentSection>
                <ContentSection
                    title="Full Transaction"
                >
                    <JsonViewer
                        text={JSON.stringify(transaction, null, 2)}
                        copy
                        copyTitle="Transaction"
                        download
                        downloadName={"Transaction_" + transaction.id}
                    />
                </ContentSection>
            </DetailLayout>
        );
    }
}