import {ActionCreator, Reducer} from "../../Action";
import {assoc} from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {ValueActionsDropDown, ValueActionStatus} from "../../../state/uistate/ValueUiState";

export interface CreateValueDropDownParams {
    valueId: string;
}

export const type = "createValueActions";

export const actionCreator: ActionCreator<CreateValueDropDownParams, CreateValueDropDownParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<CreateValueDropDownParams> = (state, action) => {
    const newValue: ValueActionsDropDown = {
        showFullCodeModalOpen: false,
        changeCodeModalOpen: false,
        transactionModalOpen: false,
        pendingValueAction: ValueActionStatus.NO_ACTION
    };

    return assoc(state,
        ["ui", "value", "actionDropdowns", action.payload?.valueId], newValue
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);