import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {CreateCurrencyForm} from "./CreateCurrencyForm";
import {actionCreator as createCurrency} from "../../../../actions/currency/server/createCurrency";
import {CreateCurrencyResponse} from "lightrail-client/dist/params";
import {CurrencyServerState} from "../../../../state/serverState/CurrencyServerState";

export interface ExternalProps {
    getSubmit?: (submit: () => void) => void;
    onCreateResponse?: (response: CreateCurrencyResponse) => void;
}

interface StateProps {
    currencyServerState: CurrencyServerState;
    userId: string;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    currencyServerState: state.server.currency,
    userId: state.server.user.id,
    ...props
});

const actionCreators = {
    createCurrency
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateCurrencyFormConnected = connector(CreateCurrencyForm);