import * as React from "react";
import {Alert} from "antd";

export interface Props {
    className?: string;
    errors: string[];
}

export class AuthFormErrorItem extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const errors = !!this.props.errors ? this.props.errors.filter(error => !!error) : [];
        if (!errors.length) {
            return <div className={this.props.className}/>;
        }

        const message: React.ReactNode = (
            (errors.length > 1)
                ? <ul style={{margin: 0}}>
                    {
                        errors.map((error, index) => (<li key={index}>{error}</li>))
                    }
                </ul>
                : <span>{errors[0]}</span>
        );

        return (
            <Alert
                className={this.props.className}
                message={message}
                showIcon
                type="error"
            />
        );
    }
}