import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {listIssuances} from "lightrail-client/dist/programs";
import {ListIssuancesParams, ListIssuancesResponse} from "lightrail-client/dist/params";

export interface ListIssuanceActionParams {
    programId: string;
    params?: ListIssuancesParams;
}

export const type = "getIssuances";
export const actionCreator = promisePattern.actionCreator(type, (p: ListIssuanceActionParams) => listIssuances(p.programId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListIssuancesResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "issuance", "list"], payload?.body,
            ["server", "issuance", "links"], payload?.links,
            ["server", "issuance", "pagination"], {maxLimit: payload?.maxLimit, limit: payload?.limit},
            ["server", "issuance", "fetching"], false,
            ["server", "issuance", "error"], null
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "issuance", "list"], [],
        ["server", "issuance", "links"], null,
        ["server", "issuance", "fetching"], true,
        ["server", "issuance", "error"], null);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);