import * as ReactRedux from "react-redux";
import {NewAccountForm} from "./NewAccountForm";
import {State} from "../../../state/State";
import {withRouter} from "react-router-dom";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";
import {actionCreator as listAccounts} from "../../../actions/account/server/listAccounts";
import {
    actionCreator as switchAccounts,
} from "../../../actions/account/server/switchAccounts";
import {
    actionCreator as createAccount,
} from "../../../actions/account/server/createAccount";
import {
    actionCreator as getAccountDetails,
} from "../../../actions/account/server/getAccountDetails";
import {Account} from "../../../communication/dtos/account/Account";

export interface ExternalProps {
    getSubmit: (submit: () => Promise<void>) => void;
    onSubmit?: () => void;
    afterSubmit?: () => void;
}

interface StateProps {
    userMode: string;
    currentAccount: Account;
}

const mapStateToProps = (state: State, externalProps: ExternalProps): ExternalProps & StateProps => ({
    userMode: state.server.user.testing ? UserModes.TEST : UserModes.LIVE,
    currentAccount: state.server.account.accountInfo,
    ...externalProps
});

const actionCreators = {
    createAccount,
    listAccounts,
    switchAccounts,
    getAccountDetails
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const NewAccountFormConnected = connector(
    withRouter(
        NewAccountForm
    )
);