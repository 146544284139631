import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ProgramPerformance} from "./ProgramPerformance";
import {actionCreator as getProgramStats} from "../../../actions/programs/server/getProgramStats";
import {actionCreator as clearProgramStats} from "../../../actions/programs/server/clearProgramStats";
import {Program} from "lightrail-client/dist/model";
import {ProgramsServerState} from "../../../state/serverState/ProgramsServerState";

export interface ExternalProps {
    program: Program;
}

interface StateProps {
    programsState: ProgramsServerState;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    programsState: state.server.programs
});

const actionCreators = {
    getProgramStats,
    clearProgramStats
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ProgramPerformanceConnected = connector(ProgramPerformance);