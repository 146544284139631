import * as React from "react";
import {Paths} from "../../util/routes/routes";
import {WithRouterProps} from "../router/WithRouterProps";
import {ReduxProps} from "./LogoutNavItemConnected";
import { LogoutOutlined } from "@ant-design/icons";

export interface Props extends WithRouterProps<any>, ReduxProps {
}

export class LogoutNavItem extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    async logout(): Promise<void> {
        await this.props.logout({});
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    render(): JSX.Element {
        return (
            <div onClick={this.logout}>
                <LogoutOutlined/>
                <span>{"Logout"}</span>
            </div>
        );
    }
}