import * as promisePattern from "../../../promisePattern";
import * as MFA from "../../../../communication/MFA";
import {EnableResponse} from "../../../../communication/dtos/auth/MFA/Enable";
import * as sprout from "sprout-data";
import {rejectionHandler, RejectionReducer} from "../../../rejectionHandler";

export const type = "enableMFA";

export const actionCreator = promisePattern.actionCreator(type, MFA.enable);

const onFulfilled: promisePattern.PromiseFulfilledHandler<EnableResponse> =
    (state, payload) => {
        //first 3 are setting things back to initial state
        if (payload.complete == false) {
            return sprout.assoc(state,
                ["ui", "MFA", "incorrectCodeMessage"], "",
                ["server", "MFA", "pending"], false,
                ["server", "MFA", "enabled"], false,
                ["ui", "MFA", "MFAComplete"], payload.complete,
                ["ui", "MFA", "CodeOneAccepted"], true,
                ["server", "MFA", "MFAIncompleteMessage"], payload.message,
            );
        } else if (!!payload.complete) {
            return sprout.assoc(state,
                ["ui", "MFA", "isMFAMethodModalOpen"], false,
                ["ui", "MFA", "QRCodeSuccess"], false,
                ["ui", "MFA", "QRCodeModalOpen"], false,
                ["ui", "MFA", "isMFAVerifyOpen"], false,
                ["ui", "MFA", "CodeOneAccepted"], false,
                ["server", "MFA", "pending"], false,
                ["server", "MFA", "enabled"], true,
                ["server", "MFA", "device"], payload?.device
            );
        } else {
            return sprout.assoc(state,
                ["ui", "MFA", "phoneNumberSuccess"], false,
                ["ui", "MFA", "QRCodeSuccess"], false,
                ["ui", "MFA", "QRCodeModalOpen"], false,
                ["ui", "MFA", "isMFAVerifyOpen"], false,
                ["ui", "MFA", "isMFAMethodModalOpen"], false,
                ["server", "MFA", "pending"], false,
                ["server", "MFA", "enabled"], true,
                ["server", "MFA", "device"], payload?.device
            );
        }
    };
const onRejectionHandler: RejectionReducer = {
    reducer: (state: any, error: any) => {
        return sprout.assoc(state,
            ["ui", "MFA", "incorrectCodeMessage"], error.message,
            ["server", "MFA", "pending"], false
        );
    },
    overrideGlobal: true,
};
const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onRejectionHandler]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return sprout.assoc(state,
            ["server", "MFA", "pending"], true
        );
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);