import {State} from "../state/State";
import {MainLayout} from "./MainLayout";
import {actionCreator as ping} from "../actions/auth/server/ping";
import {actionCreator as logout} from "../actions/auth/server/logout";
import {actionCreator as getKVStorageKeys} from "../actions/kvStorage/server/getStorage";
import {actionCreator as updateKVStorageKey} from "../actions/kvStorage/server/updateStorageValue";
import {actionCreator as getSubscriptionTier} from "../actions/account/server/getSubscriptionTier";
import {actionCreator as getJwt} from "../actions/jwt/getJwt";
import {actionCreator as getCurrencies} from "../actions/currency/server/listCurrencies";
import {actionCreator as getUser} from "../actions/auth/server/getUser";
import {actionCreator as setRestError} from "../actions/errors/server/setRestError";
import {actionCreator as uiAction} from "../actions/uiAction";
import {withRouter} from "react-router-dom";
import * as ReactRedux from "react-redux";

interface StateProps {
    testing: boolean;
    loggedIn: boolean;
    additionalAuthenticationRequired: boolean;
    accountMessageCode: string;
    email: string;
}

const mapStateToProps = (state: State): StateProps => ({
    testing: state.server.user.testing,
    loggedIn: state.server.user.loggedIn,
    additionalAuthenticationRequired: state.server.user.additionalAuthenticationRequired,
    accountMessageCode: state.server.account.authMessage?.messageCode,
    email: state.server.user.email
});

const actionCreators = {
    getCurrencies,
    getKVStorageKeys,
    getSubscriptionTier,
    updateKVStorageKey,
    getJwt,
    ping,
    logout,
    getUser,
    uiAction,
    setRestError
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const MainConnected = connector(withRouter(MainLayout));