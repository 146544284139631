import * as React from "react";
import {Button, Divider, Spin} from "antd";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {Paths} from "../../../../util/routes/routes";
import {UpdatePasswordRequest} from "../../../../communication/dtos/auth/UpdatePassword";
import {NewAccountFormConnected} from "../../../toolbars/accountChange/NewAccountFormConnected";
import {UpdatePasswordForm} from "../../../account/profile/UpdatePasswordForm";
import {AccountSelectConnected} from "../../../toolbars/accountChange/AccountSelectConnected";
import {MessageCodes} from "./AuthMessages";
import {MFA} from "../../../account/profile/MFAConnected";
import {ReduxProps} from "./AuthCodeHandlerPageConnected";

export interface Props extends WithRouterProps<any>, ReduxProps {
}

interface InternalState {
    handlerComponentOpen: boolean;
    showSpinner: boolean;
}

interface MessageCodeHandler {
    buttonText: string;
    additionalText?: string;
    buttonHandler?: () => void;
    redirectHandler?: () => void;
    component?: JSX.Element;
}

export class AuthCodeHandlerPage extends React.Component<Props, InternalState> {
    submitUpdatePassword: () => void;
    submitNewAccount: () => Promise<void>;
    messageCodeHandler: MessageCodeHandler;

    constructor(props: Props) {
        super(props);

        this.closeHandlerComponent = this.closeHandlerComponent.bind(this);
        this.getSubmitUpdatePassword = this.getSubmitUpdatePassword.bind(this);
        this.getSubmitNewAccount = this.getSubmitNewAccount.bind(this);
        this.handleSubmitUpdatePassword = this.handleSubmitUpdatePassword.bind(this);
        this.onSubmitNewAccount = this.onSubmitNewAccount.bind(this);
        this.onSubmitUpdatePassword = this.onSubmitUpdatePassword.bind(this);
        this.onCloseMfaModal = this.onCloseMfaModal.bind(this);
        this.afterMfaEnable = this.afterMfaEnable.bind(this);
        this.afterNewAccount = this.afterNewAccount.bind(this);
        this.afterChangeAccount = this.afterChangeAccount.bind(this);
        this.mapMessageCodeToHandler = this.mapMessageCodeToHandler.bind(this);
        this.goToLogin = this.goToLogin.bind(this);

        this.state = {
            handlerComponentOpen: false,
            showSpinner: false
        };

        if (this.props.restError && this.props.messageCode == MessageCodes.NoCode) {
            this.props.logout({});
        }

        this.mapMessageCodeToHandler();
    }

    mapMessageCodeToHandler(): void {
        const messageCodeHandlers: { [key in MessageCodes]: MessageCodeHandler } = {
            [MessageCodes.NoCode]: {
                buttonText: "Continue",
                additionalText: "Something went wrong. Try logging in again.",
                buttonHandler: () => {
                    this.props.logout({});
                    this.props.history.push(Paths.LOGIN_ROOT);
                },
            },

            [MessageCodes.NoAccount]: {
                buttonText: "Continue",
                buttonHandler: () => this.setState({handlerComponentOpen: true}),
                component: (
                    <React.Fragment>
                        <NewAccountFormConnected
                            getSubmit={this.getSubmitNewAccount}
                            onSubmit={this.onSubmitNewAccount}
                            afterSubmit={this.afterNewAccount}
                        />
                        <div className="marginTop8">
                            <Button onClick={this.onSubmitNewAccount} type="primary"> Create</Button>
                            <Button className="marginLeft16" onClick={this.closeHandlerComponent}>Cancel</Button>
                        </div>
                    </React.Fragment>
                ),
            },

            [MessageCodes.AccountMfaRequired]: {
                buttonText: "Enable",
                additionalText: "All users on the account will require Multi-factor Authentication (MFA). Please enable MFA to continue.",
                buttonHandler: () => this.setState({handlerComponentOpen: true}),
                component: (
                    <div>
                        <Divider/>
                        <MFA
                            afterSubmit={this.afterMfaEnable}
                            openByDefault={true}
                            onClose={this.onCloseMfaModal}
                        />
                    </div>
                )
            },

            [MessageCodes.AccountMaxPasswordAge]: {
                buttonText: "Change",
                buttonHandler: () => this.setState({handlerComponentOpen: true}),
                additionalText: "You will be asked to log in again after changing your password.",
                component: (
                    <React.Fragment>
                        <UpdatePasswordForm
                            errors={this.props.authError ? this.props.authError : []}
                            onSubmit={this.onSubmitUpdatePassword}
                            getSubmit={this.getSubmitUpdatePassword}
                        />
                        <Button onClick={this.handleSubmitUpdatePassword} type="primary">Update</Button>
                    </React.Fragment>
                )
            },

            [MessageCodes.AccountMaxInactiveDays]: {
                buttonText: "Continue",
                additionalText: "Please choose another account.",
                buttonHandler: () => this.setState({handlerComponentOpen: true}),
                component: (<AccountSelectConnected afterChange={this.afterChangeAccount}/>)
            },

            [MessageCodes.MfaAuthRequired]: {
                buttonText: "Continue",
                redirectHandler: () => this.props.history.push(Paths.MULTI_FA)
            }
        };

        switch (this.props.messageCode) {
            case ("NoAccount"):
                this.messageCodeHandler = messageCodeHandlers[MessageCodes.NoAccount];
                break;
            case ("AccountMfaRequired"):
                this.messageCodeHandler = messageCodeHandlers[MessageCodes.AccountMfaRequired];
                break;
            case ("AccountMaxPasswordAge"):
                this.messageCodeHandler = messageCodeHandlers[MessageCodes.AccountMaxPasswordAge];
                break;
            case ("AccountMaxInactiveDays"):
                this.messageCodeHandler = messageCodeHandlers[MessageCodes.AccountMaxInactiveDays];
                break;
            case ("MfaAuthRequired"):
                this.messageCodeHandler = messageCodeHandlers[MessageCodes.MfaAuthRequired];
                break;
            default:
                this.messageCodeHandler = messageCodeHandlers[MessageCodes.NoCode];
        }
    }

    componentDidMount(): void {
        if (this.messageCodeHandler.redirectHandler) {
            this.messageCodeHandler.redirectHandler();
        }
    }

    componentDidUpdate(): void {
        this.mapMessageCodeToHandler();
    }

    closeHandlerComponent(): void {
        this.setState({handlerComponentOpen: false});
    }

    getSubmitUpdatePassword(submit: () => void): void {
        this.submitUpdatePassword = submit;
    }

    getSubmitNewAccount(submit: () => Promise<void>): void {
        this.submitNewAccount = submit;
    }

    handleSubmitUpdatePassword(): void {
        this.submitUpdatePassword();
    }

    async onSubmitUpdatePassword(params: UpdatePasswordRequest): Promise<void> {
        this.setState({showSpinner: true});
        try {
            await this.props.updatePassword(params);
            this.props.removeAccountMessage({});
            this.props.logout({});
            this.props.history.push(Paths.LOGIN_ROOT);
        } catch (err) {
            this.setState({showSpinner: false});
        }
    }

    onSubmitNewAccount(): void {
        this.setState({showSpinner: true});
        this.submitNewAccount();
    }

    onCloseMfaModal(): void {
        this.props.logout({});
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    async afterMfaEnable(): Promise<void> {
        await this.props.logout({});
        this.props.removeAccountMessage({});
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    afterNewAccount(): void {
        this.props.removeAccountMessage({});
        this.props.history.push(Paths.ROOT);
    }

    afterChangeAccount(): void {
        this.setState({showSpinner: true});
        this.props.removeAccountMessage({});
        this.props.history.push(Paths.ROOT);
    }

    async goToLogin(): Promise<void> {
        await this.props.logout({});
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    render(): JSX.Element {
        const {message} = this.props;
        const {handlerComponentOpen, showSpinner} = this.state;

        return (
            <React.Fragment>
                <h2>{message}</h2>
                <p className={"marginBottom48"}>{this.messageCodeHandler?.additionalText}</p>
                {
                    handlerComponentOpen ?
                        (showSpinner ? <Spin/> : this.messageCodeHandler?.component) :
                        <div className="marginTop24 lr-align-right">
                            {
                                !!this.props.messageCode &&
                                <Button
                                    onClick={this.goToLogin}
                                >
                                    Back
                                </Button>
                            }
                            <Button
                                onClick={this.messageCodeHandler?.buttonHandler}
                                className="marginLeft16"
                                type="primary"
                            >
                                {this.messageCodeHandler?.buttonText}
                            </Button>
                        </div>
                }
            </React.Fragment>
        );
    }
}