import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {GlobalSearchDropdown} from "./GlobalSearchDropdown";
import {actionCreator as globalSearch} from "../../../actions/search/server/globalSearch";
import {actionCreator as clearSearch} from "../../../actions/search/server/clearSearch";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {withRouter} from "react-router-dom";
import {SearchId, SearchState} from "../../../state/serverState/SearchesServerState";
import {GlobalSearchUiState} from "../../../state/uistate/SearchUiState";

interface StateProps {
    globalSearchStateUI: GlobalSearchUiState;
    globalSearchState: SearchState;
}

const mapStateToProps = (state: State): StateProps => ({
    globalSearchStateUI: state.ui.search.global,
    globalSearchState: state.server.search[SearchId.GLOBAL]
});

const actionCreators = {globalSearch, clearSearch, uiAction};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const GlobalSearchDropdownConnected = connector(withRouter(GlobalSearchDropdown));