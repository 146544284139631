import * as React from "react";
import {CreditCard} from "../../../../communication/dtos/user/payments/CreditCard";
import {Body2} from "../../../ui/typography/TypographyTags";
import {TextColor} from "../../../ui/typography/ColoredText";
import {Button} from "antd";
import {WarningOutlined, CheckCircleOutlined, EditOutlined} from "@ant-design/icons";
import {DeleteCreditCardResponse} from "../../../../communication/dtos/user/payments/DeleteCard";
import {ActionResponse} from "../../../../actions/promisePattern";

// disable eslint for legacy styles
const giftbitColors: any = require("../../../../styles/legacy/_colors.scss"); // eslint-disable-line
const layoutStyles: any = require("./accountPaymentDetails.scss"); // eslint-disable-line

const inlineStyles = {
    table: {borderTop: "none"},
    greenIcon: {color: giftbitColors.green, marginLeft: 8},
    redIcon: {color: giftbitColors.alert, marginLeft: 8},
};

export interface Props {
    card: CreditCard;
    deleteCardConfirmIsOpen: boolean;
    onUpdateClick: () => void;
    setDeleteCardModalIsOpen: (isOpen: boolean) => void;
    deleteCard: (req: {}) => ActionResponse<DeleteCreditCardResponse>;
}

export class AccountPaymentDetails extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
        this.onCancelButton = this.onCancelButton.bind(this);
        this.onSubmitButton = this.onSubmitButton.bind(this);
    }

    toggleDeleteConfirmModal(): void {
        this.props.setDeleteCardModalIsOpen(!this.props.deleteCardConfirmIsOpen);
    }

    //[ ]
    onRequestClose(): void {
        this.toggleDeleteConfirmModal();
    }

    onCancelButton(): void {
        this.onRequestClose();
    }

    onSubmitButton(): void {
        this.onRequestClose();
        this.props.deleteCard({});
    }

    renderExpiryWithStatus(expMonth: number, expYear: number): JSX.Element {
        const rightNow = new Date();
        let icon = <CheckCircleOutlined style={inlineStyles.greenIcon}/>;

        if (rightNow > new Date(expYear, expMonth)) {
            icon = <WarningOutlined style={inlineStyles.redIcon} type="warning"/>;
        }

        return (
            <div className={layoutStyles.statusContainer}>{expMonth + "/" + expYear}{icon}</div>
        );
    }

    render(): JSX.Element {
        const card = this.props.card;
        const cardPrefix = (!!card.brand) ? card.brand + " ending in " : "Ends in ";

        return (
            <div>
                <table style={inlineStyles.table} className={`noHover`}>
                    <thead>
                    <tr>
                        <td><Body2 textColor={TextColor.SECONDARY}>Card</Body2></td>
                        <td><Body2 textColor={TextColor.SECONDARY}>Expiry</Body2></td>
                        <td/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{borderBottom: "none"}}>
                        <td>
                            {cardPrefix + card.last4}
                        </td>
                        <td>
                            {this.renderExpiryWithStatus(card.expMonth, card.expYear)}
                        </td>
                        <td className={`textAlignRight paddingLeft8 paddingRight0 paddingTop8 paddingBottom8`}>
                            <Button size="large" onClick={this.props.onUpdateClick}><EditOutlined/>Update</Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}