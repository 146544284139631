/**
 * Accepts a string of form input in comma separated list and return a number array
 * @param {string}value
 * @returns {number[]}
 */
export const convertFormInputStringToNumberArray = (value: string): number[] => {
    return value.replace(/\s/g, "").split(",").map(Number);
};

/**
 * Accepts a number array and returns a string
 * @param {number[]}value
 * @returns {string}
 */
export const convertNumberArrayToString = (value: number[]): string => {
    return value.join(", ");
};

/**
 * Converts a display value percent to a decimal representation
 * @param {number}value
 * @param {number}precision (defaulting to 4)
 * @returns {number}
 */
export const convertPercentToDecimal = (value: number, precision: number = 4): number => {
    return parseFloat((value / 100).toPrecision(precision));
};

/**
 * Remove all the zeroes from the array
 * @param {number[]}value
 * @returns {number[]}
 */
export const removeExtraZeroes = (value: number[]): number[] => {
    const noZeros = value.filter((value) => value > 0);
    return noZeros.length ? noZeros : null;
};
