import {ActionCreator, Reducer} from "../../Action";
import {assoc} from "sprout-data";
import * as promisePattern from "../../promisePattern";

export const type = "clearValueError";

export const actionCreator: ActionCreator<{}, {}> = () => ({
    type
});

export const reducer: Reducer<{}> = (state) => {
    return assoc(state,
        ["server", "values", "error"], null,
        ["server", "values", "status"], null
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);