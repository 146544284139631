import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {changeValuesCode} from "lightrail-client/dist/values";
import {ChangeValuesCodeParams, ChangeValuesCodeResponse} from "lightrail-client/dist/params";
import {mergeListObject, payloadHasBody} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";
import {State} from "../../../state/State";

export interface ChangeCodeActionParam {
    valueId: string;
    params?: ChangeValuesCodeParams;
}

export const type = "changeValuesCode";
export const actionCreator = promisePattern.actionCreator(type, (p: ChangeCodeActionParam) => changeValuesCode(p.valueId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<ChangeValuesCodeResponse> =
    (state, payload) => {
        const list = (payloadHasBody) ? mergeListObject(state.server.values.list, payload?.body, "id") : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "processing"], false
        );
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "values", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);