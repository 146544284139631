import * as React from "react";
import {useState, useEffect} from "react";
import {Collapse, Form, Input, InputNumber, message, Select} from "antd";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";
import {Currency} from "lightrail-client/dist/model/Currency";
import {Blank_Currency, DefaultCurrencies} from "./DefaultCurrencies";
import {formatCurrency, getCurrentCurrency} from "../../../../util/currencyUtils";
import {EmojiInputItem} from "../../../ui/forms/EmojiInputItem";
import {useForm} from "antd/es/form/Form";
import {ExternalProps, ReduxProps} from "./CreateCurrencyFormConnected";
import {usePrevious} from "../../../../util/hooks";

const CUSTOM_CURRENCY_TYPE = "CUSTOM";

export interface Props extends ExternalProps, ReduxProps {
}

export const CreateCurrencyForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [detailsOpen, setDetailsOpen] = useState(false);
    const [previewCurrency, setPreviewCurrencyValue] = useState(Blank_Currency);

    // code symbol decimalPlaces name
    const [code, setCode] = useState(Blank_Currency.code);
    const [symbol, setSymbol] = useState(Blank_Currency.symbol);
    const [decimalPlaces, setDecimalPlaces] = useState(Blank_Currency.decimalPlaces);
    const [name, setName] = useState(Blank_Currency.name);

    const previousPreviewCurrency = usePrevious(previewCurrency) || Blank_Currency;

    const {getFieldValue} = form;

    const getUnusedDefaults = (): Currency[] => {
        const existingCurrencies = props.currencyServerState.list;
        //Filter out any defaults found in existing currencies
        return DefaultCurrencies.filter(p => !(existingCurrencies.find(q => q.code === p.code)));
    };

    const unusedDefaults = getUnusedDefaults();
    const initialType = (!!unusedDefaults.length) ? unusedDefaults[0].code : CUSTOM_CURRENCY_TYPE;
    const isCustomCurrency = (getFieldValue(FormFieldIds.Type) === CUSTOM_CURRENCY_TYPE);
    const initialCurrency = getCurrentCurrency(initialType, props.currencyServerState);

    ///////////////////
    //[ SUBMIT ]
    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        const newCurrency: Currency = {
            code: values[FormFieldIds.Code],
            name: values[FormFieldIds.Name],
            symbol: values[FormFieldIds.Symbol] || " ",
            decimalPlaces: values[FormFieldIds.DecimalPlaces],
            createdDate: new Date().toString(),
            updatedDate: new Date().toString(),
            createdBy: props.userId
        };

        const response = await props.createCurrency(newCurrency).value;
        message.success(`${newCurrency.code} Created`);

        if (!!props.onCreateResponse) {
            props.onCreateResponse(response);
        }
    };

    ///////////////////
    //[ ACTIONS ]
    const onTypeChanged = (value: string): void => {
        //Fetch Definition
        const currency = getCurrentCurrency(value, props.currencyServerState);

        setSymbol(currency.symbol);
        setDecimalPlaces(currency.decimalPlaces);
        setName(currency.name);
        setCode(currency.code);

        //Set Field Values
        form.setFields([
            {name: FormFieldIds.Code, value: currency.code},
            {name: FormFieldIds.Name, value: currency.name},
            {name: FormFieldIds.Symbol, value: currency.symbol},
            {name: FormFieldIds.DecimalPlaces, value: currency.decimalPlaces}
        ]);
    };

    const onDetailsChange = (): void => {
        setDetailsOpen(!detailsOpen);
    };

    const setPreviewCurrency = (): void => {
        const {getFieldValue} = form;

        const previewChanged = Object.keys(previewCurrency).some(key => (previewCurrency as Record<string, any>)[key] != (previousPreviewCurrency as Record<string, any>) [key]);

        if (!previewChanged) {
            setPreviewCurrencyValue({
                code: getFieldValue(FormFieldIds.Code),
                name: getFieldValue(FormFieldIds.Name),
                decimalPlaces: getFieldValue(FormFieldIds.DecimalPlaces),
                symbol: getFieldValue(FormFieldIds.Symbol),
                createdDate: new Date().toString(),
                updatedDate: new Date().toString(),
                createdBy: props.userId
            });
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    useEffect(() => {
        if (form.getFieldValue(FormFieldIds.Type) === CUSTOM_CURRENCY_TYPE && !detailsOpen) {
            setDetailsOpen(true);
        }
    });

    useEffect(() => {
        setPreviewCurrency();
    }, [name, code, symbol, decimalPlaces]);

    return (
        <Form onFinish={submit} form={form} onChange={() => setPreviewCurrency()}>
            <Form.Item
                label="Currency"
                name={FormFieldIds.Type}
                initialValue={initialType}
            >
                <Select
                    onChange={onTypeChanged}
                >
                    {
                        unusedDefaults.map(p => (
                            <Select.Option key={p.code} value={p.code}>
                                {p.name} ({p.code})
                            </Select.Option>
                        ))
                    }
                    <Select.Option key={CUSTOM_CURRENCY_TYPE} value={CUSTOM_CURRENCY_TYPE}>
                        Custom
                    </Select.Option>
                </Select>
            </Form.Item>
            <Collapse
                bordered={false}
                onChange={onDetailsChange}
                activeKey={detailsOpen ? "details" : ""}
            >
                <Collapse.Panel
                    key="details"
                    header={<h3>Details</h3>}
                    forceRender={true}
                >
                    <Form.Item
                        label="Code"
                        name={FormFieldIds.Code}
                        initialValue={initialCurrency.code}
                        rules={[GenericRequiredRule]}
                    >
                        <Input
                            disabled={!isCustomCurrency}
                            value={code}
                            onChange={(event) => setCode(event.target.value)}
                        />
                    </Form.Item>
                    <EmojiInputItem
                        formItemProps={{label: "Symbol"}}
                        initialValue={initialCurrency.symbol}
                        fieldId={FormFieldIds.Symbol}
                        form={form}
                        value={symbol}
                        onChange={(event) => setSymbol(event.target.value)}
                    />
                    <Form.Item
                        label="Decimal Places"
                        name={FormFieldIds.DecimalPlaces}
                        initialValue={initialCurrency.decimalPlaces}
                        rules={[GenericRequiredRule]}
                    >
                        <InputNumber
                            disabled={!isCustomCurrency}
                            min={0}
                            max={6}
                            onChange={(numPlaces) => setDecimalPlaces(numPlaces as number)}
                            value={decimalPlaces}
                        />
                    </Form.Item>
                    <EmojiInputItem
                        formItemProps={{label: "Name"}}
                        initialValue={initialCurrency.name}
                        rules={[GenericRequiredRule]}
                        fieldId={FormFieldIds.Name}
                        form={form}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </Collapse.Panel>
            </Collapse>
            <div className="lr-code-preview-item">
                <p>Display preview for stored balance 12345: </p>
                <p className="codeDisplay fullCode textCenter width100Perc">
                    {
                        formatCurrency(12345, previewCurrency)
                    }
                    {
                        " " + (previewCurrency.code || "")
                    }
                </p>
            </div>
        </Form>
    );
};