import * as React from "react";
import {Tag} from "antd";
import {badgeGreen, badgeYellow} from "../../util/stringUtils";
import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

interface Props {
    pending: boolean;
}

export class PendingOrSuccessTag extends React.PureComponent <Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            (this.props.pending) ?
                <Tag className="lr-tag" color={badgeYellow}><ClockCircleOutlined /> Pending</Tag> :
                <Tag className="lr-tag" color={badgeGreen}><CheckCircleOutlined /> Success</Tag>
        );
    }
}