import * as React from "react";
import {Program} from "lightrail-client/dist/model";
import {RulePopover} from "../ui/rules/RulePopover";
import {FormattedCurrency} from "../ui/currency/FormattedCurrencyConnected";

export interface Props {
    program: Program;
    notSet?: string;
}

export class ProgramInitialBalanceDisplay extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const program = this.props.program;

        ///////////////////
        //[ BALANCE RULE ]
        if (!!program.balanceRule) {
            return (
                <span>
                    Custom balance rule
                    &nbsp;
                    <RulePopover title="Balance Rule" content={program.balanceRule}/>
                </span>
            );
        }

        ///////////////////
        //[ FIXED BALANCES ]
        if (!!program.fixedInitialBalances && program.fixedInitialBalances.length) {
            return (
                <span>
                    {
                        program.fixedInitialBalances.map((p: number, i: number) => (
                            <FormattedCurrency
                                value={p}
                                code={program.currency}
                                postfix={(program.fixedInitialBalances.length > 1 && i < program.fixedInitialBalances.length - 1) ? ", " : ""}
                                key={program.id}
                            />
                        ))
                    }
                    {program.currency}
                </span>
            );
        }

        ///////////////////
        //[ RANGE ]
        if (typeof program.minInitialBalance === "number" || !!program.maxInitialBalance) {
            const minValue = (!!program.minInitialBalance) ? program.minInitialBalance : 0;
            const maxBalance = (!!program.maxInitialBalance)
                ? <FormattedCurrency value={program.maxInitialBalance} code={program.currency}/>
                : "Unlimited";

            return (
                <span>
                    <FormattedCurrency value={minValue} code={program.currency}/>
                    {" - "}
                    {maxBalance}
                    {" " + program.currency}
                </span>
            );
        }

        ///////////////////
        //[ NOT SET ]
        if (this.props.notSet) {
            return (<span>{this.props.notSet}</span>);
        }

        ///////////////////
        //[ NOTHING! ]
        return null;
    }
}