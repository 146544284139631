import * as React from "react";
import {ReactNode} from "react";
import {Table} from "antd";

export interface StatsTableRow {
    key: string;
    label: ReactNode;
    value: ReactNode;
}

export interface Props {
    loading?: boolean;
    dataSource: StatsTableRow[];
}

export class StatsTable extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const columns = [
            {
                title: "Label",
                dataIndex: "label",
                key: "label"
            },
            {
                title: "Value",
                dataIndex: "value",
                key: "value",
                className: "lr-stats_table__td"
            }
        ];

        return (
            <Table
                loading={this.props.loading}
                className="lr-stats_table"
                columns={columns}
                dataSource={this.props.dataSource}
                showHeader={false}
                pagination={false}
                sortDirections={["ascend", "descend"]}
            />
        );
    }
}