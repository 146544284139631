export const haveFieldErrors = (fieldsError: { [key: string]: any }): boolean => (Object.keys(fieldsError).some(field => !!fieldsError[field]));

export const FormFieldIds = {
    Amount: "amount",
    Notes: "notes",
    Email: "email",
    FirstName: "firstName",
    LastName: "lastName",
    Metadata: "metadata",
    Password: "password",
    PasswordConfirm: "passwordConfirm",
    NewPassword: "newPassword",
    OldPassword: "oldPassword",
    NewEmail: "newEmail",
    Code: "code",
    Device: "device",
    Phone: "phone",
    Country: "country",
    TrustDevice: "trustDevice",
    UserPrivilegeType: "userPrivilegeType",
    Number: "number",
    Cvv: "cvv",
    Exp_month: "exp_month",
    Exp_year: "exp_year",
    Name: "name",
    Address_line1: "address_line1",
    Address_line2: "address_line2",
    Address_city: "address_city",
    Address_state: "address_state",
    Address_country: "address_country",
    Address_zip: "address_zip",
    Currency: "currency",
    Count: "count",
    Balance: "balance",
    BalanceRule: "balanceRule",
    BalanceRuleExplanation: "balanceRuleExplanation",
    StartDate: "startDate",
    EndDate: "endDate",
    DateRange: "dateRange",
    MonthPicker: "monthPicker",
    Prefix: "prefix",
    Suffix: "suffix",
    Length: "length",
    Type: "type",
    Value: "value",
    LimitUsesRemaining: "limitUsesRemaining",
    FixedInitialBalances: "fixedInitialBalances",
    FixedUsesRemaining: "fixedInitialUsesRemaining",
    MinValue: "minInitialBalance",
    MaxValue: "maxInitialBalance",
    Active: "active",
    Program: "program",
    TransactionType: "transactionType",
    Filter: "filter",
    UsesRemaining: "usesRemaining",
    AttachesRemaining: "attachesRemaining",
    DecimalPlaces: "decimalPlaces",
    Symbol: "symbol",
    Discount: "discount",
    DiscountSellerLiabilityType: "discountSellerLiabilityType",
    DiscountSellerLiabilityRule: "discountSellerLiabilityRule",
    DiscountSellerLiabilityExplanation: "discountSellerLiabilityExplanation",
    RedemptionRuleExplanation: "redemptionRuleExplanation",
    RedemptionRule: "redemptionRule",
    Pretax: "pretax",
    ProgramBalanceType: "programBalanceType",
    ProgramValueType: "programValueType",
    Id: "id",
    Url: "url",
    Events: "events",
    Webhook: "webhook",
    LeavingReason: "leavingReason",
    LeavingReasonOther: "leavingReasonOther",
    LeavingReasonMore: "leavingReasonMore",
    MFAMethod: "mfaMethod",
    CurrentAccountName: "currentAccountName",
    NewAccountName: "newAccountName",
    RequireAccountMfa: "requireAccountMfa",
    MaximumPasswordAge: "maximumPasswordAge",
    MaximumInactivity: "maximumInactivity",
    isBasicForm: "isBasicForm",
    isCredit: "isCredit",
    ShowCode: "showCode"
};

export const GenericRequiredRule = {required: true, message: "Required"};

export const FormItemInlineLabelProps = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 17, offset: 1},
    },
};
