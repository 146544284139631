import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {listContacts} from "lightrail-client/dist/contacts";
import {ListContactsResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getAndAppendContacts";
export const actionCreator = promisePattern.actionCreator(type, listContacts);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListContactsResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? [...state.server.contacts.list, ...payload?.body] : state.server.contacts.list;

        return sprout.assoc(state,
            ["server", "contacts", "list"], list,
            ["server", "contacts", "links"], payload?.links,
            ["server", "contacts", "pagination"], {limit: payload?.limit, maxLimit: payload?.maxLimit},
            ["server", "contacts", "fetching"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "contacts"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);