import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ContactActionMenu, ContactMenuContext} from "./ContactActionMenu";
import {actionCreator as setContactActionState} from "../../../actions/contacts/ui/setContactActionState";
import {actionCreator as detach} from "../../../actions/values/server/detachValueFromContact";
import {Contact} from "lightrail-client/dist/model";
import {ContactUiState} from "../../../state/uistate/ContactUiState";

export interface ExternalProps {
    contact: Contact;
    label?: string;
    menuContext?: ContactMenuContext;
}

interface StateProps {
    contactUiState: ContactUiState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    contactUiState: state.ui.contacts,
    ...props
});

const actionCreators = {
    setContactActionState,
    detach
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ContactActionMenuConnected = connector(ContactActionMenu);