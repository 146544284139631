import * as React from "react";

export interface Props {
    width?: string;
    height?: string;
    style?: React.CSSProperties;
    showBackgroundColor?: boolean;
    backgroundColor?: string;
    logoColor?: string;
    onClick?: () => void;
}

export class Logo extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const logoProps: any = {};

        if (this.props.width) {
            logoProps.width = this.props.width;
        }

        if (this.props.height) {
            logoProps.height = this.props.height;
        }

        if (!this.props.width && !this.props.height) {
            logoProps.height = "36px";
            logoProps.width = "18px";
        }

        const backgroundColor = (!!this.props.backgroundColor) ? this.props.backgroundColor : "#FFFFFF";
        const logoColor = (!!this.props.logoColor) ? this.props.logoColor : "#2056F7";

        const inlineStyles: React.CSSProperties = this.props.style || {};
        if (!!this.props.onClick) {
            inlineStyles.cursor = "pointer";
        }

        return (
            <div style={inlineStyles} onClick={this.props.onClick}>
                <svg
                    {...logoProps}
                    viewBox="0 0 181 360"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Bolts" transform="translate(-90.000000, 0.000000)" fill={logoColor}>
                            {!!this.props.showBackgroundColor && <rect id="Bolt" fill={backgroundColor} x="0" y="0" width="18" height="36"/>}
                            <path
                                d="M199.804766,2.91512664 L176.211618,128.316631 C175.963354,129.636192 176.768133,130.919905 178.009141,131.183883 C178.157149,131.215366 178.307721,131.231224 178.458662,131.231224 L268.218348,131.231224 C269.483946,131.231224 270.509915,132.322135 270.509915,133.667842 C270.509915,134.046115 270.427086,134.419193 270.267988,134.757531 L254.2099,168.906597 C253.821727,169.732085 253.028242,170.253527 252.160261,170.253527 L119.790365,170.253527 C118.524768,170.253527 117.498799,169.162616 117.498799,167.816909 C117.498799,167.438636 117.581628,167.065558 117.740726,166.72722 L195.508082,1.34746321 C196.074074,0.143826178 197.450557,-0.344044123 198.582541,0.257774392 C199.506865,0.749190106 200.007486,1.83763673 199.804766,2.91512664 L199.804766,2.91512664 Z M160.705149,357.084873 L184.298297,231.683369 C184.546561,230.363808 183.741782,229.080095 182.500774,228.816117 C182.352766,228.784634 182.202194,228.768776 182.051253,228.768776 L92.2915666,228.768776 C91.0259693,228.768776 90,227.677865 90,226.332158 C90,225.953885 90.0828289,225.580807 90.2419271,225.242469 L106.300015,191.093403 C106.688188,190.267915 107.481673,189.746473 108.349654,189.746473 L240.71955,189.746473 C241.985147,189.746473 243.011116,190.837384 243.011116,192.183091 C243.011116,192.561364 242.928287,192.934442 242.769189,193.27278 L165.001833,358.652537 C164.435841,359.856174 163.059358,360.344044 161.927374,359.742226 C161.00305,359.25081 160.502429,358.162363 160.705149,357.084873 Z"
                                id="LR-Logo">
                                fill={logoColor}
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}