import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ValuesList} from "./ValuesList";
import {actionCreator as getValues} from "../../../actions/values/server/getAndAppendValues";
import {actionCreator as getAttachedValues} from "../../../actions/values/server/getAttachedValues";
import {actionCreator as getValue} from "../../../actions/values/server/getValue";
import {actionCreator as clearValues} from "../../../actions/values/server/clearValues";
import {actionCreator as clearAttachedValues} from "../../../actions/values/server/clearAttachedValues";
import {actionCreator as getContacts} from "../../../actions/contacts/server/getAndMergeContacts";
import {withRouter} from "react-router-dom";
import {ListValuesParams} from "lightrail-client/dist/params";
import {actionCreator as getAndMergePrograms} from "../../../actions/programs/server/getAndMergePrograms";
import {ValueActionMenuContext} from "../valueActions/ValueActionMenu";
import {ValueServerState} from "../../../state/serverState/ValueServerState";
import {ContactsServerState} from "../../../state/serverState/ContactsServerState";
import {ProgramsServerState} from "../../../state/serverState/ProgramsServerState";
import {Program} from "lightrail-client/dist/model";

export interface ExternalProps {
    params?: ListValuesParams;
    paginate?: boolean;
    highlightNewRows?: boolean;
    menuContext?: ValueActionMenuContext;
}

interface StateProps {
    valueState: ValueServerState;
    contactsState: ContactsServerState;
    programsState: ProgramsServerState;
    programs: Program[];
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    valueState: state.server.values,
    contactsState: state.server.contacts,
    programsState: state.server.programs,
    programs: state.server.programs.allList
});

const actionCreators = {
    getContacts,
    getValues,
    getAttachedValues,
    getValue,
    clearValues,
    clearAttachedValues,
    getAndMergePrograms
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

const component = withRouter(ValuesList);
export const ValuesListConnected = connector(component);