import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {listPrograms} from "lightrail-client/dist/programs";
import {ListProgramsParams, ListProgramsResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {State} from "../../../state/State";
import moment = require("moment");

const listInactivePrograms: (params: ListProgramsParams) => Promise<ListProgramsResponse> = (params: ListProgramsParams) => {
    return listPrograms({...params, endDate: {lte: moment.utc(moment.now()).format(), isNull: false}});
};

export const type = "getInactivePrograms";
export const actionCreator = promisePattern.actionCreator(type, (params: ListProgramsParams) => listInactivePrograms(params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListProgramsResponse> =
    (state, payload) => {
        const inactiveList = payloadHasBody(payload) ? [...state.server.programs.inactiveList, ...payload?.body] : state.server.programs.inactiveList;
        return sprout.assoc(state,
            ["server", "programs", "inactiveList"], inactiveList,
            ["server", "programs", "links"], payload?.links,
            ["server", "programs", "pagination"], {maxLimit: payload?.maxLimit, limit: payload?.limit},
            ["server", "programs", "fetchingInactive"], false,
            ["server", "programs", "error"], null
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => sprout.assoc<State>(state,
    ["server", "programs", "fetchingInactive"], true,
    ["server", "programs", "status"], null,
    ["server", "programs", "error"], null,
);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);