import * as React from "react";
import {useState, useEffect} from "react";
import {Form, Input, message, Switch, TreeSelect} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../../../ui/forms/AllowSubmitOnEnter";
import {UpdateWebhookParams} from "../../../../communication/dtos/webhooks/UpdateWebhookParams";
import {InsecureUrlErrorMessage, WebhookEventEnums} from "../create/WebhookEventEnums";
import {UrlRegex} from "../../../../util/regexp";
import {UpdateWebhookActionParams} from "../../../../actions/webhooks/server/updateWebhooks";
import {useForm} from "antd/lib/form/Form";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {ExternalProps, ReduxProps} from "./EditWebhookFormConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export const EditWebhookForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [showError, setShowError] = useState(false);
    const [urlErrors, setUrlError] = useState([]);
    const [url, setUrl] = useState("");

    const webhookEvents = WebhookEventEnums;
    const EventsTreeNodes: JSX.Element[] = Object.keys(webhookEvents)
        .filter(key => isNaN(parseInt(key, 10)))
        .map(
            (key: keyof typeof webhookEvents) => ({
                title: webhookEvents[key],
                value: webhookEvents[key],
                key: webhookEvents[key],
                className: "showMonoFont",
                type: null,
                props: null
            }));

    let initialEvents = props.webhook.events;
    if (initialEvents[0] === "*") {
        initialEvents = ["All"];
    }

    const handleBlur = (): void => setShowError(true);
    const handleFocus = (): void => setShowError(false);

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        let events = values[FormFieldIds.Events];
        for (let i = 0; i < values[FormFieldIds.Events].length; i++) {
            if (values[FormFieldIds.Events][i] === "All") {
                events = ["*"];
            }
        }

        const updatedWebhook: UpdateWebhookParams = {
            url: values[FormFieldIds.Url],
            active: values[FormFieldIds.Active],
            events: events
        };

        const updateParams: UpdateWebhookActionParams = {
            id: props.webhook.id,
            params: updatedWebhook
        };

        try {
            const response = await props.updateWebhook(updateParams);
            message.success(`${updatedWebhook.url} Updated`);
            if (props.onEditResponse) {
                props.onEditResponse(response.value);
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    useEffect(() => {
        const errors = form.getFieldError(FormFieldIds.Url);
        const isTouched = form.isFieldTouched(FormFieldIds.Url);
        setUrlError(isTouched && showError ? errors : []);
    }, [url, showError]);

    return (
        <Form onFinish={submit} form={form}>
            <Form.Item
                label="Active"
                extra="Sets whether the webhook will be active upon creation."
                name={FormFieldIds.Active}
                initialValue={props.webhook.active}
            >
                <Switch
                    defaultChecked={props.webhook.active}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />
            </Form.Item>
            <Form.Item
                label="Webhook Url"
                validateStatus={showError && urlErrors.length ? "error" : null}
                help={showError && urlErrors.length ? InsecureUrlErrorMessage.URL_ERROR : ""}
                name={FormFieldIds.Url}
                rules={[{
                    required: true,
                    pattern: UrlRegex,
                    message: InsecureUrlErrorMessage.URL_ERROR
                }]}
                initialValue={props.webhook.url}
            >
                <Input
                    type="url"
                    placeholder="Url"
                    data-cy="url"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onChange={(event) => setUrl(event.target.value)}
                    value={url}
                />
            </Form.Item>
            <Form.Item
                label="Webhook Events"
                name={FormFieldIds.Events}
                initialValue={initialEvents}
                rules={[
                    {required: true, message: "Please select at least one event"}
                ]}
            >
                <TreeSelect
                    dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                    treeDefaultExpandedKeys={["All"]}
                    treeData={
                        [{
                            title: "All",
                            value: "All",
                            key: "All",
                            children: EventsTreeNodes
                        }]
                    }
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    maxTagCount={10}
                />
            </Form.Item>
            <AllowSubmitOnEnter/>
        </Form>
    );
};