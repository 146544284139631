export const spacing = {
    8: "8px",
    16: "16px",
    24: "24px",
    32: "32px",
    40: "40px",
    48: "48px",
    46: "46px",
    64: "64px",
    72: "72px",
    80: "80px"
};

export const marginTop0 = {
    marginTop: "0"
};
export const marginTop8 = {
    marginTop: "8px"
};
export const marginTop16 = {
    marginTop: "16px"
};
export const marginTop24 = {
    marginTop: "24px"
};
export const marginTop32 = {
    marginTop: "32px"
};
export const marginTop40 = {
    marginTop: "40px"
};
export const marginTop48 = {
    marginTop: "48px"
};
export const marginTop56 = {
    marginTop: "56px"
};
export const marginTop64 = {
    marginTop: "64px"
};
export const marginTop72 = {
    marginTop: "72px"
};
export const marginTop80 = {
    marginTop: "80px"
};

export const marginRight0 = {
    marginRight: "0"
};
export const marginRight8 = {
    marginRight: "8px"
};
export const marginRight16 = {
    marginRight: "16px"
};
export const marginRight24 = {
    marginRight: "24px"
};
export const marginRight32 = {
    marginRight: "32px"
};
export const marginRight40 = {
    marginRight: "40px"
};
export const marginRight48 = {
    marginRight: "48px"
};
export const marginBottom0 = {
    marginBottom: "0"
};
export const marginBottom8 = {
    marginBottom: "8px"
};
export const marginBottom16 = {
    marginBottom: "16px"
};
export const marginBottom24 = {
    marginBottom: "24px"
};
export const marginBottom32 = {
    marginBottom: "32px"
};
export const marginBottom40 = {
    marginBottom: "40px"
};
export const marginBottom48 = {
    marginBottom: "48px"
};
export const marginBottom56 = {
    marginBottom: "56px"
};
export const marginBottom64 = {
    marginBottom: "64px"
};
export const marginBottom72 = {
    marginBottom: "72px"
};
export const marginBottom80 = {
    marginBottom: "80px"
};
export const marginLeft0 = {
    marginLeft: "0"
};
export const marginLeft8 = {
    marginLeft: "8px"
};
export const marginLeft16 = {
    marginLeft: "16px"
};
export const marginLeft24 = {
    marginLeft: "24px"
};
export const marginLeft32 = {
    marginLeft: "32px"
};
export const marginLeft40 = {
    marginLeft: "40px"
};
export const marginLeft48 = {
    marginLeft: "48px"
};
export const Padding_Spacing__padding0 = {
    padding: "0"
};
export const padding8 = {
    padding: "8px"
};
export const padding16 = {
    padding: "16px"
};
export const padding24 = {
    padding: "24px"
};
export const padding32 = {
    padding: "32px"
};
export const padding40 = {
    padding: "40px"
};
export const padding48 = {
    padding: "48px"
};
export const padding56 = {
    padding: "56px"
};
export const padding64 = {
    padding: "64px"
};
export const paddingTop0 = {
    paddingTop: "0"
};
export const paddingTop8 = {
    paddingTop: "8px"
};
export const paddingTop16 = {
    paddingTop: "16px"
};
export const paddingTop24 = {
    paddingTop: "24px"
};
export const paddingTop32 = {
    paddingTop: "32px"
};
export const paddingTop40 = {
    paddingTop: "40px"
};
export const paddingTop48 = {
    paddingTop: "48px"
};
export const paddingTop56 = {
    paddingTop: "56px"
};
export const paddingTop64 = {
    paddingTop: "64px"
};
export const paddingRight0 = {
    paddingRight: "0"
};
export const paddingRight8 = {
    paddingRight: "8px"
};
export const paddingRight16 = {
    paddingRight: "16px"
};
export const paddingRight24 = {
    paddingRight: "24px"
};
export const paddingRight32 = {
    paddingRight: "32px"
};
export const paddingRight40 = {
    paddingRight: "40px"
};
export const paddingRight48 = {
    paddingRight: "48px"
};
export const paddingRight56 = {
    paddingRight: "56px"
};
export const paddingRight64 = {
    paddingRight: "64px"
};
export const paddingBottom0 = {
    paddingBottom: "0"
};
export const paddingBottom8 = {
    paddingBottom: "8px"
};
export const paddingBottom16 = {
    paddingBottom: "16px"
};
export const paddingBottom24 = {
    paddingBottom: "24px"
};
export const paddingBottom32 = {
    paddingBottom: "32px"
};
export const paddingBottom40 = {
    paddingBottom: "40px"
};
export const paddingBottom48 = {
    paddingBottom: "48px"
};
export const paddingBottom56 = {
    paddingBottom: "56px"
};
export const paddingBottom64 = {
    paddingBottom: "64px"
};
export const paddingLeft0 = {
    paddingLeft: "0"
};
export const paddingLeft8 = {
    paddingLeft: "8px"
};
export const paddingLeft16 = {
    paddingLeft: "16px"
};
export const paddingLeft24 = {
    paddingLeft: "24px"
};
export const paddingLeft32 = {
    paddingLeft: "32px"
};
export const paddingLeft40 = {
    paddingLeft: "40px"
};
export const paddingLeft48 = {
    paddingLeft: "48px"
};
export const paddingLeft56 = {
    paddingLeft: "56px"
};
export const paddingLeft64 = {
    paddingLeft: "64px"
};