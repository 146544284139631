import * as React from "react";
import {ReactNode} from "react";
import {Menu} from "antd";

export namespace ActionMenu {
    export interface MenuContext<T, P> {
        type: T;
        data?: P;
    }

    export interface MenuItemProps {
        rootPrefixCls?: string;
        disabled?: boolean;
        level?: number;
        title?: React.ReactNode;
        children?: React.ReactNode;
        className?: string;
    }

    export interface Item<T> {
        type: T;
        label?: ReactNode;
        props?: MenuItemProps;
    }

    export function getMenuItem<T>(item: Item<T>): JSX.Element {
        return (
            <Menu.Item
                key={item.type.toString()}
                {...item.props}
            >
                {item.label}
            </Menu.Item>
        );
    }
}