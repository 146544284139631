import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {getContact} from "lightrail-client/dist/contacts";
import {GetContactResponse} from "lightrail-client/dist/params";
import {mergeListObjectOrUnshift, payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";
import {State} from "../../../state/State";

export const type = "getContact";
export const actionCreator = promisePattern.actionCreator(type, getContact);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetContactResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? mergeListObjectOrUnshift(state.server.contacts.list, payload?.body, "id") : state.server.contacts.list;

        return sprout.assoc(state,
            ["server", "contacts", "list"], list,
            ["server", "contacts", "fetching"], false,
            ["server", "contacts", "status"], payload?.status
        );
    };

const onRejected = rejectionHandler([{
    reducer: (state, error) => sprout.assoc<State>(state,
        ["server", "contacts", "error"], error.message,
        ["server", "contacts", "status"], error.status,
        ["server", "contacts", "fetching"], false,
    )
}]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "contacts"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);