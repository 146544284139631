import * as React from "react";
import {useEffect} from "react";
import {Subheading} from "../ui/typography/TypographyTags";
import {dialogStyles} from "../ui/dialogs/DialogStyles";
import {Button, Col, Form, Input, Row, Tag} from "antd";
import {EnableRequest} from "../../communication/dtos/auth/MFA/Enable";
import {FormFieldIds} from "../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../ui/forms/AllowSubmitOnEnter";
import {TextColor} from "../ui/typography/ColoredText";
import {useForm} from "antd/lib/form/Form";
import {ActionParams as SetNumber} from "../../actions/account/ui/goToDeviceState";
import {ActionCreator} from "../../actions/Action";
import {badgeGreen} from "../../util/stringUtils";
import {CheckCircleOutlined} from "@ant-design/icons/lib";
import {mfaType} from "../../state/serverState/MFAState";

export interface Props {
    codeError: string;
    number: string;
    disableButtons: boolean;
    method?: string;
    isSecondCode?: boolean;
    isFirstCode?: boolean;

    resendCode?: () => void;
    editNumber?: ActionCreator<SetNumber, SetNumber>;

    getSubmitHook: (hook: () => void) => void;
    onSubmit: (data: EnableRequest) => void;
}

export const EnableMFACodeForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        props.onSubmit(values);
        form.resetFields([FormFieldIds.Code]);
    };

    useEffect(() => {
        if (props.getSubmitHook) {
            props.getSubmitHook(submit);
        }
    }, []);

    const renderSubmitCodeForPhone = (): JSX.Element => (
        <Form
            onFinish={submit}
            form={form}
        >
            <div className="bodyDialogClass">
                <div className="column">
                    <Subheading>
                        A code has been sent to {props.number}.
                    </Subheading>
                    <Subheading textColor={TextColor.SECONDARY}>
                        Enter it here to authenticate.
                    </Subheading>
                    <Form.Item
                        name={FormFieldIds.Code}
                        validateStatus={props.codeError ? "error" : null}
                        help={props.codeError ? props.codeError : null}
                        rules={[
                            {required: true, message: "Code Required"}
                        ]}
                    >
                        <Input autoFocus={true} placeholder="Code"/>
                    </Form.Item>
                    <Subheading>
                        Didn&rsquo;t get a code? <a style={dialogStyles.linkStyle}
                                                    onClick={props.resendCode}>Resend</a>
                    </Subheading>
                </div>
            </div>
            <AllowSubmitOnEnter/>
        </Form>
    );

    const renderSubmitCodeForApp = (): JSX.Element => (
        <Form
            onFinish={submit}
            form={form}
        >
            <div className="bodyDialogClass">
                <div className="column">
                    <Subheading className={"marginBottom24"}>
                        Enter two different security codes generated by your mobile authenticator app.
                    </Subheading>
                    <Form.Item
                        validateStatus={props.codeError ? "error" : null}
                        help={props.codeError && props.isFirstCode ? props.codeError : null}
                    >
                        {(props.isFirstCode) && (
                            <Form.Item
                                name={FormFieldIds.Code}
                                rules={[
                                    {required: !props.isFirstCode, message: "Code Required"}
                                ]}
                            >
                                <Row>
                                    <Col span={4}>Code1:</Col>
                                    <Col span={8}>
                                        <Input autoFocus={props.isFirstCode}
                                               placeholder="Code"
                                               disabled={props.isSecondCode}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Button type="primary" className={"marginLeft8"}
                                                onClick={submit}>Verify</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        )}
                        {
                            (props.isSecondCode) && (
                                <Row>
                                    <Col span={4}>Code1:</Col>
                                    <Col span={12}>
                                        <Tag className="lr-tag" color={badgeGreen}>
                                            <CheckCircleOutlined/> Success
                                        </Tag>
                                    </Col>
                                </Row>
                            )
                        }
                    </Form.Item>

                    <Form.Item
                        validateStatus={!!props.codeError && !!props.isSecondCode ? "error" : null}
                        help={!!props.codeError && !!props.isSecondCode ? props.codeError : null}
                    >
                        {(props.isSecondCode) && (
                            <Row>
                                <Col span={4}>Code2:</Col>
                                <Col span={8}>
                                    <Form.Item
                                        name={FormFieldIds.Code}
                                        rules={[
                                            {required: !props.isSecondCode, message: "Code Required"}
                                        ]}
                                    >
                                        <Input
                                            autoFocus={props.isSecondCode}
                                            placeholder="Code"
                                            disabled={props.isFirstCode}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Button type="primary" className={"marginLeft8"}
                                            onClick={submit}>Verify</Button>
                                </Col>
                            </Row>
                        )}
                        {(props.isFirstCode) &&
                        <Row>
                            <Col span={4}>Code2:</Col>
                            <Col span={8}>
                                <Input
                                    autoFocus={props.isSecondCode}
                                    placeholder="Code"
                                    disabled={props.isFirstCode}
                                />
                            </Col>
                            <Col span={4}>
                                <Button
                                    type="primary"
                                    className={"marginLeft8"}
                                    onClick={submit}
                                    disabled={props.isFirstCode}
                                >
                                    Verify
                                </Button>
                            </Col>
                        </Row>
                        }
                    </Form.Item>
                </div>
            </div>
            <AllowSubmitOnEnter/>
        </Form>
    );

    const isPhone = props.method === mfaType.PHONE;

    return isPhone ? renderSubmitCodeForPhone() : renderSubmitCodeForApp();
};