import * as ReactRedux from "react-redux";
import {Contact} from "lightrail-client/dist/model";
import {State} from "../../../state/State";
import {actionCreator as updateContact} from "../../../actions/contacts/server/updateContact";
import {ContactCreateAndEditForm} from "./ContactCreateAndEditForm";
import {actionCreator as createContact} from "../../../actions/contacts/server/createContact";
import {actionCreator as clearContactError} from "../../../actions/contacts/server/clearContactError";
import {ContactsServerState} from "../../../state/serverState/ContactsServerState";

export interface ExternalProps {
    contact?: Contact;
    getSubmit: (submit: () => void) => void;
    onClose: () => void;
}

interface StateProps {
    contactStateServer: ContactsServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    contactStateServer: state.server.contacts,
    ...props
});

const actionCreators = {
    updateContact,
    createContact,
    clearContactError
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ContactCreateAndEditFormConnected = connector(ContactCreateAndEditForm);