import {State} from "../../state/State";
import {actionCreator as getTransactionChain} from "../../actions/transactions/server/getTransactionChain";
import * as ReactRedux from "react-redux";
import {TransactionChainTimeline} from "./TransactionChainTimeline";
import {withRouter} from "react-router-dom";
import { TransactionChainServerState } from "../../state/serverState/TransactionsServerState";

export interface ExternalProps {
    transactionId: string;
}

interface StateProps {
    transactionChainServerState: TransactionChainServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    ...props,
    transactionChainServerState: state.server.transactions.transactionChains,
});

const actionCreators = {getTransactionChain};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const TransactionChainTimelineConnected = connector(withRouter(TransactionChainTimeline));