import * as React from "react";
import {withRouter} from "react-router-dom";
import {WithRouterProps} from "../router/WithRouterProps";
import {ValuesListConnected} from "../values/list/ValuesListConnected";
import {DetailValues} from "../ui/layout/details/DetailValues";
import {Contact} from "lightrail-client/dist/model";
import {Alert, Dropdown, Menu, Spin} from "antd";
import {formatDate} from "../../util/dateUtils";
import {ListValuesParams} from "lightrail-client/dist/params";
import {DetailLayout} from "../layout/details/DetailLayout";
import Button from "antd/es/button/button";
import {ContactEditModal} from "./update/ContactEditModal";
import {ContactAction} from "../../state/uistate/ContactUiState";
import {ContentSection} from "../layout/ContentSection";
import {MetadataDetailValue} from "../ui/layout/details/MetadataDetailValue";
import {ContactCreateValueModalConnected} from "./createValue/ContactCreateValueModalConnected";
import {decodeUrlMatchParam} from "../../util/routes/routes";
import {AttachValueModalConnected} from "./contactActions/attachValueModal/AttachValueModalConnected";
import {MenuInfo} from "rc-menu/es/interface";
import {ValueActionMenuContextType} from "../values/valueActions/ValueActionMenu";
import {StringWithCopyButton} from "../ui/StringWithCopyButton";
import {getColorFromId} from "../../util/avatarColorHelper";
import {PlusCircleOutlined, DownOutlined, UserOutlined} from "@ant-design/icons";
import {EditOutlined} from "@ant-design/icons/lib";
import {ReduxProps} from "./ContactDetailPageConnected";

interface State {
    valueListParams: ListValuesParams;
    contactId: string;
}

interface Props extends WithRouterProps<{ contactId: string }>, ReduxProps {
}

class ContactDetailPageComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const decodedId = decodeUrlMatchParam(this.props.match.params.contactId);

        this.state = {
            valueListParams: {contactId: {eq: decodedId}},
            contactId: decodedId
        };

        this.loadContact = this.loadContact.bind(this);
        this.toggleEditContactModal = this.toggleEditContactModal.bind(this);
        this.toggleCreateValueModal = this.toggleCreateValueModal.bind(this);
        this.toggleAttachValueModal = this.toggleAttachValueModal.bind(this);
        this.onAttachValueMenuClick = this.onAttachValueMenuClick.bind(this);
    }

    componentDidMount(): void {
        this.loadContact();
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.match.params.contactId != this.props.match.params.contactId) {
            this.setState({contactId: decodeUrlMatchParam(this.props.match.params.contactId)});
            this.loadContact();
        }
    }

    loadContact(): void {
        const contact = this.getContact();
        if (!contact) {
            this.props.getContact(this.state.contactId);
        }
    }

    getContact(): Contact {
        return this.props.contactServerState.list.find(c => c.id === this.state.contactId);
    }

    getToggledActionValue(action: ContactAction): ContactAction {
        if (!this.props.contactUiState.actions[this.state.contactId] || this.props.contactUiState.actions[this.state.contactId].currentAction !== action) {
            return action;
        }

        return ContactAction.NONE;
    }

    toggleEditContactModal(): void {
        const action = this.getToggledActionValue(ContactAction.EDIT);

        this.props.setContactActionState({
            contactId: this.state.contactId,
            actionState: {currentAction: action}
        });
    }

    toggleCreateValueModal(): void {
        const action = this.getToggledActionValue(ContactAction.CREATE_VALUE);

        this.props.setContactActionState({
            contactId: this.state.contactId,
            actionState: {currentAction: action}
        });
    }

    toggleAttachValueModal(): void {
        const action = this.getToggledActionValue(ContactAction.ATTACH_VALUE);

        this.props.setContactActionState({
            contactId: this.state.contactId,
            actionState: {currentAction: action}
        });
    }

    onAttachValueMenuClick(p: MenuInfo): void {
        switch (p.key) {
            case ContactAction.ATTACH_VALUE:
                this.toggleAttachValueModal();
                break;
            case ContactAction.CREATE_VALUE:
                this.toggleCreateValueModal();
                break;
        }
    }

    ///////////////////
    //[ RENDER ]
    renderContactNotFound(): JSX.Element {
        return (
            <Alert
                message="Contact Not Found"
                description={`Contact Id '${this.state.contactId}' can't be found`}
                showIcon
                type="warning"
                className="detailsNotFoundAlert"
            />
        );
    }

    render(): JSX.Element {
        const contact = this.getContact();

        if (this.props.contactServerState.status === 404) {
            return this.renderContactNotFound();
        }

        if (!contact) {
            return (<Spin/>);
        }

        const currentAction = (!!this.props.contactUiState.actions[this.state.contactId] && this.props.contactUiState.actions[this.state.contactId].currentAction) ? this.props.contactUiState.actions[this.state.contactId].currentAction : ContactAction.NONE;
        const firstName = contact.firstName != null ? contact.firstName : ""; // don't display "null"
        const lastName = contact.lastName != null ? contact.lastName : "";
        return (
            <DetailLayout
                headerProps={{
                    avatarColor: getColorFromId(contact.id),
                    title: contact.email,
                    avatar: (!!contact.email) ? contact.email[0].toUpperCase() : null,
                    icon: (!contact.email) ? <UserOutlined/> : null,
                    details: [
                        `${firstName} ${lastName}`
                    ],
                    actionButton: (
                        <div>
                            <Dropdown
                                trigger={["click"]}
                                overlay={
                                    <Menu
                                        onClick={this.onAttachValueMenuClick}
                                    >
                                        {
                                            <Menu.Item
                                                key={ContactAction.CREATE_VALUE}
                                            >
                                                <PlusCircleOutlined/>New Value
                                            </Menu.Item>
                                        }
                                        <Menu.Divider/>
                                        <Menu.Item
                                            key={ContactAction.ATTACH_VALUE}
                                        >
                                            <PlusCircleOutlined/>Existing Value
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button>Attach Value <DownOutlined/></Button>
                            </Dropdown>
                            <Button
                                className="marginLeft8"
                                style={{marginLeft: "auto"}}
                                onClick={this.toggleEditContactModal}
                            >
                                <EditOutlined/>
                                Edit
                            </Button>
                        </div>
                    )
                }}
            >
                <ContentSection
                    title="Contact Details"
                >
                    <DetailValues
                        data={[
                            [
                                {
                                    label: "Contact ID",
                                    value: <StringWithCopyButton stringToBeCopied={contact.id}/>
                                },
                                {label: "First Name", value: firstName},
                                {label: "Last Name", value: lastName},
                                {label: "Added on", value: formatDate(contact.createdDate)},
                                {label: "Last Active", value: formatDate(contact.updatedDate)}
                            ]
                        ]}
                    />
                </ContentSection>
                <ContentSection
                    title="Values"
                >
                    <ValuesListConnected
                        params={this.state.valueListParams}
                        paginate={false}
                        highlightNewRows
                        menuContext={{
                            type: ValueActionMenuContextType.CONTACT_DETAIL,
                            data: {contact: this.getContact()}
                        }}
                    />
                </ContentSection>
                <ContentSection
                    title="Metadata"
                >
                    {
                        (!!contact.metadata && !!Object.keys(contact.metadata).length) &&
                        <MetadataDetailValue showTitle={false} metadata={contact.metadata}/>
                    }
                </ContentSection>
                {
                    // Modals
                }
                <ContactEditModal
                    open={currentAction === ContactAction.EDIT}
                    contact={contact}
                    onClose={this.toggleEditContactModal}
                />
                <AttachValueModalConnected
                    open={currentAction === ContactAction.ATTACH_VALUE}
                    contact={contact}
                    onClose={this.toggleAttachValueModal}
                />
                <ContactCreateValueModalConnected
                    open={currentAction === ContactAction.CREATE_VALUE}
                    contact={contact}
                    onClose={this.toggleCreateValueModal}
                />
            </DetailLayout>
        );
    }
}

export const ContactDetailPage = withRouter(ContactDetailPageComponent);