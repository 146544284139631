import * as React from "react";
import {
    ContactCustomerSupportRequest,
    ContactCustomerSupportResponse
} from "../../../communication/dtos/contactCustomerSupport/ContactCustomerSupport";
import {getEnvironmentLabel} from "../../../util/getEnvironment";
import {getCustomerSupportEmail} from "../../../util/communicationUtils";
import {RadioChangeEvent} from "antd/es/radio";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {Alert, Divider, Form, Input, Modal, Radio} from "antd";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {useForm} from "antd/es/form/Form";
import {ActionResponse} from "../../../actions/promisePattern";

export interface Props {
    userEmail: string;
    userId: string;
    contactCustomerSupport: (request: ContactCustomerSupportRequest) => ActionResponse<ContactCustomerSupportResponse>;
    className?: string;
}

export const CypressSelectors = {
    LeavingReasonRadioBase: "leaving-reason-radio-",
    LeavingReasonRadioOther: "leaving-reason-radio-other",
    LeavingReasonTextOther: "leaving-reason-text-other",
    LeavingReasonTextMore: "leaving-reason-text-more",
    CloseAccountModal: "close-account-modal",
    CloseAccountButton: "close-account-button",
    CloseAccountModalOkButtonId: "lr-cy-submit-close-account",
    CloseAccountModalCancelButtonId: "lr-cy-cancel-close-account"
};

export const CloseAccount = (props: Props): JSX.Element => {
    const otherRadioButtonValue = "Other";

    const [form] = useForm();

    const [isAccountClosedMessageShown, setIsAccountClosedMessageShown] = React.useState(false);
    const [isCloseAccountModalOpen, setIsCloseAccountModalOpen] = React.useState(false);
    const [leavingReason, setLeavingReason] = React.useState("");

    const emailCustomerSupport = async (reason: string, moreInfo?: string): Promise<void> => {
        const customerSupportMessage = `
            Reason: ${reason}
            ${moreInfo ? "More information: " + moreInfo : ""}
            Environment: ${getEnvironmentLabel()}
        `;

        await props.contactCustomerSupport({
            customerSupportEmail: getCustomerSupportEmail(),
            subject: "Close Account Request",
            message: customerSupportMessage
        });

        setIsAccountClosedMessageShown(true);
    };

    const onCloseModal = (): void => setIsCloseAccountModalOpen(false);
    const onCloseAccountButton = (): void => setIsCloseAccountModalOpen(true);

    const onRadioSelect = (event: RadioChangeEvent): void => {
        const newReason = event.target.value;
        setLeavingReason(newReason);
        form.setFieldsValue({leavingReason: newReason});
    };

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        let reason = values[FormFieldIds.LeavingReason];
        const moreInfo = values[FormFieldIds.LeavingReasonMore] ? values[FormFieldIds.LeavingReasonMore] : null;

        if (reason == otherRadioButtonValue) {
            reason += ": " + values[FormFieldIds.LeavingReasonOther];
        }

        if (reason) {
            await emailCustomerSupport(reason, moreInfo);
            onCloseModal();
        }
    };

    const renderRadioButtons = (): JSX.Element => {
        const reasonsToLeave = [
            "It's not suitable for me anymore",
            "It's too expensive",
            "I'm switching to another platform",
        ];

        return (
            <Radio.Group
                className="lr-vertical-radio"
                onChange={onRadioSelect}
            >
                {reasonsToLeave.map((reason, idx) => (
                    <Radio
                        key={idx}
                        value={reason}
                        data-cy={CypressSelectors.LeavingReasonRadioBase + idx}
                    >
                        {reason}
                    </Radio>
                ))}
                <Radio
                    key={reasonsToLeave.length}
                    value={otherRadioButtonValue}
                    data-cy={CypressSelectors.LeavingReasonRadioOther}
                >
                    {otherRadioButtonValue}
                </Radio>
            </Radio.Group>
        );
    };

    const renderAccountClosedMessage = (): JSX.Element => {
        return (
            <Alert
                message="Account Closed"
                description="We have received your request for your account to be closed. This can take up to 5 business days. If this was a mistake, please contact hello@lightrail.com so we can keep your account active."
                closable
                className="lr-top-margin"
                type="info"
            />
        );
    };

    return (
        <div data-cy={CypressSelectors.CloseAccountModal} className={props.className}>
            {
                isAccountClosedMessageShown ?
                    renderAccountClosedMessage() :
                    (<a
                        className="lr-text-danger"
                        onClick={onCloseAccountButton}
                        data-cy={CypressSelectors.CloseAccountButton}
                    >
                        Deactivate my Profile
                    </a>)
            }
            <Modal
                title="Close Account"
                visible={isCloseAccountModalOpen}
                onOk={submit}
                okText="Close Account"
                okButtonProps={{"id": CypressSelectors.CloseAccountModalOkButtonId}}
                onCancel={onCloseModal}
                cancelText="Keep Account"
                cancelButtonProps={{"id": CypressSelectors.CloseAccountModalCancelButtonId}}
            >
                <Alert
                    message="Warning"
                    description="Closing your account will remove your access to the web app"
                    type="warning"
                    showIcon
                />
                <Divider/>
                <Form
                    layout="vertical"
                    hideRequiredMark={true}
                    form={form}
                >
                    <Form.Item
                        label="Why are you closing your account?"
                        name={FormFieldIds.LeavingReason}
                        rules={[
                            {required: true, message: "Please select one"}
                        ]}
                    >
                        {renderRadioButtons()}
                        {leavingReason == otherRadioButtonValue &&
                        <Form.Item
                            name={FormFieldIds.LeavingReasonOther}
                        >
                            <div>
                                <Input.TextArea
                                    rows={2}
                                    data-cy={CypressSelectors.LeavingReasonTextOther}
                                />
                            </div>
                        </Form.Item>
                        }
                    </Form.Item>

                    <Form.Item
                        label="Please tell us more so we can improve"
                        name={FormFieldIds.LeavingReasonMore}
                    >
                        <Input.TextArea
                            rows={4}
                            data-cy={CypressSelectors.LeavingReasonTextMore}
                        />
                    </Form.Item>
                    <AllowSubmitOnEnter/>
                </Form>
            </Modal>
        </div>
    );
};