import {GetTurnkeyStripeResponse} from "./dtos/turnkey/GetTurnkeyStripe";
import {ConnectTurnkeyStripeResponse} from "./dtos/turnkey/ConnectTurnkeyStripe";
import {fetchJson} from "./utils/restAccess";
import {DeliverGiftCardRequest, DeliverGiftCardResponse} from "./dtos/turnkey/SendGiftCard";
import {cleanNulls} from "../util/communicationUtils";

export const getStripe = (): Promise<GetTurnkeyStripeResponse> => fetchJson("/v1/turnkey/stripe");

export const connectStripe = (): Promise<ConnectTurnkeyStripeResponse> => fetchJson("/v1/turnkey/stripe", {}, {
    method: "POST"
});

export const deleteStripe = (): Promise<ConnectTurnkeyStripeResponse> => fetchJson("/v1/turnkey/stripe", {}, {
    method: "DELETE"
});

export const sendGiftCard = (params: DeliverGiftCardRequest): Promise<DeliverGiftCardResponse> => fetchJson("/v1/turnkey/giftcard/deliver", {}, {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(cleanNulls(params))
});