import * as React from "react";
import {Body1} from "../ui/typography/TypographyTags";
import {AccountUserInvitees} from "../../communication/dtos/account/User";
import {formatDate} from "../../util/dateUtils";
import {Button, Popconfirm, Tooltip} from "antd";
import {MailOutlined, DeleteOutlined} from "@ant-design/icons";
import "./teams.scss";

export interface Props {
    accountUserInvitee: AccountUserInvitees;

    onDelete: (userId: string) => void;
    onResendInvite: (value: { email: string }) => void;
}

export class InvitedAccountUserRow extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
        this.onResendInvite = this.onResendInvite.bind(this);
    }

    onDelete(): void {
        this.props.onDelete(this.props.accountUserInvitee.userId);
    }

    onResendInvite(): void {
        this.props.onResendInvite({
            email: this.props.accountUserInvitee.email
        });
    }

    render(): JSX.Element {
        return (
            <div className={"memberRow"} key={this.props.accountUserInvitee.userId}>
                <div>{this.props.accountUserInvitee.email}</div>
                <Body1 className="marginBottom0">
                    <span
                        className="inviteLabel">Invite Expiry:
                    </span>
                    {this.props.accountUserInvitee.expiresDate ? formatDate(this.props.accountUserInvitee.expiresDate) : ""}
                </Body1>
                <div className={"lastRowItem"}>
                    <Button.Group>
                        <Tooltip
                            title="Resend"
                            placement="left"
                        >
                            <Button size="large" onClick={this.onResendInvite}>
                                <MailOutlined/>
                            </Button>
                        </Tooltip>
                        <Popconfirm title="Are you sure?" okText="Delete" cancelText="No" onConfirm={this.onDelete}>
                            <Tooltip
                                title="Delete"
                                placement="left"
                            >
                                <Button size="large">
                                    <DeleteOutlined/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                </div>
            </div>
        );
    }
}