import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {listContacts} from "lightrail-client/dist/contacts";
import {ListContactsResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getAndMergeContacts";
export const actionCreator = promisePattern.actionCreator(type, listContacts);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListContactsResponse> =
    (state, payload) => {
        let list = state.server.contacts.list;

        if (payloadHasBody(payload)) {
            const newList = payload?.body;
            list = state.server.contacts.list.concat();

            newList.forEach(incoming => {
                const existingIndex = list.findIndex(existing => existing.id === incoming.id);
                if (existingIndex !== -1) {
                    list.splice(existingIndex, 1, incoming);
                } else {
                    list.push(incoming);
                }
            });
        }

        return sprout.assoc(state,
            ["server", "contacts", "list"], list,
            ["server", "contacts", "links"], payload?.links,
            ["server", "contacts", "fetching"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "contacts"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);