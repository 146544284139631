import * as sprout from "sprout-data";
import {State} from "../state/State";

/**
 * Reset base server values and set processing to true
 * @param {State} state
 * @param {string[]} path
 * @returns {State}
 */
export const onPendingProcessing = (state: object, path: string[]): State => {
    return sprout.assoc(state,
        [...path, "processing"], true,
        [...path, "status"], null,
        [...path, "error"], null,
    );
};

/**
 * Reset base server values and set fetching to true
 * @param {State} state
 * @param {string[]} path ie: ["server", "values"]
 * @returns {State}
 */
export const onPendingFetching = (state: object, path: string[]): State => {
    return sprout.assoc(state,
        [...path, "fetching"], true,
        [...path, "status"], null,
        [...path, "error"], null,
    );
};

export const nullValues = (state: object, path: string[], list: string[]): State => {
    let newState = state;
    list.forEach(key => {
        newState = sprout.assoc(newState, [...path, key], null);
    });

    return newState;
};