import * as React from "react";
import {Checkbox, Form, InputNumber} from "antd";
import {FormInstance} from "antd/es/form";

export interface State {
    limitAttachesChecked: boolean;

}

export interface Props {
    fieldId: string;
    form: FormInstance;
}

export class LimitAttachesItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            limitAttachesChecked: false
        };

        this.onLimitAttachesCheckboxChange = this.onLimitAttachesCheckboxChange.bind(this);
        this.validateAttachesRemaining = this.validateAttachesRemaining.bind(this);
    }

    onLimitAttachesCheckboxChange(): void {
        this.props.form.setFieldsValue({[this.props.fieldId]: null});
        this.setState({limitAttachesChecked: !this.state.limitAttachesChecked});
    }

    validateAttachesRemaining(rule: any, value: any, callback: any): void {
        if (this.state.limitAttachesChecked) {
            if (typeof value === "string" || value < 1 || value == undefined) {
                callback(`Must be at least 1.`);
            }
        }
        callback();
    }

    render(): JSX.Element {
        return (
            <div>
                <p>
                    <Checkbox
                        checked={this.state.limitAttachesChecked}
                        onChange={this.onLimitAttachesCheckboxChange}
                    >
                        Limit Attaches
                    </Checkbox>
                </p>
                <Form.Item
                    extra={
                        <span>The number of contacts that can attach this code.</span>
                    }
                    style={{display: this.state.limitAttachesChecked ? "" : "none"}}
                    name={this.props.fieldId}
                    initialValue={null}
                    rules={[{validator: this.validateAttachesRemaining}]}
                >
                    <InputNumber
                        size="large"
                        min={1}
                        precision={0}
                    />
                </Form.Item>
            </div>
        );
    }
}