import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {ForgotPasswordPage} from "./ForgotPasswordPage";
import {actionCreator as resendPassword} from "../../../../actions/auth/server/forgotPassword";
import {actionCreator as resetAuthErrorAndSuccessStates} from "../../../../actions/auth/server/resetAuthErrorAndSuccessStates";
import {AuthState} from "../../../../state/serverState/AuthServerState";

interface StateProps {
    auth: AuthState;
}

const mapStateToProps = (state: State): StateProps => ({
    auth: state.server.auth
});

const actionCreators = {
    resendPassword,
    resetAuthErrorAndSuccessStates
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ForgotPasswordPageConnected = connector(ForgotPasswordPage);