import {SubscriptionState} from "../state/serverState/User";

export const emptyArray: any = [];

export const arrayRandVal = <T>(myArray: T[]): T => {
    return myArray[Math.floor(Math.random() * myArray.length)];
};

export const arrayRandIndex = (myArray: any[]): number => {
    return Math.floor(Math.random() * myArray.length);
};

export type KeyValObj = {key: any, value: any};

export const objToKeyValArray = (obj: any): KeyValObj[] => {
    const keys: string[] = Object.keys(obj);
    return keys.map((key: string) => ({key: key, value: obj[key]}));
};

export const isEmpty = (value: any): boolean => (value == null);

export const validateObjProps = (obj: any, expectedProps: string[]): boolean => expectedProps.every(key => !!obj[key]);

/**
 * @todo Look at usage and switch to newLIst.unshift if it makes sense (most lists are in cronological order to new object should prob be added to the start)
 * Looks for a match based on the test key in an array and replaces the object with the new one, otherwise just pushes the new on onto the end
 * @param {Array<T>} list
 * @param {T} value
 * @param {string} testKey
 * @returns {Array<T>}
 */
export function mergeListObject<T>(list: Array<T>, value: T, testKey: string): Array<T> {
    const existingIndex = list.findIndex(p => (value as any)[testKey] === (p  as any)[testKey]);
    const newList = list.concat();
    if (existingIndex !== -1) {
        newList.splice(existingIndex, 1, value);
    } else {
        newList.push(value);
    }

    return newList;
}

/**
 * Looks for a match based on the test key in an array and replaces the object with the new one, otherwise unshifts the new on to the start
 * @param {Array<T>} list
 * @param {T} value
 * @param {string} testKey
 * @returns {Array<T>}
 */
export function mergeListObjectOrUnshift<T>(list: Array<T>, value: T, testKey: string): Array<T> {
    const existingIndex = list.findIndex(p => (value as any)[testKey] === (p  as any)[testKey]);
    const newList = list.concat();
    if (existingIndex !== -1) {
        newList.splice(existingIndex, 1, value);
    } else {
        newList.unshift(value);
    }

    return newList;
}

/**
 *  Looks for a match based on the test key in an array and replaces the object with the new one, otherwise just pushes the new on onto the end
 * @param {Array<T>} list
 * @param {string} match
 * @param {string} testKey
 * @returns {Array<T>}
 */
export function deleteListObject<T>(list: Array<T>, match: string, testKey: string): Array<T> {
    const existingIndex = list.findIndex(p => (p  as any)[testKey] === match);
    const newList = list.concat();
    if (existingIndex !== -1) {
        newList.splice(existingIndex, 1);
        return newList;
    }

    return list;
}

export const payloadHasBody = (payload: any): boolean => (!!payload && !!payload?.body);

////////////////////////
// [ USER DATA ]
export const hasNoSubscription = (state: SubscriptionState): boolean => {
    return (!!state.fetchCount && !state.subscriptionTier);
};

/**
 * Returns true if input is either object that can be JSON.stringify'd or a valid JSON string.
 */
export function isJSON(input: any): boolean {
    return tryParseJSON(input) != null;
}

/**
 * Returns object or null if invalid json string or object is passed in.
 */
export function tryParseJSON(input: any): Record<string, any> {
    try {
        if (typeof input == "object") {
            input = JSON.stringify(input);
        }
        const o = JSON.parse(input);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {
        // not valid json
    }
    return null;
}