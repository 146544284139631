import * as React from "react";
import {useEffect} from "react";
import {Form, Input, InputNumber, message} from "antd";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";
import {Currency} from "lightrail-client/dist/model/Currency";
import {UpdateCurrencyActionParams} from "../../../../actions/currency/server/updateCurrency";
import {AllowSubmitOnEnter} from "../../../ui/forms/AllowSubmitOnEnter";
import {useForm} from "antd/lib/form/Form";
import {ExternalProps, ReduxProps} from "./EditCurrencyFormConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export const EditCurrencyForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        const getResponse = await props.getCurrency(props.currency);
        const currentCurrency = getResponse.value;

        const updatedCurrency: Currency = {
            code: props.currency.code,
            name: values[FormFieldIds.Name],
            symbol: values[FormFieldIds.Symbol] || " ",
            decimalPlaces: props.currency.decimalPlaces,
            createdDate: currentCurrency.body.createdDate,
            createdBy: currentCurrency.body.createdBy,
            updatedDate: new Date().toString()
        };

        const updateParams: UpdateCurrencyActionParams = {
            code: props.currency.code,
            params: updatedCurrency
        };

        const updateResponse = await props.updateCurrency(updateParams);
        message.success(`${updatedCurrency.code} Updated`);

        if (props.onEditResponse) {
            props.onEditResponse(updateResponse.value);
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    });

    return (
        <Form onFinish={submit} form={form}>
            <Form.Item
                label="Code"
                name={FormFieldIds.Code}
                initialValue={props.currency.code}
                rules={[GenericRequiredRule]}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="Symbol"
                name={FormFieldIds.Symbol}
                initialValue={props.currency.symbol}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Decimal Places"
                name={FormFieldIds.DecimalPlaces}
                initialValue={props.currency.decimalPlaces}
                rules={[GenericRequiredRule]}
            >
                <InputNumber disabled/>
            </Form.Item>
            <Form.Item
                label="Name"
                name={FormFieldIds.Name}
                initialValue={props.currency.name}
                rules={[GenericRequiredRule]}
            >
                <Input/>
            </Form.Item>
            <AllowSubmitOnEnter/>
        </Form>
    );
};