import * as promisePattern from "../../promisePattern";
import * as userInformation from "../../../communication/userInformation";
import {GetIntercomUserHashResponse} from "../../../communication/dtos/userInformation/GetIntercomUserHash";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import * as sprout from "sprout-data";
import {State} from "../../../state/State";

export const type = "getIntercomUserHash";

export const actionCreator = promisePattern.actionCreator(type, userInformation.getIntercomUserHash);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetIntercomUserHashResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "intercom", "userHash"], payload?.userHash,
            ["server", "intercom", "userId"], payload?.userId,
            ["server", "intercom", "processing"], false,
            ["server", "intercom", "hasUpdated"], true
        );
    };
const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return sprout.assoc(state,
            ["server", "intercom", "processing"], true
        );
    };

const onIntercomRejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            intercom: {
                error: error.message
            }
        }
    }),
    overrideGlobal: true
};

const onRejected = rejectionHandler([onIntercomRejected]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);