import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {AccountPaymentSection} from "./AccountPaymentSection";

import {actionCreator as setStripeModalOpen} from "../../../actions/account/ui/setStripeModalOpen";
import {actionCreator as setDeleteCardConfirmIsOpen} from "../../../actions/account/ui/setDeleteCardConfirmIsOpen";
import {actionCreator as setCreateTokenState} from "../../../actions/account/server/createToken";
import {actionCreator as setSuccessModalIsOpen} from "../../../actions/account/ui/setSuccessModalIsOpen";
import {actionCreator as setRestError} from "../../../actions/errors/server/setRestError";

import {actionCreator as addCreditCard} from "../../../actions/account/server/addCreditCard";
import {actionCreator as getCreditCard} from "../../../actions/account/server/getCreditCard";
import {actionCreator as deleteCreditCard} from "../../../actions/account/server/deleteCreditCard";

import {actionCreator as getSubscriptionTier} from "../../../actions/account/server/getSubscriptionTier";
import {PaymentUIState} from "../../../state/uistate/AccountUiState";
import {PaymentState} from "../../../state/serverState/User";
import {RestError} from "../../../state/serverState/RestError";

export interface ExternalProps {
    className?: string;
}

interface StateProps {
    uiState: PaymentUIState;
    serverState: PaymentState;
    restError: RestError;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    uiState: state.ui.account.payment,
    serverState: state.server.user.payment,
    restError: state.server.restError,
    ...ownProps
});

const actionCreators = {
    setStripeModalOpen,
    setCreateTokenState,
    setDeleteCardConfirmIsOpen,
    setSuccessModalIsOpen,
    addCard: addCreditCard,
    deleteCard: deleteCreditCard,
    getCard: getCreditCard,
    getSubscriptionTier,
    setRestError,
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

const AccountPaymentSection_Connected = connector(AccountPaymentSection);

export {AccountPaymentSection_Connected as AccountPaymentSection};