import * as React from "react";
import {ellipsis, filterLightrailNamespace, getReadableKey, truncate} from "../../../../util/stringUtils";
import {formatDate} from "../../../../util/dateUtils";
import {
    getAttachesRemainingForDisplay,
    getFormattedValueCode,
    isGenericCodeWithPerContactProperties
} from "../../../../util/valueUtils";
import {Value} from "lightrail-client/dist/model";
import {RulePopover} from "../../../ui/rules/RulePopover";
import {DetailValues} from "../../../ui/layout/details/DetailValues";
import {Divider} from "antd";
import {StringWithCopyButton} from "../../../ui/StringWithCopyButton";
import {FormattedCurrency} from "../../../ui/currency/FormattedCurrencyConnected";
import {Link} from "react-router-dom";
import {stopPropagation} from "../../../../util/link";
import {formatRoute, Paths} from "../../../../util/routes/routes";

export interface Props {
    value: Value;
    attachedValue?: Value;
}

export class ValueDetailsList extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        const value = this.props.value;
        const attachedValue = this.props.attachedValue;

        let valueTypeValue;
        if (value.isGenericCode) {
            valueTypeValue = "Generic Code";
        } else if (attachedValue) {
            valueTypeValue = "Attached Generic";
        } else if (!!value.code) {
            valueTypeValue = "Unique Code";
        } else {
            valueTypeValue = "Unique";
        }

        let codeLabel;
        let codeValue;
        if (attachedValue) {
            codeValue =
                <Link onClick={stopPropagation}
                      to={formatRoute(Paths.VALUE_DETAIL, {valueId: attachedValue.id})}>{attachedValue.code}</Link>;
            codeLabel = "Parent Code";
        } else {
            codeLabel = "Code";
            codeValue = getFormattedValueCode(value);
        }

        const firstColValues: ({ label: string, value: JSX.Element | string | number })[] = [
            {
                label: "Value ID",
                value: <StringWithCopyButton stringToBeCopied={value.id}/>
            },
            {
                label: codeLabel,
                value: codeValue
            },
            {
                label: "Type",
                value: valueTypeValue
            },
            {
                label: "Discount",
                value: value.discount ? "Yes" : "No"
            },
            {
                label: "Pretax",
                value: value.pretax ? "Yes" : "No"
            },
        ];

        if (!!value.discountSellerLiabilityRule) {
            firstColValues.push({
                label: "Discount Seller Liability",
                value: (
                    <span>
                        {value.discountSellerLiabilityRule.explanation}&nbsp;
                        <RulePopover
                            title="Discount Seller Liability Rule"
                            content={value.discountSellerLiabilityRule}
                        />
                    </span>
                ),
            });
        }

        if (value.usesRemaining != null && !isGenericCodeWithPerContactProperties(value)) {
            firstColValues.push({
                label: "Uses Remaining",
                value: value.usesRemaining || "Not set (unlimited)"
            });
        }

        if (value.isGenericCode) {
            if (isGenericCodeWithPerContactProperties(value) && value.genericCodeOptions.perContact.usesRemaining) {
                firstColValues.push({
                    label: "Uses Per Contact",
                    value: value.genericCodeOptions.perContact.usesRemaining
                });
            }

            if (isGenericCodeWithPerContactProperties(value) && value.genericCodeOptions.perContact.balance) {
                firstColValues.push({
                    label: "Balance Per Contact",
                    value: <FormattedCurrency
                        value={value.genericCodeOptions.perContact.balance}
                        code={value.currency}
                    />
                });
            }

            firstColValues.push({
                label: "Attaches Remaining",
                value: getAttachesRemainingForDisplay(value)
            });
        }

        if (!!value.redemptionRule) {
            firstColValues.push({
                label: "Redemption Rules",
                value: (
                    <span>
                        {value.redemptionRule.explanation}&nbsp;
                        <RulePopover
                            title="Redemption Rule"
                            content={value.redemptionRule}
                        />
                    </span>
                )
            });
        }

        const secondColValues: ({ label: string, value: JSX.Element | string | number })[] = [
            {label: "Created On", value: formatDate(value.createdDate)},
            {label: "Active from", value: value.startDate ? formatDate(value.startDate) : "Not set"},
            {label: "Expires On", value: value.endDate ? formatDate(value.endDate) : "Not set"},
            {label: "Last Transacted On", value: formatDate(value.updatedDate)}
        ];
        if (!!attachedValue) {
            secondColValues.push({
                label: "Attached From",
                value: <Link onClick={stopPropagation}
                             to={formatRoute(Paths.VALUE_DETAIL, {valueId: attachedValue.id})}>{truncate(attachedValue.id, 25, ellipsis)}</Link>
            });
        }

        return (
            <div>
                <DetailValues
                    title="Value Details"
                    data={[
                        firstColValues, secondColValues
                    ]}
                    jsonPopover={
                        {
                            title: "Value Details",
                            data: value
                        }
                    }
                />
                {
                    (!!value.metadata && !!Object.keys(value.metadata).length) &&
                    <DetailValues
                        title="Metadata"
                        className="lr-detailValues-marginTop"
                        data={[
                            Object.keys(value.metadata).map(key => {
                                const mdValue = (value.metadata as { [key: string]: any })[key];

                                return ({
                                    label: getReadableKey(filterLightrailNamespace(key)),
                                    value: (typeof mdValue != "string") ? JSON.stringify(mdValue) : mdValue
                                });
                            })
                        ]}/>
                }
                <Divider/>
            </div>
        );
    }
}