import * as React from "react";
import {Modal, Spin} from "antd";
import {Value} from "lightrail-client/dist/model";
import {ModifyDatesFormConnected} from "./ModifyDatesFormConnected";

export interface Props {
    open: boolean;
    fetching: boolean;
    value: Value;

    onCancel: () => void;
}

export class ModifyDatesModal extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);
        this.setSubmit = this.setSubmit.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onOk(): void {
        if (!!this.submitForm) {
            this.submitForm();
        }
    }

    setSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    onCancel(): void {
        this.props.onCancel();
    }

    render(): JSX.Element {
        const label = "Modify Dates";
        return (
            <Modal
                title={label}
                visible={this.props.open}
                okText={label}
                onOk={this.onOk}
                onCancel={this.props.onCancel}
                destroyOnClose
            >
                <div>
                    {
                        (!!this.props.fetching)
                            ?
                            <Spin size={"small"} style={{margin: "16px auto", padding: "16px"}}/>
                            :
                            <ModifyDatesFormConnected
                                valueId={this.props.value.id}
                                getSubmit={this.setSubmit}
                                initialStartDate={this.props.value.startDate}
                                initialEndDate={this.props.value.endDate}
                                onUpdateValueResponse={this.onCancel}
                            />
                    }
                </div>
            </Modal>
        );
    }
}