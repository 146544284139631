import * as React from "react";
import {CreateToken} from "../../../../state/serverState/User";
import {CreditCardForm} from "../creditCardForm/CreditCardForm";
import {StripeModalUIState} from "../../../../state/uistate/AccountUiState";
import {ActionCreatorArgs as SetIsOpenArgs} from "../../../../actions/account/ui/setStripeModalOpen";
import {ActionCreatorArgs as SetTokenArgs} from "../../../../actions/account/server/createToken";
import {AddCreditCardRequest} from "../../../../communication/dtos/user/payments/AddCard";
import {CreditCard} from "../../../../communication/dtos/user/payments/CreditCard";
import {isProductionEnvironment} from "../../../../util/getEnvironment";
import {Modal} from "antd";
import StripeTokenResponse = stripe.StripeTokenResponse;
import {reloadPage} from "../../../../util/navigation";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {withRouter} from "react-router-dom";

const stripeKeyLive = process.env["STRIPE_KEY_LIVE"];
const stripeKeyTest = process.env["STRIPE_KEY_TEST"];

export interface Props extends WithRouterProps<any> {
    uiState: StripeModalUIState;
    createTokenState: CreateToken;
    cardDetails: CreditCard;
    processingCard: boolean;
    addressError: string;

    setIsOpen: (args: SetIsOpenArgs) => void;
    setCreateTokenState: (args: SetTokenArgs) => void;
    addCard: (args: AddCreditCardRequest) => void;
}

//TODO [DO] Apply Formatting from ui Kit
export class StripeModalUnconnected extends React.PureComponent<Props, {}> {
    creditCardFormSubmit: () => void;

    constructor(props: Props) {
        super(props);

        const stripeKey = isProductionEnvironment() ? stripeKeyLive : stripeKeyTest;
        Stripe.setPublishableKey(stripeKey);

        this.onStripeResponse = this.onStripeResponse.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.getCCFormSubmit = this.getCCFormSubmit.bind(this);
        this.onCancelButton = this.onCancelButton.bind(this);
        this.onSubmitButton = this.onSubmitButton.bind(this);
    }

    componentDidMount(): void {
        this.props.setCreateTokenState({});
    }

    getCCFormSubmit(submit: () => void): void {
        this.creditCardFormSubmit = submit;
    }

    //[ STRIPE RESPONSE HANDLING ]
    formatStripeError(message: string): string {
        return message ? message : "There was an error with your card details.";
    }

    onStripeResponse(status: number, response: StripeTokenResponse): void {
        let errorMessage = "";

        if (status === 200) {
            this.props.addCard({cardToken: response.id});
            this.onRequestClose(true);
        } else if (!!response.error) {
            errorMessage = this.formatStripeError(response.error.message);
        }

        this.props.setCreateTokenState({response: response, error: errorMessage});

        reloadPage(this.props.history);
    }

    //[ ]
    onRequestClose(buttonClicked: boolean): void {
        if (buttonClicked) {
            this.props.setCreateTokenState({error: ""});
            this.props.setIsOpen({isOpen: false});
        }
    }

    onSubmit(data: any): void {
        this.props.setCreateTokenState({processing: true});

        Stripe.card.createToken({
            number: data.number.trim(),
            cvc: data.cvv,
            exp_month: data.exp_month,
            exp_year: data.exp_year,
            address_zip: data.address_zip,
            name: data.name,
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            address_city: data.address_city,
            address_state: data.address_state,
            address_country: data.address_country
        }, this.onStripeResponse);
    }

    onCancelButton(): void {
        this.onRequestClose(true);
    }

    onSubmitButton(): void {
        this.creditCardFormSubmit();
    }

    render(): JSX.Element {
        return (
            <Modal
                title="Payment Details"
                visible={this.props.uiState.isOpen}
                confirmLoading={this.props.createTokenState.processing}
                onCancel={this.onCancelButton}
                okText="Submit"
                onOk={this.onSubmitButton}
            >
                <CreditCardForm
                    cardDetails={this.props.cardDetails}
                    cardErrorMessage={this.props.createTokenState.error}
                    addressErrorMessage={this.props.addressError}
                    getSubmit={this.getCCFormSubmit}
                    onSubmit={this.onSubmit}
                />
            </Modal>
        );
    }
}

export const StripeModal = withRouter(StripeModalUnconnected);