import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    isOpen: boolean;
}

export interface ActionCreatorArgs {
    isOpen: boolean;
}
export const type = "setDeleteCardModalIsOpen";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state, ["ui", "account", "payment", "deleteCardConfirmIsOpen"], action.payload?.isOpen);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);