import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {listPrograms} from "lightrail-client/dist/programs";
import {ListProgramsParams, ListProgramsResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getPrograms";
export const actionCreator = promisePattern.actionCreator(type, (params: ListProgramsParams) => listPrograms(params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListProgramsResponse> =
    (state, payload) => {
        const allList = payloadHasBody(payload) ? payload?.body : state.server.programs.allList;
        return sprout.assoc(state,
            ["server", "programs", "allList"], allList,
            ["server", "programs", "links"], payload?.links,
            ["server", "programs", "pagination"], {maxLimit: payload?.maxLimit, limit: payload?.limit},
            ["server", "programs", "fetching"], false,
            ["server", "programs", "error"], null
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(onPendingFetching(state, ["server", "programs"]),
        ["server", "programs", "allList"], []
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);