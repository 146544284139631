import * as React from "react";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {Form, Radio} from "antd";
import {Program} from "lightrail-client/dist/model";
import {FormInstance} from "antd/es/form";

export interface Props {
    program?: Program;
    form: FormInstance;
}

export class InitialStatusItem extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <Form.Item
                label="Initial Status"
                name={FormFieldIds.Active}
                initialValue={(!!this.props.program) ? this.props.program.active : true}
            >
                <Radio.Group
                    className="lr-vertical-radio marginTop4"
                    style={{marginTop: -8}}
                    name={FormFieldIds.Active}
                >
                    <Radio className="verticalRadio" value={true}>Active</Radio>
                    <Radio className="verticalRadio" value={false}>Inactive (Activated by API)</Radio>
                </Radio.Group>
            </Form.Item>

        );
    }
}