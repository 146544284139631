import * as React from "react";
import {Spin} from "antd";

export interface SpinnerProps {
    style?: React.CSSProperties;
    className?: string;
}

export interface LoadingScreenProps {
    backgroundColor?: string;
}

export class LoadingSpinner extends React.PureComponent<SpinnerProps, {}> {
    render(): JSX.Element {
        return (
            <div style={!!this.props.style? this.props.style : {}} className={`${this.props.className} Spinner`}>
                <div className={`Cube1`}></div>
                <div className={`Cube2`}></div>
            </div>
        );
    }
}

export class PaddedLoadingSpinner extends React.PureComponent<SpinnerProps, {}> {
    render(): JSX.Element {
        return (
            <div style={!!this.props.style? this.props.style : {}} className="Spinner SpinnerPageContainer">
                <div className="Cube1">&nbsp;</div>
                <div className="Cube2">&nbsp;</div>
            </div>
        );
    }
}

export class PaddedSpin extends React.PureComponent<{}, {}> {
    render(): JSX.Element {
        return (
            <div className="lr-layout-padded-spin">
                <Spin/>
            </div>
        );
    }
}