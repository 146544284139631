import * as React from "react";
import {Form, Modal, Select} from "antd";
import {Account} from "../../../communication/dtos/account/Account";
import {SwitchAccountsRequest} from "../../../communication/dtos/account/SwitchAccount";
import {WithRouterProps} from "../../router/WithRouterProps";
import {reloadPage} from "../../../util/navigation";
import {NewAccountFormConnected} from "./NewAccountFormConnected";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {ExternalProps, ReduxProps} from "./AccountSelectConnected";
import {useForm} from "antd/lib/form/Form";
import {usePrevious} from "../../../util/hooks";
import {PlusCircleOutlined} from "@ant-design/icons";
import {SizeType} from "antd/es/config-provider/SizeContext";

export interface Props extends WithRouterProps<any>, ExternalProps, ReduxProps {
}

const NewAccountSelectValue = "NEW_ACCOUNT_SELECTED";

export const AccountSelect = (props: Props): JSX.Element => {
    let newAccountSubmit: () => Promise<void>;

    const [form] = useForm();

    const [createAccountModalVisible, setCreateAccountModalVisible] = React.useState(false);

    const getCurrentAccount = async (): Promise<void> => {
        if (props.loggedIn && !props.additionalAuthenticationRequired) {
            await Promise.all([
                props.getAccountDetails({}),
                props.listAccounts({})
            ]);
        }

        form.resetFields([FormFieldIds.CurrentAccountName]);
    };

    const getNewAccountSubmit = (submit: () => Promise<void>): void => {
        newAccountSubmit = submit;
    };

    const handleChange = async (accountId: string): Promise<void> => {
        const mode = props.userMode;

        if (accountId == NewAccountSelectValue) {
            setCreateAccountModalVisible(true);
        } else {
            const request: SwitchAccountsRequest = {accountId, mode};
            await props.switchAccounts(request);

            await getCurrentAccount();

            if (!props.messageCode) {
                reloadPage(props.history);
            }

            if (props.afterChange) {
                props.afterChange();
            }
        }
    };

    const closeNewAccountModal = (): void => {
        setCreateAccountModalVisible(false);
        getCurrentAccount();
    };

    const handleNewAccountSubmit = (): void => {
        newAccountSubmit();
    };

    const afterNewAccountSubmit = (): void => {
        closeNewAccountModal();
    };

    React.useEffect(() => {
        getCurrentAccount();
    }, []);

    const prevMessageCode = usePrevious(props.messageCode);
    const prevAuthRequired = usePrevious(props.additionalAuthenticationRequired);
    React.useEffect(() => {
        if (prevMessageCode != props.messageCode || prevAuthRequired != props.additionalAuthenticationRequired) {
            getCurrentAccount();
        }
    });

    return (
        <React.Fragment>
            <Form
                form={form}
                id={"lr-account-select"}
            >
                <Form.Item
                    htmlFor="lr-account-select-form-item"
                >
                    <div id="lr-account-select-selector-wrapper">
                        <Select
                            onChange={handleChange}
                            value={props.currentAccount?.name}
                            size={"large" as SizeType}
                            id="lr-account-select-selector"
                        >
                            {
                                props.accounts
                                    .filter((account: Account) => account.id != props.currentAccount?.id)
                                    .map((account: Account) => (
                                        <Select.Option
                                            value={account.id}
                                            key={account.id}
                                            className={"lr-account-select-option"}
                                        >
                                            {account.name}
                                        </Select.Option>
                                    ))
                            }
                            <Select.Option
                                value={NewAccountSelectValue}
                                className={"lr-account-select-option"}
                            >
                                <PlusCircleOutlined/> New Account
                            </Select.Option>
                        </Select>
                    </div>
                </Form.Item>
            </Form>
            <Modal
                title="Create a New Account"
                visible={createAccountModalVisible}
                onOk={handleNewAccountSubmit}
                onCancel={closeNewAccountModal}
                okText="Create"
            >
                <NewAccountFormConnected
                    getSubmit={getNewAccountSubmit}
                    onSubmit={handleNewAccountSubmit}
                    afterSubmit={afterNewAccountSubmit}
                />
            </Modal>
        </React.Fragment>
    );
};