import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {attachContactToValue} from "lightrail-client/dist/contacts";
import {onPendingProcessing} from "../../reducerHelpers";
import {
    AttachContactToValueParams,
    AttachContactToValueResponse
} from "lightrail-client/dist/params/contacts/AttachContactToValueParams";
import {mergeListObjectOrUnshift} from "../../../util/dataUtils";

export interface AttachValueToContactActionParams {
    contactId: string;
    params: AttachContactToValueParams;
}

//Include contactId with response
export interface AttachValueToContactActionResponse {
    contactId: string;
    response: AttachContactToValueResponse;
}

export const type = "attachValueToContact";
export const actionCreator = promisePattern.actionCreator(type, (p: AttachValueToContactActionParams) => attachContactToValue(p.contactId, p.params).then(
    response => ({
        contactId: p.contactId,
        response
    }))
);

const onFulfilled: promisePattern.PromiseFulfilledHandler<AttachValueToContactActionResponse> =
    (state, payload) => {
        const newValueList = mergeListObjectOrUnshift(state.server.values.list, payload?.response.body, "id");

        return sprout.assoc(state,
            ["server", "values", "list"], newValueList,
            ["server", "values", "processing"], false
        );
    };

const rejectionReducer: RejectionReducer = {
    overrideGlobal: true,
    reducer: (state, error) => sprout.assoc(state,
        ["server", "values", "processing"], false,
        ["server", "values", "error"], error.message
    )
};
const onRejected = rejectionHandler([rejectionReducer]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);