import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {AccountBillingPage} from "./AccountBillingPage";
import {actionCreator as contactCustomerSupport} from "../../../actions/contactCustomerSupport/server/contactCustomerSupport";
import {actionCreator as setPaymentModalOpen} from "../../../actions/account/ui/setStripeModalOpen";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {SubscriptionState} from "../../../state/serverState/User";

interface StateProps {
    subscriptionState: SubscriptionState;
    isContactSuccessOpen: boolean;
    isPaymentModalOpen: boolean;
    userEmail: string;
}

const mapStateToProps = (state: State): StateProps => ({
    subscriptionState: state.server.user.subscription,
    isContactSuccessOpen: state.ui.account.isContactSalesSuccessOpen,
    isPaymentModalOpen: state.ui.account.payment.stripeModal.isOpen,
    userEmail: state.server.user.email,
});

const actionCreators = {
    contactCustomerSupport,
    setPaymentModalOpen,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AccountBillingPageConnected = connector(AccountBillingPage);