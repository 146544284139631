import * as React from "react";
import {Spin, Timeline} from "antd";
import {formatRoute, Paths} from "../../util/routes/routes";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../util/stringUtils";
import {dateTimeWithSlashFormat, formatDate} from "../../util/dateUtils";
import {Transaction} from "lightrail-client/dist/model";
import {PendingOrSuccessTag} from "./PendingOrSuccessTag";
import {WithRouterProps} from "../router/WithRouterProps";
import {ExternalProps, ReduxProps} from "./TransactionChainTimelineConnected";

export interface Props extends WithRouterProps<{}>, ExternalProps, ReduxProps {
}

export class TransactionChainTimeline extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.getChain = this.getChain.bind(this);
    }

    componentDidMount(): void {
        this.getChain();
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.transactionId !== this.props.transactionId) {
            this.getChain();
        }
    }

    getChain(): {} {
        return this.props.getTransactionChain(this.props.transactionId);
    }

    //////////////////
    //[ RENDER ]
    renderChainItem(chainItem: Transaction): JSX.Element {
        return (
            <span>
                {
                    chainItem.id === (this.props.transactionId) ?
                        chainItem.id :
                        (
                            <Link
                                to={formatRoute(Paths.TRANSACTION_DETAIL, {transactionId: chainItem.id})}
                            >
                                {chainItem.id + " "}
                            </Link>
                        )
                }
                <p>
                    {capitalizeFirstLetter(chainItem.transactionType) + " "}
                    {<PendingOrSuccessTag pending={chainItem.pending}/>}
                    {formatDate(chainItem.createdDate, dateTimeWithSlashFormat)}
                </p>
            </span>
        );
    }

    render(): JSX.Element {
        return (
            (!!this.props.transactionChainServerState.fetching || !this.props.transactionChainServerState.chains[this.props.transactionId]) ?
                <Spin size={"small"}/> :
                (
                    <Timeline>
                        {
                            this.props.transactionChainServerState.chains[this.props.transactionId]
                                .map(chainItem => (
                                        <Timeline.Item
                                            key={chainItem.id}
                                            color={chainItem.id === (this.props.transactionId) ? "black" : "blue"}
                                        >
                                            {this.renderChainItem(chainItem)}
                                        </Timeline.Item>
                                    )
                                )
                                .reverse()
                        }
                    </Timeline>
                )
        );
    }
}