import * as React from "react";
import {Button, Input} from "antd";
import {ExternalProps, ReduxProps} from "./HideShowValueConnected";
import { EyeOutlined } from "@ant-design/icons";

export interface Props extends ExternalProps, ReduxProps{
}

export class HideShowValue extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onToggleVis = this.onToggleVis.bind(this);
    }

    componentDidMount(): void {
        this.props.updatePasswordField({
            id: this.props.id,
            state: {
                value: this.props.value ? this.props.value : "",
                visible: false
            }
        });
    }

    componentWillUnmount(): void {
        this.props.updatePasswordField({id: this.props.id, state: null});
    }

    onToggleVis(): void {
        this.props.updatePasswordField({id: this.props.id, state: {...this.props.uiState, visible: !this.props.uiState.visible}});
    }

    render(): JSX.Element {
        return (
            <div className="flexCentered">
                <Input
                    key={this.props.id}
                    id={this.props.id}
                    size="large"
                    type={this.props.uiState && this.props.uiState.visible ? null : "password"}
                    value={this.props.uiState ? this.props.uiState.value : ""}
                />
                <Button
                    type="ghost"
                    style={{marginLeft: -40}}
                    shape="circle"
                    onClick={this.onToggleVis}
                >
                    <EyeOutlined/>
                </Button>
            </div>
        );
    }
}