import {isProductionEnvironment} from "./getEnvironment";

// Disable Intercom for now but don't delete the code for now.
// Either Lightrail lives and Intercom makes sense, or Lightrail
// doesn't live and code neatness doesn't matter so much any more.
const intercomEnabled = false;

export const bootIntercom = (): void => {
    if (!intercomEnabled) {
        return;
    }

    const appId: string = getAppIdFromEnvironment();
    (window as any).Intercom("boot", {
        app_id: appId,
    });
};

export const updateIntercom = (userHash: string, email: string, userId: string): void => {
    if (!intercomEnabled) {
        return;
    }

    const appId: string = getAppIdFromEnvironment();
    (window as any).Intercom("update", {
        app_id: appId,
        user_hash: userHash,
        user_id: userId,
        email: email,
        "Product": "currency"
    });
};

export const getAppIdFromEnvironment = (): string => {
    if (isProductionEnvironment()) {
        return "hfseir0n";
    } else {
        return "h8xc4har";
    }
};

export const hideIntercomDisplay = (): void => {
    if (!intercomEnabled) {
        return;
    }

    const intercomDisplay: any = document.getElementById("intercom-container");

    if (!intercomDisplay) {
        const timer = window.setTimeout((intercomDisplay: any): void => {
            intercomDisplay = document.getElementById("intercom-container");

            if (!!intercomDisplay) {
                intercomDisplay.style.display = "none";
                clearTimeout(timer);
            }
        }, 1000);

    } else {
        intercomDisplay.style.display = "none";
    }
};

export const showIntercomDisplay = (): void => {
    if (!intercomEnabled) {
        return;
    }

    const intercomDisplay = document.getElementById("intercom-container");
    if (intercomDisplay) {
        intercomDisplay.style.display = "block";
    }
};
