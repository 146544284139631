import * as ReactRedux from "react-redux";
import {AccountMessageWatcher} from "./AccountMessageWatcher";
import {State} from "../../../state/State";
import {withRouter} from "react-router-dom";

interface StateProps {
    messageCode: string;
}

const mapStateToProps = (state: State): StateProps => ({
    messageCode: state.server.account.authMessage?.messageCode,
});

const connector = ReactRedux.connect<StateProps, {}, {}>(mapStateToProps);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AccountMessageWatcherConnected = connector(
    withRouter(
        AccountMessageWatcher
    )
);