import { WithRouterProps } from "./WithRouterProps";
import { PureComponent, ReactNode } from "react";
import { withRouter } from "react-router-dom";
import { Location } from "history";

interface Props extends WithRouterProps<any> {
    logInitialLocation?: boolean;
    onLocationChange: (location: Location) => void;
}

class LocationChangeListenerInner extends PureComponent<Props, {}> {
    componentDidMount(): void {
        if (this.props.logInitialLocation) {
            this.props.onLocationChange(this.props.location);
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.location !== prevProps.location) {
            this.props.onLocationChange(this.props.location);
        }
    }

    render(): ReactNode {
        return (
            this.props.children
        );
    }
}

export const LocationChangeListener = withRouter(LocationChangeListenerInner);