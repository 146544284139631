import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {UpdateProgramParams, UpdateProgramResponse} from "lightrail-client/dist/params";
import {updateProgram} from "lightrail-client/dist/programs";
import {deleteListObject, mergeListObject} from "../../../util/dataUtils";
import {dateIsInFuture} from "../../../util/dateUtils";

export interface UpdateProgramActionParams {
    programId: string;
    params?: UpdateProgramParams;
}

export const type = "updateProgram";
export const actionCreator = promisePattern.actionCreator(type, (p: UpdateProgramActionParams) => updateProgram(p.programId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdateProgramResponse> =
    (state, payload) => {
        const newProgramList = mergeListObject(state.server.programs.allList, payload?.body, "id");
        if (dateIsInFuture(payload?.body.endDate)) {
            const newProgramActiveList = mergeListObject(state.server.programs.activeList, payload?.body, "id");
            const newProgramInactiveList = deleteListObject(state.server.programs.inactiveList, payload?.body.id, "id");

            return sprout.assoc(state,
                ["server", "programs", "allList"], newProgramList,
                ["server", "programs", "activeList"], newProgramActiveList,
                ["server", "programs", "inactiveList"], newProgramInactiveList,
                ["server", "programs", "processing"], false
            );
        } else {
            const newProgramInactiveList = mergeListObject(state.server.programs.inactiveList, payload?.body, "id");
            const newProgramActiveList = deleteListObject(state.server.programs.activeList, payload?.body.id, "id");

            return sprout.assoc(state,
                ["server", "programs", "allList"], newProgramList,
                ["server", "programs", "inactiveList"], newProgramInactiveList,
                ["server", "programs", "activeList"], newProgramActiveList,
                ["server", "programs", "processing"], false
            );
        }
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "programs", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "programs", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
