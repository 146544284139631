import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {CreateContactValueUiState} from "../../../state/uistate/ContactUiState";

export interface CreateContactValueActionParams {
    set?: CreateContactValueUiState;
    merge?: CreateContactValueUiState;
}

export const type = "setCreateContactValueState";

export const actionCreator: ActionCreator<CreateContactValueActionParams, CreateContactValueActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<CreateContactValueActionParams> = (state, action) => {
    const params = action.payload;
    const newState = (params.set) ? params.set : {...state.ui.issuance.create, ...params.merge};
    return sprout.assoc(state, ["ui", "contacts", "createValue"], newState);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);