import * as ReactRedux from "react-redux";
import {AccountSelect} from "./AccountSelect";
import {
    actionCreator as getAccountDetails,
} from "../../../actions/account/server/getAccountDetails";
import {actionCreator as listAccounts} from "../../../actions/account/server/listAccounts";
import {
    actionCreator as switchAccounts,
} from "../../../actions/account/server/switchAccounts";
import {
    actionCreator as createAccount,
} from "../../../actions/account/server/createAccount";
import {State} from "../../../state/State";
import {UserMode, UserModes} from "../../../communication/dtos/auth/PingResponse";
import {withRouter} from "react-router-dom";
import {Account} from "../../../communication/dtos/account/Account";

export interface ExternalProps {
    afterChange?: () => void;
}

interface StateProps {
    accounts: Account[];
    userMode: UserMode;
    currentAccount: Account;
    loggedIn: boolean;
    messageCode: string;
    additionalAuthenticationRequired: boolean;
}

const mapStateToProps = (state: State): StateProps => ({
    accounts: state.server.account.accounts,
    userMode: (state.server.user.testing ? UserModes.TEST : UserModes.LIVE) as UserMode,
    currentAccount: state.server.account.accountInfo,
    loggedIn: state.server.user.loggedIn,
    messageCode: state.server.account.authMessage?.messageCode,
    additionalAuthenticationRequired: state.server.user.additionalAuthenticationRequired
});

const actionCreators = {
    getAccountDetails,
    createAccount,
    listAccounts,
    switchAccounts
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AccountSelectConnected = connector(
    withRouter(
        AccountSelect
    )
);