import * as React from "react";
import {CSSProperties} from "react";
import {Col, Popover, Row} from "antd";
import {SimpleStat} from "../../data/SimpleStat";
import {capitalizeFirstLetter} from "../../../../util/stringUtils";
import {JsonViewer} from "../../data/JsonViewer";

export interface DetailValuesItem {
    label: string;
    key?: string;
    className?: string;
    value: string | number | JSX.Element;
}

export interface Props {
    data: DetailValuesItem[][];
    jsonPopover?: {
        title: string;
        data: any;
    };
    title?: string;
    colMax?: number;
    fullWidth?: boolean;
    style?: CSSProperties;
    className?: string;
}

export class DetailValues extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const mdSpan = (this.props.fullWidth ? 24 : 12);

        return (
            <div className={ this.props.className } style={ this.props.style }>
                {
                    this.props.title &&
                    <Row gutter={8}>
                        <Col>
                            <h3>{this.props.title}</h3>
                        </Col>
                        {
                            (!!this.props.jsonPopover) &&
                            <Col>
                                <Popover
                                    title={this.props.jsonPopover.title}
                                    content={<JsonViewer
                                        text={JSON.stringify(this.props.jsonPopover.data, null, 2)}
                                    />}
                                    trigger="click"
                                    placement="right">
                                    <a className="lr-link-normal" style={{fontSize: 16, lineHeight: 1.6}}>View</a>
                                </Popover>
                            </Col>
                        }
                    </Row>

                }
                <Row gutter={8} style={{width: "100%"}}>
                    {
                        this.props.data.map((col, i) => (
                            (
                                <Col key={i.toString()} xs={24} sm={24} md={mdSpan}>
                                    {
                                        col.map(row => (
                                            (!!row)
                                                ? (
                                                    <SimpleStat
                                                        key={row.label}
                                                        label={capitalizeFirstLetter(row.label)}
                                                        value={row.value}
                                                        className={row.className}
                                                    />
                                                )
                                                : null
                                        ))
                                    }
                                </Col>
                            )
                        ))
                    }
                </Row>
            </div>
        );
    }
}