import * as React from "react";
import {RegisterForm} from "./RegisterForm";
import {LoginRequest} from "../../../../communication/dtos/auth/Login";
import {ExternalPaths, Paths} from "../../../../util/routes/routes";
import {AuthLayoutHeader} from "../../AuthLayoutHeader";
import {Alert, Button} from "antd";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {withRouter} from "react-router-dom";
import {ReduxProps} from "./RegisterPageConnected";

export interface RegisterProps extends WithRouterProps<{}>, ReduxProps {
}

export class RegisterPage extends React.PureComponent<RegisterProps, {}> {
    submitForm: () => void;

    constructor(props: RegisterProps) {
        super(props);

        this.onLogin = this.onLogin.bind(this);
        this.onRequestDemo = this.onRequestDemo.bind(this);
        this.onFormUpdated = this.onFormUpdated.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCreateAccountClick = this.onCreateAccountClick.bind(this);
        this.getFormSubmit = this.getFormSubmit.bind(this);
    }

    componentWillUnmount(): void {
        this.props.resetAuthErrorAndSuccessStates({});
    }

    onLogin(): void {
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    onCreateAccountClick(): void {
        this.submitForm();
    }

    onFormUpdated(): void {
        if (!!this.props.registerState.error && !!this.props.registerState.error.length) {
            this.props.resetAuthErrorAndSuccessStates({});
        }
    }

    onFormSubmit(params: LoginRequest): void {
        if (!this.props.registerState.processing) {
            this.props.register(params);
        }
    }

    onRequestDemo(): void {
        window.open(ExternalPaths.LIGHTRAIL_CONTACT_SALES, "_blank");
    }

    getFormSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    renderRegistrationContent(): JSX.Element {
        return (
            <div>
                <AuthLayoutHeader
                    title="Create a free account."
                    description={<div>
                        <p>Build rule-based promotions. Offer gift cards and loyalty points. Engage your customers using
                            value.</p>
                    </div>
                    }
                />
                {
                    (this.props.registerState.emailSent)
                        ? <div>
                            <Alert
                            message="Success"
                            description="Please check your email to verify your account."
                            type="success"
                            showIcon
                        />
                            <Button size="large" style={{width: "100%", marginTop: 16}} type="primary"
                                    onClick={this.onLogin}>Go to Log in</Button>
                        </div>
                        : <RegisterForm
                            onUpdate={this.onFormUpdated}
                            errorMessage={this.props.registerState.error}
                            processing={this.props.registerState.processing}
                            onSubmit={this.onFormSubmit}
                            getSubmit={this.getFormSubmit}
                        />
                }
            </div>
        );
    }

    renderRequestAccessContent(): JSX.Element {
        return (
            <div>
                <AuthLayoutHeader
                    title="Request access."
                    description={<div>
                        <p>Self-registration to Lightrail has been disabled.  To request access please send an email to <a href={"mailto:hello@kartarail.com"}>hello@kartarail.com</a>.</p>
                    </div>
                    }
                />
            </div>
        );
    }

    render(): JSX.Element {
        return (
            this.renderRegistrationContent()
            // this.renderRequestAccessContent() // Use to disable self-registration.
        );
    }
}

export const RegisterLayoutRouted = withRouter(RegisterPage);
