import * as React from "react";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {Form, Input} from "antd";
import {FormInstance} from "antd/es/form";
import {UserOutlined} from "@ant-design/icons";
import {ChangeEventHandler} from "react";

const FormItem = Form.Item;

export interface Props {
    hideHelp?: boolean;
    size?: "large" | "small";
    autoFocus?: boolean;
    initialValue?: string;
    required?: boolean;
    form: FormInstance;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    value?: string;
}

export interface State {
    showError: boolean;
}

export class EmailItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.state = {showError: false};
    }

    handleBlur(): void {
        this.setState({showError: true});
    }

    handleFocus(): void {
        this.setState({showError: false});
    }

    render(): JSX.Element {
        const {getFieldError, isFieldTouched} = this.props.form;
        const emailError = isFieldTouched(FormFieldIds.Email) && getFieldError(FormFieldIds.Email).length;

        return (
            <FormItem
                validateStatus={this.state.showError && emailError ? "error" : null}
                help={this.state.showError && emailError ? "Valid Email Required" : ""}
                name={FormFieldIds.Email}
                initialValue={this.props.initialValue}
                rules={[
                    {type: "email", message: "Invalid Email"},
                    {required: this.props.required, message: "Email Required"}
                ]}
            >
                <Input
                    autoFocus={this.props.autoFocus}
                    size={this.props.size}
                    type="email"
                    prefix={<UserOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                    placeholder="Email"
                    data-cy="email"
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onChange={this.props.onChange}
                    value={this.props.value}
                />
            </FormItem>
        );
    }
}