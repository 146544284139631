import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {AccountUsersLayout} from "../AccountUsersLayout";
import {actionCreator as getUsers} from "../../../actions/account/server/getAccountUsers";
import {actionCreator as getAccountInvites} from "../../../actions/account/server/getAccountInvites";
import {actionCreator as createAccountUser} from "../../../actions/account/server/createAccountUser";
import {actionCreator as updateUser} from "../../../actions/account/server/updateAccountUser";
import {actionCreator as deleteActiveAccountUser} from "../../../actions/account/server/deleteActiveAccountUser";
import {actionCreator as deleteInvitedAccountUser} from "../../../actions/account/server/deleteInvitedAccountUser";
import {actionCreator as resendAccountUserInvite} from "../../../actions/account/server/resendAccountUserInvite";
import {actionCreator as clearAccountUsersList} from "../../../actions/account/server/clearAccountUsersList";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {TeamsUI} from "../../../state/uistate/TeamsUiState";
import {AccountUserInvitees, User} from "../../../communication/dtos/account/User";
import {AccountInvitee, AccountUser} from "../../../state/serverState/AccountState";
import {withRouter} from "react-router-dom";

interface StateProps {
    uiState: TeamsUI;
    userId: string;
    usersState: AccountUser;
    invitesState: AccountInvitee;
    users: User[];
    invites: AccountUserInvitees[];
    processing: boolean;
}

const mapStateToProps = (state: State): StateProps => ({
    uiState: state.ui.team,
    userId: state.browser.jwt.data.g.tmi,
    usersState: state.server.account.users,
    invitesState: state.server.account.invites,
    users: state.server.account.users.list,
    invites: state.server.account.invites.invitees,
    processing: state.server.account.invites.processing
});

const actionCreators = {
    getUsers,
    getAccountInvites,
    createAccountUser,
    updateUser,
    deleteActiveAccountUser,
    deleteInvitedAccountUser,
    resendAccountUserInvite,
    clearAccountUsersList,
    uiAction,
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const TeamMembersLayoutConnected = connector(
    withRouter(AccountUsersLayout)
);