import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {GetTransactionResponse} from "lightrail-client/dist/params";
import {getTransaction} from "lightrail-client/dist/transactions";
import {mergeListObject, payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getTransaction";
export const actionCreator = promisePattern.actionCreator(type, getTransaction);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetTransactionResponse> =
    (state, payload) => {
        const list = (payloadHasBody(payload)) ? mergeListObject(state.server.transactions.list, payload?.body, "id") : state.server.transactions.list;
        return sprout.assoc(state,
            ["server", "transactions", "list"], list,
            ["server", "transactions", "fetching"], false,
            ["server", "transactions", "status"], payload?.status
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "transactions"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);