import * as React from "react";
import {Subheading} from "../../../ui/typography/TypographyTags";
import {Form, Input} from "antd";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../../../ui/forms/AllowSubmitOnEnter";
import {AlphaSpacesRegex, NumbersOnlyRegExp} from "../../../../util/regexp";
import {CreditCard} from "../../../../communication/dtos/user/payments/CreditCard";
import {useForm} from "antd/lib/form/Form";
import {WarningOutlined} from "@ant-design/icons";
import {useEffect} from "react";

import "../creditCardForm/ccFormStyles.scss";

// disable eslint for legacy styles and icons
/* eslint-disable @typescript-eslint/no-var-requires */
// const visaIcon: any = require("payment-icons/min/flat/visa.svg");
// const mastercardIcon: any = require("payment-icons/min/flat/mastercard.svg");
// const amexIcon: any = require("payment-icons/min/flat/amex.svg");
// const defaultIcon: any = require("payment-icons/min/mono/default.svg");
const giftbitColors: any = require("../../../../styles/legacy/_colors.scss");

/* eslint-enable @typescript-eslint/no-var-requires */

// Disable eslint to disable typescript to allow svg files to be loaded in
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import visaIcon from "payment-icons/min/flat/visa.svg";

//@ts-ignore
import mastercardIcon from "payment-icons/min/flat/mastercard.svg";

//@ts-ignore
import amexIcon from "payment-icons/min/flat/amex.svg";

//@ts-ignore
import defaultIcon from "payment-icons/min/flat/default.svg";

/* eslint-enable @typescript-eslint/ban-ts-comment */

enum CREDIT_CARD_TYPES {UNKNOWN, VISA, MASTERCARD, AMEX}

export interface Props {
    cardDetails: CreditCard;
    cardErrorMessage: string;
    addressErrorMessage: string;
    getSubmit?: (submit: () => void) => void;
    onSubmit: (data: any) => void;
}

export const CreditCardForm = (props: Props): JSX.Element => {
        const [form] = useForm();

        const [cardType, setCardType] = React.useState(CREDIT_CARD_TYPES.UNKNOWN);
        const [cardIcon, setCardIcon] = React.useState(defaultIcon);

        const [formErrors, setFormErrors] = React.useState([]);

        const submit = async (): Promise<void> => {
            try {
                const values = await form.validateFields();
                props.onSubmit(values);
            } catch (errs) {
                const errorArray: string[] = [];
                (errs as any).errorFields.forEach((errorField: any) => errorArray.push(...errorField.errors));

                setFormErrors(errorArray);
            }
        };

        const getCreditCardType = (accountNumber: string): number => {
            if (/^5[1-5]/.test(accountNumber)) {
                return CREDIT_CARD_TYPES.MASTERCARD;

            } else if (/^4/.test(accountNumber)) {
                return CREDIT_CARD_TYPES.VISA;

            } else if (/^3[47]/.test(accountNumber)) {
                return CREDIT_CARD_TYPES.AMEX;
            }

            return CREDIT_CARD_TYPES.UNKNOWN;
        };

        const setCreditCardIcon = (type: CREDIT_CARD_TYPES): void => {
            if (type === CREDIT_CARD_TYPES.VISA) {
                setCardIcon(visaIcon);
            }
            if (type === CREDIT_CARD_TYPES.MASTERCARD) {
                setCardIcon(mastercardIcon);
            }
            if (type === CREDIT_CARD_TYPES.AMEX) {
                setCardIcon(amexIcon);
            }
        };

        const renderErrorMessage = (): JSX.Element => {
            if (props.cardErrorMessage || props.addressErrorMessage) {
                return (
                    <div className="errorRow">
                        <WarningOutlined style={{color: giftbitColors.red, marginRight: 8}}/>
                        <p className="errorMessage">
                            {`${props.cardErrorMessage || ""} ${props.addressErrorMessage || ""}`}
                        </p>
                    </div>
                );
            }
            if (formErrors.length) {
                return (
                    <div className="errorRow">
                        <WarningOutlined style={{color: giftbitColors.red, marginRight: 8}}/>
                        <ul className="errorMessage">
                            {formErrors.map((err, idx) => <li key={idx}>{err}</li>)}
                        </ul>
                    </div>
                );
            }
            return null;
        };

        useEffect(() => {
            if (props.getSubmit) {
                props.getSubmit(submit);
            }
        });

        useEffect(() => {
            setCreditCardIcon(cardType);
        }, [cardType]);

        return (
            <div>
                {renderErrorMessage()}
                <Form className="form" layout="vertical" onFinish={submit} form={form}>
                    <Subheading className="ccInfoHeader">Credit Card Information</Subheading>
                    <div className=" flexRow width100Perc">
                        <Form.Item
                            label="Credit Card #"
                            className="flexColumn cc-number-container"
                            name={FormFieldIds.Number}
                            rules={[
                                GenericRequiredRule,
                                {pattern: /^[0-9\s-]*$/, message: "Invalid Number"}
                            ]}
                        >
                            <Input
                                className="flexColumn width100Perc"
                                placeholder="1234 5678 9012 3456"
                                onChange={(event) => {
                                    setCardType(getCreditCardType(event.target.value));
                                }}
                            />
                        </Form.Item>
                        <div className="cc-icon-container">
                            <img className="cardImage" src={cardIcon} alt="Credit card icon"/>
                        </div>
                    </div>
                    <div className="flexRow">
                        <Form.Item
                            label="Name on Card"
                            className="flexColumn"
                            name={FormFieldIds.Name}
                            rules={[GenericRequiredRule]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="Full Name"
                            />
                        </Form.Item>
                        <Form.Item
                            label="CVC"
                            className="flexColumn"
                            name={FormFieldIds.Cvv}
                            rules={[
                                GenericRequiredRule,
                                {pattern: /^[0-9]{3,5}$/, message: "Invalid"}
                            ]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="123"
                            />
                        </Form.Item>
                    </div>
                    <div className="flexRow">
                        <Form.Item
                            label="Exp. Month"
                            className="flexColumn"
                            name={FormFieldIds.Exp_month}
                            rules={[
                                GenericRequiredRule,
                                {pattern: NumbersOnlyRegExp, message: "Invalid"}
                            ]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="MM"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Exp. Year"
                            className="flexColumn"
                            name={FormFieldIds.Exp_year}
                            rules={[
                                GenericRequiredRule,
                                {pattern: NumbersOnlyRegExp, message: "Invalid"}
                            ]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="YYYY"
                            />
                        </Form.Item>
                    </div>

                    <Subheading className="billingHeader">Billing Address</Subheading>

                    <div className="flexRow">
                        <Form.Item
                            label="Address"
                            className="flexColumn"
                            name={FormFieldIds.Address_line1}
                            initialValue={props.cardDetails.addressLine1}
                            rules={[GenericRequiredRule]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="123 St"
                            />
                        </Form.Item>
                    </div>
                    <div className="flexRow">
                        <Form.Item
                            label="Address line 2"
                            className="flexColumn"
                            name={FormFieldIds.Address_line2}
                            initialValue={props.cardDetails.addressLine2}
                        >
                            <Input
                                className="flexColumn"
                                placeholder=""
                            />
                        </Form.Item>
                    </div>
                    <div className="flexRow">
                        <Form.Item
                            label="City"
                            className="flexColumn"
                            name={FormFieldIds.Address_city}
                            initialValue={props.cardDetails.addressCity}
                            rules={[GenericRequiredRule]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="City"
                            />
                        </Form.Item>
                        <Form.Item
                            label="State"
                            className="flexColumn"
                            name={FormFieldIds.Address_state}
                            initialValue={props.cardDetails.addressState}
                            rules={[GenericRequiredRule]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="State"
                            />
                        </Form.Item>
                    </div>
                    <div className="flexRow">
                        <Form.Item
                            label="Country"
                            className="flexColumn"
                            name={FormFieldIds.Country}
                            initialValue={props.cardDetails.country}
                            rules={[GenericRequiredRule]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="Country"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Zip Code"
                            className="flexColumn"
                            name={FormFieldIds.Address_zip}
                            initialValue={props.cardDetails.addressZip}
                            rules={[
                                GenericRequiredRule,
                                {pattern: AlphaSpacesRegex, message: "Invalid"}
                            ]}
                        >
                            <Input
                                className="flexColumn"
                                placeholder="Zip Code"
                            />
                        </Form.Item>
                    </div>
                    <AllowSubmitOnEnter/>
                </Form>
                {renderErrorMessage()}
            </div>
        );
    }
;