import * as React from "react";
import {Value} from "lightrail-client/dist/model";
import {Col, Row} from "antd";
import {RulePopover} from "../../ui/rules/RulePopover";
import {FormattedCurrency} from "../../ui/currency/FormattedCurrencyConnected";
import {isGenericCodeWithPerContactProperties} from "../../../util/valueUtils";

export interface Props {
    value: Value;
    showCurrency?: boolean;
    viewRule?: boolean;
}

export class ValueBalanceDisplay extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const value = this.props.value;

        if (!!value.balanceRule) {
            return (
                <Row gutter={8}>
                    <Col>
                        {
                            (!!value.balanceRule.explanation) ? value.balanceRule.explanation : "Custom balance rule"
                        }
                    </Col>
                    {
                        (this.props.viewRule) &&
                        <Col>
                            <RulePopover title="Balance Rule" content={this.props.value.balanceRule}/>
                        </Col>
                    }
                </Row>
            );
        }

        return (
            <span>
                <FormattedCurrency
                    value={isGenericCodeWithPerContactProperties(value) && value.genericCodeOptions.perContact.balance ? value.genericCodeOptions.perContact.balance : value.balance}
                    code={value.currency}
                />
                {
                    (!!this.props.showCurrency) &&
                    <span className="lr-title-postfix">
                    {
                        value.currency
                    }
                    </span>
                }
            </span>
        );
    }
}