import * as React from "react";
import {Button, Dropdown, Menu} from "antd";
import {ContactAction} from "../../../state/uistate/ContactUiState";
import {Contact} from "lightrail-client/dist/model";
import {ContactCreateValueModalConnected} from "../createValue/ContactCreateValueModalConnected";
import {AttachValueModalConnected} from "./attachValueModal/AttachValueModalConnected";
import {ActionMenu} from "../../ui/menus/ActionMenu";
import { DownOutlined, EllipsisOutlined } from "@ant-design/icons";
import {MenuInfo} from "rc-menu/es/interface";
import {ExternalProps, ReduxProps} from "./ContactActionMenuConnected";

export enum ContactActionMenuType {DEFAULT, VALUE_DETAIL}

export interface ContactValueDetailMenuContextData {
    onDetach: (c: Contact) => void;
}

export interface ContactMenuContext extends ActionMenu.MenuContext<ContactActionMenuType, ContactValueDetailMenuContextData> {
    type: ContactActionMenuType;
    data: ContactValueDetailMenuContextData | null;
}

export interface Props extends ExternalProps, ReduxProps{
}

export class ContactActionMenu extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.clearCurrentAction = this.clearCurrentAction.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
    }

    componentDidMount(): void {
        this.props.setContactActionState({contactId: this.props.contact.id, deleteNode: true});
    }

    getCurrentAction(): ContactAction {
        const actionState = this.props.contactUiState.actions[this.props.contact.id];
        if (!!actionState && !!actionState.currentAction) {
            return actionState.currentAction;
        }

        return ContactAction.NONE;
    }

    clearCurrentAction(): void {
        this.props.setContactActionState({
            contactId: this.props.contact.id,
            actionState: {currentAction: ContactAction.NONE}
        });
    }

    onMenuClick(p: MenuInfo): void {
        if (p.key === ContactAction.DETACH_VALUE) {
            this.props.menuContext.data.onDetach(this.props.contact);
            return;
        }

        this.props.setContactActionState({
            contactId: this.props.contact.id,
            actionState: {currentAction: p.key as ContactAction}
        });
    }

    ///////////////////
    //[ RENDERING ]
    renderMenu(): JSX.Element {
        const items: ActionMenu.Item<ContactAction>[] = [];

        // Setup Items based on menuContext type
        if (!!this.props.menuContext) {
            switch (this.props.menuContext.type) {
                case ContactActionMenuType.VALUE_DETAIL:
                    items.push({type: ContactAction.DETACH_VALUE, label: "Remove From Value"});
                    break;
                default:
                    items.push(
                        {type: ContactAction.CREATE_VALUE, label: "Attach New Value"},
                        {type: ContactAction.ATTACH_VALUE, label: "Attach Existing Value"}
                    );
            }
        } else {
            items.push(
                {type: ContactAction.CREATE_VALUE, label: "Attach New Value"},
                {type: ContactAction.ATTACH_VALUE, label: "Attach Existing Value"}
            );
        }

        return (
            <Menu onClick={this.onMenuClick}>
                {
                    items.map(item => (
                        ActionMenu.getMenuItem(item)
                    ))
                }
            </Menu>
        );
    }

    render(): JSX.Element {
        const menu = this.renderMenu();
        if (!menu) {
            return null;
        }

        const currentAction = this.getCurrentAction();

        return (
            <div>
                <Dropdown overlay={menu} trigger={["click"]}>
                    <Button className={(!this.props.label) ? "lr-smallerAntBtnPadding" : ""}>
                        {this.props.label ? this.props.label : null}
                        {
                            this.props.label ?
                            <DownOutlined/> :
                            <EllipsisOutlined/>
                        }
                    </Button>
                </Dropdown>
                <ContactCreateValueModalConnected
                    open={currentAction === ContactAction.CREATE_VALUE}
                    contact={this.props.contact}
                    onClose={this.clearCurrentAction}
                />
                <AttachValueModalConnected
                    open={currentAction === ContactAction.ATTACH_VALUE}
                    contact={this.props.contact}
                    onClose={this.clearCurrentAction}
                />
            </div>
        );
    }
}