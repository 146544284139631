import * as React from "react";
import {Tag} from "antd";
import {badgeRed} from "../../util/stringUtils";
import { ClockCircleOutlined } from "@ant-design/icons";

interface Props {
    expired: boolean;
}

export class ProgramExpiredTag extends React.PureComponent <Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            (this.props.expired) ?
                <Tag className="lr-tag" color={badgeRed}> <ClockCircleOutlined/> Expired</Tag> : null
        );
    }
}