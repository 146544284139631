import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {updateValue} from "lightrail-client/dist/values";
import {UpdateValueResponse} from "lightrail-client/dist/params";
import {mergeListObject, payloadHasBody} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";
import {State} from "../../../state/State";
import {UpdateValueActionParams} from "./updateValue";

export const type = "modifyValuesDate";
export const actionCreator = promisePattern.actionCreator(type, (p: UpdateValueActionParams) => updateValue(p.valueId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdateValueResponse> =
    (state, payload) => {
        const list = (payloadHasBody) ? mergeListObject(state.server.values.list, payload?.body, "id") : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "processing"], false
        );
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "values", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);