import * as React from "react";
import {ReactNode} from "react";
import {Col, Row} from "antd";

export interface AccountHeaderProps {
    title: ReactNode;
    actionButton?: ReactNode;
}

export class AccountHeader extends React.PureComponent<AccountHeaderProps, {}> {
    render(): JSX.Element {
        return (
            <div>
                <Row className="lr-layout-header">
                    <Col span={12}>
                        <h2 className="lr-title">
                            {
                                this.props.title
                            }
                        </h2>
                    </Col>
                    {
                        (!!this.props.actionButton) &&
                        <Col style={{marginLeft: "auto"}}>
                            {
                                this.props.actionButton
                            }
                        </Col>
                    }
                </Row>
            </div>
        );
    }
}