import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {actionCreator as authMfa} from "../../../../actions/auth/server/MFA/authMFA";
import {actionCreator as logout} from "../../../../actions/auth/server/logout";
import {actionCreator as forgotPassword} from "../../../../actions/auth/server/forgotPassword";
import {actionCreator as addDevice} from "../../../../actions/auth/server/MFA/addDevice";
import {actionCreator as setMfaPhoneNumber} from "../../../../actions/account/ui/setMFAPhoneNumber";
import {actionCreator as getUser} from "../../../../actions/auth/server/getUser";
import {actionCreator as removeAccountMessage} from "../../../../actions/auth/server/removeAccountMessage";
import {actionCreator as switchAccounts} from "../../../../actions/account/server/switchAccounts";
import {actionCreator as listAccounts} from "../../../../actions/account/server/listAccounts";
import {actionCreator as updatePassword} from "../../../../actions/auth/server/updatePassword";
import {UserMode, UserModes} from "../../../../communication/dtos/auth/PingResponse";
import {withRouter} from "react-router-dom";
import {AuthCodeHandlerPage} from "./AuthCodeHandlerPage";
import {RestError} from "../../../../state/serverState/RestError";
import {MFAState} from "../../../../state/serverState/MFAState";
import {AuthServerStateErrorMessage} from "../../../../state/serverState/AuthServerState";
import {Account} from "../../../../communication/dtos/account/Account";

interface StateProps {
    message: string;
    messageCode: string;
    restError: RestError;
    MfaServerState: MFAState;
    authError: AuthServerStateErrorMessage;
    stateHistory: string[];
    currentAccount: Account;
    userMode: UserMode;
}

const mapStateToProps = (state: State): StateProps => ({
    message: state.server.account.authMessage?.message,
    messageCode: state.server.account.authMessage?.messageCode,
    restError: state.server.restError,
    MfaServerState: state.server.MFA,
    authError: state.server.auth.errorMessage,
    stateHistory: state.ui.history,
    currentAccount: state.server.account.accountInfo,
    userMode: (state.server.user.testing ? UserModes.TEST : UserModes.LIVE) as UserMode,
});

const actionCreators = {
    authMfa,
    logout,
    addDevice,
    setMfaPhoneNumber,
    forgotPassword,
    getUser,
    switchAccounts,
    updatePassword,
    listAccounts,
    removeAccountMessage
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AuthCodeHandlerPageConnected = connector(
    withRouter(
        AuthCodeHandlerPage
    )
);