import * as sprout from "sprout-data";
import {ActionPack, Reducer} from "./Action";
import * as combineReducers from "./combineReducers";
import {ReducerMap} from "./combineReducers";
import {initialState} from "../state/State";
//Notifications
import * as authLogin from "./auth/server/login";
import * as getUser from "./auth/server/getUser";
import * as authReLogin from "./auth/server/reLogin";
import * as updatePassword from "./auth/server/updatePassword";
import * as forgotPassword from "./auth/server/forgotPassword";
import * as resetPassword from "./auth/server/resetPassword";
import * as setResetPasswordToken from "./auth/server/setResetPasswordToken";
import * as resetAuthErrorAndSuccessStates from "./auth/server/resetAuthErrorAndSuccessStates";
import * as logout from "./auth/server/logout";
import * as toggleTestingMode from "./account/server/toggleTestingMode";
import * as register from "./auth/server/register";
import * as ping from "./auth/server/ping";
import * as pingnorefresh from "./auth/server/pingNoRefresh";
import * as setChangePasswordModalState from "./account/ui/setChangePasswordModalState";
import * as setStripeModalOpen from "./account/ui/setStripeModalOpen";
import * as setDeleteCardConfirmIsOpen from "./account/ui/setDeleteCardConfirmIsOpen";
import * as setSuccessModalIsOpen from "./account/ui/setSuccessModalIsOpen";
import * as setTwoFAPhoneNumber from "./account/ui/setMFAPhoneNumber";
import * as setCreateTokenState from "./account/server/createToken";
import * as addCreditCard from "./account/server/addCreditCard";
import * as getCreditCard from "./account/server/getCreditCard";
import * as deleteCreditCard from "./account/server/deleteCreditCard";
//
import * as setRestErrorAlertOpen from "./errors/ui/setRestErrorAlertOpen";
import * as setRestError from "./errors/server/setRestError";
import * as getIntercomUserHash from "./account/server/getIntercomUserHash";
import * as getSubscriptionTier from "./account/server/getSubscriptionTier";
import * as getLongLivedToken from "./account/server/getLongLivedToken";
//user/apikeys
import * as getApiKeys from "./user/server/getApiKeys";
import * as getApiKey from "./user/server/getApiKey";
import * as deleteApiKey from "./user/server/deleteApiKey";
import * as createApiKey from "./user/server/createApiKey";
//MFA packs
import * as toggleMFAVerify from "./account/ui/toggleMFAVerify";
import * as setMFAMethodModalState from "./account/ui/setMFAMethodModalState";
import * as setQRCodeModalOpen from "./account/ui/setQRCodeModalOpen";
import * as toggleMFASuccessModal from "./account/ui/toggleMFASuccessModal";
import * as goToDeviceState from "./account/ui/goToDeviceState";
import * as addDeviceMFA from "./auth/server/MFA/addDevice";
import * as authMFA from "./auth/server/MFA/authMFA";
import * as disableMFA from "./auth/server/MFA/disable";
import * as enableMFA from "./auth/server/MFA/enable";
import * as getDeviceMFA from "./auth/server/MFA/getDevice";
import * as getDeviceForLoginMFA from "./auth/server/MFA/getDeviceForLogin";
import * as resendAuthCode from "./auth/server/MFA/resendAuthCode";
import * as getMFABackupCodes from "./auth/server/MFA/getBackupCodes";
//GENERIC UI ACTION
import * as genericUIAction from "./uiAction";
//Account
import * as createAccountUser from "./account/server/createAccountUser";
import * as deleteActiveAccountUser from "./account/server/deleteActiveAccountUser";
import * as deleteInvitedAccountUser from "./account/server/deleteInvitedAccountUser";
import * as getAccountInvites from "./account/server/getAccountInvites";
import * as getAccountUser from "./account/server/getAccountUser";
import * as getAccountUsers from "./account/server/getAccountUsers";
import * as clearAccountUsersList from "./account/server/clearAccountUsersList";
import * as updateAccountUser from "./account/server/updateAccountUser";
import * as resendTeamMemberInvite from "./account/server/resendAccountUserInvite";

import * as updatePasswordField from "./components/ui/updatePasswordField";
//Contacts
import * as getContacts from "./contacts/server/getContacts";
import * as createContact from "./contacts/server/createContact";
import * as getContact from "./contacts/server/getContact";
import * as getAndMergeContacts from "./contacts/server/getAndMergeContacts";
import * as getAndAppendContacts from "./contacts/server/getAndAppendContacts";
import * as clearContact from "./contacts/server/clearContacts";
import * as updateContact from "./contacts/server/updateContact";
import * as setCreateContactValueState from "./contacts/ui/setCreateContactValueState";
import * as setContactActionState from "./contacts/ui/setContactActionState";
import * as clearContactError from "./contacts/server/clearContactError";
// Programs
import * as getPrograms from "./programs/server/getPrograms";
import * as getProgramById from "./programs/server/getProgram";
import * as getAllPrograms from "./programs/server/getAndAppendPrograms";
import * as getActivePrograms from "./programs/server/getAndAppendActivePrograms";
import * as getInactivePrograms from "./programs/server/getAndAppendInactivePrograms";
import * as clearAllPrograms from "./programs/server/clearAllProgramsList";
import * as clearActivePrograms from "./programs/server/clearActiveProgramsList";
import * as clearInactivePrograms from "./programs/server/clearInactiveProgramsList";
import * as clearProgramStats from "./programs/server/clearProgramStats";
import * as createProgram from "./programs/server/createProgram";
import * as updateProgram from "./programs/server/updateProgram";
import * as getAndMergePrograms from "./programs/server/getAndMergePrograms";
import * as getProgramStats from "./programs/server/getProgramStats";
// Issuances
import * as getIssuances from "./issuance/server/getIssuances";
import * as getAndAppendIssuances from "./issuance/server/getAndAppendIssuances";
import * as getIssuance from "./issuance/server/getIssuance";
import * as clearIssuances from "./issuance/server/clearIssuances";
import * as setIssuanceCreate from "./issuance/ui/setIssuanceCreateState";
import * as createIssuance from "./issuance/server/createIssuance";
//Values
import * as createValue from "./values/server/createValue";
import * as getValues from "./values/server/getValues";
import * as getAttachedValues from "./values/server/getAttachedValues";
import * as getValuesCSV from "./values/server/getValuesCSV";
import * as getValue from "./values/server/getValue";
import * as changeValuesCode from "./values/server/changeValuesCode";
import * as modifyValuesDate from "./values/server/modifyValuesDate";
import * as getAndAppendValues from "./values/server/getAndAppendValues";
import * as clearValues from "./values/server/clearValues";
import * as clearAttachedValues from "./values/server/clearAttachedValues";
import * as updateValue from "./values/server/updateValue";
import * as toggleFullCodeModal from "./values/ui/toggleFullCodeModal";
import * as toggleChangeCodeModal from "./values/ui/toggleChangeCodeModal";
import * as toggleModifyDateModal from "./values/ui/toggleModifyDateModal";
import * as setPendingValueAction from "./values/ui/setPendingValueAction";
import * as createValueDropDown from "./values/ui/createValueDropDown";
import * as clearValueDropDown from "./values/ui/clearValueDropDown";
import * as toggleTransactionModal from "./values/ui/toggleTransactionModal";
import * as attachValueToContact from "./values/server/attachValueToContact";
import * as detachValueFromContact from "./values/server/detachValueFromContact";
import * as getValueStats from "./values/server/getValueStats";
import * as clearValueError from "./values/server/clearValueError";
//Storage
import * as getStorage from "./kvStorage/server/getStorage";
import * as getStorageValue from "./kvStorage/server/getStorageValue";
import * as updateStorageValue from "./kvStorage/server/updateStorageValue";
import * as deleteStorageValue from "./kvStorage/server/deleteStorageValue";
//TurnKey
import * as updateTurnKey from "./turnkey/ui/updateTurnKeyUiConfig";
import * as setTurnKeyEditing from "./turnkey/ui/setTurnKeyEditMode";
import * as updateTurnkeyImageUpload from "./turnkey/ui/updateTurnKeyImageUpload";
import * as updateTurnkeyErrors from "./turnkey/ui/updateTurnKeyUiErrors";
import * as getTurnkeyStripe from "./turnkey/server/getTurnkeyStripe";
import * as connectTurnkeyStripe from "./turnkey/server/connectTurnkeyStripe";
import * as deleteTurnkeyStripe from "./turnkey/server/deleteTurnkeyStripeConnection";
// Transactions
import * as getAndAppendTransactions from "./transactions/server/getAndAppendTransactions";
import * as clearTransactionsList from "./transactions/server/clearTransactionsList";
import * as getTransaction from "./transactions/server/getTransaction";
import * as getTransactions from "./transactions/server/getTransactions";
import * as debitTransactions from "./transactions/server/debitTransaction";
import * as creditTransactions from "./transactions/server/creditTransaction";
import * as getTransactionChain from "./transactions/server/getTransactionChain";
import * as capturePendingTransaction from "./transactions/server/capturePendingTransaction";
import * as voidPendingTransaction from "./transactions/server/voidPendingTransaction";
import * as reverseTransaction from "./transactions/server/reverseTransaction";
import * as setTransactionActionState from "./transactions/ui/setTransactionActionState";
// Currencies
import * as createCurrency from "./currency/server/createCurrency";
import * as deleteCurrency from "./currency/server/deleteCurrency";
import * as getCurrency from "./currency/server/getCurrency";
import * as listCurrencies from "./currency/server/listCurrencies";
import * as updateCurrency from "./currency/server/updateCurrency";
import * as setEditCurrencyState from "./currency/ui/setEditCurrencyState";
// Webhooks
import * as createWebhook from "./webhooks/server/createWebhook";
import * as getWebhook from "./webhooks/server/getWebhook";
import * as listWebhooks from "./webhooks/server/listWebhooks";
import * as updateWebhooks from "./webhooks/server/updateWebhooks";
import * as deleteWebhook from "./webhooks/server/deleteWebhook";
import * as clearWebhooksList from "./webhooks/server/clearWebhooksList";
import * as getSecret from "./webhooks/server/getSecret";
import * as createSecret from "./webhooks/server/createSecret";
// Search
import * as globalSearch from "./search/server/globalSearch";
import * as clearGlobalSearch from "./search/server/clearSearch";
// History
import * as addLocationToHistory from "./history/ui/addLocationToHistory";
// Components
import * as saveAntFormData from "./form/saveAntFormData";
import * as contactCustomerSupport from "./contactCustomerSupport/server/contactCustomerSupport";
// JWT
import * as getJwt from "./jwt/getJwt";
// Reports
import * as generateReports from "./generateReport/server/generateReport";
// Accounts
import * as listAccounts from "./account/server/listAccounts";
import * as switchAccounts from "./account/server/switchAccounts";
import * as createAccount from "./account/server/createAccount";
import * as editAccount from "./account/server/editAccount";
import * as getAccountDetails from "./account/server/getAccountDetails";
import * as removeAccountMessage from "./auth/server/removeAccountMessage";

/**
 * Lists of ActionPacks to be combined. Combined array actionPacks is only exported for unit testing.
 */
//Account
const userServerActions: ActionPack[] = [getApiKeys, getApiKey, createApiKey, deleteApiKey];
const MFAServerActions: ActionPack[] = [addDeviceMFA, authMFA, disableMFA, enableMFA, getDeviceMFA, getDeviceForLoginMFA, resendAuthCode, getMFABackupCodes];
const MFAUIActions: ActionPack[] = [toggleMFAVerify, goToDeviceState, toggleMFASuccessModal, setTwoFAPhoneNumber, setMFAMethodModalState, setQRCodeModalOpen];
const loginActions: ActionPack[] = [authLogin, authReLogin, register, ping, pingnorefresh, updatePassword, forgotPassword, resetPassword, setResetPasswordToken, getLongLivedToken, resetAuthErrorAndSuccessStates, logout];
const accountUIActions: ActionPack[] = [setChangePasswordModalState, toggleTestingMode, setStripeModalOpen, setDeleteCardConfirmIsOpen, setSuccessModalIsOpen];
const accountServerActions: ActionPack[] = [addCreditCard, getCreditCard, deleteCreditCard, setCreateTokenState];
const errors: ActionPack[] = [setRestError, setRestErrorAlertOpen];
const userInformationActions: ActionPack[] = [getIntercomUserHash, getSubscriptionTier, getUser];
const accountUserServerActions: ActionPack[] = [getAccountDetails, getAccountUsers, updateAccountUser, getAccountUser, getAccountInvites, createAccountUser, deleteActiveAccountUser, deleteInvitedAccountUser, resendTeamMemberInvite, clearAccountUsersList, listAccounts, switchAccounts, createAccount, editAccount, getAccountDetails, removeAccountMessage];
const componentActions: ActionPack[] = [updatePasswordField];

//Contacts
const contactServerActions: ActionPack[] = [getContacts, getAndMergeContacts, createContact, getContact, getAndAppendContacts, clearContact, updateContact, clearContactError];
const contactUiActions: ActionPack[] = [setCreateContactValueState, setContactActionState];
//Programs
const programServerAction: ActionPack[] = [getProgramById, getPrograms, getAllPrograms, getActivePrograms, getInactivePrograms, clearAllPrograms, clearActivePrograms, clearInactivePrograms, clearProgramStats, createProgram, updateProgram, getAndMergePrograms, getProgramStats];
//Issuances
const issuanceServerActions: ActionPack[] = [getIssuance, getIssuances, getAndAppendIssuances, clearIssuances, setIssuanceCreate, createIssuance];
//Values
const valuesServerAction: ActionPack[] = [createValue, getValues, getAttachedValues, getValue, getAndAppendValues, clearValues, clearAttachedValues, getValuesCSV, updateValue, attachValueToContact, detachValueFromContact, getValueStats, clearValueError, changeValuesCode, modifyValuesDate];
const valuesUiActions: ActionPack[] = [toggleFullCodeModal, toggleChangeCodeModal, setPendingValueAction, createValueDropDown, clearValueDropDown, toggleTransactionModal, toggleModifyDateModal];
//
const storageActions: ActionPack[] = [getStorage, getStorageValue, updateStorageValue, deleteStorageValue];
//
const turnKeyActions: ActionPack[] = [updateTurnKey, setTurnKeyEditing, connectTurnkeyStripe, getTurnkeyStripe, deleteTurnkeyStripe, updateTurnkeyImageUpload, updateTurnkeyErrors];
//
const transactionActions: ActionPack[] = [getTransactions, getTransaction, getAndAppendTransactions, clearTransactionsList, debitTransactions, creditTransactions, getTransactionChain, capturePendingTransaction, voidPendingTransaction, reverseTransaction, setTransactionActionState];
//
const searchActions: ActionPack[] = [globalSearch, clearGlobalSearch];
//
const currencyActions: ActionPack[] = [updateCurrency, listCurrencies, getCurrency, deleteCurrency, createCurrency, setEditCurrencyState];
//
const webhooksActions: ActionPack[] = [updateWebhooks, listWebhooks, getWebhook, createWebhook, deleteWebhook, clearWebhooksList, getSecret, createSecret];
//

export const actionPacks: ActionPack[] = [
    genericUIAction,
    addLocationToHistory,
    saveAntFormData,
    contactCustomerSupport,
    getJwt,
    generateReports,
    ...loginActions,
    ...MFAServerActions,
    ...MFAUIActions,
    ...accountUIActions,
    ...accountServerActions,
    ...contactServerActions,
    ...contactUiActions,
    ...issuanceServerActions,
    ...programServerAction,
    ...valuesServerAction,
    ...valuesUiActions,
    ...errors,
    ...userInformationActions,
    ...userServerActions,
    ...accountUserServerActions,
    ...transactionActions,
    ...componentActions,
    ...storageActions,
    ...turnKeyActions,
    ...searchActions,
    ...currencyActions,
    ...webhooksActions,
];

/**
 * A list of ReducerMaps compiled from the actionPacks.  Only exported for unit testing.
 */
export const reducerMaps: ReducerMap[] = [
    ...actionPacks
        .filter(actionPack => !!actionPack.type && !!actionPack.reducer)
        .map(actionPack => ({[actionPack.type]: actionPack.reducer} as ReducerMap)),
    ...actionPacks
        .filter(actionPack => !!actionPack.reducerMap)
        .map(actionPack => actionPack.reducerMap)
];
const mergedReducerMap = sprout.merge<ReducerMap>({}, ...reducerMaps);

const mergedReducer = process.env["DEEP_FREEZE"] == "true"
    ? combineReducers.deepFreeze(combineReducers.byType(mergedReducerMap))
    : combineReducers.byType(mergedReducerMap);
export const reducer: Reducer<any> = combineReducers.setDefaultState(initialState, combineReducers.byBruteForce([mergedReducer, combineReducers.byKey({
    ui: (s: any): any => s || {},
    server: (s: any): any => s || {},
    form: (s: any): any => s || {},
    browser: (s: any): any => s || {}
})]));
//export const reducer: Reducer<any> = combineReducers.setDefaultState(initialState, mergedReducer);
