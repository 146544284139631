//[ TAG MANAGER EVENTß ]
export interface TagManagerEvent {
    event: string;

    [item: string]: string | number;
}

export const updateTagManagerDataLayer = (event: TagManagerEvent): void => {
    const dataLayer = !!(window as any).dataLayer ? (window as any).dataLayer : [];
    dataLayer.push(event);
};