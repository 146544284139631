import * as React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {AuthMFARequest} from "../../../communication/dtos/auth/MFA/MFA";
import {useForm} from "antd/lib/form/Form";
import {LockOutlined} from "@ant-design/icons";

export interface Props {
    processing?: boolean;
    errorText: string;
    onSubmit: (data: AuthMFARequest) => void;
    logout: () => void;
}

export const MFAAuthForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [disableSubmit, setDisableSubmit] = React.useState(true);

    let requiredError: boolean = false;
    const codeError = props.errorText;

    const submit = async (): Promise<void> => {

        try {
            const values = await form.validateFields();

            const request: AuthMFARequest = {
                code: values[FormFieldIds.Code],
                trustThisDevice: values[FormFieldIds.TrustDevice]
            };

            props.onSubmit(request);
        } catch {
            requiredError = true;
        }

    };

    React.useEffect(() => {
        const fieldError = form.getFieldsError([FormFieldIds.Code]);
        if (fieldError?.length) {
            setDisableSubmit(
                !!fieldError[0].errors.length
            );
        }
    });

    return (
        <div>
            <Form
                onFinish={submit}
                form={form}
            >
                <Form.Item
                    validateStatus={(codeError || requiredError) ? "error" : "success"}
                    help={!!codeError ? codeError : (!!requiredError) ? requiredError : ""}
                    name={FormFieldIds.Code}
                    rules={[{required: true, message: "Code Required"}]}
                    className="marginBottom0"
                >
                    <Input
                        size="large"
                        autoFocus={true}
                        prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                        placeholder="Enter Code"
                    />
                </Form.Item>
                <Form.Item
                    name={FormFieldIds.TrustDevice}
                    valuePropName="checked"
                >
                    <Checkbox>Trust This Device</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        size="large"
                        style={{width: "100%"}}
                        htmlType="submit"
                        disabled={disableSubmit}
                        loading={props.processing}
                    >
                        Log in
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        size="large"
                        className="marginRight16"
                        style={{width: "100%"}}
                        onClick={props.logout}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};