import * as React from "react";
import {useEffect, useState} from "react";
import * as moment from "moment";
import {Collapse, Divider, Form, message, Select, Spin} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {ProgramValueItem} from "../../ui/forms/items/ProgramValueItem";
import {findCurrency, normalizeValueForServer} from "../../../util/currencyUtils";
import {ExternalProps, ReduxProps} from "./CreateValueForContactFormConnected";
import {generateId} from "../../../communication/utils/urlUtils";
import {CreateValueParams, ListProgramsParams,} from "lightrail-client/dist/params";
import {withCurrencies, WithCurrencies} from "../../ui/currency/withCurrenciesHOC";
import {MetadataItems} from "../../ui/forms/items/MetadataItems";
import {UsesRemainingItem} from "../../ui/forms/items/UsesRemainingItem";
import {InitialStatusItem} from "../../ui/forms/items/InitialStatusItem";
import {DateTimePicker} from "../../ui/forms/items/DateTimePicker";
import {withRouter} from "react-router-dom";
import {WithRouterProps} from "../../router/WithRouterProps";
import {ClickToAdd} from "../../ui/forms/ClickToAdd";
import {getInitialEndDate} from "../../../util/forms/forms";
import {cleanNulls} from "../../../util/communicationUtils";
import {useForm} from "antd/lib/form/Form";
import {getProgram} from "lightrail-client/dist/programs";

const METADATA_NOTES_KEY = "lightrail_webapp_new_contact_value_notes";

interface InternalProps extends WithCurrencies, WithRouterProps<{}>, ExternalProps, ReduxProps {
}

export const CreateValueForContactForm = withRouter(withCurrencies((props: InternalProps) => {
    const [form] = useForm();

    const [programId, setProgramId] = useState(props.programs[0]?.id);
    const [program, setProgram] = useState(
        (!!programId) ? props.programs.find(p => p.id === programId) : props.programs[0]
    );

    const fetchPrograms = (params?: ListProgramsParams): void => {
        props.getActivePrograms({
            ...params,
            limit: 1000
        });
    };

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        // Program
        let program = props.programs.find(p => p.id === values[FormFieldIds.Program]);
        if (!program) {
            // It's possible that props.programs is empty since it may have been empty
            // when this component was loaded which causes submit to have a stale
            // reference to props.programs. This is kind of hacky solution to the problem.
            program = (await getProgram(values[FormFieldIds.Program])).body;
            if (!program) {
                return;
            }
        }

        // Fetch Currency
        const currency = findCurrency(props.currencies, program.currency);
        if (!currency) {
            return;
        }

        //Setup Creation Params
        const creationValues: CreateValueParams = {
            id: generateId(),
            active: values[FormFieldIds.Active],
            currency: program.currency,
            contactId: props.contact.id,
            balanceRule: program.balanceRule,
            programId: program.id,
            discount: program.discount,
            redemptionRule: program.redemptionRule,
            usesRemaining: null,
            pretax: program.pretax
        };

        if (values[FormFieldIds.Balance] != undefined) {
            creationValues.balance = normalizeValueForServer(values.balance, currency.decimalPlaces);
        }

        if (values[FormFieldIds.BalanceRule] != undefined) {
            creationValues.balanceRule = values[FormFieldIds.BalanceRule];
        }

        if (values[FormFieldIds.StartDate]) {
            creationValues.startDate = values[FormFieldIds.StartDate].toISOString();
        }

        if (values[FormFieldIds.EndDate]) {
            creationValues.endDate = values[FormFieldIds.EndDate].toISOString();
        }

        const metadataFromForm = MetadataItems.parseMetadataFromSubmitValues(values, METADATA_NOTES_KEY);
        if (metadataFromForm != null) {
            creationValues.metadata = metadataFromForm;
        }

        if (values[FormFieldIds.UsesRemaining] != undefined) {
            creationValues.usesRemaining = values.usesRemaining;
        }

        // Create
        try {
            await props.createValue(cleanNulls(creationValues));
            message.success(`Value attached ${(props.contact.email) ? `to ${props.contact.email}` : ""}`);
            props.onSuccess();
        } catch {
            message.error(`Error creating value: ${props.valueServerState.error}`);
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }

        if (!props.programs.length) {
            fetchPrograms();
        }
    }, []);

    useEffect(() => {
        setProgramId(props.programs[0]?.id);
    }, [props.programs]);

    useEffect(() => {
        const newProgram = (!!programId) ? props.programs.find(p => p.id === programId) : props.programs[0];
        setProgram(newProgram);
    }, [programId]);

    if (!props.programs.length || props.fetchingActivePrograms || !program) {
        return (
            <Spin/>
        );
    }

    return (
        <Form
            onFinish={submit}
            form={form}
            layout="vertical"
            className="lr-create-form"
        >
            <Form.Item
                label="Choose Program"
                name={FormFieldIds.Program}
                initialValue={program?.id}
            >
                <Select
                    size="large"
                    onChange={(id) => {
                        setProgramId(id.toString());
                    }}
                >
                    {
                        props.programs.map(p => (
                            <Select.Option key={p.id} value={p.id}>
                                {p.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <ProgramValueItem
                program={program}
                form={form}
            />
            <Collapse
                defaultActiveKey={["details"]}
                bordered={false}
            >
                <Collapse.Panel
                    key="advanced"
                    header={<h3>Advanced</h3>}
                    forceRender
                >
                    <p className="lr-text-secondary">These Value Details were set by the program. Any changes below
                        will override the program defaults.</p>
                    <Divider/>
                    <ClickToAdd
                        forceActive={!!program.startDate}
                        message="+ Add Start Date"
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.StartDate}
                            form={form}
                            label="Codes active from:"
                            initialValue={program.startDate || moment().startOf("day").toISOString()}
                        />
                    </ClickToAdd>
                    <ClickToAdd
                        forceActive={!!program.endDate}
                        message="+ Add Expiry Date"
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.EndDate}
                            form={form}
                            label="Codes expire on:"
                            initialValue={program.endDate || getInitialEndDate(form.getFieldValue(FormFieldIds.StartDate))}
                        />
                    </ClickToAdd>
                    <Divider/>
                    <UsesRemainingItem
                        fieldId={FormFieldIds.UsesRemaining}
                        form={form}
                        program={program}
                    />
                    <Divider/>
                    <InitialStatusItem form={form} program={program}/>
                </Collapse.Panel>
                <Collapse.Panel key="metadata" header={<h3>Metadata</h3>}>
                    <MetadataItems
                        metadata={program.metadata}
                        form={form}
                        metadataNotesKey={METADATA_NOTES_KEY}
                    />
                </Collapse.Panel>
            </Collapse>
        </Form>
    );
}));