import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {ListTransactionsResponse} from "lightrail-client/dist/params";
import {listTransactions} from "lightrail-client/dist/transactions";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getAndAppendTransactions";
export const actionCreator = promisePattern.actionCreator(type, listTransactions);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListTransactionsResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? [...state.server.transactions.list, ...payload?.body] : state.server.transactions.list;
        return sprout.assoc(state,
            ["server", "transactions", "list"], list,
            ["server", "transactions", "links"], payload?.links,
            ["server", "transactions", "pagination"], {maxLimit: payload?.maxLimit, limit: payload?.limit},
            ["server", "transactions", "fetching"], false,
            ["server", "transactions", "error"], null
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "transactions"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);