import * as React from "react";
import {useEffect} from "react";
import {Button, Form} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {EmailItem} from "../../../ui/forms/items/EmailItem";
import {AuthServerStateErrorMessage} from "../../../../state/serverState/AuthServerState";
import {AuthFormErrorItem} from "../../forms/AuthFormErrorItem";
import {useForm} from "antd/lib/form/Form";

export interface Props {
    errorMessage?: AuthServerStateErrorMessage;

    onUpdate?: () => void;
    processing?: boolean;
    onSubmit: (email: string) => void;
}

export const ForgotPasswordForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [email, setEmail] = React.useState("");

    const [errors, setErrors] = React.useState([
        ...(props.errorMessage || [])
    ]);

    const submit = (): void => {
        form.validateFields().then(
            values => props.onSubmit(values[FormFieldIds.Email])
        );
    };

    useEffect(() => {
        if (props.onUpdate && props.errorMessage?.length) {
            props.onUpdate();
        }
    });

    useEffect(() => {
        const fieldErrors = form.getFieldError(FormFieldIds.Email);
        setErrors(fieldErrors);
    }, [email]);

    return (
        <Form onFinish={submit} form={form}>
            <EmailItem
                size="large"
                form={form}
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
            />
            <AuthFormErrorItem errors={errors as string[]}/>
            <Button
                type="primary"
                htmlType="submit"
                style={{width: "100%"}}
                size="large"
                disabled={!!errors.length || !email.length}
                loading={props.processing}
            >
                Reset Password
            </Button>
        </Form>
    );
};