import {State} from "../../state/State";
import {actionCreator as listValuesCSV} from "../../actions/values/server/getValuesCSV";
import * as ReactRedux from "react-redux";
import {Issuance} from "lightrail-client/dist/model/Issuance";
import {ButtonProps} from "antd/lib/button";
import {IssuanceDownloadButton} from "./IssuanceDownloadButton";

interface StateProps {}

export interface ExternalProps {
    issuance: Issuance;
    buttonProps?: ButtonProps;
    text?: string;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): StateProps & ExternalProps => ({
    ...ownProps
});

const actionCreators = {listValuesCSV};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const IssuanceDownloadButtonConnected = connector(IssuanceDownloadButton);