import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ValueActionMenu, ValueActionMenuContext} from "./ValueActionMenu";
import {ValueActionsDropDown, ValueActionStatus} from "../../../state/uistate/ValueUiState";
import {actionCreator as getValue} from "../../../actions/values/server/getValue";
import {actionCreator as changeValuesCode} from "../../../actions/values/server/changeValuesCode";
import {actionCreator as updateValue} from "../../../actions/values/server/updateValue";
import {actionCreator as toggleFullCodeModal} from "../../../actions/values/ui/toggleFullCodeModal";
import {actionCreator as toggleChangeCodeModal} from "../../../actions/values/ui/toggleChangeCodeModal";
import {actionCreator as toggleModifyDateModal} from "../../../actions/values/ui/toggleModifyDateModal";
import {actionCreator as setPendingValueAction} from "../../../actions/values/ui/setPendingValueAction";
import {actionCreator as clearValueDropDown} from "../../../actions/values/ui/clearValueDropDown";
import {actionCreator as toggleTransactionModal} from "../../../actions/values/ui/toggleTransactionModal";
import {actionCreator as createValueDropDown} from "../../../actions/values/ui/createValueDropDown";
import {actionCreator as detachContact} from "../../../actions/values/server/detachValueFromContact";
import {actionCreator as attachContact} from "../../../actions/values/server/attachValueToContact";
import {Contact, Transaction, Value} from "lightrail-client/dist/model";
import {ValueServerState} from "../../../state/serverState/ValueServerState";

export interface ExternalProps {
    value: Value;
    transaction?: Transaction;
    label?: string;
    onContactAttached?: (contactId: string) => void;
    menuContext?: ValueActionMenuContext;
}

export interface StateProps {
    valueStateServer: ValueServerState;
    valueStateUI: ValueActionsDropDown;
    attachedContact: Contact;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    valueStateServer: state.server.values,
    valueStateUI: state.ui.value.actionDropdowns[props.value.id] || {
        changeCodeModalOpen: false,
        showFullCodeModalOpen: false,
        transactionModalOpen: false,
        pendingValueAction: ValueActionStatus.NO_ACTION,
        modifyDatesModalOpen: false,
    },
    attachedContact: (!!props.value.contactId) ? state.server.contacts.list.find(c => c.id === props.value.contactId) : null,
    ...props
});

const actionCreators = {
    getValue,
    updateValue,
    changeValuesCode,
    toggleFullCodeModal,
    toggleChangeCodeModal,
    toggleTransactionModal,
    setPendingValueAction,
    clearValueDropDown,
    createValueDropDown,
    detachContact,
    attachContact,
    toggleModifyDateModal,
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ValueActionMenuConnected = connector(ValueActionMenu);