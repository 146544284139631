import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler, RejectionReducer } from "../../rejectionHandler";
import { deleteStripe } from "../../../communication/turnkey";
import { DeleteTurnkeyStripeResponse } from "../../../communication/dtos/turnkey/DeleteTurnkeyStripe";

export const type = "deleteTurnkeyStripeConnection";

export const actionCreator = promisePattern.actionCreator(type, deleteStripe);

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteTurnkeyStripeResponse> =
    (state) => {
        return sprout.assoc(state,
            ["server", "turnkey", "stripe", "connected"], false,
            ["server", "turnkey", "stripe", "location"], "",
            ["server", "turnkey", "stripe", "processing"], false,
            ["server", "turnkey", "stripe", "error"], null
        );
    };

const onRejection: RejectionReducer = {
    reducer: (state, error) => sprout.assoc(state,
        ["server", "turnkey", "stripe", "processing"], false,
        ["server", "turnkey", "stripe", "error"], error.message
    )
};

const onRejected = rejectionHandler([onRejection]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            turnkey: {
                stripe: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);