import * as React from "react";

export interface Props {
}

export class RouteNotFoundPage extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div>
                <h2>Page Not Found!</h2>
            </div>
        );
    }
}