import * as React from "react";
import {useEffect} from "react";
import {Form, Input} from "antd";
import {AllowSubmitOnEnter} from "./AllowSubmitOnEnter";
import {FormInstance} from "antd/es/form";
import {useForm} from "antd/lib/form/Form";

export interface Props {
    hint?: string;
    initialValue?: string;
    required?: boolean;
    regex?: string;
    autoFocus?: boolean;
    form?: FormInstance;

    getSubmit?: (submit?: () => void) => void;
    onSubmit: (value: string | number) => void;
}

const FieldID = "value";

export const SingleEntryForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        props.onSubmit(values[FieldID]);
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    return (
        <Form
            onFinish={submit}
            form={form}
        >
            <Form.Item
                name={FieldID}
                rules={[
                    {required: props.required, message: "Required"}
                ]}
            >
                <Input
                    autoFocus={props.autoFocus}
                    placeholder={props.hint}
                />
            </Form.Item>
            <AllowSubmitOnEnter/>
        </Form>
    );
};