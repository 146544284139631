import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler, RejectionReducer } from "../../rejectionHandler";
import { getStripe } from "../../../communication/turnkey";
import { GetTurnkeyStripeResponse } from "../../../communication/dtos/turnkey/GetTurnkeyStripe";

export const type = "getStripe";

export const actionCreator = promisePattern.actionCreator(type, getStripe);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetTurnkeyStripeResponse> =
    (state, payload) => sprout.assoc(state,
        ["server", "turnkey", "stripe", "connected"], payload?.connected,
        ["server", "turnkey", "stripe", "fetching"], false,
        ["server", "turnkey", "stripe", "error"], null
    );

const onRejection: RejectionReducer = {
    reducer: (state, error) => sprout.assoc(state,
        ["server", "turnkey", "stripe", "fetching"], false,
        ["server", "turnkey", "stripe", "error"], error.message
    )
};

const onRejected = rejectionHandler([onRejection]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            turnkey: {
                stripe: {
                    fetching: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);