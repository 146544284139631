import * as ReactRedux from "react-redux";
import {ValuePerformance} from "./ValuePerformance";
import {actionCreator as getStats} from "../../../../../actions/values/server/getValueStats";
import {State} from "../../../../../state/State";
import {Value} from "lightrail-client/dist/model";
import { ValueStatsServerState } from "../../../../../state/serverState/ValueServerState";

export interface ExternalProps {
    value: Value;
}

interface StateProps {
    statsState: ValueStatsServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    statsState: state.server.values.stats,
    ...props
});

const actionCreators = {
    getStats
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ValuePerformanceConnected = connector(ValuePerformance);