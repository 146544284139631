import * as React from "react";
import {ValueTabs} from "../../../../../state/uistate/SelectedTabsUiState";
import {Tabs} from "antd";
import {UiActionPaths} from "../../../../../state/uistate/UiActionPaths";
import {ValuePerformanceConnected} from "../valuePerformance/ValuePerformanceConnected";
import {ContactsListConnected} from "../../../../contacts/list/ContactsListConnected";
import {ContactActionMenuType} from "../../../../contacts/contactActions/ContactActionMenu";
import {TransactionsListConnected} from "../../../../transactions/list/TransactionsListConnected";
import {ExternalProps, ReduxProps} from "./ValueDetailTabsConnected";
import {WithRouterProps} from "../../../../router/WithRouterProps";

export interface Props extends  WithRouterProps<any>, ExternalProps, ReduxProps {
}

export class ValueDetailTabs extends React.PureComponent<Props, {}> {
    valueIdParams: { valueId: string };

    constructor(props: Props) {
        super(props);

        this.valueIdParams = {valueId: (!!this.props.value) ? this.props.value.id : null};

        this.onTabChange = this.onTabChange.bind(this);
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.value !== this.props.value) {
            this.valueIdParams = {valueId: this.props.value.id};
        }
    }

    onTabChange(activeTab: string): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.SET_SELECTED_VALUE_TAB,
            value: activeTab
        });
    }

    render(): JSX.Element {
        const genericCode = this.props.value.isGenericCode;

        return (
            <Tabs activeKey={this.props.selectedTab} animated={false} onChange={this.onTabChange}>
                <Tabs.TabPane tab="Transactions" key={ValueTabs.TRANSACTIONS}>
                    {
                        (this.props.selectedTab === ValueTabs.TRANSACTIONS) &&
                        <TransactionsListConnected
                            params={this.valueIdParams}
                        />
                    }
                </Tabs.TabPane>
                {
                    (genericCode) &&
                    <Tabs.TabPane tab="Contacts" key={ValueTabs.CONTACTS}>
                        {
                            (this.props.selectedTab === ValueTabs.CONTACTS) &&
                            <ContactsListConnected
                                params={this.valueIdParams}
                                menuContext={{
                                    type: ContactActionMenuType.DEFAULT,
                                    data: null
                                }}
                                highlightNewRows
                            />
                        }
                    </Tabs.TabPane>
                }
                {
                    (genericCode) &&
                    <Tabs.TabPane tab="Performance" key={ValueTabs.PERFORMANCE}>
                        {
                            (this.props.selectedTab === ValueTabs.PERFORMANCE) &&
                            <ValuePerformanceConnected value={this.props.value}/>
                        }
                    </Tabs.TabPane>
                }
            </Tabs>
        );
    }
}
