import * as React from "react";
import {MFA} from "../profile/MFAConnected";

export interface Props {
}

export class AccountSecurityLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <MFA/>
        );
    }
}