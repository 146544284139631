import * as ReactRedux from "react-redux";
import {State} from "../../state/State";
import {ContactsPage} from "./ContactsPage";
import {actionCreator as uiAction} from "../../actions/uiAction";

interface StateProps {
    createContactOpen: boolean;
    contactsProcessing: boolean;
}

const mapStateToProps = (state: State): StateProps => ({
    createContactOpen: state.ui.contacts.createContactOpen,
    contactsProcessing: state.server.contacts.processing
});

const actionCreators = {
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ContactsPageConnected = connector(ContactsPage);