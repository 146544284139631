import * as React from "react";
import {ReactNode} from "react";
import {ListTransactionsParams} from "lightrail-client/dist/params";
import {Table} from "antd";
import {capitalizeFirstLetter, defaultDash} from "../../../util/stringUtils";
import {dateTimeSecondsFormat, formatDate} from "../../../util/dateUtils";
import {LoadMoreFooter} from "../../ui/pagination/LoadMoreFooter";
import {formatRoute, Paths} from "../../../util/routes/routes";
import {WithRouterProps} from "../../router/WithRouterProps";
import {TransactionAmountDisplay} from "../TransactionAmountDisplay";
import {PendingOrSuccessTag} from "../PendingOrSuccessTag";
import {ColumnProps} from "antd/lib/table";
import {ExternalProps, ReduxProps} from "./TransactionsListConnected";

interface TableRow {
    key: string;
    id: string;
    createdDate: ReactNode;
    amount: ReactNode;
    type: ReactNode;
    status: ReactNode;
}

export interface Props extends WithRouterProps<{}>, ExternalProps, ReduxProps {
}

export class TransactionsList extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.fetchTransactions = this.fetchTransactions.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.setupRow = this.setupRow.bind(this);
    }

    componentDidUpdate(oldProps: Props): void {
        if (this.props.params && this.props.params.valueId != oldProps.params.valueId) {
            this.props.clearTransactions({});
            this.fetchTransactions(this.props.params);
        }
    }

    componentDidMount(): void {
        this.props.clearTransactions({});
        (this.props.params) ? this.fetchTransactions(this.props.params) : this.fetchTransactions();
    }

    fetchTransactions(params?: ListTransactionsParams): void {
        this.props.getTransactions({...params, limit: 50});
    }

    ///////////////////
    //[ ACTIONS ]
    onRowClick(record: TableRow): void {
        this.props.history.push(formatRoute(Paths.TRANSACTION_DETAIL, {transactionId: record.id}));
    }

    setupRow(record: TableRow): { onClick: () => void } {
        return {onClick: () => this.onRowClick(record)};
    }

    ///////////////////
    //[ RENDER ]
    getTableRows(): TableRow[] {
        return this.props.transactionsState.list.map(
            transaction => ({
                key: transaction.id,
                id: transaction.id,
                createdDate: formatDate(transaction.createdDate, dateTimeSecondsFormat),
                amount: <TransactionAmountDisplay transaction={transaction}/>,
                type: (!!transaction.transactionType) ?
                    capitalizeFirstLetter(transaction.transactionType) :
                    defaultDash,
                status: <PendingOrSuccessTag pending={transaction.pending}/>,
            })
        );
    }

    render(): JSX.Element {
        const tableColumns: ColumnProps<TableRow>[] = [
            {
                title: "",
                dataIndex: "amount",
                key: "amount",
                width: 180,
                fixed: "left"
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type"
            },
            {
                title: "",
                dataIndex: "status",
                key: "status"
            },
            {
                title: "Date",
                dataIndex: "createdDate",
                key: "createdDate",
                width: 200,
                fixed: "right"
            }
        ];

        return (
            <React.Fragment>
                <Table
                    className="lr-table-row-clickable"
                    loading={this.props.transactionsState.fetching}
                    columns={tableColumns}
                    dataSource={this.getTableRows()}
                    onRow={this.setupRow}
                    pagination={false}
                    sortDirections={["ascend", "descend"]}
                    scroll={{x: 700}}
                />
                <LoadMoreFooter
                    numResults={this.props.transactionsState.list.length}
                    limit={this.props.transactionsState.pagination.limit}
                    loading={this.props.transactionsState.fetching}
                    links={this.props.transactionsState.links}
                    onClick={this.fetchTransactions}
                />
            </React.Fragment>
        );
    }
}