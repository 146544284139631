import {ActionCreator, Reducer} from "../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../promisePattern";

export interface ActionParams {
    id: string;
    data: any;
}

export interface ActionCreatorArgs {
    id: string;
    data: any;
}

export const type = "saveAntFormData";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.deepMerge(state, {
        form: {
            [action.payload?.id]: action.payload?.data
        }
    });
};

export const reducerMap = promisePattern.reducerMap(type, reducer);