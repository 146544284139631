import * as sprout from "sprout-data";
import * as promisePattern from "../promisePattern";
import {getParsedJwt} from "../../util/jwt";

export const type = "getJwt";
export const actionCreator = promisePattern.actionCreator(type, getParsedJwt);

const onFulfilled: promisePattern.PromiseFulfilledHandler<any> =
    (state, payload) => {
        return sprout.assoc(state,
            ["browser", "jwt", "fetching"], false,
            ["browser", "jwt", "data"], payload
        );
    };

const onRejected: promisePattern.PromiseRejectedHandler = (state) => sprout.assoc(state,
    ["browser", "jwt", "fetching"], false,
    ["browser", "jwt", "error"], true
);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc(state,
        ["browser", "jwt", "fetching"], true,
        ["browser", "jwt", "error"], false
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);