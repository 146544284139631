import * as restAccess from "./utils/restAccess";
import {CreateWebhookParams} from "./dtos/webhooks/CreateWebhookParams";
import {Webhook} from "./dtos/webhooks/Webhook";
import {GetWebhookRequest} from "./dtos/webhooks/GetWebhook";
import {ListWebhooksResponse} from "./dtos/webhooks/ListWebhooks";
import {DeleteWebhookResponse} from "./dtos/webhooks/DeleteWebhook";
import {GetSecretRequest, Secret} from "./dtos/webhooks/Secret";
import {UpdateWebhookParams} from "./dtos/webhooks/UpdateWebhookParams";
import {cleanNulls} from "../util/communicationUtils";

const webhookPrefix = "/v2/webhooks";

export const createWebhook = (values: CreateWebhookParams): Promise<Webhook> =>
    restAccess.fetchJson(`${webhookPrefix}`, {}, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

export const getWebhook = (id: string): Promise<GetWebhookRequest> => restAccess.fetchJson(`${webhookPrefix}/${id}`);

export const listWebhooks = (): Promise<ListWebhooksResponse> => restAccess.fetchJson(`${webhookPrefix}`);

export const updateWebhooks = (id: string, params: UpdateWebhookParams): Promise<Webhook> =>
    restAccess.fetchJson(`${webhookPrefix}/${id}`, {}, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(params))
    });

export const deleteWebhook = (id: string): Promise<DeleteWebhookResponse> =>
    restAccess.fetchJson(`${webhookPrefix}/${id}`, {}, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });

export const createSecret = (id: string): Promise<Secret> =>
    restAccess.fetchJson(`${webhookPrefix}/${id}/secrets`, {}, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        }
    });

export const deleteSecret = (values: GetSecretRequest): Promise<Secret> =>
    restAccess.fetchJson(`${webhookPrefix}/${values.webhookId}/secrets/${values.secretId}`, {}, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    });

export const getSecret = (values: GetSecretRequest): Promise<Secret> => restAccess.fetchJson(`${webhookPrefix}/${values.webhookId}/secrets/${values.secretId}`);
