import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {deleteValue} from "lightrail-client/dist/values";
import {deleteListObject} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";
import {DeleteValueResponse} from "lightrail-client/dist/params/values/DeleteValueParams";

export interface DeleteValueActionResponse {
    valueId: string;
    response: DeleteValueResponse;
}

export const type = "deleteValue";
export const actionCreator = promisePattern.actionCreator(type, (valueId: string) => deleteValue(valueId).then(response => (
    {
        response,
        valueId
    })).catch(error => error)
);

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteValueActionResponse> =
    (state, payload) => {
        const list = (payload?.response.body.success) ? deleteListObject(state.server.values.list, payload?.valueId, "id") : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "processing"], false,
            ["server", "values", "status"], payload?.response.status
        );
    };

const onRejected = rejectionHandler([
    {
        overrideGlobal: true,
        reducer: (state, error) => {
            return sprout.assoc(state,
                ["server", "values", "processing"], false,
                ["server", "values", "error"], error.message,
                ["server", "values", "status"], error.status
            );
        }
    }
]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);