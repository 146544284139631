import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {ValueActionsDropDown} from "../../../state/uistate/ValueUiState";

export interface ActionParams {
    valueId: string;
    params: ValueActionsDropDown;
}

export const type = "setPendingValueAction";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state, ["ui", "value", "actionDropdowns", action.payload?.valueId, "pendingValueAction"], action.payload?.params.pendingValueAction);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);