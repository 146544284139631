import * as moment from "moment";
import {defaultDash} from "./stringUtils";

//NOTE: dateUtils should be used to encapsulate all usage of moment to reduce the cost if we ever need to drop/replace moment

/**
 * Compares two dates to see if they are from the same day
 * @param date1 - Date or isoString
 * @param date2 - Optional, if not provided current Date will be used
 * @returns {boolean}
 */
export const isSameDay = (date1: Date | string, date2?: Date | string): boolean => moment(date2).isSame(moment(date1), "day");

/**
 * Compares date to today's date to see if the date is in the past
 * @param date1 - Date or isoString
 * @returns {boolean}
 */
export const isBeforeToday = (date1: Date | string): boolean => moment(date1).isBefore(moment(), "day");

////////////////////////////////////////////
//[ DATE FORMATTING ]
// @see https://momentjs.com/#format-dates
export const defaultFormat = "MMM D, YYYY  hh:mm A";
export const dateTimeSecondsFormat = "MMM D, YYYY  hh:mm:ss A";
export const dateFormat = "MMM Do, YYYY";
export const dateTimeWithSlashFormat = "YYYY/MM/DD hh:mm A";

/**
 * Date formatting, should be used instead of directly using momentjs module
 *
 * @param date
 * @param template
 * @returns {string}
 */
export const formatDate = (date: string | Date, template?: string): string  => {
    if (!date) {
        return "Not Set";
    }
    return moment(date).format(template ? template : defaultFormat);
};

export const dateIsInFuture = (endDate: string): boolean => {
    return endDate > moment.utc(moment.now()).format();
};

export const dateIsInPast = (endDate: string): boolean => {
    return endDate <= moment.utc(moment.now()).format();
};

export const formatedDateOrDash = (date: string | Date, template?: string): string => (!!date) ? formatDate(date, template) : defaultDash;