import * as React from "react";
import {HTMLProps} from "react";
import {dissoc} from "sprout-data";

import "./paper.scss";

export interface Props extends HTMLProps<HTMLDivElement> {
}

export class Paper extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div
                className={this.props.className ? `${this.props.className} paper` : "paper"} {...dissoc(this.props, ["children"], ["className"])}>
                {this.props.children}
            </div>
        );
    }
}