import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {TransactionsList} from "./TransactionsList";
import {actionCreator as getTransactions} from "../../../actions/transactions/server/getAndAppendTransactions";
import {actionCreator as clearTransactions} from "../../../actions/transactions/server/clearTransactionsList";
import {ListTransactionsParams} from "lightrail-client/dist/params";
import {withRouter} from "react-router-dom";
import {TransactionsServerState} from "../../../state/serverState/TransactionsServerState";

export interface ExternalProps {
    params?: ListTransactionsParams;
    expandable?: boolean;
}

interface StateProps {
    transactionsState: TransactionsServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    ...props,
    transactionsState: state.server.transactions
});

const actionCreators = {getTransactions, clearTransactions};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const TransactionsListConnected = ReactRedux.connect(mapStateToProps, actionCreators)(withRouter(TransactionsList));