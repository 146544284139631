import * as React from "react";
import {formatMetadataForDetailsDisplay} from "../../../../util/formatMetadataForDetailsDisplay";
import {DetailValues} from "./DetailValues";

export interface Props {
    showTitle?: boolean;
    metadata: { [key: string]: any };
}

export class MetadataDetailValue extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        if (!this.props.metadata) {
            return null;
        }

        if (!!Object.keys(this.props.metadata).length) {
            return (
                <DetailValues
                    title={(this.props.showTitle === false) ? "" : "Metadata"}
                    className="lr-detailValues-marginTop"
                    style={{whiteSpace: "pre"}}
                    data={[formatMetadataForDetailsDisplay(this.props.metadata)]}/>);
        }

        return <div>No metadata.</div>;
    }
}