import * as React from "react";
import * as CopyToClipboard from "copy-to-clipboard";
import {Button, message, Modal, Spin} from "antd";
import {Value} from "lightrail-client/dist/model";
import {stopPropagation} from "../../../util/link";
import {FormattedCurrency} from "../../ui/currency/FormattedCurrencyConnected";
import { CopyOutlined } from "@ant-design/icons";

export interface Props {
    open: boolean;
    fetching: boolean;
    value: Value;

    onClose: () => void;
}

export class ShowFullCodeModal extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
    }

    onCopyToClipboard(e?: any): void {
        stopPropagation(e);

        CopyToClipboard(this.props.value.code);
        message.success("Full Code copied to Clipboard");
    }

    render(): JSX.Element {
        return (
            <Modal
                title="Full Code"
                visible={this.props.open}
                onCancel={this.props.onClose}
                footer={[
                    <Button key="back" onClick={this.props.onClose}>Close</Button>,
                    <Button
                        key="submit"
                        type="primary"
                        disabled={this.props.fetching}
                        onClick={this.onCopyToClipboard}
                    >
                        <CopyOutlined/>Copy to clipboard
                    </Button>
                ]}
            >
                <div className="textCenter">
                    {
                        (!!this.props.fetching)
                            ?
                            <Spin className="lr-modal-spinner"/>
                            :
                            <div className="codeDisplay fullCode">
                                {this.props.value.code}
                            </div>
                    }
                    {(!!this.props.value.balanceRule) ? <p>{this.props.value.balanceRule.explanation}</p> :
                        <p>
                            <FormattedCurrency
                                code={this.props.value.currency}
                                value={this.props.value.balance}
                            />
                            {this.props.value.currency}
                        </p>}
                </div>
            </Modal>
        );
    }
}