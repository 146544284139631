import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {TurnkeyTokens} from "./TurnkeyTokens";
import {actionCreator as updateStorageValue} from "../../../../actions/kvStorage/server/updateStorageValue";
import {actionCreator as getStorageValue} from "../../../../actions/kvStorage/server/getStorageValue";
import {actionCreator as uiAction} from "../../../../actions/uiAction";
import {StorageKeys} from "../../../../state/serverState/keyValue/KVStorageState";

interface StateProps {
    jwtSecret: string;
    confirmGenerateTokenOpen: boolean;
}

const mapStateToProps = (state: State): StateProps => ({
    jwtSecret: state.server.kvStorage.values[StorageKeys.JWT_SECRET],
    confirmGenerateTokenOpen: state.ui.team.confirmGenerateTokenOpen
});

const actionCreators = {
    getStorageValue,
    updateStorageValue,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const TurnkeyTokensConnected = connector(TurnkeyTokens);