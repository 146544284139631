import * as promisePattern from "../../../promisePattern";
import * as MFA from "../../../../communication/MFA";
import {GetDeviceResponse} from "../../../../communication/dtos/auth/MFA/GetDevice";
import * as sprout from "sprout-data";
import {rejectionHandler, RejectionReducer} from "../../../rejectionHandler";
import {State} from "../../../../state/State";

export const type = "getMFADeviceForLogin";

export const actionCreator = promisePattern.actionCreator(type, MFA.getDeviceForLogin);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetDeviceResponse> =
    (state, payload) => {
        return sprout.assoc(state, ["server", "MFA", "device"], payload?.device);
    };

const onGetDeviceRejected: RejectionReducer = {
    status: 403,
    overrideGlobal: true,
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            MFA: {
                error: error.message,
            }
        }
    })
};

const onRejected = rejectionHandler([onGetDeviceRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return state;
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);