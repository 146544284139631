import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {ActionCreator, Reducer} from "../../Action";
import {SearchId} from "../../../state/serverState/SearchesServerState";

export interface ClearGlobalSearchParams {
    id: SearchId;
}

export const type = "clearGlobalSearch";
export const actionCreator: ActionCreator<ClearGlobalSearchParams, ClearGlobalSearchParams> = (payload) => ({
    type,
    payload
});

export const reducer: Reducer<ClearGlobalSearchParams> = (state, action) => {
    return sprout.assoc(state,
        ["server", "search", action.payload?.id, "fetching"], false,
        ["server", "search", action.payload?.id, "fetchCount"], 0,
        ["server", "search", action.payload?.id, "searchedType"], 0,
        ["server", "search", action.payload?.id, "searchedValue"], "",
        ["server", "search", action.payload?.id, "results"], {
            programs: [],
            contacts: [],
            values: [],
            transactions: []
        }
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);