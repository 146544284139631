import * as ReactRedux from "react-redux";
import {State} from "../../../../../state/State";
import {ValueDetailTabs} from "./ValueDetailTabs";
import {actionCreator as uiAction} from "../../../../../actions/uiAction";
import {Contact, Value} from "lightrail-client/dist/model";
import { ValueTabs } from "../../../../../state/uiState/SelectedTabsUiState";
import { withRouter } from "react-router-dom";

export interface ExternalProps {
    value: Value;
    detachContact: (contact: Contact) => void;
}

interface StateProps {
    selectedTab: ValueTabs;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    selectedTab: state.ui.selectedTabs.valueTab,
    ...ownProps
});

const actionCreators = {
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ValueDetailTabsConnected = connector(withRouter(ValueDetailTabs));