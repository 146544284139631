import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {CurrencyPage} from "./CurrencyPage";
import {actionCreator as listCurrencies} from "../../../actions/currency/server/listCurrencies";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {actionCreator as deleteCurrency} from "../../../actions/currency/server/deleteCurrency";
import {actionCreator as setEditCurrency} from "../../../actions/currency/ui/setEditCurrencyState";
import {CurrencyServerState} from "../../../state/serverState/CurrencyServerState";
import {CurrencyUiState} from "../../../state/uistate/CurrencyUiState";

interface StateProps {
    currencyServerState: CurrencyServerState;
    currencyUiState: CurrencyUiState;
}

const mapStateToProps = (state: State): StateProps => ({
    currencyServerState: state.server.currency,
    currencyUiState: state.ui.currency,
});

const actionCreators = {
    listCurrencies,
    setEditCurrency,
    deleteCurrency,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CurrencyPageConnected = connector(CurrencyPage);