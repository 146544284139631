import * as React from "react";
import {find} from "lodash";
import {
    AccountUserInvitees,
    Role,
    User,
    UserPrivilege,
    UserPrivilegeTypes
} from "../../communication/dtos/account/User";
import {TeamInviteMemberForm} from "./creation/TeamInviteMemberForm";
import {ActiveAccountUserRow} from "./ActiveAccountUserRow";
import {InvitedAccountUserRow} from "./InvitedAccountUserRow";
import {Body1, Body2, Subheading} from "../ui/typography/TypographyTags";
import {TextColor} from "../ui/typography/ColoredText";
import {UpdateAccountUserDialog} from "./UpdateAccountUserDialog";
import {UiActionPaths} from "../../state/uistate/UiActionPaths";
import {errorStrings} from "../../state/serverState/RestError";
import {Button, Divider, message, Popover} from "antd";
import {AccountHeader} from "../layout/account/AccountHeader";
import {AccountLayout} from "../layout/account/AccountLayout";
import {ReduxProps} from "./integrations/AccountUsersLayoutConnected";
import {PlusOutlined} from "@ant-design/icons";

import "./teams.scss";
import {WithRouterProps} from "../router/WithRouterProps";
import {stripUserIdTestMode} from "../../util/userUtil";

export interface Props extends ReduxProps, WithRouterProps<any> {
}

export class AccountUsersLayout extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onUpdateTeamMemberRequestClose = this.onUpdateTeamMemberRequestClose.bind(this);
        this.onUpdateTeamMember = this.onUpdateTeamMember.bind(this);
        this.onInviteTeamMemberFormSubmit = this.onInviteTeamMemberFormSubmit.bind(this);
        this.onOpenUpdateTeamMember = this.onOpenUpdateTeamMember.bind(this);
        this.onDeleteActiveMember = this.onDeleteActiveMember.bind(this);
        this.onDeleteInvitedMember = this.onDeleteInvitedMember.bind(this);
        this.onResendMemberInvite = this.onResendMemberInvite.bind(this);
        this.onToggleInviteTeamMember = this.onToggleInviteTeamMember.bind(this);
        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount(): void {
        this.getUsers();
    }

    async getUsers(): Promise<void> {
        await Promise.all([
            this.props.getAccountInvites({}),
            this.props.clearAccountUsersList({}),
            this.props.getUsers({})
        ]);
    }

    ///////////////////
    // [ ACTIONS ]
    onToggleInviteTeamMember(): void {
        const value = !this.props.uiState.inviteTeamMemberOpen;
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_INVITE_MEMBER_OPEN, value});
    }

    async onInviteTeamMemberFormSubmit(values: { email: string, userPrivilegeType: UserPrivilege }): Promise<void> {
        const params = {email: values.email, userPrivilegeType: values.userPrivilegeType};
        const response = await this.props.createAccountUser(params);
        await this.getUsers();
        this.onToggleInviteTeamMember();
        message.success(`${response.value.email} has been invited`);
    }

    onOpenUpdateTeamMember(userId: string): void {
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_MEMBER_TO_EDIT, value: userId});
    }

    async onUpdateTeamMember(userId: string, roles: Role[]): Promise<void> {
        const teamMember = find(this.props.usersState.list, {userId: this.props.uiState.teamMemberToUpdate});

        if (!!teamMember && teamMember.roles === roles) {
            this.onUpdateTeamMemberRequestClose();
        } else {
            try {
                await this.props.updateUser({userId: userId, roles});
                this.onUpdateTeamMemberRequestClose();
                message.success(`${!!teamMember ? teamMember.userDisplayName : "Team member"} updated`);
                this.getUsers();
            } catch {
                this.onUpdateTeamMemberRequestClose();
            }
        }
    }

    onUpdateTeamMemberRequestClose(): void {
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_MEMBER_TO_EDIT, value: ""});
    }

    async onDeleteActiveMember(userId: string): Promise<void> {
        await this.props.deleteActiveAccountUser({userId: userId});
        message.success(`Team Member Deleted. This will not effect API Keys they created.`);
        this.getUsers();
    }

    async onDeleteInvitedMember(userId: string): Promise<void> {
        await this.props.deleteInvitedAccountUser({userId: userId});
        message.success(`Invite Deleted`);
    }

    onResendMemberInvite(value: { email: string }): void {
        const params = {email: value.email};
        this.props.resendAccountUserInvite(params);
        this.props.getAccountInvites({});
        message.success(`Invitation Resent`);
        this.getUsers();
    }

    ///////////////////
    //[ RENDERING ]
    renderInviteMemberForm(): JSX.Element {
        const content = (
            <div>
                <Subheading className="marginTop8">Invite Team Member</Subheading>
                <Body1 textColor={TextColor.ACCENT3}>
                    {
                        (!!this.props.invitesState.error) ? `Error: ${this.props.invitesState.error}` : "\u00a0"
                    }
                </Body1>
                <TeamInviteMemberForm
                    processing={this.props.processing}
                    initialValues={{email: "", userPrivilegeType: UserPrivilegeTypes.FULL_ACCESS}}
                    onSubmit={this.onInviteTeamMemberFormSubmit}
                />
            </div>
        );

        return (
            <div className={"inviteTeamMemberContainer"}>
                <Popover
                    content={content}
                    trigger="click"
                    placement="left"
                    onVisibleChange={this.onToggleInviteTeamMember}
                    visible={this.props.uiState.inviteTeamMemberOpen}
                >
                    <Button><PlusOutlined/>Invite Team Member</Button>
                </Popover>
            </div>
        );
    }

    renderActiveAccountUserListItem(user: User, isSelf: boolean, hideButtons?: boolean): JSX.Element {
        return (
            <ActiveAccountUserRow
                key={user.userId}
                accountUser={user}
                isSelf={isSelf}
                hideButtons={hideButtons}
                onEdit={this.onOpenUpdateTeamMember}
                onDelete={this.onDeleteActiveMember}
            />
        );
    }

    renderInvitedAccountUserListItem(accountUserInvitee: AccountUserInvitees): JSX.Element {
        return (
            <InvitedAccountUserRow
                key={accountUserInvitee.userId}
                accountUserInvitee={accountUserInvitee}
                onDelete={this.onDeleteInvitedMember}
                onResendInvite={this.onResendMemberInvite}
            />
        );
    }

    listActiveMembers(): JSX.Element {
        const fullAccessMembers: User[] = [];
        const limitedAccessMembers: User[] = [];

        if (!!this.props.users) {
            this.props.users.forEach(user => {
                let isAdmin = false;
                for (let i = 0; i < user.roles.length; i++) {
                    if (user.roles[i] == "teamAdmin") {
                        isAdmin = true;
                        fullAccessMembers.push(user);
                    }
                }
                if (!isAdmin) {
                    limitedAccessMembers.push(user);
                }
            });
        }

        const renderFullAccess: JSX.Element = (fullAccessMembers.length > 0)
            ? <div>
                <Subheading style={{marginTop: 24, marginBottom: 8, paddingTop: 8}}>Full Access</Subheading>
                {fullAccessMembers.map((activeMember: User) => (
                    this.renderActiveAccountUserListItem(activeMember, stripUserIdTestMode(this.props.userId) === stripUserIdTestMode(activeMember.userId))
                ))}
            </div>
            : null;

        const renderLimitedAccess: JSX.Element = (limitedAccessMembers.length > 0)
            ? <div>
                <Subheading style={{marginTop: 24, marginBottom: 8, paddingTop: 8}}>Limited Access</Subheading>
                {limitedAccessMembers.map((activeMember: User) => (
                    this.renderActiveAccountUserListItem(activeMember, stripUserIdTestMode(this.props.userId) === stripUserIdTestMode(activeMember.userId))
                ))}
            </div>
            : null;

        return (
            <div>
                {renderFullAccess}
                {renderLimitedAccess}
            </div>
        );
    }

    renderActiveMembersTable(): JSX.Element {
        return (
            <div>
                {
                    !!this.props.users && this.listActiveMembers()
                }
            </div>
        );
    }

    listInvitedMembers(): JSX.Element[] {
        if (!!this.props.invites) {
            return (
                this.props.invites.map((invitee: AccountUserInvitees) => (
                    this.renderInvitedAccountUserListItem(invitee)
                ))
            );
        }
        return null;
    }

    // If there are invited team members, always show this
    renderInvitedMembersTable(): JSX.Element {
        if (this.props.invites && this.props.invites.length) {
            return (
                <div>
                    <div className="paddingBottom32 paddingTop24">
                        <AccountHeader title="Invited Members"/>
                        {this.listInvitedMembers()}
                    </div>
                    <Divider/>
                </div>
            );
        }

        return null;
    }

    renderUpdateAccountUserDialog(): JSX.Element {
        const accountUser = find(this.props.usersState.list, {userId: this.props.uiState.teamMemberToUpdate});

        return (
            <UpdateAccountUserDialog
                open={!!this.props.uiState.teamMemberToUpdate}
                accountUser={accountUser}
                processing={this.props.usersState.processing}
                onUpdate={this.onUpdateTeamMember}
                onRequestClose={this.onUpdateTeamMemberRequestClose}
            />
        );
    }

    render(): JSX.Element {
        if (this.props.usersState.error === errorStrings.PERMISSION_ERROR_403) {
            return (
                <AccountLayout>
                    <Body2>You do not have sufficient permissions to view this section.</Body2>
                </AccountLayout>
            );
        } else {
            return (
                <AccountLayout>
                    <AccountHeader title="Active Members" actionButton={this.renderInviteMemberForm()}/>
                    {this.renderActiveMembersTable()}
                    <Divider/>
                    {this.renderInvitedMembersTable()}
                    {this.renderUpdateAccountUserDialog()}
                </AccountLayout>
            );
        }
    }
}