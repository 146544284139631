import * as React from "react";
import {EnableRequest} from "../../../communication/dtos/auth/MFA/Enable";
import {EnableMFANumberForm} from "../EnableMFANumberForm";
import {supportedPhoneRegions} from "../../../util/forms/Countries";
import {EnableMFACodeForm} from "../EnableMFACodeForm";
import {download} from "../../../util/downloadUtils";
import {marginRight8} from "../../../styles/legacy/spacing";
import {BackupCode} from "../../../communication/dtos/auth/MFA/GetBackupCodes";
import {Button, message, Modal, Switch} from "antd";
import {displayInlineBlock} from "../../../styles/legacy/styles";
import {ReduxProps, ExternalProps} from "./MFAConnected";
import {MFABackupCodeModal} from "./MFABackupCodeModal";
import {EnableMFAMethodForm} from "../EnableMFAMethodForm";
import {EnableMFAQrCodeForm} from "../EnableMFAQrCodeForm";
import {EditOutlined, DownloadOutlined} from "@ant-design/icons";

export interface Props extends ExternalProps, ReduxProps {
}

interface InternalState {
    isBackupCodeModalOpen: boolean;
}

export class MFA extends React.PureComponent<Props, InternalState> {
    numberFormSubmit: () => void;
    QrFormSubmit: () => void;
    enableCodeFormSubmit: () => void;
    setMFAMethodSubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.state = {
            isBackupCodeModalOpen: false
        };

        this.getEnableCodeSubmit = this.getEnableCodeSubmit.bind(this);
        this.getNumberFormSubmit = this.getNumberFormSubmit.bind(this);
        this.getQrFormSubmit = this.getQrFormSubmit.bind(this);
        this.getMFAMethodFormSubmit = this.getMFAMethodFormSubmit.bind(this);
        this.onOK = this.onOK.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateMFAMethod = this.updateMFAMethod.bind(this);
        this.closeVerifyModal = this.closeVerifyModal.bind(this);
        this.submitNumber = this.submitNumber.bind(this);
        this.submitQr = this.submitQr.bind(this);
        this.submitCode = this.submitCode.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.renderEnabledElements = this.renderEnabledElements.bind(this);
        this.renderMFAMethodForm = this.renderMFAMethodForm.bind(this);
        this.renderDevice = this.renderDevice.bind(this);
        this.renderCode = this.renderCode.bind(this);
        this.setMFAMethod = this.setMFAMethod.bind(this);
        this.closeBackupCodeModal = this.closeBackupCodeModal.bind(this);
        this.openBackupCodeModal = this.openBackupCodeModal.bind(this);
    }

    componentDidMount(): void {
        this.fetchDevice();
    }

    componentDidUpdate(): void {
        this.fetchDevice();
    }

    fetchDevice(): void {
        if (this.props.server.enabled) {
            this.props.getDevice({});
        }
    }

    async toggle(checked: boolean): Promise<void> {
        if (checked) {
            this.props.toggleVerify({toggle: checked});
        } else {
            await this.props.disable({});
            message.info("MFA Disabled");
        }
    }

    updateMFAMethod(): void {
        this.props.toggleVerify({toggle: true});
    }

    closeVerifyModal(): void {
        this.props.toggleVerify({toggle: false});
    }

    openBackupCodeModal(): void {
        this.setState({isBackupCodeModalOpen: true});
    }

    closeBackupCodeModal(): void {
        this.setState({isBackupCodeModalOpen: false});
    }

    getNumberFormSubmit(submit: () => void): void {
        this.numberFormSubmit = submit;
    }

    getQrFormSubmit(submit: () => void): void {
        this.QrFormSubmit = submit;
    }

    getEnableCodeSubmit(submit: () => void): void {
        this.enableCodeFormSubmit = submit;
    }

    getMFAMethodFormSubmit(submit: () => void): void {
        this.setMFAMethodSubmit = submit;
    }

    setMFAMethod(data: { isOpen: boolean, method: string }): void {
        this.props.setMFAMethod({
            isOpen: data.isOpen,
            method: data.method
        });
        if (data.method === "App") {
            this.props.addDevice({device: "totp"});
        }
    }

    submitNumber(data: { number: string, countryCode: string }): void {
        const device = data.number;
        const deviceArea = data.countryCode;
        const deviceAreaCode = supportedPhoneRegions[deviceArea];

        this.props.setDeviceNumber({device, deviceArea});

        this.props.addDevice({device: deviceAreaCode + device});
    }

    submitQr(): void {
        this.props.setQRCodeModalOpen({isOpen: true});
    }

    resendCode(): void {
        const device = this.props.ui.device;
        const deviceAreaCode = supportedPhoneRegions[this.props.ui.deviceArea];
        this.props.addDevice({device: deviceAreaCode + device});
    }

    async submitCode(data: EnableRequest): Promise<void> {
        const response = await this.props.enable(data);

        if (response.value.device || response.value.complete) {
            message.success("MFA enabled.");
            if (this.props.afterSubmit) {
                this.props.afterSubmit();
            }
        } else {
            message.success("Please proceed to enter the next code from the authenticator app.");
        }
    }

    onOK(): void {
        if (!this.props.ui.isMFAMethodModalOpen) {
            if (!!this.setMFAMethodSubmit) {
                this.setMFAMethodSubmit();
            }
        } else if (!!this.enableCodeFormSubmit && (!!this.props.ui.QRCodeModalOpen || !!this.props.ui.phoneNumberSuccess)) {
            this.enableCodeFormSubmit();
        } else {
            if (!!this.QrFormSubmit && !!this.props.ui.QRCodeSuccess) {
                this.QrFormSubmit();
            } else {
                this.numberFormSubmit();
            }
        }
    }

    async downloadBackupCodes(): Promise<void> {
        await this.props.getBackupCodes({});
        const codes = this.props.server.backupCodes;
        const activeCodes = codes.map((code: BackupCode) => {
            return code;
        });
        const codesString = activeCodes.join("\n");

        download("lightrail-recovery-codes", codesString);
    }

    ///////////////////
    //[ RENDER ]
    renderEnabledElements(): JSX.Element {
        const device = (!!this.props.server.device && this.props.server.device === "totp" ? "Authenticator app" : `SMS(${this.props.server.device})`);
        return (
            <React.Fragment>
                <div className="marginTop16">
                    <span>Preferred method: <span className="fontColorSecondary">{device}</span></span>
                    <Button className="marginLeft8" onClick={this.updateMFAMethod}>
                        <EditOutlined/>Update
                    </Button>
                </div>
                <Button
                    className="marginTop24"
                    onClick={this.openBackupCodeModal}
                >
                    <DownloadOutlined/>Get Backup Codes
                </Button>
                <MFABackupCodeModal
                    visible={this.state.isBackupCodeModalOpen}
                    onClose={this.closeBackupCodeModal}
                />
            </React.Fragment>
        );
    }

    renderMFAMethodForm(): JSX.Element {
        return (
            <EnableMFAMethodForm
                getSubmitHook={this.getMFAMethodFormSubmit}
                onSubmit={this.setMFAMethod}
                disableButtons={this.props.server.pending}
            />
        );
    }

    renderDevice(): JSX.Element {
        return (
            <EnableMFANumberForm
                getSubmitHook={this.getNumberFormSubmit}
                onSubmit={this.submitNumber}
                numberError={this.props.ui.illegalDeviceMessage}
                disableButtons={this.props.server.pending}
                initialNumber={this.props.ui.device}
                initialCountry={this.props.ui.deviceArea}
            />
        );
    }

    renderQRCodeDevice(): JSX.Element {
        return (
            <EnableMFAQrCodeForm
                getSubmitHook={this.getQrFormSubmit}
                onSubmit={this.submitQr}
                disableButtons={this.props.server.pending}
                uri={this.props.server.uri}
                secret={this.props.server.secret}
            />
        );
    }

    renderCode(): JSX.Element {
        return (
            <EnableMFACodeForm
                number={this.props.ui.device ? this.props.ui.device : ""}
                codeError={this.props.ui.incorrectCodeMessage}
                resendCode={this.resendCode}
                disableButtons={this.props.server.pending}
                editNumber={this.props.goToDeviceState}
                method={this.props.ui.MFAMethod}
                isFirstCode={!this.props.ui.CodeOneAccepted}
                isSecondCode={!!this.props.ui.CodeOneAccepted}

                onSubmit={this.submitCode}
                getSubmitHook={this.getEnableCodeSubmit}
            />
        );
    }

    renderQRCode(): JSX.Element {
        return (
            <EnableMFACodeForm
                number={this.props.ui.device ? this.props.ui.device : ""}
                codeError={this.props.ui.incorrectCodeMessage}
                resendCode={this.resendCode}
                disableButtons={this.props.server.pending}
                editNumber={this.props.goToDeviceState}
                method={this.props.ui.MFAMethod}
                isFirstCode={!this.props.ui.CodeOneAccepted}
                isSecondCode={!!this.props.ui.CodeOneAccepted}

                onSubmit={this.submitCode}
                getSubmitHook={this.getEnableCodeSubmit}
            />
        );
    }

    render(): JSX.Element {
        const enabled = this.props.server.enabled;
        return (
            <div>
                <div style={{width: "250px"}}>
                    <h4 style={{...displayInlineBlock, ...marginRight8}}>MFA Enabled</h4>
                    <Switch
                        checked={enabled}
                        onChange={this.toggle}
                    />
                </div>
                {
                    enabled &&
                    this.renderEnabledElements()
                }
                <Modal
                    title={"Multi Factor Authentication"}
                    visible={this.props.ui.isMFAVerifyOpen || this.props.openByDefault}
                    okText={this.props.ui.QRCodeModalOpen ? "Done" : "Next"}
                    onOk={this.onOK}
                    confirmLoading={this.props.server.pending}
                    onCancel={this.props.onClose || this.closeVerifyModal}
                    okButtonProps={{disabled: this.props.ui.QRCodeModalOpen}}
                >
                    {!this.props.ui.isMFAMethodModalOpen && this.renderMFAMethodForm()}
                    {!!this.props.server.uri && this.props.ui.isMFAMethodModalOpen && (this.props.ui.MFAMethod === "App") && !this.props.ui.QRCodeModalOpen && this.renderQRCodeDevice()}
                    {this.props.ui.isMFAMethodModalOpen && (this.props.ui.MFAMethod === "Phone") && !this.props.ui.phoneNumberSuccess && this.renderDevice()}
                    {this.props.ui.phoneNumberSuccess && this.renderCode()}
                    {this.props.ui.QRCodeModalOpen && this.renderQRCode()}
                </Modal>
            </div>
        );
    }
}