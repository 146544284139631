import * as promisePattern from "../../promisePattern";
import * as sprout from "sprout-data";
import {State} from "../../../state/State";
import {ActionCreator, Reducer} from "../../Action";

export interface LoginErrorMessage {
    message: string;
    messageCode: string;
}

export const type = "removeAccountMessage";

export const actionCreator: ActionCreator<{}, {}> = () => ({type});

export const reducer: Reducer<LoginErrorMessage> = (state: State) => {
    return sprout.assoc(state,
        ["server", "account", "accountMessage"], {
            message: "",
            messageCode: ""
        },
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);