import {ActionCreator, Reducer} from "../../Action";
import {assoc, dissoc} from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    valueId: string;
}

export const type = "clearValueActions";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    const newValue = dissoc(state.ui.value.actionDropdowns, [action.payload?.valueId]);

    return assoc(state,
        ["ui", "value", "actionDropdowns"], newValue
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);