import * as React from "react";
import {Spin} from "antd";
import {FormattedCurrency} from "../../ui/currency/FormattedCurrencyConnected";
import {generateId} from "../../../communication/utils/urlUtils";
import {StatsTable} from "../../ui/data/StatsTable";
import {ExternalProps, ReduxProps} from "./ProgramPerformanceConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export class ProgramPerformance extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.fetchProgramStats = this.fetchProgramStats.bind(this);
    }

    componentDidMount(): void {
        this.props.clearProgramStats({});
        if (!!this.props.program) {
            this.fetchProgramStats(this.props.program.id);
        }
    }

    componentDidUpdate(oldProps: Props): void {
        if ((!oldProps.program && !!this.props.program) || (!!oldProps.program && oldProps.program.id != this.props.program.id)) {
            this.fetchProgramStats(this.props.program.id);
        }
    }

    componentWillUnmount(): void {
        this.props.clearProgramStats({});
    }

    displayRedemptionRate(value: number): string {
        if (isNaN(value)) {
            return "0%";
        }

        return `${value.toFixed(2)}%`;
    }

    //////////////////////////////////
    // Make the request for stats here
    //////////////////////////////////
    fetchProgramStats(programId: string): void {
        this.props.getProgramStats({programId});
    }

    ///////////////////
    //[ RENDER ]
    render(): JSX.Element {
        const programStatsData = this.props.programsState.stats.data;
        const program = this.props.program;

        if ((programStatsData == null || Object.keys(programStatsData).length === 0) || !program) {
            return (<Spin/>);
        }

        const firstTableDataSource = [
            {
                key: generateId(),
                label: "Total amount redeemed",
                value: <FormattedCurrency value={programStatsData.redeemed.balance} code={program.currency}/>
            },
            {
                key: generateId(),
                label: "Values used at least once",
                value: programStatsData.redeemed.count.toString()
            },
            {
                key: generateId(),
                label: "Total Overspend",
                value: <FormattedCurrency value={programStatsData.checkout.overspend} code={program.currency}/>
            },
            {
                key: generateId(),
                label: "Average Overspend",
                value: <FormattedCurrency
                    value={programStatsData.checkout.overspend / programStatsData.checkout.transactionCount}
                    code={program.currency}/>
            }
        ];

        const secondTableDataSource = [
            {
                key: generateId(),
                label: "Outstanding values",
                value: programStatsData.outstanding.count.toString()
            },
            {
                key: generateId(),
                label: "Outstanding amount",
                value: <FormattedCurrency value={programStatsData.outstanding.balance} code={program.currency}/>
            },
            {
                key: generateId(),
                label: "Canceled Values",
                value: programStatsData.canceled.count.toString()
            },
            {
                key: generateId(),
                label: "Canceled amount",
                value: <FormattedCurrency value={programStatsData.canceled.balance} code={program.currency}/>
            },
            {
                key: generateId(),
                label: "Expired values",
                value: programStatsData.expired.count.toString()
            },
            {
                key: generateId(),
                label: "Expired amount",
                value: <FormattedCurrency value={programStatsData.expired.balance} code={program.currency}/>
            }
        ];

        return (
            <div>
                <StatsTable dataSource={firstTableDataSource}/>
                <StatsTable dataSource={secondTableDataSource}/>
            </div>
        );
    }
}