import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {GetTransactionChainResponse} from "lightrail-client/dist/params";
import {getTransactionChain} from "lightrail-client/dist/transactions";
import {onPendingFetching} from "../../reducerHelpers";

export interface GetTransactionChainActionResponse {
    transactionId: string;
    response: GetTransactionChainResponse;
}

export const type = "getTransactionChain";
export const actionCreator = promisePattern.actionCreator(type, (id: string) => getTransactionChain(id).then(response => ({
    response: response,
    transactionId: id
})));

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetTransactionChainActionResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "transactions", "transactionChains", "chains", payload?.transactionId], payload?.response.body,
            ["server", "transactions", "transactionChains", "fetching"], false,
            ["server", "transactions", "transactionChains", "status"], payload?.response.status
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => {
    return onPendingFetching(state, ["server", "transactions", "transactionChains"]);
};

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);