import * as ReactRedux from "react-redux";
import {State} from "../../state/State";
import {ProgramDetailPage} from "./ProgramDetailPage";
import {actionCreator as getProgram} from "../../actions/programs/server/getProgram";
import {actionCreator as uiAction} from "../../actions/uiAction";
import {ProgramsServerState} from "../../state/serverState/ProgramsServerState";
import {ProgramUiState} from "../../state/uistate/ProgramUiState";
import {IssuanceUiState} from "../../state/uistate/IssuanceUiState";
import {CreateGenericCodeUIState} from "../../state/uistate/ValueUiState";
import {ProgramTabs} from "../../state/uistate/SelectedTabsUiState";

interface StateProps {
    programsState: ProgramsServerState;
    programUiState: ProgramUiState;
    issuanceUIState: IssuanceUiState;
    processingValue: boolean;
    createGenericCodeUiState: CreateGenericCodeUIState;
    tab: ProgramTabs;
}

const mapStateToProps = (state: State): StateProps => ({
    programsState: state.server.programs,
    programUiState: state.ui.programs,
    issuanceUIState: state.ui.issuance,
    processingValue: state.server.values.processing,
    createGenericCodeUiState: state.ui.value.createGenericCode,
    tab: state.ui.selectedTabs.programTab
});

const actionCreators = {
    getProgram,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ProgramDetailPageConnected = connector(ProgramDetailPage);