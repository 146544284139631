import * as React from "react";
import {Contact} from "lightrail-client/dist/model";
import Modal from "antd/es/modal/Modal";
import {ContactCreateAndEditFormConnected} from "./ContactCreateAndEditFormConnected";

export interface Props {
    open: boolean;
    contact: Contact;
    onClose: () => void;
}

export class ContactEditModal extends React.PureComponent<Props, {}> {
    formSubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.formSubmit = submit;
    }

    onOk(): void {
        if (this.formSubmit) {
            this.formSubmit();
        }
    }

    render(): JSX.Element {
        return (
            <Modal
                title="Edit Contact"
                onOk={this.onOk}
                onCancel={this.props.onClose}
                visible={this.props.open}
                destroyOnClose
            >
                <ContactCreateAndEditFormConnected contact={this.props.contact} getSubmit={this.setSubmit}
                                                   onClose={this.props.onClose}/>
            </Modal>
        );
    }
}