import * as React from "react";
import {message} from "antd";

export interface Props<P> {
    params?: P;
    successMessage?: string;
    failureMessage?: string;
    onUndoAsync?: (p?: P) => Promise<any>;
    onUndo?: (p?: P) => void;
}

export class UndoLink<P> extends React.PureComponent<Props<P>, {}> {
    constructor(props: Props<P>) {
        super(props);

        this.doAsyncUndo = this.doAsyncUndo.bind(this);
        this.onUndoClick = this.onUndoClick.bind(this);
    }

    doAsyncUndo(): void {
        const successMessage = this.props.successMessage || "Action undone";
        const failureMessage = this.props.failureMessage || "Action could not be undone";

        this.props.onUndoAsync(this.props.params)
            .then(() => {
                message.success(successMessage);
            }).catch(() => {
                message.error(failureMessage);
            });
    }

    onUndoClick(): void {
        message.destroy();

        if (!!this.props.onUndoAsync) {
            this.doAsyncUndo();
        }

        if (!!this.props.onUndo) {
            this.props.onUndo(this.props.params);
        }
    }

    render(): JSX.Element {
        return (<a className="lr-undolink" onClick={this.onUndoClick}>Undo</a>);
    }
}