import * as React from "react";
import {Body1} from "../../ui/typography/TypographyTags";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {Paths} from "../../../util/routes/routes";
import {ActionResponse} from "../../../actions/promisePattern";
import {LoginResponse} from "../../../communication/dtos/auth/Login";
import {TextColor} from "../../ui/typography/ColoredText";
import {WithRouterProps} from "../../router/WithRouterProps";
import {message, Modal} from "antd";
import {SessionWatcherForm} from "../forms/SessionWatcherForm";
import {ReduxProps} from "./SessionWatcherConnected";

export const PASSWORD_ID = "continueSession";
const INTERVAL_TIME = (0.5 * (60 * 1000));

export interface Props extends WithRouterProps<{}>, ReduxProps {
}

export class SessionWatcher extends React.PureComponent<Props, {}> {
    submitForm: () => void;
    pingInterval: number;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.setSubmitForm = this.setSubmitForm.bind(this);
        this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
        this.onSecondary = this.onSecondary.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
        this.onShowExpiryDialog = this.onShowExpiryDialog.bind(this);
        this.startPingInterval = this.startPingInterval.bind(this);
        this.killPingInterval = this.killPingInterval.bind(this);
        this.pingServer = this.pingServer.bind(this);
    }

    componentDidMount(): void {
        this.startPingInterval();
    }

    componentWillUnmount(): void {
        this.killPingInterval();
    }

    componentDidUpdate(newProps: Props): void {
        if (this.props.sessionState.expiredOpen !== newProps.sessionState.expiredOpen) {
            //If we don't have an email we can't easy re-log the user in, navigate them to the login screen
            if (!this.props.userEmail) {
                this.props.uiAction({uiStatePath: UiActionPaths.SESSION_EXPIRED_DIALOG_OPEN, value: false});
                this.props.history.push(Paths.LOGIN_ROOT);
            }
        }
    }

    setSubmitForm(submit: () => void): void {
        this.submitForm = submit;
    }

    onOk(): void {
        this.submitForm();
    }

    ///////////////////
    //[ INTERVAL ]
    startPingInterval(): void {
        this.killPingInterval();
        this.pingInterval = window.setInterval(this.pingServer, INTERVAL_TIME);
    }

    killPingInterval(): void {
        window.clearInterval(this.pingInterval);
    }

    async pingServer(): Promise<void> {
        try {
            await this.props.pingNoRefresh({});
            if (this.props.sessionState.expiredOpen) {
                this.props.uiAction({uiStatePath: UiActionPaths.SESSION_EXPIRED_DIALOG_OPEN, value: false});
            }
        } catch {
            this.killPingInterval();
            this.onShowExpiryDialog();
        }
    }

    async login(password: string): Promise<ActionResponse<LoginResponse>> {
        const response = await this.props.login({email: this.props.userEmail, password});
        this.onRequestClose();
        this.startPingInterval();

        return response;
    }

    ///////////////////
    //[ ACTIONS ]
    onShowExpiryDialog(): void {
        this.props.uiAction({uiStatePath: UiActionPaths.SESSION_EXPIRED_DIALOG_OPEN, value: true});
    }

    onPasswordSubmit(password: string): void {
        this.login(password).then(() => {
            message.success("Welcome back!");
        });
    }

    async onSecondary(): Promise<void> {
        await this.props.logout({});
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    onRequestClose(): void {
        if (this.props.sessionState.expiredOpen) {
            this.props.uiAction({uiStatePath: UiActionPaths.SESSION_EXPIRED_DIALOG_OPEN, value: false});
        }
    }

    ///////////////////
    //[ RENDER ]
    render(): JSX.Element {
        if (this.props.sessionState.expiredOpen) {
            return (
                <Modal
                    title="Your Session Expired"
                    visible={true}
                    onCancel={this.onSecondary}
                    cancelText="Leave"
                    onOk={this.onOk}
                    okText="Continue"
                    confirmLoading={this.props.authState.processing}
                    zIndex={2000}
                >
                    <div>
                        <Body1 className="marginBottom16">Enter your password to pick up where you left off.</Body1>
                        <SessionWatcherForm
                            processing={this.props.authState.processing}
                            getSubmitHook={this.setSubmitForm}
                            onSubmit={this.onPasswordSubmit}
                        />
                        {
                            (!!this.props.authState.errorMessage) &&
                            <Body1
                                textColor={TextColor.ACCENT3}
                            >
                                {
                                    this.props.authState.errorMessage.join(" ")
                                }
                            </Body1>
                        }
                    </div>
                </Modal>
            );
        }

        return null;
    }
}