import {doesLocationMatchRoute, Paths} from "./routes";

export type SubNavRoutes = { routeKey: string, tabs: SubNavItem[] };
export type SubNavItem = { label: string, route: string };

const SubnavRoutes: { [key: string]: SubNavRoutes } = {
    [Paths.ACCOUNT_SECTION_KEY]: {
        routeKey: Paths.ACCOUNT_SECTION_KEY,
        tabs: [
            {label: "Profile", route: Paths.ACCOUNT_PROFILE},
            {label: "Billing", route: Paths.ACCOUNT_BILLING},
            {label: "Integrations", route: Paths.ACCOUNT_API},
            {label: "Team", route: Paths.ACCOUNT_TEAM},
            {label: "Currencies", route: Paths.ACCOUNT_CURRENCY},
            {label: "Webhooks", route: Paths.ACCOUNT_WEBHOOK},
        ]
    }
};

//////////////////////////////
///[ UTIL METHODS ]
export const getSubnavRoutes = (location: string): SubNavRoutes => {
    let subRoutes: SubNavRoutes;
    const keys = Object.keys(SubnavRoutes);
    keys.forEach((key: string) => {
        if (doesLocationMatchRoute(location, key)) {
            subRoutes = SubnavRoutes[key];
        }
    });

    return subRoutes;
};