import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {StripeOAuth} from "./StripeOAuth";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {actionCreator as getTurnkeyStripe} from "../../../actions/turnkey/server/getTurnkeyStripe";
import {actionCreator as connectTurnkeyStripe} from "../../../actions/turnkey/server/connectTurnkeyStripe";
import {actionCreator as deleteTurnkeyStripe} from "../../../actions/turnkey/server/deleteTurnkeyStripeConnection";
import {TurnkeyStripeState} from "../../../state/serverState/TurnkeyServerState";

interface StateProps {
    serverState: TurnkeyStripeState;
}

const mapStateToProps = (state: State): StateProps => ({
    serverState: state.server.turnkey.stripe
});

const actionCreators = {
    getTurnkeyStripe,
    connectTurnkeyStripe,
    deleteTurnkeyStripe,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const StripeOAuthConnected = connector(StripeOAuth);