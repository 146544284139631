import {Dispatch, Middleware} from "redux";
import {Action} from "../actions/Action";
import {getFulfilledType, getPendingType} from "../actions/promisePattern";

import {type as RegisterActionType} from "../actions/auth/server/register";
import {type as GetProgramStatsActionType} from "../actions/programs/server/getProgramStats";
import {type as GetValueStatsActionType} from "../actions/values/server/getValueStats";

import {updateTagManagerDataLayer} from "../util/googleTagManager";

export const analyticsMiddleware: Middleware = () => (next: Dispatch) => <A extends Action<any>>(action: A) => {
    //[ REGISTER ]
    if (action.type === getFulfilledType(RegisterActionType)) {
        updateTagManagerDataLayer({"event": "account_created"});
    }

    //[ PROGRAM STATS REQUESTED EVENT - SERVER VERSION ]
    if (action.type === getPendingType(GetProgramStatsActionType)) {
        updateTagManagerDataLayer({"event": "program_stats_requested"});
    }

    //[ VALUE STATS REQUESTED EVENT - SERVER VERSION ]
    if (action.type === getPendingType(GetValueStatsActionType)) {
        updateTagManagerDataLayer({"event": "value_stats_requested"});
    }

    return next(action);
};