import {Currency} from "lightrail-client/dist/model/Currency";

export const USD_Default: Currency = {
    code: "USD",
    name: "United States dollar",
    symbol: "$",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const CAD_Default: Currency = {
    code: "CAD",
    name: "Canadian dollar",
    symbol: "$",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const GBP_Default: Currency = {
    code: "GBP",
    name: "Pound sterling",
    symbol: "£",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const EUR_Default: Currency = {
    code: "EUR",
    name: "Euro",
    symbol: "€",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const AUD_Default: Currency = {
    code: "AUD",
    name: "Australian dollar",
    symbol: "$",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const CNY_Default: Currency = {
    code: "CNY",
    name: "Chinese yuan",
    symbol: "¥",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const INR_Default: Currency = {
    code: "INR",
    name: "Indian rupee",
    symbol: "₹",
    decimalPlaces: 2,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const Points_Default: Currency = {
    code: "PNTS",
    name: "Points",
    symbol: "",
    decimalPlaces: 0,
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const Blank_Currency: Currency = {
    code: "",
    name: "",
    decimalPlaces: 0,
    symbol: "",
    createdBy: "Lightrail",
    createdDate: new Date().toString(),
    updatedDate: new Date().toString()
};

export const DefaultCurrencies: Currency[] = [USD_Default, AUD_Default, CAD_Default, CNY_Default, EUR_Default, GBP_Default, INR_Default, Points_Default];