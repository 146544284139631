import * as React from "react";
import {Alert, Form, Input, InputNumber, Row, Switch} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {EditAccountRequest} from "../../../communication/dtos/account/EditAccount";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {ExternalProps, ReduxProps} from "./EditAccountFormConnected";
import {useForm} from "antd/lib/form/Form";
import {usePrevious} from "../../../util/hooks";

export interface Props extends ExternalProps, ReduxProps {
}

export const EditAccountForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [accountName, setAccountName] = React.useState(props.currentAccount?.name);
    const [mfaRequired, setMfaRequired] = React.useState(props.currentAccount?.requireMfa);
    const [showMaximumPasswordAge, setShowMaximumPasswordAge] = React.useState(!!props.currentAccount?.maxPasswordAge);
    const [showMaximumInactivity, setShowMaximumInactivity] = React.useState(!!props.currentAccount?.maxInactiveDays);

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        const maxPasswordAge = values[FormFieldIds.MaximumPasswordAge] || null;
        const maxInactiveDays = values[FormFieldIds.MaximumInactivity] || null;

        const request: EditAccountRequest = {
            name: values[FormFieldIds.NewAccountName],
            requireMfa: values[FormFieldIds.RequireAccountMfa],
            maxPasswordAge,
            maxInactiveDays
        };

        await props.editAccount(request);
        await props.listAccounts({});
        await props.getAccountDetails({});
    };

    const handleMfaRequiredCheckbox = (checked: boolean): void => {
        setMfaRequired(checked);
    };

    const handleMaximumInactivityCheckbox = (checked: boolean): void => {
        setShowMaximumInactivity(checked);
    };

    const handleMaximumPasswordAgeCheckbox = (checked: boolean): void => {
        setShowMaximumPasswordAge(checked);
    };

    React.useEffect(() => {
        props.getAccountDetails({});
        form.resetFields();
        props.getSubmit(submit);
    }, []);

    const prevAccount = usePrevious(props.currentAccount);
    React.useEffect(() => {
        if (props.currentAccount != prevAccount) {
            form.resetFields();
            setAccountName(props.currentAccount.name);
            setMfaRequired(props.currentAccount.requireMfa);
            setShowMaximumPasswordAge(!!props.currentAccount.maxPasswordAge);
            setShowMaximumInactivity(!!props.currentAccount.maxInactiveDays);
        }
    });

    const {maxPasswordAge, maxInactiveDays} = props.currentAccount || {
        name: "Not found",
        maxPasswordAge: null,
        maxInactiveDays: null
    };

    return (
        <Form
            hideRequiredMark={true}
            colon={false}
            form={form}
            onFinish={props.onSubmit}
            layout={"vertical"}
        >
            <Form.Item
                label="Account Name"
                name={FormFieldIds.NewAccountName}
                initialValue={accountName}
                rules={[
                    {required: true, message: "Please enter a name for the account"}
                ]}
            >
                <Input size="large"/>
            </Form.Item>
            <h4 className="marginTop48">Require Multifactor Authentication</h4>
            <Alert
                message={"If enabled, all users on account must enable an extra layer of authentication before they are able to access any data on the account."}
            />
            <Form.Item
                name={FormFieldIds.RequireAccountMfa}
            >
                <Switch
                    checked={mfaRequired}
                    onChange={handleMfaRequiredCheckbox}
                />
            </Form.Item>

            <h4 className="marginTop48">Maximum Password Age</h4>
            <Alert
                message={"If enabled, all users on account must change their password after a given amount of time."}
            />
            <Row>
                <Switch
                    className="marginRight8 marginTop4"
                    onChange={handleMaximumPasswordAgeCheckbox}
                    checked={showMaximumPasswordAge}
                />
                {
                    showMaximumPasswordAge &&
                    <Form.Item
                        name={FormFieldIds.MaximumPasswordAge}
                        initialValue={maxPasswordAge}
                    >
                        <InputNumber
                            placeholder={"180"}
                            className="marginRight8"
                            required={showMaximumPasswordAge}
                            min={7}
                            max={999}
                        />
                    </Form.Item>
                }
                {
                    showMaximumPasswordAge && <p className="lr-inline-block"> Days</p>
                }
            </Row>

            <h4 className="marginTop48">Maximum inactive days before account is locked out</h4>
            <Alert
                message={"If enabled, all users on account must log in regularly to maintain access to the account."}
            />
            <Row>
                <Switch
                    className="marginRight8 marginTop4"
                    onChange={handleMaximumInactivityCheckbox}
                    checked={showMaximumInactivity}
                />
                {
                    showMaximumInactivity &&
                    <Form.Item
                        name={FormFieldIds.MaximumInactivity}
                        initialValue={maxInactiveDays}
                    >
                        <InputNumber
                            placeholder={"180"}
                            className="marginRight8"
                            required={showMaximumInactivity}
                            min={7}
                            max={999}
                        />
                    </Form.Item>
                }
                {
                    showMaximumInactivity && <p className="lr-inline-block"> Days</p>
                }
            </Row>
            <AllowSubmitOnEnter/>
        </Form>
    );
};