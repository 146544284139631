import * as React from "react";
import {AccountPaymentSection} from "../payment/PaymentLayoutConnected";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {SubscriptionState} from "../../../state/serverState/User";
import {hasNoSubscription} from "../../../util/dataUtils";
import {SubscriptionTypes} from "../../../communication/dtos/userInformation/SubscriptionTier";
import {message, Modal} from "antd";
import {RadioChangeEvent} from "antd/lib/radio";
import {AccountHeader} from "../../layout/account/AccountHeader";
import {AccountLayout} from "../../layout/account/AccountLayout";
import {getCustomerSupportEmail} from "../../../util/communicationUtils";
import {ReduxProps} from "./AccountBillingPageConnected";

enum SubscriptionTier {NONE, STANDARD, CUSTOM}

import "./billingLayout.scss";

export interface Props extends ReduxProps {
}

export class AccountBillingPage extends React.PureComponent<Props, {}> {
    customerSupportEmailAddress = getCustomerSupportEmail();

    constructor(props: Props) {
        super(props);

        this.contactSalesToUpdate = this.contactSalesToUpdate.bind(this);
        this.contactSalesForCustomPricing = this.contactSalesForCustomPricing.bind(this);
        this.contactSalesAboutNewCustomer = this.contactSalesAboutNewCustomer.bind(this);
        this.onStandardGroupSelect = this.onStandardGroupSelect.bind(this);
        this.contactSales = this.contactSales.bind(this);
        this.onSelectTier = this.onSelectTier.bind(this);
        this.onContactSuccessConfirm = this.onContactSuccessConfirm.bind(this);
        this.onContactSuccessRequestClose = this.onContactSuccessRequestClose.bind(this);
        this.getSubscriptionTier = this.getSubscriptionTier.bind(this);
    }

    componentDidUpdate(oldProps: Props): void {
        const wasNone = this.getSubscriptionTier(oldProps.subscriptionState) === SubscriptionTier.NONE;
        const isStandard = this.getSubscriptionTier() === SubscriptionTier.STANDARD;
        const notFirstRodeo = this.props.subscriptionState.fetchCount > 1;

        if (wasNone && isStandard && notFirstRodeo) {
            this.contactSalesAboutNewCustomer();
        }
    }

    getSubscriptionTier(subscriptionState?: SubscriptionState): SubscriptionTier {
        if (!subscriptionState) {
            subscriptionState = this.props.subscriptionState;
        }

        if (!hasNoSubscription(subscriptionState) && !!subscriptionState.subscriptionTier) {
            if (subscriptionState.subscriptionTier.type === SubscriptionTypes.STANDARD) {
                return SubscriptionTier.STANDARD;
            } else if (subscriptionState.subscriptionTier.type === SubscriptionTypes.ENTERPRISE) {
                return SubscriptionTier.CUSTOM;
            }
        }

        return SubscriptionTier.NONE;
    }

    ///////////////////
    // [ EMAILERS ]
    contactSalesAboutNewCustomer(): void {
        const customerSupportEmail = this.customerSupportEmailAddress;
        this.props.contactCustomerSupport({
            customerSupportEmail,
            subject: "New Standard Customer!",
            message: "This customer has just signed up for a standard pricing account, don't forget to bill them!"
        });
    }

    async contactSalesToUpdate(): Promise<void> {
        const customerSupportEmail = this.customerSupportEmailAddress;
        const description = (
            <span>
                We’ll email
                <span className="fontColorSecondary">{!!this.props.userEmail ? this.props.userEmail : "you"}</span> shortly to go over your new needs.
            </span>
        );

        try {
            await this.props.contactCustomerSupport({
                customerSupportEmail,
                subject: "Update my Enterprise Plan",
                message: "This customer would like to be contacted about updating their enterprise plan."
            });

            Modal.success({
                title: "Let's update your enterprise plan",
                content: description
            });
        } catch (err) {
            message.error(err.message);
        }
    }

    async contactSalesForCustomPricing(): Promise<void> {
        const customerSupportEmail = this.customerSupportEmailAddress;

        try {
            await this.props.contactCustomerSupport({
                customerSupportEmail,
                subject: "Enterprise Plan",
                message: "This customer has asked to be contacted about an enterprise plan with custom pricing."
            });

            Modal.success({
                title: "We'll be in touch shortly",
                content: "Let's build the enterprise plan that's best for you. We'll be in touch shortly to determine your needs and get you going."
            });
        } catch (err) {
            message.error(err.message);
        }
     }

    ///////////////////
    // [ ACTIONS ]
    contactSales(): void {
        if (this.getSubscriptionTier() === SubscriptionTier.CUSTOM) {
            this.contactSalesToUpdate();
        } else {
            this.contactSalesForCustomPricing();
        }
    }

    onStandardGroupSelect(e: RadioChangeEvent): void {
        this.onSelectTier(e.target.value);
    }

    onSelectTier(value: string): void {
        if (value === "standard" && hasNoSubscription(this.props.subscriptionState)) {
            this.props.setPaymentModalOpen({isOpen: true});
        }
    }

    onContactSuccessConfirm(): void {
        this.onContactSuccessRequestClose();
    }

    onContactSuccessRequestClose(): void {
        this.props.uiAction({uiStatePath: UiActionPaths.ACCOUNTS_CONTACT_SALES_SUCCESS_OPEN, value: false});
    }

    ///////////////////
    // [ RENDER ]
    render(): JSX.Element {
        return (
            <AccountLayout>
                <div className={"contentContainer"}>
                    <div>
                        <AccountHeader title="Payment"/>
                        <div className="creditCardContainer">
                            <AccountPaymentSection/>
                        </div>
                    </div>
                </div>
            </AccountLayout>
        );
    }
}