import * as React from "react";
import {CreationFormStep} from "../../ui/layout/CreationFormStep";
import {Button} from "antd";
import {LoadingOutlined, CheckCircleOutlined} from "@ant-design/icons";
import {ReduxProps} from "./StripeOAuthConnected";

// disable eslint for legacy styles
const giftbitColors = require("../../../styles/legacy/_colors.scss") as any; // eslint-disable-line

export interface Props extends ReduxProps {
}

export class StripeOAuth extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onConnect = this.onConnect.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
    }

    componentDidMount(): void {
        this.props.getTurnkeyStripe({});
    }

    async onConnect(): Promise<void> {
        const response = await this.props.connectTurnkeyStripe({});
        //Redirect
        window.location.href = response.value.location;
    }

    onDisconnect(): void {
        this.props.deleteTurnkeyStripe({});
    }

    renderConnectedState(): JSX.Element {
        const connected = this.props.serverState.connected;
        const buttonLabel = connected ? "Disconnect stripe" : "Connect Stripe";
        const buttonAction = connected ? this.onDisconnect : this.onConnect;

        if (this.props.serverState.fetching) {
            return (
                <div>
                    <LoadingOutlined/>
                </div>
            );
        }

        return (
            <div>
                <p className="flexCenteredVerticle marginTop8 marginBottom8">
                    {
                        connected &&
                        <CheckCircleOutlined style={{color: giftbitColors.green, marginRight: 8}}/>
                    }
                    {
                        connected ? "Connected" : "Not Connected"
                    }
                </p>
                <Button type="primary" loading={this.props.serverState.processing} onClick={buttonAction}>
                    {buttonLabel}
                </Button>
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div>
                <CreationFormStep
                    title="Connect Lightrail to Stripe"
                    description="Stripe needs to be able to tell Lightrail when your customer’s payment has been accepted. Please connect Lightrail to Stripe."
                >
                    {
                        this.renderConnectedState()
                    }
                </CreationFormStep>
            </div>
        );
    }
}