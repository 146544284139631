import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Action} from "../actions/Action";
import {AuthorizationPaths} from "../util/routes/routes";
import {actionCreator as setRestError} from "../actions/errors/server/setRestError";
import {getLocation} from "../util/location";
import {actionCreator} from "../actions/uiAction";
import {UiActionPaths} from "../state/uistate/UiActionPaths";
import {State} from "../state/State";

export const httpErrorMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, State>) => (next: Dispatch) => <A extends Action<any>>(action: A) => {
    if (!!action.error && !!action.payload && !!action.payload.status) {
        const error = action.payload;
        const location = getLocation();

        //Should the global error dialog ignore this error
        switch (error.status) {
            case 401:
            case 404:
            case 502:
            case 503:
                api.dispatch(setRestError({ignoreError: true}));
                break;

            default:
                api.dispatch(setRestError({ignoreError: false}));
                break;
        }

        //Take Action or modify error message
        switch (error.status) {
            case 401:

                if (AuthorizationPaths.indexOf(location) === -1) {
                    api.dispatch(actionCreator({uiStatePath: UiActionPaths.SESSION_EXPIRED_DIALOG_OPEN, value: true}));
                }
                break;

            case 403:
                action.payload = {
                    ...action.payload,
                    message: "Looks like your account permissions need a boost in order to access this section. Contact your team admin to inquire about increasing your permissions."
                };
                break;
        }

    }

    return next(action);
};