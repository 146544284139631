import * as React from "react";
import {Badge} from "antd";
import {isBeforeToday} from "../../util/dateUtils";

export interface Props {
    active: boolean;
    frozen?: boolean;
    canceled?: boolean;
    expired?: Date | string;
}

export class StatusBadge extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const isExpired = isBeforeToday(this.props.expired);

        if (this.props.canceled) {
            return (<Badge status="error" text="Canceled"/>);
        }
        if (isExpired) {
            return (<Badge status="error" text="Expired"/>);
        }
        if (this.props.frozen) {
            return (<Badge status="processing" text="Frozen"/>);
        }
        if (this.props.active) {
            return (<Badge status="success" text="Active"/>);
        }

        return (<Badge status="warning" text="Inactive"/>);
    }
}