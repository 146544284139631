import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {IssuanceCreationUIState} from "../../../state/uistate/IssuanceUiState";

export interface ActionCreatorArgs {
    set?: IssuanceCreationUIState;
    merge?: IssuanceCreationUIState;
}

export const type = "setIssuanceCreationState";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionCreatorArgs> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionCreatorArgs> = (state, action) => {
    const params = action.payload;
    const newState = (params.set) ? params.set : {...state.ui.issuance.create, ...params.merge};
    return sprout.assoc(state, ["ui", "issuance", "create"], newState);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);