import * as React from "react";
import {useEffect} from "react";
import {UserPrivilege, UserPrivilegeTypes} from "../../../communication/dtos/account/User";
import {Button, Form, Radio} from "antd";
import {EmailItem} from "../../ui/forms/items/EmailItem";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {Body1} from "../../ui/typography/TypographyTags";
import {TextColor} from "../../ui/typography/ColoredText";
import {useForm} from "antd/lib/form/Form";
import {UserAddOutlined} from "@ant-design/icons";

export interface Props {
    processing?: boolean;
    updateMember?: boolean;
    initialValues?: { email?: string, userPrivilegeType: any };
    getSubmit?: (submit: () => void) => void;
    onSubmit?: (values: { email: string, userPrivilegeType: UserPrivilege }) => void;
    showSubmitButton?: boolean;
}

export const memberAccessTypes = {
    fullAccess: UserPrivilegeTypes.FULL_ACCESS,
    limitedAccess: UserPrivilegeTypes.LIMITED_ACCESS
};

export const TeamInviteMemberForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [email, setEmail] = React.useState("");
    const [errors, setErrors] = React.useState([]);

    const submit = (): void => {
        form.validateFields().then(
            (values: Record<string, any>) => {
                props.onSubmit({
                    email: values.email, userPrivilegeType: values.userPrivilegeType
                });
            }
        );
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    useEffect(() => {
        const errors = form.getFieldError(FormFieldIds.Email);
        setErrors(errors);
    }, [email]);

    const privilegeTypeInitialValue = (props.initialValues?.userPrivilegeType) ? props.initialValues.userPrivilegeType : memberAccessTypes.fullAccess;

    return (
        <Form
            onFinish={submit}
            form={form}
        >
            {
                !props.updateMember &&
                <EmailItem
                    form={form}
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                />
            }
            <Form.Item
                name={FormFieldIds.UserPrivilegeType}
                initialValue={privilegeTypeInitialValue}
                className="marginBottom16"
            >
                <Radio.Group style={{marginTop: -8}} name="userPrivilegeType">
                    <Radio className="verticalRadio" value={memberAccessTypes.fullAccess}>Full
                        Access</Radio>
                    <Radio className="verticalRadio" value={memberAccessTypes.limitedAccess}>Limited
                        Access</Radio>
                    <Body1 className="paddingLeft24" textColor={TextColor.DISABLED}>
                        User type cannot access team settings
                    </Body1>
                </Radio.Group>
            </Form.Item>
            <div className="marginTop8">
                {
                    (!props.updateMember)
                        ?
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!email.length || !!errors.length}
                            loading={props.processing}
                        >
                            <UserAddOutlined/>Invite
                        </Button>
                        :
                        <AllowSubmitOnEnter/>
                }
            </div>
        </Form>
    );
};