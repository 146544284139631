import * as React from "react";
import {Modal} from "antd";
import {CreateGenericValueFormConnected} from "./CreateGenericValueFormConnected";
import {Program} from "lightrail-client/dist/model";

export interface Props {
    open: boolean;
    program: Program;
    processing?: boolean;

    onSuccess: () => void;
    onClose: () => void;
}

export class CreateGenericValueModal extends React.PureComponent<Props, {}> {
    formSubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.formSubmit = submit;
    }

    onOk(): void {
        if (this.formSubmit) {
            this.formSubmit();
        }
    }

    render(): JSX.Element {
        if (!this.props.program) {
            return null;
        }

        return (
            <Modal
                title="Create Generic Code"
                visible={this.props.open}
                onCancel={this.props.onClose}
                okText="Create Code"
                destroyOnClose
                maskClosable={false}
                onOk={this.onOk}
                confirmLoading={this.props.processing}
            >
                <CreateGenericValueFormConnected
                    program={this.props.program}
                    getSubmit={this.setSubmit}
                    onSuccess={this.props.onSuccess}
                />
            </Modal>
        );
    }
}