import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {EditCurrencyModal} from "./EditCurrencyModal";
import {Currency} from "lightrail-client/dist/model/Currency";

export interface ExternalProps {
    currency: Currency;
    onCancel?: () => void;
}

interface StateProps {
    processingCurrency: boolean;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    processingCurrency: state.server.currency.processing,
    ...props
});

const actionCreators = {};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const EditCurrencyModalConnected = ReactRedux.connect(mapStateToProps, actionCreators)(EditCurrencyModal);