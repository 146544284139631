import * as React from "react";
import * as CopyToClipboard from "copy-to-clipboard";
import {CreationFormStep} from "../../../ui/layout/CreationFormStep";
import {HideShowValueConnected} from "../../../ui/data/hideShowValue/HideShowValueConnected";
import {StorageKeys} from "../../../../state/serverState/keyValue/KVStorageState";
import {UiActionPaths} from "../../../../state/uistate/UiActionPaths";
import {generateSecret} from "../../../../communication/utils/urlUtils";
import {message} from "antd";
import {Caption} from "../../../ui/typography/TypographyTags";
import { LoadingOutlined } from "@ant-design/icons";
import {ReduxProps} from "./TurkeyTokensConnected";

export interface Props extends ReduxProps{
}

export class TurnkeyTokens extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.getStorageValue = this.getStorageValue.bind(this);
        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
        this.onGenerateKeyClick = this.onGenerateKeyClick.bind(this);
        this.onCancelGenerateKeyClick = this.onCancelGenerateKeyClick.bind(this);
        this.generateKey = this.generateKey.bind(this);
    }

    componentDidMount(): void {
        this.getStorageValue();
    }

    componentDidUpdate(props: Props): void {
        if (!!this.props.jwtSecret && !props.jwtSecret) {
            this.getStorageValue();
        }
    }

    async getStorageValue(): Promise<void> {
        try {
            await this.props.getStorageValue({key: StorageKeys.JWT_SECRET})
        } catch {
            const newSecret = generateSecret();
            this.props.updateStorageValue({key: StorageKeys.JWT_SECRET, value: newSecret});
        }
    }

    onGenerateKeyClick(): void {
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_CONFIRM_GENERATE_TOKEN, value: true});
    }

    onCancelGenerateKeyClick(): void {
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_CONFIRM_GENERATE_TOKEN, value: false});
    }

    onCopyToClipboard(): void {
        CopyToClipboard(this.props.jwtSecret);
        message.success("Shared Secret Copied to Clipboard");
    }

    generateKey(): void {
        //Actually Generate a key
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_CONFIRM_GENERATE_TOKEN, value: false});
    }

    render(): JSX.Element {
        return (
            <div>
                <CreationFormStep
                    title="Shared Secret Key"
                    description="The shared secret key will be used by your developers to generate the shopperToken that identifies your customers account."
                >
                    {
                        !!this.props.jwtSecret &&
                        <div>
                            <HideShowValueConnected
                                id="SecretKey"
                                value={this.props.jwtSecret}
                            />
                            <div className="textAlignRight marginTop8">
                                <Caption onClick={this.onCopyToClipboard}>Copy To Clipboard</Caption>
                            </div>
                        </div>
                    }
                    {
                        !this.props.jwtSecret &&
                        <LoadingOutlined/>
                    }
                </CreationFormStep>
            </div>
        );
    }
}