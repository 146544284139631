import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {getProgram} from "lightrail-client/dist/programs";
import {GetProgramResponse} from "lightrail-client/dist/params";
import {mergeListObject, payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getProgram";
export const actionCreator = promisePattern.actionCreator(type, getProgram);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetProgramResponse> =
    (state, payload) => {
        const allList = payloadHasBody(payload) ? mergeListObject(state.server.programs.allList, payload?.body, "id") : state.server.programs.allList;
        return sprout.assoc(state,
            ["server", "programs", "allList"], allList,
            ["server", "programs", "fetching"], false,
            ["server", "programs", "status"], payload?.status,
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "programs"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);