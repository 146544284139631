import * as React from "react";
import {ResetPasswordForm} from "./ResetPasswordForm";
import {ResetPasswordRequest} from "../../../../communication/dtos/auth/ResetPassword";
import {Paths} from "../../../../util/routes/routes";
import {getQueryParams} from "../../../../communication/utils/urlUtils";
import {AuthLayoutHeader} from "../../AuthLayoutHeader";
import {Alert, Button} from "antd";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {onSuccessLogin} from "../../../../util/routes/onSuccessLogin";
import {ExternalProps, ReduxProps} from "./ResetPasswordPageConnected";

export interface ResetPasswordProps extends WithRouterProps<{}>, ExternalProps, ReduxProps {
}

export class ResetPasswordPage extends React.PureComponent<ResetPasswordProps, {}> {
    constructor(props: ResetPasswordProps) {
        super(props);

        this.onFormUpdated = this.onFormUpdated.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount(): void {
        const queryParams = getQueryParams(window.location.href);
        if (!!queryParams.token) {
            this.props.setResetPasswordToken({token: queryParams.token});
        }
    }

    componentWillUnmount(): void {
        this.props.resetAuthErrorAndSuccessStates({});
    }

    onDashboardClick(): void {
        this.props.history.push(Paths.DASHBOARD);
    }

    onFormUpdated(): void {
        if (!!this.props.auth.errorMessage && !!this.props.auth.errorMessage.length) {
            this.props.resetAuthErrorAndSuccessStates({});
        }
    }

    async resetPassword(params: ResetPasswordRequest): Promise<void> {
        const queryParams = getQueryParams(window.location.href);
        if (!!queryParams.token) {
            this.props.setResetPasswordToken({token: queryParams.token});
            if (!!this.props.auth.resetToken) {
                await this.props.resetPassword({password: params.password, token: this.props.auth.resetToken});
                this.props.setRestError({status: 0, code: "", message: "", ignoreError: true});

                if (this.props.additionalAuthenticationRequired) {
                    this.props.history.push(Paths.MULTI_FA);
                    if (!!this.props.onMFALogin) {
                        this.props.onMFALogin();
                    }
                } else {
                    if (!!this.props.onSuccessLogin) {
                        this.props.onSuccessLogin();
                    }
                    onSuccessLogin(this.props.stateHistory, this.props.history);
                }
            }
        }
    }

    render(): JSX.Element {
        const displayMessage: string = this.props.auth.resetPasswordMessage;

        return (
            <div>
                <AuthLayoutHeader
                    title="Set your password"
                    description={"Enter your new password"}
                />
                {
                    (!displayMessage)
                        ? <ResetPasswordForm
                            onUpdate={this.onFormUpdated}
                            errorMessage={this.props.auth.errorMessage}
                            processing={this.props.auth.processing}
                            onSubmit={this.resetPassword}
                        />
                        : <div>
                            <Alert
                                message="Success"
                                description={displayMessage}
                                type="success"
                                showIcon
                            />
                            <Button
                                type="primary"
                                size="large"
                                style={{width: "100%", marginTop: 16}}
                                onClick={this.onDashboardClick}
                            >
                                Proceed to Dashboard

                            </Button>
                        </div>
                }
            </div>
        );
    }
}