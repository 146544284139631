import * as React from "react";
import {Button, Form, Input} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {LockOutlined} from "@ant-design/icons";
import {ExternalProps, ReduxProps} from "../passwordField/PasswordFieldConnected";
import {SizeType} from "antd/es/config-provider/SizeContext";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons/lib";

export interface PasswordRequirement {
    error: string;
    desc: string;
    regexp: RegExp;
}

export interface Props extends ExternalProps, ReduxProps {
}

const requirements: PasswordRequirement[] = [
    {
        desc: "8 character minimum",
        error: "Your password must be at least 8 Characters. ",
        regexp: /.{8,}/
    },
    {
        desc: "At least one number or symbol",
        error: "Your password must contain a number or symbol. ",
        regexp: /[0-9!@#$%^&*()\-_+=?\/><.,`~]/
    }
];

export class PasswordItem extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onBlur = this.onBlur.bind(this);
        this.onToggleVis = this.onToggleVis.bind(this);
    }

    componentDidMount(): void {
        this.props.updatePasswordField({
            id: this.props.id,
            state: {
                value: "",
                visible: false
            }
        });
    }

    componentWillUnmount(): void {
        this.props.updatePasswordField({
            id: this.props.id, state: {
                value: "",
                visible: false
            }
        });
    }

    ///////////////////
    //[ ACTIONS ]
    onBlur(): void {
        this.props.updatePasswordField({
            id: this.props.id,
            state: {...this.props.uiState, blurred: true}
        });
    }

    onToggleVis(): void {
        this.props.updatePasswordField({
            id: this.props.id,
            state: {...this.props.uiState, visible: !this.props.uiState.visible}
        });
    }

    ///////////////////
    //[ RENDER ]
    renderRequirementList(rules: PasswordRequirement[]): JSX.Element {
        if (!!rules && !!rules.length) {
            return (
                <ul style={{lineHeight: 1.5}}>
                    {
                        rules.map((requirement, index): JSX.Element => (
                            <li key={`PASSWORD_ERROR_${index}`}>{requirement.desc}</li>))
                    }
                </ul>
            );
        }

        return null;
    }

    render(): JSX.Element {
        const {getFieldError} = this.props.form;

        const fieldKey = (this.props.formId) ? this.props.formId : FormFieldIds.Password;

        const passwordError = this.props.form.isFieldTouched(fieldKey) && getFieldError(fieldKey);

        const passwordVisible = this.props.uiState && this.props.uiState.visible;

        const allErrors = [
            ...(this.props.errors || []),
            ...(passwordError || [])
        ];

        return (
            <React.Fragment>
                <div className={this.props.className || ""} style={{display: "flex", marginBottom: 8, height: 48}}>
                    <Form.Item
                        label={this.props.label}
                        validateStatus={allErrors.length ? "error" : "success"}
                        help=""
                        name={fieldKey}
                        validateTrigger={"onChange"}
                        rules={[
                            {required: true, message: "Password Required"},
                            {min: 8, message: "Your new password must be at least 8 characters long."},
                            ...requirements.map(regex => ({
                                pattern: regex.regexp,
                                message: regex.error
                            }))
                        ]}
                    >
                        <Input
                            key={this.props.id}
                            style={{marginRight: 4}}
                            id={this.props.id}
                            onBlur={this.onBlur}
                            size={((!!this.props.size) ? this.props.size : "large") as SizeType}
                            prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                            placeholder="Password"
                            type={(passwordVisible) ? null : "password"}
                            onChange={this.props.onChange}
                            value={this.props.value}
                        />
                    </Form.Item>
                    <Button
                        type={(passwordVisible) ? "primary" : "ghost"}
                        size={"large" as SizeType}
                        onClick={this.onToggleVis}
                    >
                        {
                            passwordVisible ? <EyeOutlined/> : <EyeInvisibleOutlined/>
                        }
                    </Button>
                </div>
                {
                    this.renderRequirementList(requirements)
                }
            </React.Fragment>
        );
    }
}