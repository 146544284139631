import * as ReactRedux from "react-redux";
import {MFA} from "./MFA";
import {actionCreator as addDevice} from "../../../actions/auth/server/MFA/addDevice";
import {actionCreator as enable} from "../../../actions/auth/server/MFA/enable";
import {actionCreator as disable} from "../../../actions/auth/server/MFA/disable";
import {actionCreator as getDevice} from "../../../actions/auth/server/MFA/getDevice";
import {actionCreator as getBackupCodes} from "../../../actions/auth/server/MFA/getBackupCodes";
import {actionCreator as toggleVerify} from "../../../actions/account/ui/toggleMFAVerify";
import {actionCreator as goToDeviceState} from "../../../actions/account/ui/goToDeviceState";
import {actionCreator as toggleMFASuccessModal} from "../../../actions/account/ui/toggleMFASuccessModal";
import {actionCreator as setDeviceNumber} from "../../../actions/account/ui/setMFAPhoneNumber";
import {actionCreator as setMFAMethod} from "../../../actions/account/ui/setMFAMethodModalState";
import {actionCreator as setQRCodeModalOpen} from "../../../actions/account/ui/setQRCodeModalOpen";
import {State} from "../../../state/State";
import { MFAState } from "../../../state/serverState/MFAState";
import { MFAUiState } from "../../../state/uiState/MFAUiState";

export interface ExternalProps {
    afterSubmit?: () => void;
    openByDefault?: boolean;
    onClose?: () => void;
}

interface StateProps {
    server: MFAState;
    ui: MFAUiState;
}

const mapStateToProps = (state: State, externalProps: ExternalProps): ExternalProps & StateProps => ({
    server: state.server.MFA,
    ui: state.ui.MFA,
    ...externalProps
});

const actionCreators = {
    addDevice,
    enable,
    disable,
    getDevice,
    toggleVerify,
    getBackupCodes,
    goToDeviceState,
    toggleMFASuccessModal,
    setDeviceNumber,
    setMFAMethod,
    setQRCodeModalOpen
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

const MFAConnected = connector(MFA);

export {MFAConnected as MFA};