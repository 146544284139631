import * as promisePattern from "../../../promisePattern";
import * as MFA from "../../../../communication/MFA";
import {GetDeviceResponse} from "../../../../communication/dtos/auth/MFA/GetDevice";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../../rejectionHandler";

export const type = "getMFADevice";

export const actionCreator = promisePattern.actionCreator(type, MFA.getDevice);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetDeviceResponse> =
    (state, payload) => {
        return sprout.assoc(state, ["server", "MFA", "device"], payload?.device);
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return state;
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);