import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { TurnkeyConfigRequired } from "../../../state/serverState/keyValue/TurnkeyConfig";

export interface ActionParams extends TurnkeyConfigRequired {
}

export interface ActionCreatorArgs extends ActionParams {
}

export const type = "updateTurnkeyErrors";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    const payload = action.payload;
    return sprout.assoc(state,
        ["ui", "turnKey", "errors"], payload
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);