import * as React from "react";
import {Value} from "lightrail-client/dist/model/Value";
import {Contact, Program} from "lightrail-client/dist/model";
import {Alert, Button, Col, message, Row} from "antd";
import {ValueActionMenuConnected} from "../valueActions/ValueActionMenuConnected";
import {ValueBalanceDisplay} from "../balance/ValueBalanceDisplay";
import {StatusBadge} from "../../ui/StatusBadge";
import {getFormattedValueCode} from "../../../util/valueUtils";
import {ValueDetailsList} from "./detailSections/ValueDetailsList";
import {ValueDetailLinks} from "./detailSections/ValueDetailLinks";
import {Link, withRouter} from "react-router-dom";
import {ValueDetailTabsConnected} from "./detailSections/genericValueTabs/ValueDetailTabsConnected";
import {DetailHeaderProps} from "../../layout/details/DetailHeader";
import {DetailLayout} from "../../layout/details/DetailLayout";
import {WithRouterProps} from "../../router/WithRouterProps";
import {ValueActionStatus} from "../../../state/uistate/ValueUiState";
import {decodeUrlMatchParam, formatRoute, Paths} from "../../../util/routes/routes";
import {ValueActionMenuContextType} from "../valueActions/ValueActionMenu";
import {UndoLink} from "../../ui/undo/UndoLink";
import {
    AttachValueToContactActionParams,
} from "../../../actions/values/server/attachValueToContact";
import {stopPropagation} from "../../../util/link";
import {findCurrency} from "../../../util/currencyUtils";
import {Currency} from "lightrail-client/dist/model/Currency";
import {ReduxProps} from "./ValueDetailPageConnected";
import {TagOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {GetValueResponse} from "lightrail-client/dist/params";
import {ActionResponse} from "../../../actions/promisePattern";

const FALLBACK_VALUE: Value = {
    id: "-",
    currency: "-",
    balance: 0,
    balanceRule: {rule: "", explanation: ""},
    usesRemaining: 0,
    programId: "-",
    code: "-",
    isGenericCode: false,
    genericCodeOptions: {perContact: {}},
    attachedFromValueId: "",
    contactId: "-",
    pretax: false,
    active: false,
    frozen: false,
    canceled: false,
    redemptionRule: {rule: "", explanation: ""},
    discount: false,
    discountSellerLiability: 0,
    discountSellerLiabilityRule: {rule: "", explanation: ""},
    startDate: "",
    endDate: "",
    tags: [],
    metadata: {},
    createdDate: "",
    updatedDate: "",
    createdBy: ""
};

export interface State {
    valueId: string;
}

export interface Props extends WithRouterProps<{ valueId: string }>, ReduxProps {
}

class ValuePageComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            valueId: decodeUrlMatchParam(this.props.match.params.valueId)
        };

        this.loadContact = this.loadContact.bind(this);
        this.loadValue = this.loadValue.bind(this);
        this.onAttachContact = this.onAttachContact.bind(this);
        this.onDetachContact = this.onDetachContact.bind(this);
        this.reAttachContact = this.reAttachContact.bind(this);
        this.onContactAttached = this.onContactAttached.bind(this);
    }

    componentDidMount(): void {
        this.loadValue();
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.match.params.valueId != this.props.match.params.valueId) {
            this.setState({valueId: decodeUrlMatchParam(this.props.match.params.valueId)});
            this.loadValue();
        }

        const value = this.getValue();
        const oldValue = this.getValue(oldProps);
        if (!!value && !!oldValue) {
            if (oldValue.contactId != value.contactId) {
                this.loadContact();
            }
        }
    }

    async loadValue(): Promise<void> {
        const response = (await this.props.getValue({valueId: this.state.valueId}) as unknown as ActionResponse<GetValueResponse>).value;

        if (response.body.attachedFromValueId) {
            this.props.getValue({valueId: response.body.attachedFromValueId});
        }

        this.fetchProgram();
        this.loadContact();
    }

    fetchProgram(): void {
        const value = this.getValue();
        const program = this.getProgram(value);

        if (!program && !!value.programId) {
            this.props.getProgram(value.programId);
        }
    }

    onContactAttached(id?: string): void {
        this.loadContact(id);
        this.props.getTransactions({valueId: this.state.valueId, limit: 50});
    }

    loadContact(id?: string): void {
        let contactId = id;

        if (!id) {
            const value = this.getValue();
            const contact = this.getContact(value);

            if (!contact && !!value.contactId) {
                contactId = value.contactId;
            }
        }

        if (!!contactId) {
            this.props.getContact(contactId);
        }
    }

    getContact(value: Value): Contact {
        if (!value) {
            return null;
        }

        return this.props.contactStateServer.list.find(c => c.id === value.contactId);
    }

    getProgram(value: Value): Program {
        if (!value) {
            return null;
        }

        return this.props.programStateServer.allList.find(p => p.id === value.programId);
    }

    getValue(props?: Props): Value {
        const propsToUse = props || this.props;
        return propsToUse.valueStateServer.list.find(v => v.id === this.state.valueId);
    }

    getCurrency(value: Value): Currency {
        return this.props.currencyServerState.list.find(c => c.code === value.currency);
    }

    getAttachedFromValue(value: Value): Value {
        if (!!value.attachedFromValueId) {
            return this.props.valueStateServer.list.find(v => v.id === value.attachedFromValueId);
        }
        return null;
    }

    getSymbol(value: Value): string {
        if (!!value && this.props.currencyServerState.list.length > 0) {
            const currency = this.getCurrency(value);
            if (!!currency) {
                return findCurrency(this.props.currencyServerState.list, currency.code).symbol;
            }
        }
        return null;
    }

    onAttachContact(): void {
        // The action menu in the page will react to this pending action status for us
        this.props.setPendingValueAction({
            valueId: this.getValue().id,
            params: {
                pendingValueAction: ValueActionStatus.ATTACH_CONTACT
            }
        });
    }

    async reAttachContact(p: AttachValueToContactActionParams): Promise<void> {
        await this.props.attachValueToContact(p).value;
        return this.loadContact(p.contactId);
    }

    async onDetachContact(contact: Contact): Promise<void> {
        const value = this.getValue();

        await this.props.detachValueFromContact({
            contactId: contact.id,
            params: {valueId: value.id},
            removeContact: true
        });
        message.info(
            <span>
                {
                    `${(!!contact.email) ? contact.email : "Contact"} has been removed. `
                }
                <UndoLink
                    onUndoAsync={this.reAttachContact}
                    params={{contactId: contact.id, params: {valueId: this.state.valueId}}}
                    successMessage="Remove undone"
                />
            </span>
        );
    }

    ///////////////////
    //[ RENDER ]
    render404(): JSX.Element {
        return (
            <div>
                <Alert
                    message="Value Not Found"
                    description={`Value Id '${this.state.valueId}' can't be found`}
                    showIcon
                    type="warning"
                    className="detailsNotFoundAlert"
                />
            </div>
        );
    }

    renderActionButtons(value: Value): JSX.Element {
        return (
            <Row className="lr-space-between marginRight16">
                {
                    (value.isGenericCode || !value.contactId) &&
                    <Col span={8}>
                        <Button onClick={this.onAttachContact}>
                            <UsergroupAddOutlined/>Attach Contact
                        </Button>
                    </Col>
                }
                <Col span={8}>
                    <ValueActionMenuConnected
                        label="Actions"
                        value={value}
                        onContactAttached={this.onContactAttached}
                        menuContext={{type: ValueActionMenuContextType.VALUE_DETAIL}}
                    />
                </Col>
            </Row>
        );
    }

    uniqueValueHeaderProps(value: Value, attachedFromValue?: Value): DetailHeaderProps {
        let codeDisplayElement: JSX.Element;
        if (!!attachedFromValue) {
            codeDisplayElement =
                <div>
                    Parent Code: {
                    <Link onClick={stopPropagation}
                          to={formatRoute(Paths.VALUE_DETAIL, {valueId: attachedFromValue.id})}>{attachedFromValue.code}</Link>
                }
                </div>;
        } else {
            codeDisplayElement =
                <div>
                    Code: {getFormattedValueCode(value)}
                </div>;
        }
        return ({
            avatar: this.getSymbol(value),
            title: (
                <ValueBalanceDisplay
                    value={value}
                    viewRule
                    showCurrency
                />
            ),
            details: [
                <StatusBadge
                    active={value.active}
                    frozen={value.frozen}
                    canceled={value.canceled}
                    expired={value.endDate}
                    key={value.id}
                />,
                codeDisplayElement
            ],
            actionButton: this.renderActionButtons(value)
        });
    }

    genericValueHeaderProps(value: Value): DetailHeaderProps {
        return ({
            icon: <TagOutlined/>,
            title: getFormattedValueCode(value),
            details: [
                <ValueBalanceDisplay
                    value={value}
                    viewRule
                    showCurrency
                    key={value.id}
                />,
                <StatusBadge
                    active={value.active}
                    frozen={value.frozen}
                    canceled={value.canceled}
                    expired={value.endDate}
                    key={value.id}
                />
            ],
            actionButton: this.renderActionButtons(value)
        });
    }

    render(): JSX.Element {
        let value = this.getValue();
        let attachedFromValue;

        // Defaults for loading state, fallback in-case old version isn't in state from list page
        if (!value) {
            value = FALLBACK_VALUE;
        }

        if (value.attachedFromValueId) {
            attachedFromValue = this.getAttachedFromValue(value);
        }

        if (this.props.valueStateServer.status === 404) {
            return this.render404();
        }

        return (
            <DetailLayout
                loading={this.props.valueStateServer.fetching}
                headerProps={(value.isGenericCode) ? this.genericValueHeaderProps(value) : this.uniqueValueHeaderProps(value, attachedFromValue)}
            >
                {
                    (!!value.programId || !value.isGenericCode) &&
                    <ValueDetailLinks
                        value={value}
                        program={this.getProgram(value)}
                        contact={this.getContact(value)}
                        loadingContact={this.props.contactStateServer.fetching}
                        attachContact={this.onAttachContact}
                        detachContact={this.onDetachContact}
                    />
                }
                <ValueDetailsList
                    value={value}
                    attachedValue={attachedFromValue}
                />
                <ValueDetailTabsConnected
                    value={value}
                    detachContact={this.onDetachContact}
                />
            </DetailLayout>
        );
    }
}

export const ValueDetailPage = withRouter(ValuePageComponent);
