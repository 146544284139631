import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {TransactionDetailPage} from "./TransactionDetailPage";
import {actionCreator as getTransaction} from "../../../actions/transactions/server/getTransaction";
import {withRouter} from "react-router-dom";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {TransactionsServerState} from "../../../state/serverState/TransactionsServerState";
import {TransactionUiState} from "../../../state/uistate/TransactionUiState";

interface StateProps {
    transactionsServerState: TransactionsServerState;
    transactionUiState: TransactionUiState;
}

const mapStateToProps = (state: State): StateProps => ({
    transactionsServerState: state.server.transactions,
    transactionUiState: state.ui.transactions
});

const actionCreators = {
    getTransaction,
    uiAction,
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const TransactionDetailPageConnected = connector(withRouter(TransactionDetailPage));