import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {getSecret} from "../../../communication/webhooks";
import {GetSecretRequest, Secret} from "../../../communication/dtos/webhooks/Secret";

export const type = "getSecret";
export const actionCreator = promisePattern.actionCreator(type, (p: GetSecretRequest) => getSecret(p));

const onFulfilled: promisePattern.PromiseFulfilledHandler<Secret> =
    (state) => {
        return sprout.assoc(state,
            ["server", "webhook", "secret", "fetching"], false
        );
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "webhook", "secret", "fetching"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "webhook", "secret", "fetching"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);