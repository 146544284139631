import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {WebhookDetailPage} from "./WebhookDetailPage";
import {actionCreator as uiAction} from "../../../../actions/uiAction";
import {actionCreator as deleteWebhook} from "../../../../actions/webhooks/server/deleteWebhook";
import {actionCreator as getWebhook} from "../../../../actions/webhooks/server/getWebhook";
import {actionCreator as createSecret} from "../../../../actions/webhooks/server/createSecret";
import {actionCreator as getSecret} from "../../../../actions/webhooks/server/getSecret";
import {actionCreator as deleteSecret} from "../../../../actions/webhooks/server/deleteSecret";
import {WebhookServerState} from "../../../../state/serverState/WebhookServerState";
import {WebhookUiState} from "../../../../state/uistate/WebhookUiState";

interface StateProps {
    webhookServerState: WebhookServerState;
    webhookUiState: WebhookUiState;
}

const mapStateToProps = (state: State): StateProps => ({
    webhookServerState: state.server.webhook,
    webhookUiState: state.ui.webhook,
});

const actionCreators = {
    getWebhook,
    deleteWebhook,
    createSecret,
    getSecret,
    deleteSecret,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const WebhookDetailPageConnected = connector(WebhookDetailPage);