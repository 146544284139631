import * as React from "react";
import {useEffect} from "react";
import {Button, Form} from "antd";
import {ResetPasswordRequest} from "../../../../communication/dtos/auth/ResetPassword";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {AuthServerStateErrorMessage} from "../../../../state/serverState/AuthServerState";
import {PasswordFieldConnected} from "../../../ui/forms/passwordField/PasswordFieldConnected";
import {AuthFormErrorItem} from "../../forms/AuthFormErrorItem";
import {useForm} from "antd/lib/form/Form";

const FormItem = Form.Item;

export interface Props {
    onUpdate?: () => void;
    errorMessage?: AuthServerStateErrorMessage;
    processing?: boolean;
    onSubmit: (params: ResetPasswordRequest) => void;
}

export const ResetPasswordForm = (props: Props): JSX.Element => {
    const [form] = useForm();
    const [password, setPassword] = React.useState("");

    const [errors, setErrors] = React.useState([
        ...form.getFieldError(FormFieldIds.Password),
        ...(props.errorMessage || [])
    ]);

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        props.onSubmit(values as ResetPasswordRequest);
    };

    useEffect(() => {
        if (props.onUpdate && props.errorMessage?.length) {
            props.onUpdate();
        }
    });

    useEffect(() => {
        const fieldErrors = form.getFieldError(FormFieldIds.Password);
        setErrors(fieldErrors);
    }, [password]);

    return (
        <Form onFinish={submit} form={form}>
            <PasswordFieldConnected
                form={form}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
            />
            {
                (!!errors && !!errors.length) &&
                <AuthFormErrorItem errors={errors}/>
            }
            <FormItem
                name={FormFieldIds.Email}
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{width: "100%"}}
                    size="large"
                    disabled={!!errors.length || !password.length}
                    loading={props.processing}
                >
                    Set Password
                </Button>
            </FormItem>
        </Form>
    );
};