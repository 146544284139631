import * as React from "react";
import {TeamMembersLayoutConnected} from "../../teams/integrations/AccountUsersLayoutConnected";
import {PaddedLoadingSpinner} from "../../ui/Loading";
import {errorStrings} from "../../../state/serverState/RestError";
import {ReduxProps} from "./AccountUsersPageConnected";

// disable eslint for legacy styles
const layoutStyles: any = require("./accountTeamList.scss"); // eslint-disable-line

export interface Props extends ReduxProps {
}

export class AccountUsersPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {
        this.props.getAccount({});
    }

    ///////////////////
    // [ RENDER ]
    renderTeamLayout(): JSX.Element {
        return (
            <TeamMembersLayoutConnected/>
        );
    }

    ///////////////////
    // [ RENDER ]
    render(): JSX.Element {
        if (this.props.account.fetching) {
            return (
                <PaddedLoadingSpinner/>
            );
        } else if (this.props.account.error === errorStrings.PERMISSION_ERROR_403) {
            return (
                <div className="marginTop48">
                    <p>You do not have sufficient permissions to view this section.</p>
                </div>
            );
        } else {
            return (
                <div className={layoutStyles.container}>
                    {
                        this.renderTeamLayout()
                    }
                </div>
            );
        }
    }
}