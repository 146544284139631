import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {CurrencyUiEditState} from "../../../state/uistate/CurrencyUiState";

export interface EditCurrencStateArgs extends CurrencyUiEditState {
}

export const type = "setEditCurrencyState";

export const actionCreator: ActionCreator<EditCurrencStateArgs, EditCurrencStateArgs> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<EditCurrencStateArgs> = (state, action) => {
    return sprout.assoc(state, ["ui", "currency", "edit"], action.payload);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);