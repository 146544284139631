import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {listWebhooks} from "../../../communication/webhooks";
import {ListWebhooksResponse} from "../../../communication/dtos/webhooks/ListWebhooks";

export const type = "listWebhooks";
export const actionCreator = promisePattern.actionCreator(type, listWebhooks);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListWebhooksResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "webhook", "list"], payload,
            ["server", "webhook", "fetching"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return sprout.assoc<State>(state,
            ["server", "webhook", "fetching"], true
        );
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);