import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {AccountUsersPage} from "./AccountUsersPage";
import {actionCreator as getAccount} from "../../../actions/account/server/getAccountDetails";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {AccountState} from "../../../state/serverState/AccountState";

interface StateProps {
    account: AccountState;
}

const mapStateToProps = (state: State): StateProps => ({
    account: state.server.account
});

const actionCreators = {
    getAccount,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AccountUsersPageConnected = connector(AccountUsersPage);