import {AuthorizationPaths, isValidPath, Paths} from "./routes";
import {History} from "history";

export const onSuccessLogin = (stateHistory: string[], history: History): void => {
    let navPath = Paths.ROOT;

    if (stateHistory.length > 1) {
        //Look backwards through history for first non-auth path
        [...stateHistory].reverse().some((path) => {
            const isPathNonAuth = AuthorizationPaths.indexOf(path) === -1;
            const isPathValid = isValidPath(path);

            if (isPathNonAuth && isPathValid) {
                navPath = path;
                return true;
            } else {
                return false;
            }
        });
    }

    history.push(navPath);
};