import * as React from "react";
import {Col, Row} from "antd";

export interface Props {
    key: string;
    label: string;
    value: string | number | JSX.Element;
    className?: string;
}

export class SimpleStat extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <Row className="lr-detail-value-row">
                <Col xs={24} sm={24} md={24}>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                {this.props.label}
                            </td>
                            <td className={this.props.className ? this.props.className : ""}>
                                {this.props.value}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
    }
}