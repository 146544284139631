import * as ReactRedux from "react-redux";
import {EditAccountForm} from "./EditAccountForm";
import {actionCreator as editAccount} from "../../../actions/account/server/editAccount";
import {actionCreator as listAccounts} from "../../../actions/account/server/listAccounts";
import {State} from "../../../state/State";
import {
    actionCreator as getAccountDetails,
} from "../../../actions/account/server/getAccountDetails";
import {Account} from "../../../communication/dtos/account/Account";

export interface ExternalProps {
    getSubmit: (submit: () => void) => void;
    onSubmit: () => void;
}

interface StateProps {
    currentAccount: Account;
}

const mapStateToProps = (state: State, externalProps: ExternalProps): ExternalProps & StateProps => ({
    currentAccount: state.server.account.accountInfo,
    ...externalProps
});

const actionCreators = {
    editAccount,
    listAccounts,
    getAccountDetails
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const EditAccountFormConnected = connector(EditAccountForm);