import * as React from "react";
import {formatRoute, getLocationParamsFromRoute} from "../../util/routes/routes";
import {getSubnavRoutes, SubNavItem, SubNavRoutes} from "../../util/routes/subnavRoutes";
import {WithRouterProps} from "../router/WithRouterProps";
import {Tabs} from "antd";

// disable eslint for legacy styles
const localStyles: any = require("./subnav.scss"); // eslint-disable-line

export interface Props extends WithRouterProps<{}> {
}

interface InternalState {
    routes: SubNavRoutes;
}

/**
 * Subnav monitors current route and uses definitions from
 * subnavRoutes.ts config (getSubnavRoutes)
 * to automatically populate subnavs and highlight a selected subnav item
 */
export class Subnav extends React.PureComponent<Props, InternalState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            routes: null
        };

        this.onTabChanged = this.onTabChanged.bind(this);
    }

    componentDidMount(): void {
        this.setState({
            routes: getSubnavRoutes(this.props.location.pathname)
        });
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.location != prevProps.location) {
            this.setState({
                routes: getSubnavRoutes(this.props.location.pathname)
            });
        }
    }

    onTabChanged(activeKey: string): void {
        const newRoute = formatRoute(activeKey, getLocationParamsFromRoute(this.state.routes.routeKey, this.props.location.pathname));

        if (!!newRoute) {
            this.props.history.push(newRoute);
        }
    }

    render(): JSX.Element {
        if (this.state.routes?.tabs?.length) {
            const routeParams = getLocationParamsFromRoute(this.state.routes.routeKey, this.props.location.pathname);
            const selectedPath = ("subsection" in routeParams) ? formatRoute(this.state.routes.routeKey, {subsection: routeParams["subsection"]}) : "";

            const tabsRender: JSX.Element[] = this.state.routes.tabs.map((subnavItem: SubNavItem) => (
                <Tabs.TabPane key={subnavItem.route} tab={subnavItem.label}/>
            ));

            return (
                <div className={localStyles.root}>
                    <Tabs
                        activeKey={selectedPath}
                        onChange={this.onTabChanged}
                        className={localStyles.tabsRoot}
                        tabBarStyle={{borderBottom: "none", marginBottom: 0}}
                    >
                        {
                            tabsRender
                        }
                    </Tabs>
                </div>
            );
        }

        return null;
    }
}