import * as ReactRedux from "react-redux";
import {LoginPage} from "./LoginPage";
import {actionCreator as login} from "../../../../actions/auth/server/login";
import {actionCreator as ping} from "../../../../actions/auth/server/ping";
import {actionCreator as resetAuthErrorAndSuccessStates} from "../../../../actions/auth/server/resetAuthErrorAndSuccessStates";
import {actionCreator as setRestError} from "../../../../actions/errors/server/setRestError";

import {State} from "../../../../state/State";
import {withRouter} from "react-router-dom";
import {AuthState} from "../../../../state/serverState/AuthServerState";
import { RestError } from "../../../../state/serverState/RestError";

export interface ExternalProps {
    onMFALogin?: () => {};
}

interface StateProps {
    stateHistory: string[];
    auth: AuthState;
    MFA: boolean;
    inTestMode: boolean;
    processing: boolean;
    additionalAuthenticationRequired: boolean;
    mfaMethod: string;
    authMessage: {message?: string, messageCode?: string};
    restError: RestError;
    userId: string;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    stateHistory: state.ui.history,
    auth: state.server.auth,
    MFA: !!state.server.MFA.mfa,
    inTestMode: state.server.user.testing,
    processing: state.server.auth.processing,
    additionalAuthenticationRequired: state.server.user.additionalAuthenticationRequired,
    mfaMethod: state.server.MFA.mfa,
    authMessage: state.server.account.authMessage,
    restError: state.server.restError,
    userId: state.browser.jwt.data.g.gui
});

const actionCreators = {
    login,
    resetAuthErrorAndSuccessStates,
    setRestError,
    ping
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const LoginConnected = ReactRedux.connect(mapStateToProps, actionCreators)(withRouter(LoginPage));