import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {getCurrency} from "lightrail-client/dist/currencies";
import {GetCurrencyResponse} from "lightrail-client/dist/params/currencies/GetCurrencyParams";
import {mergeListObject} from "../../../util/dataUtils";

export const type = "getCurrency";
export const actionCreator = promisePattern.actionCreator(type, getCurrency);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetCurrencyResponse> =
    (state, payload) => {
        const newCurrencyList = mergeListObject(state.server.currency.list, payload?.body, "code");
        return sprout.assoc(state,
            ["server", "currency", "list"], newCurrencyList,
            ["server", "currency", "fetching"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "currency", "fetching"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);