import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import {deepMerge} from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {DefaultTurnkeyConfig, TurnkeyConfig} from "../../../state/serverState/keyValue/TurnkeyConfig";

export interface ActionParams {
    clear?: boolean;
    setState?: TurnkeyConfig;
    mergeState?: {
        additionalInfo?: string;
        claimLink?: string;
        companyName?: string;
        companyWebsiteUrl?: string;
        copyright?: string;
        currency?: string;
        customerSupportEmail?: string;
        emailSubject?: string;
        giftEmailReplyToAddress?: string;
        logo?: string;
        linkToPrivacy?: string;
        linkToTerms?: string;
        programId?: string;
        termsAndConditions?: string;
    };
}

export interface ActionCreatorArgs extends ActionParams {
}

export const type = "updateTurnKeyUiState";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    const payload = action.payload;

    if (payload?.clear) {
        return sprout.assoc(state,
            ["ui", "turnKey", "config"], DefaultTurnkeyConfig
        );
    } else if (!!payload?.mergeState) {
        const mergedState = deepMerge(state.ui.turnKey.config, payload?.mergeState);
        return sprout.assoc(state,
            ["ui", "turnKey", "config"], mergedState
        );
    } else if (!!payload?.setState) {
        return sprout.assoc(state,
            ["ui", "turnKey", "config"], payload?.setState
        );
    } else {
        return state;
    }
};

export const reducerMap = promisePattern.reducerMap(type, reducer);