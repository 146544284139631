import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {CurrencyWatcher} from "./CurrencyWatcher";
import {withRouter} from "react-router-dom";
import {actionCreator as uiAction} from "../../../../actions/uiAction";
import {actionCreator as createCurrency} from "../../../../actions/currency/server/createCurrency";
import {CurrencyServerState} from "../../../../state/serverState/CurrencyServerState";

interface StateProps {
    currencyState: CurrencyServerState;
    internalHistory: string[];
}

const mapStateToProps = (state: State): StateProps => ({
    currencyState: state.server.currency,
    internalHistory: state.ui.history
});

const actionCreators = {
    createCurrency,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CurrencyWatcherConnected = connector(withRouter(CurrencyWatcher));