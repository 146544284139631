import * as React from "react";
import {ReactNode, useEffect} from "react";
import {Form, Select} from "antd";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {SearchId, SearchType} from "../../../state/serverState/SearchesServerState";
import {isEmpty} from "../../../util/dataUtils";
import {EmojiInputItem} from "../../ui/forms/EmojiInputItem";
import {IGNORE_CLOSE} from "../global/GlobalSearchDropdown";
import {ReduxProps, ExternalProps} from "./SearchFormConnected";
import {SearchActionParams} from "../../../actions/search/server/globalSearch";
import {useForm} from "antd/lib/form/Form";
import {SearchOutlined, CloseOutlined} from "@ant-design/icons";
import {ActionCreator} from "../../../actions/Action";

export interface Props extends ExternalProps, ReduxProps {
}

export const SearchForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    // Right now we don't overlap use so I'm using Global for everything, but this is set up so we can use a specific reducer if needed to avoid collision
    // ie: SearchId.VALUE_LIST with a props.valueListSearch action
    // const getSearchAction = (): (params: SearchActionParams) => Promise<ActionResponse<SearchActionResponse>> => {
    const getSearchAction = (): ActionCreator<SearchActionParams, Promise<unknown>> => {
        switch (props.id) {
            case SearchId.GLOBAL:
                return props.globalSearch as unknown as ActionCreator<SearchActionParams, Promise<unknown>>;
            default:
                return props.globalSearch as unknown as ActionCreator<SearchActionParams, Promise<unknown>>;
        }
    };

    const search = async (): Promise<void> => {
        const values = await form.validateFields();

        const searchAction = getSearchAction();
        searchAction({
            searchType: values[FormFieldIds.Type],
            value: values[FormFieldIds.Value] || ""
        });
    };

    const clearValue = (): void => {
        props.clearSearch({id: props.id});
        form.setFieldsValue({[FormFieldIds.Value]: ""});
    };

    const getSearchType = (): SearchType => {
        if (props.searchType) {
            return props.searchType;
        }

        if (props.defaultSearchType) {
            return props.defaultSearchType;
        }

        return SearchType.EVERYTHING;
    };

    const getPlaceholder = (type: SearchType): string => {
        switch (type) {
            case SearchType.PROGRAMS:
                return "Program Id";
            case SearchType.CONTACTS:
                return "Contact Id or Full Email";
            case SearchType.VALUES:
                return "Value Id or Code";
            case SearchType.TRANSACTIONS:
                return "Transaction Id";
            default:
                return "Program, Contact, Value or Transaction Id";
        }
    };

    useEffect(() => {
        clearValue();
        return clearValue;
    }, []);

    const {getFieldValue} = form;
    const value = getFieldValue(FormFieldIds.Value);
    const type = (isEmpty(getFieldValue(FormFieldIds.Type))) ? getSearchType() : getFieldValue(FormFieldIds.Type);
    const typeChanged = (type != props.serverState.searchedType);
    const valueChanged = (value != props.serverState.searchedValue);
    const searchAction = (!props.serverState.fetchCount || valueChanged || typeChanged);

    const renderSelector = (): ReactNode => {
        return (
            <div className="lr-dropdown">
                <Form.Item
                    name={FormFieldIds.Type}
                    initialValue={getSearchType()}
                >
                    <Select
                        className="lr-global-search-select"
                        dropdownClassName={props.dropdownClassName}
                        disabled={!!props.searchType}
                    >
                        <Select.Option value={SearchType.EVERYTHING}>Everything</Select.Option>
                        <Select.Option value={SearchType.PROGRAMS}>Programs</Select.Option>
                        <Select.Option value={SearchType.CONTACTS}>Contacts</Select.Option>
                        <Select.Option value={SearchType.VALUES}>Values</Select.Option>
                        <Select.Option value={SearchType.TRANSACTIONS}>Transactions</Select.Option>
                    </Select>
                </Form.Item>
            </div>
        );
    };

    return (
        <Form onFinish={search} form={form}>
            <EmojiInputItem
                fieldId={FormFieldIds.Value}
                form={form}
                inputProps={{
                    placeholder: getPlaceholder(type),
                    disabled: props.serverState[props.id].fetching,
                    autoFocus: true,
                    addonBefore: renderSelector(),
                    addonAfter: (
                        (searchAction) ?
                            <SearchOutlined className="lr-interactive"
                                            onClick={(searchAction) ? search : clearValue}/> :
                            <CloseOutlined className="lr-interactive" onClick={(searchAction) ? search : clearValue}/>
                    )
                }}
                emojiPopoverProps={{
                    overlayStyle: {zIndex: 1051},
                    overlayClassName: IGNORE_CLOSE
                }}
                initialValue={props.initialValue}
            />
            <AllowSubmitOnEnter/>
        </Form>
    );
};