import * as React from "react";
import {ReactNode} from "react";
import {Form, Input, Radio} from "antd";
import {Program} from "lightrail-client/dist/model";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";
import {findCurrency, normalizeValueForServer, normalizeValueFromServer} from "../../../../util/currencyUtils";
import {FormattedCurrency} from "../../currency/FormattedCurrencyConnected";
import {withCurrencies, WithCurrencies} from "../../currency/withCurrenciesHOC";
import {USD_Default} from "../../../account/currency/create/DefaultCurrencies";
import {FormInstance} from "antd/es/form";

export interface Props extends WithCurrencies {
    program: Program;
    isForCreatingGenericCode?: boolean;
    form: FormInstance;
}

class ProgramValueItemClass extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.validateRange = this.validateRange.bind(this);
    }

    validateRange(rule: any, value: any, callback: any): void {
        const program = this.props.program;
        const currency = findCurrency(this.props.currencies, program.currency, USD_Default);

        if (typeof program.minInitialBalance === "number" && normalizeValueForServer(value, currency.decimalPlaces) < program.minInitialBalance) {
            callback(
                <span>
                    Minimum balance of <FormattedCurrency
                    value={program.minInitialBalance}
                    code={this.props.program.currency}
                /> allowed
                </span>
            );
        }

        if (typeof program.maxInitialBalance === "number" && normalizeValueForServer(value, currency.decimalPlaces) > program.maxInitialBalance) {
            callback(
                <span>
                    Maximum balance of <FormattedCurrency
                    value={program.maxInitialBalance}
                    code={this.props.program.currency}
                /> allowed
                </span>
            );
        }

        if (value < 0) {
            callback(`Balance cannot be less than 0.`);
        }

        callback();
    }

    renderBalanceRule(): JSX.Element {
        return (
            <Form.Item
                label="Value Rule"
            >
                <p style={{
                    wordWrap: "break-word"
                }}>
                    {`${this.props.program.balanceRule.explanation}`}
                </p>
            </Form.Item>
        );
    }

    renderSelector(): ReactNode {
        const balances = this.props.program.fixedInitialBalances;
        const currency = findCurrency(this.props.currencies, this.props.program.currency, USD_Default);

        return (
            <Form.Item
                name={FormFieldIds.Balance}
                initialValue={normalizeValueFromServer(balances[0], currency.decimalPlaces)}
            >
                <Radio.Group size="large" buttonStyle="solid">
                    {
                        balances.map(balance => (
                            <Radio.Button
                                key={balance.toString()}
                                value={normalizeValueFromServer(balance, currency.decimalPlaces)}
                            >
                                <FormattedCurrency
                                    value={balance}
                                    code={this.props.program.currency}
                                />
                            </Radio.Button>
                        ))
                    }
                </Radio.Group>
            </Form.Item>
        );
    }

    renderInput(): ReactNode {
        const currency = findCurrency(this.props.currencies, this.props.program.currency, USD_Default);

        return (
            <Form.Item
                name={FormFieldIds.Balance}
                rules={[{validator: this.validateRange}, GenericRequiredRule]}
                initialValue={(!!this.props.program.minInitialBalance) ? normalizeValueFromServer(this.props.program.minInitialBalance, currency.decimalPlaces) : 1}
            >
                <Input size="large" addonAfter={this.props.program.currency}/>
            </Form.Item>
        );
    }

    render(): JSX.Element {
        const program = this.props.program;
        if (!program) {
            return null;
        }

        if (program.balanceRule) {
            return this.renderBalanceRule();
        }

        const renderSelector = (!!program.fixedInitialBalances);
        const renderInputField = (!renderSelector);

        return (
            <Form.Item
                label={this.props.isForCreatingGenericCode ? `Per-Contact Balance (${program.currency})` : `Balance (${program.currency})`}
            >
                {
                    (renderSelector) &&
                    this.renderSelector()
                }
                {
                    (renderInputField) &&
                    this.renderInput()
                }
            </Form.Item>
        );
    }
}

export const ProgramValueItem = withCurrencies(ProgramValueItemClass);