import * as ReactRedux from "react-redux";
import {CreateGenericValueForm} from "./CreateGenericValueForm";
import {State} from "../../../../state/State";
import {actionCreator as createValue} from "../../../../actions/values/server/createValue";
import {actionCreator as clearValueError} from "../../../../actions/values/server/clearValueError";
import {Program} from "lightrail-client/dist/model";
import {ValueServerState} from "../../../../state/serverState/ValueServerState";

export interface ExternalProps {
    program: Program;
    getSubmit: (submit: () => void) => void;
    onSuccess?: () => void;
}

interface StateProps {
    valueServerState: ValueServerState;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    valueServerState: state.server.values,
    ...ownProps
});

const actionCreators = {
    createValue,
    clearValueError
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateGenericValueFormConnected = connector(CreateGenericValueForm);