import * as React from "react";
import {Links} from "../../../communication/links/Links";
import {PaginationParams} from "lightrail-client/dist/params";
import * as sprout from "sprout-data";
import {Button} from "antd";

export interface Props {
    links: Links;
    onClick: (params: PaginationParams) => void;

    loading?: boolean;
    limit?: number;
    numResults?: number;
}

export class LoadMoreFooter extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(): void {
        this.props.onClick(sprout.dissoc(this.props.links.next, ["url"], ["rel"]) as { [param: string]: string });
    }

    render(): JSX.Element {
        if (this.props.numResults === 0) {
            return null;
        }

        const showMoreButton = (!!this.props.links && this.props.links.next) && !(this.props.numResults < this.props.limit) || this.props.loading;
        return (
            <div className="lr-table-load-more-footer">
                {
                    (showMoreButton)
                        ? <Button loading={this.props.loading} onClick={this.onClick}>Load More</Button>
                        : <p className="lr-text-secondary">&mdash; End &mdash;</p>
                }
            </div>
        );
    }
}