import * as React from "react";
import {Modal} from "antd";
import {CreateWebhookFormConnected} from "./CreateWebhookFormConnected";
import {ExternalProps, ReduxProps} from "./CreateWebhookModalConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export class CreateWebhookModal extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);

        this.setSubmit = this.setSubmit.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    onOk(): void {
        if (!!this.submitForm) {
            this.submitForm();
        }
    }

    onCancel(): void {
        this.props.onCancel();
    }

    render(): JSX.Element {
        return (
            <Modal
                title={"Create Webhook"}
                maskClosable={false}
                visible={true}
                okText="Create"
                onOk={this.onOk}
                onCancel={this.onCancel}
                confirmLoading={this.props.processingWebhook}
            >
                <CreateWebhookFormConnected
                    onCreateResponse={this.onCancel}
                    getSubmit={this.setSubmit}
                />
            </Modal>
        );
    }
}