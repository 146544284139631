import * as React from "react";
import {Body1} from "../../ui/typography/TypographyTags";
import {ApiKey} from "../../../communication/dtos/user/apiKeys/ApiKey";
import {Button, Popconfirm, Tooltip} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import "./apiKeysLayout.scss";

export interface Props {
    apiKey: ApiKey;
    creator: string;

    onDelete: (tokenId: string) => void;
}

export class ApiKeyRow extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(): void {
        this.props.onDelete(this.props.apiKey.tokenId);
    }

    render(): JSX.Element {
        return (
            <div className={"apiKeyRow"}>
                <div>{this.props.apiKey.name}</div>
                <Body1>Created by: {this.props.creator}</Body1>
                <div className={"lastRowItem"}>
                    <Popconfirm
                        title={"Are you sure?"}
                        okText="Delete"
                        onConfirm={this.onDelete}
                    >
                        <Tooltip
                            title="Delete Key"
                            placement="left"
                        >
                            <Button>
                                <DeleteOutlined/>
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </div>
            </div>
        );
    }
}