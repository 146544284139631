export interface CountryCodeName {
    code: string;
    name: string;
}

/**
 * Get an array of just the Country Names from our ISOCountries object ex: ["Canada", "United States"]
 * @returns {string[]}
 */
export const getCountryNamesArray = (): string[] => {
    const list: string[] = [];

    const ISOCountryKeys: string[] = Object.keys(ISOCountries);
    ISOCountryKeys.forEach((countryCode: string)=> {
        list.push(ISOCountries[countryCode]);
    });
    return list;
};

/**
 * Get an array of ISOCountries Data
 *
 * @param specificCodes: list of specific codes to fetch, ie: specificCodes=["CA"] will return [{code:"CA", name:"Canada}]
 * @returns {CountryCodeName[]}
 */
export const getISOCountriesArray = (specificCodes?: string[]): CountryCodeName[]=> {
    const items: CountryCodeName[] = [];

    //CherryPick from filter list
    if (!!specificCodes) {
        specificCodes.forEach((item: string)=> {
            if (!!ISOCountries[item]) {
                items.push({code: item, name: ISOCountries[item]});
            }
        });
    } else {
        const ISOCountryKeys: string[] = Object.keys(ISOCountries);
        ISOCountryKeys.forEach((countryCode: string)=> {
            items.push({code: countryCode, name: ISOCountries[countryCode]});
        });
    }

    return items;
};

export const ISOCountries: {[key: string]: string} = {
    "BD": "Bangladesh",
    "BE": "Belgium",
    "BF": "Burkina Faso",
    "BG": "Bulgaria",
    "BA": "Bosnia and Herzegovina",
    "BB": "Barbados",
    "WF": "Wallis and Futuna",
    "BL": "Saint Barthelemy",
    "BM": "Bermuda",
    "BN": "Brunei",
    "BO": "Bolivia",
    "BH": "Bahrain",
    "BI": "Burundi",
    "BJ": "Benin",
    "BT": "Bhutan",
    "JM": "Jamaica",
    "BV": "Bouvet Island",
    "BW": "Botswana",
    "WS": "Samoa",
    "BQ": "Bonaire, Saint Eustatius and Saba ",
    "BR": "Brazil",
    "BS": "Bahamas",
    "JE": "Jersey",
    "BY": "Belarus",
    "BZ": "Belize",
    "RU": "Russia",
    "RW": "Rwanda",
    "RS": "Serbia",
    "TL": "East Timor",
    "RE": "Reunion",
    "TM": "Turkmenistan",
    "TJ": "Tajikistan",
    "RO": "Romania",
    "TK": "Tokelau",
    "GW": "Guinea-Bissau",
    "GU": "Guam",
    "GT": "Guatemala",
    "GS": "South Georgia and the South Sandwich Islands",
    "GR": "Greece",
    "GQ": "Equatorial Guinea",
    "GP": "Guadeloupe",
    "JP": "Japan",
    "GY": "Guyana",
    "GG": "Guernsey",
    "GF": "French Guiana",
    "GE": "Georgia",
    "GD": "Grenada",
    "GB": "United Kingdom",
    "GA": "Gabon",
    "SV": "El Salvador",
    "GN": "Guinea",
    "GM": "Gambia",
    "GL": "Greenland",
    "GI": "Gibraltar",
    "GH": "Ghana",
    "OM": "Oman",
    "TN": "Tunisia",
    "JO": "Jordan",
    "HR": "Croatia",
    "HT": "Haiti",
    "HU": "Hungary",
    "HK": "Hong Kong",
    "HN": "Honduras",
    "HM": "Heard Island and McDonald Islands",
    "VE": "Venezuela",
    "PR": "Puerto Rico",
    "PS": "Palestinian Territory",
    "PW": "Palau",
    "PT": "Portugal",
    "SJ": "Svalbard and Jan Mayen",
    "PY": "Paraguay",
    "IQ": "Iraq",
    "PA": "Panama",
    "PF": "French Polynesia",
    "PG": "Papua New Guinea",
    "PE": "Peru",
    "PK": "Pakistan",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PM": "Saint Pierre and Miquelon",
    "ZM": "Zambia",
    "EH": "Western Sahara",
    "EE": "Estonia",
    "EG": "Egypt",
    "ZA": "South Africa",
    "EC": "Ecuador",
    "IT": "Italy",
    "VN": "Vietnam",
    "SB": "Solomon Islands",
    "ET": "Ethiopia",
    "SO": "Somalia",
    "ZW": "Zimbabwe",
    "SA": "Saudi Arabia",
    "ES": "Spain",
    "ER": "Eritrea",
    "ME": "Montenegro",
    "MD": "Moldova",
    "MG": "Madagascar",
    "MF": "Saint Martin",
    "MA": "Morocco",
    "MC": "Monaco",
    "UZ": "Uzbekistan",
    "MM": "Myanmar",
    "ML": "Mali",
    "MO": "Macao",
    "MN": "Mongolia",
    "MH": "Marshall Islands",
    "MK": "Macedonia",
    "MU": "Mauritius",
    "MT": "Malta",
    "MW": "Malawi",
    "MV": "Maldives",
    "MQ": "Martinique",
    "MP": "Northern Mariana Islands",
    "MS": "Montserrat",
    "MR": "Mauritania",
    "IM": "Isle of Man",
    "UG": "Uganda",
    "TZ": "Tanzania",
    "MY": "Malaysia",
    "MX": "Mexico",
    "IL": "Israel",
    "FR": "France",
    "IO": "British Indian Ocean Territory",
    "SH": "Saint Helena",
    "FI": "Finland",
    "FJ": "Fiji",
    "FK": "Falkland Islands",
    "FM": "Micronesia",
    "FO": "Faroe Islands",
    "NI": "Nicaragua",
    "NL": "Netherlands",
    "NO": "Norway",
    "NA": "Namibia",
    "VU": "Vanuatu",
    "NC": "New Caledonia",
    "NE": "Niger",
    "NF": "Norfolk Island",
    "NG": "Nigeria",
    "NZ": "New Zealand",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "CK": "Cook Islands",
    "XK": "Kosovo",
    "CI": "Ivory Coast",
    "CH": "Switzerland",
    "CO": "Colombia",
    "CN": "China",
    "CM": "Cameroon",
    "CL": "Chile",
    "CC": "Cocos Islands",
    "CA": "Canada",
    "CG": "Republic of the Congo",
    "CF": "Central African Republic",
    "CD": "Democratic Republic of the Congo",
    "CZ": "Czech Republic",
    "CY": "Cyprus",
    "CX": "Christmas Island",
    "CR": "Costa Rica",
    "CW": "Curacao",
    "CV": "Cape Verde",
    "CU": "Cuba",
    "SZ": "Swaziland",
    "SY": "Syria",
    "SX": "Sint Maarten",
    "KG": "Kyrgyzstan",
    "KE": "Kenya",
    "SS": "South Sudan",
    "SR": "Suriname",
    "KI": "Kiribati",
    "KH": "Cambodia",
    "KN": "Saint Kitts and Nevis",
    "KM": "Comoros",
    "ST": "Sao Tome and Principe",
    "SK": "Slovakia",
    "KR": "South Korea",
    "SI": "Slovenia",
    "KP": "North Korea",
    "KW": "Kuwait",
    "SN": "Senegal",
    "SM": "San Marino",
    "SL": "Sierra Leone",
    "SC": "Seychelles",
    "KZ": "Kazakhstan",
    "KY": "Cayman Islands",
    "SG": "Singapore",
    "SE": "Sweden",
    "SD": "Sudan",
    "DO": "Dominican Republic",
    "DM": "Dominica",
    "DJ": "Djibouti",
    "DK": "Denmark",
    "VG": "British Virgin Islands",
    "DE": "Germany",
    "YE": "Yemen",
    "DZ": "Algeria",
    "US": "United States",
    "UY": "Uruguay",
    "YT": "Mayotte",
    "UM": "United States Minor Outlying Islands",
    "LB": "Lebanon",
    "LC": "Saint Lucia",
    "LA": "Laos",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TT": "Trinidad and Tobago",
    "TR": "Turkey",
    "LK": "Sri Lanka",
    "LI": "Liechtenstein",
    "LV": "Latvia",
    "TO": "Tonga",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "LR": "Liberia",
    "LS": "Lesotho",
    "TH": "Thailand",
    "TF": "French Southern Territories",
    "TG": "Togo",
    "TD": "Chad",
    "TC": "Turks and Caicos Islands",
    "LY": "Libya",
    "VA": "Vatican",
    "VC": "Saint Vincent and the Grenadines",
    "AE": "United Arab Emirates",
    "AD": "Andorra",
    "AG": "Antigua and Barbuda",
    "AF": "Afghanistan",
    "AI": "Anguilla",
    "VI": "U.S. Virgin Islands",
    "IS": "Iceland",
    "IR": "Iran",
    "AM": "Armenia",
    "AL": "Albania",
    "AO": "Angola",
    "AQ": "Antarctica",
    "AS": "American Samoa",
    "AR": "Argentina",
    "AU": "Australia",
    "AT": "Austria",
    "AW": "Aruba",
    "IN": "India",
    "AX": "Aland Islands",
    "AZ": "Azerbaijan",
    "IE": "Ireland",
    "ID": "Indonesia",
    "UA": "Ukraine",
    "QA": "Qatar",
    "MZ": "Mozambique"
};

export const displayRegex = /listDivider[0-9]+/;
export const supportedPhoneRegions: { [key: string]: string } = {
    "Canada": "+1",
    "United States": "+1",
    "Afghanistan": "+93",
    "Albania": "+355",
    "Algeria": "+213",
    "American Samoa": "+1684",
    "Andorra": "+376",
    "Anguilla": "+1264",
    "Antigua and Barbuda": "+1268",
    "Argentina": "+54",
    "Aruba": "+297",
    "Ascension": "+247",
    "Australia/Cocos/Christmas Island": "+61",
    "Austria": "+43",
    "Bahamas": "+1",
    "Bahrain": "+973",
    "Bangladesh": "+880",
    "Barbados": "+1246",
    "Belarus": "+375",
    "Belgium": "+32",
    "Belize": "+501",
    "Bermuda": "+1441",
    "Bolivia": "+591",
    "Brazil": "+55",
    "Brunei": "+673",
    "Bulgaria": "+359",
    "Cambodia": "+855",
    "Cape Verde": "+238",
    "Cayman Islands": "+1345",
    "Central Africa": "+236",
    "Chad": "+235",
    "Chile": "+56",
    "Colombia": "+57",
    "Congo": "+242",
    "Congo, Dem Rep": "+243",
    "Costa Rica": "+506",
    "Croatia": "+385",
    "Cuba": "+53",
    "Czech Republic": "+420",
    "Denmark": "+45",
    "Dominican Republic": "+1809",
    "East Timor": "+670",
    "Ecuador": "+593",
    "Egypt": "+20",
    "Ethiopia": "+251",
    "Fiji": "+679",
    "Finland/Aland Islands": "+358",
    "France": "+33",
    "Georgia": "+995",
    "Germany": "+49",
    "Ghana": "+233",
    "Gibraltar": "+350",
    "Greece": "+30",
    "Greenland": "+299",
    "Guam": "+1671",
    "Guatemala": "+502",
    "Guinea": "+224",
    "Haiti": "+509",
    "Honduras": "+504",
    "Hong Kong": "+852",
    "Hungary": "+36",
    "Iceland": "+354",
    "India": "+91",
    "Iran": "+98",
    "Iraq": "+964",
    "Ireland": "+353",
    "Italy": "+39",
    "Ivory Coast": "+225",
    "Jamaica": "+1876",
    "Japan": "+81",
    "Kenya": "+254",
    "Kyrgyzstan": "+996",
    "Latvia": "+371",
    "Libya": "+218",
    "Lithuania": "+370",
    "Luxembourg": "+352",
    "Madagascar": "+261",
    "Mexico": "+52",
    "Monaco": "+377",
    "Namibia": "+264",
    "Nepal": "+977",
    "Netherlands": "+31",
    "New Zealand": "+64",
    "Niger": "+227",
    "Nigeria": "+234",
    "Norway": "+47",
    "Panama": "+507",
    "Paraguay": "+595",
    "Peru": "+51",
    "Philippines": "+63",
    "Poland": "+48",
    "Portugal": "+351",
    "Puerto Rico": "+1787",
    "Qatar": "+974",
    "Romania": "+40",
    "Russia/Kazakhstan": "+7",
    "Rwanda": "+250",
    "Sao Tome and Principe": "+239",
    "Saudi Arabia": "+966",
    "Serbia": "+381",
    "Sierra Leone": "+232",
    "Singapore": "+65",
    "Slovakia": "+421",
    "Slovenia": "+386",
    "Solomon Islands": "+677",
    "Somalia": "+252",
    "South Africa": "+27",
    "Spain": "+34",
    "Sri Lanka": "+94",
    "Sudan": "+249",
    "Suriname": "+597",
    "Swaziland": "+268",
    "Sweden": "+46",
    "Switzerland": "+41",
    "Taiwan": "+886",
    "Tajikistan": "+992",
    "Thailand": "+66",
    "Togo": "+228",
    "Trinidad and Tobago": "+1868",
    "Tunisia": "+216",
    "Turkey": "+90",
    "Turkmenistan": "+993",
    "Turks and Caicos Islands": "+1649",
    "Tuvalu": "+688",
    "Uganda": "+256",
    "Ukraine": "+380",
    "United Arab Emirates": "+971",
    "United Kingdom": "+44",
    "Uruguay": "+598",
    "Uzbekistan": "+998",
    "Vanuatu": "+678",
    "Vatican City": "+379",
    "Venezuela": "+58",
    "Vietnam": "+84",
    "Virgin Islands, British": "+1284",
    "Virgin Islands, U.S.": "+1340",
    "Yemen": "+967",
    "Zambia": "+260",
    "Zimbabwe": "+263"
};