import * as React from "react";
import {useState, useEffect} from "react";
import {Form, Input, message, Switch, TreeSelect} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {InsecureUrlErrorMessage, WebhookEventEnums} from "./WebhookEventEnums";
import {CreateWebhookParams} from "../../../../communication/dtos/webhooks/CreateWebhookParams";
import {generateId} from "../../../../communication/utils/urlUtils";
import {UrlRegex} from "../../../../util/regexp";
import {useForm} from "antd/lib/form/Form";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {ExternalProps, ReduxProps} from "./CreateWebhookFormConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export interface State {
    showError: boolean;
}

export const CreateWebhookForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [showError, setShowError] = useState(false);
    const [urlErrors, setUrlError] = useState([]);
    const [address, setAddress] = useState("");

    const webhookEvents = WebhookEventEnums;
    const EventsTreeNodes: JSX.Element[] = Object.keys(webhookEvents)
        .filter(key => isNaN(parseInt(key, 10)))
        .map(
            (key: keyof typeof webhookEvents) => ({
                title: webhookEvents[key],
                value: webhookEvents[key],
                key: webhookEvents[key],
                className: "showMonoFont",
                type: null,
                props: null
            }));

    const handleBlur = (): void => {
        setShowError(true);
    };

    const handleFocus = (): void => {
        setShowError(false);
    };

    const submit = async (): Promise<void> => {
        try {
            const values = await form.validateFields();
            let events = values[FormFieldIds.Events];
            for (let i = 0; i < values[FormFieldIds.Events].length; i++) {
                if (values[FormFieldIds.Events][i] === "All") {
                    events = ["*"];
                }
            }

            const newWebhook: CreateWebhookParams = {
                id: generateId(),
                url: values[FormFieldIds.Url],
                active: values[FormFieldIds.Active],
                events: events
            };
            const response = await props.createWebhook(newWebhook).value;
            message.success("New Webhook Created");
            if (props.onCreateResponse) {
                props.onCreateResponse(response);
            }
        } catch (err) {
            setUrlError([...urlErrors, err]);
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    useEffect(() => {
        const errors = form.getFieldError(FormFieldIds.Url);
        const isTouched = form.isFieldTouched(FormFieldIds.Url);
        setUrlError(isTouched && showError ? errors : []);
    }, [address, showError]);

    return (
        <Form
            onFinish={submit}
            form={form}
            layout="vertical"
        >
            <Form.Item
                label="Active"
                extra="Sets whether the webhook will be active upon creation."
                name={FormFieldIds.Active}
                initialValue={true}
            >
                <Switch
                    defaultChecked={true}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />
            </Form.Item>
            <Form.Item
                label="Webhook Url"
                validateStatus={urlErrors.length ? "error" : null}
                help={urlErrors.length ? InsecureUrlErrorMessage.URL_ERROR : ""}
                name={FormFieldIds.Url}
                rules={[{
                    required: true,
                    message: "Please enter an address for the webhook."
                }, {
                    pattern: UrlRegex,
                    message: InsecureUrlErrorMessage.URL_ERROR
                }]}
            >
                <Input
                    type="url"
                    placeholder="Url"
                    data-cy="url"
                    autoFocus={true}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                />
            </Form.Item>
            <Form.Item
                label="Webhook Events"
                name={FormFieldIds.Events}
                initialValue={["All"]}
                rules={[
                    {required: true, message: "Please select at least one event"}
                ]}
            >
                <TreeSelect
                    dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                    treeDefaultExpandedKeys={["All"]}
                    treeData={
                        [{
                            title: "All",
                            value: "All",
                            key: "All",
                            children: EventsTreeNodes
                        }]
                    }
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    maxTagCount={10}
                />
            </Form.Item>
        </Form>
    );
};