import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {CreateProgramDrawer} from "./CreateProgramDrawer";
import {Program} from "lightrail-client/dist/model";

export interface ExternalProps {
    program?: Program;
    open: boolean;
    onCancel?: () => void;
}

interface StateProps {
    processingProgram: boolean;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    processingProgram: state.server.programs.processing,
    ...props
});

const actionCreators = {};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateProgramDrawerConnected = connector(CreateProgramDrawer);