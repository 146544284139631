import * as React from "react";
import * as Sentry from "@sentry/browser";
import {HashRouter, Route, Switch} from "react-router-dom";
import {getPageTitle, Paths} from "../util/routes/routes";
import {MainConnected} from "./MainConnected";
import {bootIntercom} from "../util/intercom";
import {ScreenSize} from "../state/uistate/LayoutUiState";
import {onMatchMedia} from "../util/viewportLayoutUtil";
import {UiActionPaths} from "../state/uistate/UiActionPaths";
import {Location} from "history";
import {MaintenanceModeLayout} from "./maintenance/MaintenanceModeLayout";
import {LocationChangeListener} from "./router/LocationChangeListener";
import {AuthMain} from "./auth/AuthMain";
import {AuthSidebarLayout} from "./layout/auth/AuthSidebarLayout";
import {ResetPasswordPageConnected} from "./auth/pages/resetPassword/ResetPasswordPageConnected";
import {message} from "antd";
import {scrubState, sentryUrl} from "../util/sentry";
import {ReduxProps} from "./AppConnected";

export interface Props extends ReduxProps {
}

/**
 * The top-level container of the application.  In development this can be hot-loaded.
 */
export class App extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onLocationChange = this.onLocationChange.bind(this);
        this.onScreenSizeChange = this.onScreenSizeChange.bind(this);
    }

    shouldComponentUpdate(): boolean {
        return false;
    }

    componentDidMount(): void {
        this.setupMQLs();
        this.setupSentry();
        bootIntercom();
        message.config({duration: 3});

        this.props.ping({});
    }

    setupMQLs(): void {
        //NOTE: These values match the media queries used in _breakpoints.scss
        onMatchMedia("(max-width: 480px)", this.onScreenSizeChange.bind(this, ScreenSize.SMALL));
        onMatchMedia("(min-width: 480px) and (max-width: 840px)", this.onScreenSizeChange.bind(this, ScreenSize.MEDIUM));
        onMatchMedia("(min-width: 840px)", this.onScreenSizeChange.bind(this, ScreenSize.LARGE));
    }

    onScreenSizeChange(screenSize: ScreenSize): void {
        this.props.setUIState({uiStatePath: UiActionPaths.LAYOUT_SCREEN_SIZE, value: screenSize});
    }

    setupSentry(): void {
        const host = (window && window.location) ? window.location.hostname : "unknown";

        const sentryDataCallback = (data: any): any => {
            if (data.extra) {
                data.extra.state = scrubState(this.props.state);
            }
            return data;
        };

        Sentry.init({
            dsn: sentryUrl,
            environment: host,
            release: process.env["RELEASE_NAME"],
            beforeSend: sentryDataCallback
        });
    }

    onLocationChange(location: Location): void {
        window.scrollTo(0, 0);

        const path = location.pathname;
        this.props.addLocationToHistory({path});

        document.title = getPageTitle(path);
    }

    render(): JSX.Element {
        return (
            <HashRouter>
                <LocationChangeListener logInitialLocation={true} onLocationChange={this.onLocationChange}>
                    <Switch>
                        <Route path={Paths.AUTH_ROOT} component={AuthMain}/>

                        <Route path={Paths.RESET_PASSWORD}>
                            <AuthSidebarLayout>
                                <ResetPasswordPageConnected/>
                            </AuthSidebarLayout>
                        </Route>

                        <Route exact path={Paths.MAINTENANCE_ROOT} component={MaintenanceModeLayout}/>

                        <Route path={Paths.ROOT} component={MainConnected}/>
                    </Switch>
                </LocationChangeListener>
            </HashRouter>
        );
    }
}
