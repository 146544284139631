import * as React from "react";
import {Col, Radio, Row} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {AdvancedRuleItem} from "./rules/AdvancedRuleItem";
import {ProgramValueItem} from "./ProgramValueItem";
import {ProgramCopy} from "../../../programs/create/CreateProgramFormCopy";
import {Program} from "lightrail-client/dist/model";
import {FormInstance} from "antd/es/form";

export interface Props {
    form: FormInstance;
    program: Program;
    onChange?: () => void;
    isBalance?: boolean;
    isGeneric?: boolean;
}

export const CypressSelectors = {
    BalanceRuleExplanation: "lr-cy-balance-rule-explanation",
    BalanceRuleSyntax: "lr-cy-balance-rule-syntax"
};

export class BalanceAndBalanceRuleButton extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    renderBalanceRuleFormField(): JSX.Element {
        const program = this.props.program;
        return (
            <AdvancedRuleItem
                ruleItemId={FormFieldIds.BalanceRule}
                rule={(!!program) ? program.balanceRule : null}
                explanationItemId={FormFieldIds.BalanceRuleExplanation}
                form={this.props.form}
                explanationProps={
                    {
                        placeholder: ProgramCopy.BALANCE_RULE_EXPLANATION_PLACEHOLDER,
                        size: "large",
                        id: "lr-cy-balance-rule-explanation"
                    }
                }
                ruleProps={
                    {
                        placeholder: ProgramCopy.BALANCE_RULE_SYNTAX_PLACEHOLDER,
                        size: "large",
                        id: "lr-cy-balance-rule-syntax"
                    }
                }
                disableEditToggle
            />
        );
    }

    render(): JSX.Element {
        return (
            <Row style={{paddingBottom: "24px", display: "block"}}>
                <Col>
                    <Radio.Group name="balanceSelect"
                                 size={"large"}
                                 buttonStyle="solid"
                                 defaultValue="balance"
                                 onChange={this.props.onChange}
                                 value={this.props.isBalance}
                    >
                        <Radio.Button value={true}>Balance</Radio.Button>
                        <Radio.Button value={false}>Balance Rule</Radio.Button>
                    </Radio.Group>
                </Col>
                {
                    this.props.isBalance &&
                    <Col>
                        <Row style={{padding: "0 8px"}}>
                            <Col span={16} style={{width: "100%"}}>
                                <ProgramValueItem isForCreatingGenericCode={this.props.isGeneric}
                                                  program={this.props.program}
                                                  form={this.props.form}
                                />
                            </Col>
                        </Row>
                    </Col>
                }
                {
                    !this.props.isBalance &&
                    <Col>
                        <p style={{marginTop: 16, color: "rgba(0, 0, 0, 0.54)"}}>{ProgramCopy.BALANCE_RULE_INTRO}&nbsp;
                            <a href={ProgramCopy.REDEMPTION_RULES_DOCS_LINK_URL} target="_blank" rel="noreferrer">{ProgramCopy.DOCS_LINK_LABEL}</a>
                        </p>
                        <Row style={{padding: "0 8px 24px"}}>
                            <Col span={16} style={{width: "100%"}}>
                                {
                                    this.renderBalanceRuleFormField()
                                }
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
        );
    }
}
