import * as restAccess from "./utils/restAccess";
import {AddDeviceRequest, AddDeviceResponse} from "./dtos/auth/MFA/AddDevice";
import {AuthMFARequest, AuthMFAResponse} from "./dtos/auth/MFA/MFA";
import {DisableResponse} from "./dtos/auth/MFA/Disable";
import {EnableRequest, EnableResponse} from "./dtos/auth/MFA/Enable";
import {GetBackupCodesResponse} from "./dtos/auth/MFA/GetBackupCodes";
import {GetDeviceResponse} from "./dtos/auth/MFA/GetDevice";
import {ResendAuthCodeResponse} from "./dtos/auth/MFA/ResendAuthCode";
import {cleanNulls} from "../util/communicationUtils";

const endpointPrefix = "/v2/user";

export const addDevice = (values: AddDeviceRequest): Promise<AddDeviceResponse> =>
    restAccess.fetchJson(`${endpointPrefix}/mfa`, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

export const authMFA = (values: AuthMFARequest): Promise<AuthMFAResponse> =>
    restAccess.fetchJson(`${endpointPrefix}/login/mfa`, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

export const disable =
    (): Promise<DisableResponse> =>
        restAccess.fetchJson(`${endpointPrefix}/mfa`, {}, {
            method: "DELETE"
        });

export const enable = (values: EnableRequest): Promise<EnableResponse> =>
    restAccess.fetchJson(`${endpointPrefix}/mfa/complete`, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(values)
    });

export const getBackupCodes =
    (): Promise<GetBackupCodesResponse> => restAccess.fetchJson(`${endpointPrefix}/mfa/backupCodes`);

export const getDevice =
    (): Promise<GetDeviceResponse> => restAccess.fetchJson(`${endpointPrefix}/mfa`);

export const getDeviceForLogin =
    (): Promise<GetDeviceResponse> => restAccess.fetchJson(`${endpointPrefix}/mfa`);

export const resendAuthCode =
    (): Promise<ResendAuthCodeResponse> => restAccess.fetchJson(`${endpointPrefix}/login/mfa`);
