import * as React from "react";
import {DetailHeader, DetailHeaderProps} from "./DetailHeader";
import {PaddedSpin} from "../../ui/Loading";

export interface Props {
    loading?: boolean;
    headerProps: DetailHeaderProps;
}

export class DetailLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div>
                {
                    (!!this.props.loading) &&
                    <div style={{position: "absolute", left: "calc(50% - 34px)"}}>
                        <PaddedSpin/>
                    </div>
                }
                <DetailHeader {...this.props.headerProps}/>
                {
                    this.props.children
                }
            </div>
        );
    }
}