import * as React from "react";
import {classNames} from "../../../util/stringUtils";
import {dissoc} from "sprout-data";

// Disable eslint for legacy styles
const typeClasses: any = require("../../../styles/legacy/_typography.scss"); // eslint-disable-line

/**
 *  todo: Add WARNING and ALERT colors
 *  ACCENT1: accent color
 *  ACCENT2: warning color
 *  ACCENT3: alert color
 */
export enum TextColor { NORMAL, SECONDARY, DISABLED, ACTIVE, ACCENT1, ACCENT2, ACCENT3, WHITE, WHITE_MID }

export interface ColoredTextProps extends React.HTMLProps<HTMLParagraphElement> {
    className?: string;
    textColor?: TextColor;
    inline?: boolean;
    inheritColor?: boolean;
    style?: React.CSSProperties;
    onClickStyles?: boolean;
}

export class ColoredText extends React.PureComponent<ColoredTextProps, {}> {
    public static defaultProps: ColoredTextProps = {
        onClickStyles: true
    };

    getColorClassByType(textType: number): string {
        if (textType === TextColor.SECONDARY) {
            return typeClasses.fontColorSecondary;
        }
        if (textType === TextColor.DISABLED) {
            return typeClasses.fontColorDisabled;
        }
        if (textType === TextColor.ACTIVE) {
            return typeClasses.fontColorActive;
        }
        if (textType === TextColor.ACCENT1) {
            return typeClasses.fontColorAccent1;
        }
        if (textType === TextColor.ACCENT2) {
            return typeClasses.fontColorAccent2;
        }
        if (textType === TextColor.ACCENT3) {
            return typeClasses.fontColorAccent3;
        }
        if (textType === TextColor.WHITE) {
            return typeClasses.fontColorWhite;
        }
        if (textType === TextColor.WHITE_MID) {
            return typeClasses.fontColorWhiteMid;
        }
        return typeClasses.fontColorNormal;
    }

    render(): JSX.Element {
        const textType = this.props.textColor ? this.props.textColor : TextColor.NORMAL;

        let className = this.props.className ? this.props.className : "";
        if (!this.props.inheritColor) {
            className = classNames([className, this.getColorClassByType(textType)]);
        }

        if (this.props.inline) {
            className = classNames([className, "displayInline"]);
        }

        if (!!this.props.onClick) {
            if (!!this.props.onClickStyles) {
                className = classNames([className, typeClasses.link]);
            } else {
                className = classNames([className, typeClasses.pointer]);
            }
        }

        return (
            <p
                style={this.props.style}
                className={className}
                {...dissoc(this.props, ["inline"], ["textType"], ["style"], ["textColor"], ["className"], ["onClickStyles"], ["inheritColor"])}
            >
                {
                    this.props.children
                }
            </p>
        );
    }
}