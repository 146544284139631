import * as React from "react";
import {message} from "antd";
import * as CopyToClipboard from "copy-to-clipboard";

export interface Props {
    content: object;
}

interface State {
    contentJsonString: string;
}

export class RuleDisplay extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setContentJsonString();
        this.state = {
            contentJsonString: JSON.stringify(this.props.content, null, 2)
        };
        this.setContentJsonString = this.setContentJsonString.bind(this);
        this.copyJsonToClipboard = this.copyJsonToClipboard.bind(this);
    }

    componentDidUpdate(): void {
        this.setState({
            contentJsonString: JSON.stringify(this.props.content, null, 2)
        });
        this.setContentJsonString();
    }

    setContentJsonString(): void {
        try {
            this.setState({contentJsonString: JSON.stringify(this.props.content, null, 2)});
        } catch (e) {
            this.setState({contentJsonString: ""});
        }
    }

    copyJsonToClipboard(): void {
        CopyToClipboard(this.state.contentJsonString);
        message.success("Balance Rule Copied to Clipboard");
    }

    render(): JSX.Element {
        return (
            <div>
                <pre className="json">
                    {
                        this.state.contentJsonString
                    }
                    </pre>
                <div className="footer">
                    <a onClick={this.copyJsonToClipboard}>Copy to clipboard</a>
                </div>
            </div>
        );
    }
}