import * as React from "react";
import {ReactNode} from "react";
import {Row} from "antd";

export interface ListHeaderProps {
    createButton?: ReactNode;
    headerActions?: ReactNode;
}

export class ListHeader extends React.PureComponent<ListHeaderProps, {}> {
    render(): JSX.Element {
        return (
            <Row className="lr-list-header lr-justify-right">
                {
                    (!!this.props.headerActions) &&
                    this.props.headerActions
                }
                {
                    (!!this.props.createButton) &&
                    this.props.createButton
                }
            </Row>
        );
    }
}