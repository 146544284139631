import * as React from "react";
import {filterLightrailNamespace, getReadableKey, looksLikeJson} from "../../../../util/stringUtils";
import {Form, Input} from "antd";
import {isJSON, tryParseJSON} from "../../../../util/dataUtils";
import {FormInstance} from "antd/es/form";

const {TextArea} = Input;

const LIGHTRAIL_NOTES_KEY = "lightrail_webapp_notes";

export interface Props {
    metadata: { [key: string]: any };
    metadataNotesKey?: string;
    form: FormInstance;
}

export class MetadataItems extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        const metadata: { [key: string]: any } = this.props.metadata ? Object.assign({}, this.props.metadata) : {};

        const notesKey = this.props.metadataNotesKey ? this.props.metadataNotesKey : LIGHTRAIL_NOTES_KEY;

        if (!metadata[notesKey]) {
            metadata[notesKey] = null;
        }

        return (
            <div className="width100Perc">
                {
                    Object.keys(metadata).sort((key1) => key1 == notesKey ? -1 : 0) // notes show up first
                        .map(key => (
                            <Form.Item
                                label={getReadableKey(filterLightrailNamespace(key))}
                                key={key}
                                name={"metadata-" + key}
                                initialValue={isJSON(metadata[key]) ? JSON.stringify(tryParseJSON(metadata[key]), null, 4) : metadata[key]}
                            >
                                <TextArea
                                    className={isJSON(metadata[key]) ? "codeDisplay" : ""}
                                    autoSize={{minRows: 2}}
                                    disabled={isJSON(metadata[key])}
                                    data-cy="form-item-metadata"
                                />
                            </Form.Item>
                        ))
                }
            </div>
        );
    }
}

/**
 * Returns either an object or null. If it returns an object then the metadata should be updated.
 * If it returns null, then it means the metadata can be excluded from the update request.
 * This function differentiates returning null from {}. This allows for different behaviors when updating metadata.
 */
export namespace MetadataItems {
    export function parseMetadataFromSubmitValues(values: any, metadataNotesKey: string = LIGHTRAIL_NOTES_KEY): { [k: string]: any } | null {
        const metadata: { [key: string]: any } = {};
        Object.keys(values).forEach(key => {
            if (/metadata/.test(key)) {
                const mdKey = key.split("-")[1];
                let value = values[key];
                if (looksLikeJson(value)) {
                    try {
                        value = JSON.parse(value);
                    } catch (e) {
                        // Failed to parse. Just use whatever they provided as a string.
                    }
                }
                metadata[mdKey] = value;
            }
        });

        if (Object.keys(metadata).length > 0) {
            // if notes input is null or empty remove notes key from metadata
            if (metadata[metadataNotesKey] === "" || metadata[metadataNotesKey] === null) {
                delete metadata[metadataNotesKey];
            }
            return metadata;
        } else {
            return null; // this is subtle. If no metadata to update then return null indicating no metadata updates were in the form.
        }
    }
}