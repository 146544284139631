import * as React from "react";
import {Form, Input, Spin} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";
import {reloadPage} from "../../../util/navigation";
import {WithRouterProps} from "../../router/WithRouterProps";
import {ExternalProps, ReduxProps} from "./NewAccountFormConnected";
import {useForm} from "antd/lib/form/Form";

export interface Props extends WithRouterProps<any>, ExternalProps, ReduxProps {
}

export const NewAccountForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [showSpinner, setShowSpinner] = React.useState(false);

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        setShowSpinner(true);
        const newName = values[FormFieldIds.NewAccountName];

        try {
            await props.createAccount({name: newName});
            const listResponse = await props.listAccounts({});
            const currentAccount = listResponse.value.find((account) => account.accountDisplayName == newName);

            await props.switchAccounts({
                accountId: currentAccount.accountId,
                mode: UserModes.TEST
            });
        } finally {
            setShowSpinner(false);

            if (props.afterSubmit) {
                props.afterSubmit();
            } else {
                reloadPage(props.history);
            }
        }
    };

    React.useEffect(() => {
        props.getSubmit(submit);
    }, []);

    return (
        showSpinner ?
            <Spin/> :
            <Form
                hideRequiredMark
                form={form}
                onFinish={submit}
            >
                <Form.Item
                    label="Account Name"
                    name={FormFieldIds.NewAccountName}
                    rules={[
                        {required: true, min: 1, message: "Please enter a name for the new account"}
                    ]}
                >
                    <Input placeholder="Your New Account Name"/>
                </Form.Item>
                <AllowSubmitOnEnter/>
            </Form>
    );
};