import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {Currency} from "lightrail-client/dist/model/Currency";
import {Omit} from "react-router";
import * as React from "react";

export interface WithCurrencies {
    currencies: Currency[];
}

const mapStateToProps = (state: State): WithCurrencies => ({
    currencies: state.server.currency.list
});

// Modeling this after how withRouter() Higher Order Component works
// you just wrap your component to provide it with currencies state and extend your props interface with WithCurrencies
// Not sure about file name
export function withCurrencies<P extends WithCurrencies>(
    component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WithCurrencies>> {
    const connector = ReactRedux.connect<WithCurrencies, {}, P>(mapStateToProps);
    const connected = connector<any>(component);
    return connected as React.ComponentType<Omit<P, keyof WithCurrencies>>;
}