import * as React from "react";
import {FormEvent} from "react";
import {Drawer} from "antd";
import {ContactCreateAndEditFormConnected} from "../update/ContactCreateAndEditFormConnected";
import {CreateFormDrawerFooter} from "../../layout/forms/CreateFormDrawerFooter";

export interface Props {
    onCancel: () => void;
    open: boolean;
    contactsProcessing: boolean;
}

export class CreateContactDrawer extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    onCancel(): void {
        this.props.onCancel();
    }

    onSubmit(e: FormEvent<any>): void {
        e.preventDefault();
        this.submitForm();
    }

    render(): JSX.Element {
        return (
            <Drawer
                title={`Create a new Contact`}
                maskClosable
                visible={this.props.open}
                destroyOnClose
                className="createContactDrawer"
                width={800}
                onClose={this.props.onCancel}
            >
                <ContactCreateAndEditFormConnected onClose={this.onCancel} getSubmit={this.setSubmit}/>
                <CreateFormDrawerFooter
                    onCancelClick={this.onCancel}
                    onSubmitClick={this.onSubmit}
                    loading={this.props.contactsProcessing}
                />
            </Drawer>
        );
    }
}