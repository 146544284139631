import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {createProgram} from "lightrail-client/dist/programs";
import {payloadHasBody} from "../../../util/dataUtils";
import {CreateProgramResponse} from "lightrail-client/dist/params";
import {dateIsInFuture} from "../../../util/dateUtils";

export const type = "createProgram";
export const actionCreator = promisePattern.actionCreator(type, createProgram);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateProgramResponse> =
    (state, payload) => {
        const allList = payloadHasBody(payload) ? [payload?.body, ...state.server.programs.allList] : state.server.programs.allList;

        if (dateIsInFuture(payload?.body.endDate)) {
            const activeList = payloadHasBody(payload) ? [payload?.body, ...state.server.programs.activeList] : state.server.programs.activeList;
            return sprout.assoc(state,
                ["server", "programs", "allList"], allList,
                ["server", "programs", "activeList"], activeList,
                ["server", "programs", "processing"], false
            );
        } else {
            const inactiveList = payloadHasBody(payload) ? [payload?.body, ...state.server.programs.inactiveList] : state.server.programs.inactiveList;
            return sprout.assoc(state,
                ["server", "programs", "allList"], allList,
                ["server", "programs", "inactiveList"], inactiveList,
                ["server", "programs", "processing"], false
            );
        }
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "programs", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "programs", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);