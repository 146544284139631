import {updateIntercom} from "../../util/intercom";
import * as React from "react";
import {ReduxProps} from "./IntercomUserHashLoaderConnected";

export interface Props extends ReduxProps {
}

export class IntercomUserHashLoader extends React.PureComponent<Props, {}> {
    async componentDidUpdate(): Promise<void> {
        if (!this.props.intercom.fetching && !this.props.intercom.hasUpdated && this.props.user.loggedIn) {
            await this.props.getIntercomUserHash({});
            updateIntercom(this.props.intercom.userHash, this.props.user.email, this.props.intercom.userId)
        }
    }

    render(): JSX.Element {
        return null;
    }
}