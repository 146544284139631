import * as React from "react";
import {ReactNode} from "react";
import {Checkbox, Form, Popover} from "antd";
import {DiscountSellerLiabilityRadioGroup} from "./DiscountSellerLiabilityRadioGroup";
import {DiscountSellerLiabilityRule} from "lightrail-client/dist/model";
import {FormInstance} from "antd/es/form";
import {InfoCircleOutlined} from "@ant-design/icons";

export interface State {
    includeDiscountSellerLiabilityRule: boolean;
}

export interface Props {
    fieldId: string;
    className?: string;
    extraText?: string;
    disabled?: boolean;
    isBasic?: boolean;
    discountSellerLiabilityRule?: DiscountSellerLiabilityRule;
    form: FormInstance;
}

export class DiscountSellerLiabilityItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            includeDiscountSellerLiabilityRule: (!!this.props.discountSellerLiabilityRule)
        };

        this.onDiscountCheckboxChange = this.onDiscountCheckboxChange.bind(this);
    }

    onDiscountCheckboxChange(): void {
        this.setState({includeDiscountSellerLiabilityRule: !this.state.includeDiscountSellerLiabilityRule});
        if (!this.state.includeDiscountSellerLiabilityRule) {
            this.props.form.setFieldsValue({[this.props.fieldId]: 0});
        } else {
            this.props.form.setFieldsValue({[this.props.fieldId]: null});
        }
    }

    sellerLiabilityCheckboxLabel(): ReactNode {
        return (
            <span>
                Seller liability&nbsp;
                <Popover
                    content={"The percent of discount that the seller is liable for"}
                >
                    <InfoCircleOutlined/>
                </Popover>
            </span>
        );
    }

    render(): JSX.Element {
        const className = this.props.className ? `${this.props.className} paddingBottom0` : "paddingBottom0";
        const discountSellerLiabilityRule = (!!this.props.discountSellerLiabilityRule && this.state.includeDiscountSellerLiabilityRule) ? this.props.discountSellerLiabilityRule : null;

        return (
            <div>
                {
                    (!this.props.disabled) &&
                    <p className={"marginBottom0"}>
                        <Checkbox checked={this.state.includeDiscountSellerLiabilityRule}
                                  onChange={this.onDiscountCheckboxChange}>{this.sellerLiabilityCheckboxLabel()}</Checkbox>
                    </p>
                }
                {
                    this.state.includeDiscountSellerLiabilityRule &&
                    <Form.Item
                        className={className}
                    >
                        <DiscountSellerLiabilityRadioGroup
                            form={this.props.form}
                            discountSellerLiabilityRule={discountSellerLiabilityRule}
                        />
                    </Form.Item>
                }
            </div>
        );
    }
}