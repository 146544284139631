export type Role =
    "accountManager"
    | "contactManager"
    | "customerServiceManager"
    | "customerServiceRepresentative"
    | "pointOfSale"
    | "programManager"
    | "promoter"
    | "reporter"
    | "securityManager"
    | "teamAdmin"
    | "webPortal"
    | "self";
export type Roles = Role[];
export type Scopes = null[];
export type UserPrivilege = "OWNER | FULL_ACCESS" | "LIMITED_ACCESS";

export const FullAccessRoles: Role[] = [
    "webPortal",
    "pointOfSale",
    "accountManager",
    "programManager",
    "contactManager",
    "customerServiceRepresentative",
    "customerServiceManager",
    "promoter",
    "teamAdmin",
    "reporter",
    "securityManager",
    "self"
];

export const LimitedAccessRoles: Role[] = [
    "webPortal",
    "pointOfSale",
    "accountManager",
    "programManager",
    "contactManager",
    "customerServiceRepresentative",
    "customerServiceManager",
    "promoter",
    "reporter",
    "securityManager",
    "self"
];

export const UserPrivilegeTypes = {
    OWNER: "OWNER",
    FULL_ACCESS: "FULL_ACCESS",
    LIMITED_ACCESS: "LIMITED_ACCESS"
};

export interface User {
    accountId: string;
    userId: string;
    userDisplayName: string;
    roles?: Roles;
    scopes?: Scopes;
    lockedByInactivity?: boolean;
    userPrivilegeType?: UserPrivilege;
}

export interface AccountUserInvitees {
    accountId: string;
    userId: string;
    email: string;
    createdDate: string;
    expiresDate: string;
}