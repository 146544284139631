import * as React from "react";

export interface Props {
}

export class AccountLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div className="lr-layout-account-page">
                {
                    this.props.children
                }
            </div>
        );
    }
}