import * as React from "react";
import {Col, Row} from "antd";
import {LogoWordMark} from "../../ui/brand/LogoWordMark";
import {ExternalPaths} from "../../../util/routes/routes";

export interface Props {
}

export class AuthLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <Row
                    align="middle"
                    style={{
                        margin: "12px 24px",
                        paddingBottom: 12,
                        borderBottom: "1px solid rgb(217, 217, 217)"
                    }}
                >
                    <Col span={12}>
                        <a className="lr-logo-link" href={ExternalPaths.LIGHTRAIL_STATIC_ROOT}>
                            <LogoWordMark height="38px"/>
                        </a>
                    </Col>
                </Row>
                <div style={{margin: "auto 0"}}>
                    <Row align="middle" justify="center" style={{flex: 1}}>
                        <Col xs={18} md={12} lg={6}>
                            {this.props.children}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}