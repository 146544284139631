import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {deleteWebhook} from "../../../communication/webhooks";
import {DeleteWebhookResponse} from "../../../communication/dtos/webhooks/DeleteWebhook";

export const type = "deleteWebhook";
export const actionCreator = promisePattern.actionCreator(type, (id: string) => {
    return deleteWebhook(id).then(response => ({
            success: (!!response),
            id
        })
    );
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteWebhookResponse> =
    (state, payload) => {
        //Not successful?
        if (!payload?.success) {
            return sprout.assoc(state, ["server", "webhook", "processing"], false);
        }

        const newList = state.server.webhook.list.concat();
        const deleteIndex = newList.findIndex(p => p.id === payload?.id);
        if (deleteIndex > -1) {
            newList.splice(deleteIndex, 1);
        }
        return sprout.assoc(state,
            ["server", "webhook", "list"], newList,
            ["server", "webhook", "processing"], false
        );
    };

const onRejected = rejectionHandler([
    {
        reducer: (state) => sprout.assoc<State>(state,
            ["server", "webhook", "processing"], false
        )
    },
    {
        status: 409,
        overrideGlobal: true,
        reducer: (state, error) => sprout.assoc<State>(state,
            ["server", "webhook", "error"], error.message,
            ["server", "webhook", "processing"], false
        )
    }
]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "webhook", "error"], "",
        ["server", "webhook", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);