import * as React from "react";
import {ReactNode} from "react";
import {Spin, Table} from "antd";
import {StatusBadge} from "../../ui/StatusBadge";
import {defaultDash, ellipsis, prefixIdWithDots, truncate} from "../../../util/stringUtils";
import {formatDate} from "../../../util/dateUtils";
import {Link} from "react-router-dom";
import {ValueActionMenuConnected} from "../valueActions/ValueActionMenuConnected";
import {LoadMoreFooter} from "../../ui/pagination/LoadMoreFooter";
import {stopPropagation} from "../../../util/link";
import {formatRoute, Paths} from "../../../util/routes/routes";
import {
    ListValuesParams,
} from "lightrail-client/dist/params";
import {ValueBalanceDisplay} from "../balance/ValueBalanceDisplay";
import {WithRouterProps} from "../../router/WithRouterProps";
import {Value} from "lightrail-client/dist/model";
import {getFormattedValueCode} from "../../../util/valueUtils";
import {ColumnProps} from "antd/lib/table";
import {ExternalProps, ReduxProps} from "./ValuesListConnected";

interface State {
    highlightRowIds: string[];
}

interface TableRow {
    key: string;
    balance: ReactNode;
    currency: string;
    codeType: JSX.Element;
    code: JSX.Element | string;
    contact: JSX.Element | string;
    status: JSX.Element | string;
    createdDate: string;
    valueActionButton: JSX.Element;
}

export interface Props extends WithRouterProps<{}>, ExternalProps, ReduxProps {
}

export class ValuesList extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {highlightRowIds: []};

        this.fetchValues = this.fetchValues.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.setupRow = this.setupRow.bind(this);
    }

    componentDidMount(): void {
        this.props.clearValues({});
        this.props.clearAttachedValues({});
        this.fetchValues();
    }

    componentDidUpdate(oldProps: Props): void {
        if (!!oldProps.valueState.list.length && oldProps.valueState.list !== this.props.valueState.list) {
            const highlightRowIds = this.props.valueState.list
                .concat()
                .filter(p => oldProps.valueState.list.indexOf(p) === -1)
                .map(p => p.id);

            this.setState({highlightRowIds});
        }
    }

    async fetchValues(params?: ListValuesParams): Promise<void> {
        const internalParams = params || {};
        const externalParams = this.props.params || {};
        const fetchParams = {...internalParams, ...externalParams, limit: 50};
        // remove duplicates from the list of array values
        const getUniqueIdsToLoad = (val: string, ind: number, arr: string[]): boolean => {
            return arr.indexOf(val) === ind;
        };

        const resp = (await this.props.getValues(fetchParams)).value;

        const contactIdsToLoad: string[] = resp.body
            .filter(value => !!value.contactId)
            .map(value => value.contactId)
            .filter(id => (!this.props.contactsState.list.find(contact => contact.id === id)));

        const uniqueContactIdsList = contactIdsToLoad.filter(getUniqueIdsToLoad);

        if (uniqueContactIdsList.length) {
            this.props.getContacts({
                id: {in: uniqueContactIdsList.join(",")}
            });
        }

        const programIdsToLoad: string[] = resp.body
            .filter(value => value.programId)
            .map(value => value.programId)
            .filter(id => (!this.props.programsState.allList.find(program => program.id === id)));

        const uniqueProgramIdsList = programIdsToLoad.filter(getUniqueIdsToLoad);

        if (uniqueProgramIdsList.length) {
            this.props.getAndMergePrograms({
                id: {in: uniqueProgramIdsList.join(",")}
            });
        }

        const attachedValueIdsToLoad: string[] = resp.body
            .filter(value => value.attachedFromValueId)
            .map(value => value.attachedFromValueId)
            .filter(attachedFromId => (!this.props.valueState.attachedList.find(value => value.id === attachedFromId)));

        const uniqueAttachedValueIdsList = attachedValueIdsToLoad.filter(getUniqueIdsToLoad);

        if (uniqueAttachedValueIdsList.length) {
            this.props.getAttachedValues({
                id: {in: uniqueAttachedValueIdsList.join(",")}
            });
        }
    }

    ///////////////////
    //[ ACTIONS ]
    onRowClick(record: TableRow, e: any): void {
        const statusClass = e.target.className;
        if (e.target.nodeName == "TD" || e.target.nodeName == "DIV" || e.target.nodeName == "SPAN" || (!!statusClass && !!statusClass.match && statusClass.match("ant-badge-status"))) {
            this.props.history.push(formatRoute(Paths.VALUE_DETAIL, {valueId: record.key}));
        }
    }

    setupRow(record: TableRow): any {
        const highlightThisRow = (this.props.highlightNewRows && this.state.highlightRowIds.indexOf(record.key) !== -1);

        return {
            className: (highlightThisRow) ? "lr-highlight-row" : null,
            onClick: this.onRowClick.bind(this, record)
        };
    }

    ///////////////////
    //[ RENDER ]
    renderContactLink(contactId: string): JSX.Element {
        if (this.props.contactsState.fetching) {
            return (<Spin size="small"/>);
        }

        if (!!contactId) {
            const contact = this.props.contactsState.list.find(c => c.id === contactId);

            return (
                <Link onClick={stopPropagation}
                      to={formatRoute(Paths.CONTACT_DETAIL, {contactId})}>{(!!contact && contact.email) ? contact.email : `Contact ${prefixIdWithDots(contactId)}`}</Link>
            );
        }

        return (<span>{defaultDash}</span>);
    }

    renderCode(value: Value): JSX.Element | string {
        if (this.props.valueState.fetchingAttached) {
            return (<Spin size={"small"}/>);
        }

        if (value.attachedFromValueId) {
            const attachedListValue = this.props.valueState.attachedList.find(v => v.id === value.attachedFromValueId);
            if (!!attachedListValue) {
                return (
                    <Link onClick={stopPropagation}
                          to={formatRoute(Paths.VALUE_DETAIL, {valueId: attachedListValue.id})}>{attachedListValue.code}</Link>
                );
            }
        }
        return getFormattedValueCode(value);
    }

    renderCodeType(value: Value): JSX.Element {
        if (value.isGenericCode) {
            return (<span>Generic</span>);
        } else if (value.attachedFromValueId) {
            return (<span>Attached Generic</span>);
        } else {
            return (<span>Unique</span>);
        }
    }

    renderProgramLink(programId: string): JSX.Element {
        if (this.props.programsState.fetching) {
            return (<Spin size="small"/>);
        }

        if (!!programId) {
            const program = this.props.programsState.allList.find(p => p.id === programId);

            return (
                <Link onClick={stopPropagation}
                      to={formatRoute(Paths.PROGRAM_DETAIL, {programId})}>{(!!program && program.name) ? program.name : `Program ${prefixIdWithDots(programId)}`}</Link>
            );
        }

        return (<span>{defaultDash}</span>);
    }

    getTableRows(): TableRow[] {
        return this.props.valueState.list.map(value => ({
            key: value.id,
            id: truncate(value.id, 15, ellipsis),
            balance: <ValueBalanceDisplay value={value}/>,
            currency: value.currency,
            codeType: this.renderCodeType(value),
            code: this.renderCode(value),
            contact: this.renderContactLink(value.contactId),
            status: <StatusBadge active={value.active} canceled={value.canceled} frozen={value.frozen}
                                 expired={value.endDate}/>,
            createdDate: formatDate(value.createdDate),
            valueActionButton: <ValueActionMenuConnected value={value} menuContext={this.props.menuContext}/>,
            program: this.renderProgramLink(value.programId)
        }));
    }

    render(): JSX.Element {
        const columns: ColumnProps<TableRow>[] = [
            {
                title: "Id",
                dataIndex: "id",
                key: "id"
            },
            {
                title: "Code",
                dataIndex: "code",
                key: "code"
            },
            {
                title: "Balance",
                dataIndex: "balance",
                key: "balance"
            },
            {
                title: "Currency",
                dataIndex: "currency",
                key: "currency"
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                className: "fixedWidth110"
            },
            {
                title: "Attached To",
                dataIndex: "contact",
                key: "contact"
            },
            {
                title: "Code Type",
                dataIndex: "codeType",
                key: "codeType"
            },
            {
                title: "Program",
                dataIndex: "program",
                key: "program"
            },
            {
                title: "Created",
                dataIndex: "createdDate",
                key: "createdDate"
            },
            {
                title: "",
                dataIndex: "valueActionButton",
                key: "valueActionButton",
                width: 60,
                className: "lr-text-align-center",
                fixed: "right"
            }
        ];

        return (
            <div>
                <Table
                    className="lr-table-row-clickable"
                    loading={this.props.valueState.fetching}
                    columns={columns}
                    dataSource={this.getTableRows()}
                    pagination={false}
                    onRow={this.setupRow}
                    scroll={{x: 1300}}
                    sortDirections={["ascend", "descend"]}
                />
                {
                    (this.props.paginate != false) &&
                    <LoadMoreFooter
                        numResults={this.props.valueState.list.length}
                        limit={this.props.valueState.pagination.limit}
                        loading={this.props.valueState.fetching}
                        links={this.props.valueState.links}
                        onClick={this.fetchValues}
                    />
                }
            </div>
        );
    }
}