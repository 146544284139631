import * as ReactRedux from "react-redux";
import {State} from "../../state/State";
import {IssuanceList} from "./IssuanceList";
import {actionCreator as getIssuances} from "../../actions/issuance/server/getAndAppendIssuances";
import {actionCreator as clearIssuances} from "../../actions/issuance/server/clearIssuances";
import {actionCreator as listValues} from "../../actions/values/server/getValues";
import {Program} from "lightrail-client/dist/model";
import {IssuanceServerState} from "../../state/serverState/IssuanceServerState";

export interface ExternalProps {
    program: Program;
    expandable?: boolean;
}

interface StateProps {
    issuanceServerState: IssuanceServerState;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    issuanceServerState: state.server.issuance
});

const actionCreators = {
    getIssuances,
    clearIssuances,
    listValues
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const IssuanceListConnected = connector(IssuanceList);