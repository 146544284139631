export enum ProgramCopy {
    BALANCE_RANGE_APPLIES_TO_PER_CONTACT_BALANCE = "If you’ll be creating a generic code from this Program, balance applies on a per-contact basis.",
    BALANCE_INTRO = "A balance is a predefined amount of money offered to your customer. For example: $10, $20, $50 gift card.",
    BALANCE_RULE_INTRO = "A balance-rule calculates what the value’s worth in checkout. Used for discounts like 10% off.",
    BALANCE_RULE_EXPLANATION_PLACEHOLDER = "50% off item's subtotal",
    BALANCE_RULE_SYNTAX_PLACEHOLDER = "currentLineItem.lineTotal.subtotal * 0.5",
    CREDIT_APPLIES_TO_PER_CONTACT_BALANCE = "If you’ll be creating a generic code from this Program, credit applies on a per-contact basis.",
    DOCS_LINK_LABEL = "Learn More",
    REDEMPTION_RULES_DOCS_LINK_URL = "https://www.lightrail.com/docs/#use-cases/redemption-and-balance-rules",
    PROMOTIONS_DOCS_LINK_URL = "https://www.lightrail.com/docs/#use-cases/promotions",
    REFERRALS_DOCS_LINK_URL = "https://www.lightrail.com/docs/#use-cases/referral-programs",
    ACCOUNTS_AND_POINTS_DOCS_LINK_URL = "https://www.lightrail.com/docs/#use-cases/accounts-and-points",
    GIFT_CARDS_DOCS_LINK_URL = "https://www.lightrail.com/docs/#use-cases/gift-cards",
    FIXED_AMOUNTS_APPLIES_TO_PER_CONTACT_BALANCE = "If you’ll be creating a generic code from this Program, fixed-amount balance applies on a per-contact basis.",
    REDEMPTION_RULE_INTRO = "Redemption Rules control when Values created in the Program can be used in checkout.",
    REDEMPTION_RULE_EXPLANATION_PLACEHOLDER = "Promotion can be used towards purchase of red hats",
    REDEMPTION_RULE_SYNTAX_PLACEHOLDER = "currentLineItem.productId == 'red-hat'"
}