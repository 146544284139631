import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    toggle: boolean;
}

export const type = "toggleMFASuccessModal";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: {toggle: args.toggle}
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state, ["ui", "MFA", "successDialog"], action.payload?.toggle);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);