import * as React from "react";
import {AllowSubmitOnEnter} from "../ui/forms/AllowSubmitOnEnter";
import {Form, Radio} from "antd";
import {FormFieldIds} from "../../util/forms/AntFormHelpers";
import {useForm} from "antd/lib/form/Form";

export interface Props {
    disableButtons?: boolean;
    getSubmitHook: (hook: () => void) => void;
    onSubmit: (data: { isOpen: boolean, method: string }) => void;
}

export const EnableMFAMethodForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        props.onSubmit({
            isOpen: true,
            method: values[FormFieldIds.MFAMethod]
        });
    };

    React.useEffect(() => {
        if (props.getSubmitHook) {
            props.getSubmitHook(submit);
        }
    }, []);

    return (
        <Form
            form={form}
            onFinish={submit}
        >
            <div className="bodyDialogClass">
                <div className="column columnLeft">
                    <Form.Item
                        name={FormFieldIds.MFAMethod}
                        initialValue="App"
                    >
                        <Radio.Group name="MFAMethod" style={{display: "block"}}>
                            <Radio className="verticalRadio marginTop24" value={"App"}>Use
                                mobile app
                            </Radio>
                            {
                                <h4 className="marginLeft24 marginTop24" style={{fontSize: "16px"}}>
                                    Security codes will be generated by an authenticator app
                                </h4>
                            }
                            <Radio className="verticalRadio marginTop24" value={"Phone"}>
                                Use text messages
                            </Radio>
                            {
                                <h4 className="marginLeft24 marginTop24" style={{fontSize: "16px"}}>
                                    Security codes will be sent to your mobile phone
                                </h4>
                            }
                        </Radio.Group>
                    </Form.Item>
                </div>
            </div>
            <AllowSubmitOnEnter/>
        </Form>
    );
};