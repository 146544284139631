import * as React from "react";
import {FormEvent} from "react";
import {Button, Row} from "antd";

export interface Props {
    cancelTest?: string;
    submitTest?: string;
    loading?: boolean;
    submitButtonLabel?: string;

    onCancelClick?: () => void;
    onSubmitClick?: (e: FormEvent<any>) => void;
}

export class CreateFormDrawerFooter extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <Row className="lr-drawer-footer">
                <Button
                    data-cy={this.props.cancelTest}
                    onClick={this.props.onCancelClick}
                >
                    Cancel
                </Button>
                <Button
                    className="lr-drawer-footer-submit-button"
                    htmlType="submit"
                    type="primary"
                    data-cy={this.props.submitTest}
                    onClick={this.props.onSubmitClick}
                    loading={this.props.loading}
                >
                    {
                        (!!this.props.submitButtonLabel) ? this.props.submitButtonLabel : "Create"
                    }
                </Button>
            </Row>
        );
    }
}