import * as React from "react";
import {Button, Form, Input} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {EmailItem} from "../../../ui/forms/items/EmailItem";
import {LoginRequest} from "../../../../communication/dtos/auth/Login";
import {AuthFormErrorItem} from "../../forms/AuthFormErrorItem";
import {LockOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {AllowSubmitOnEnter} from "../../../ui/forms/AllowSubmitOnEnter";

export interface Props {
    processing: boolean;
    errorMessage: string[];
    onSubmit: (params: LoginRequest) => void;
}

export const LoginForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const {isFieldTouched, getFieldError} = form;
    const passwordError = isFieldTouched(FormFieldIds.Password) && getFieldError(FormFieldIds.Password);
    const errors = [
        ...(props.errorMessage || [])
    ];

    const submit = async (): Promise<void> => {
        const values = await form.validateFields() as LoginRequest;
        props.onSubmit(values);
    };

    return (
        <Form
            onFinish={submit}
            layout="vertical"
            form={form}
            id="lr-login-form"
        >
            <EmailItem autoFocus size="large" form={form}/>
            <Form.Item
                validateStatus={errors.length ? "error" : "success"}
                help={passwordError || ""}
                name={FormFieldIds.Password}
                rules={[
                    {required: true, message: "Password Required"}
                ]}
            >
                <Input
                    size="large"
                    prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                    type="password"
                    placeholder="Password"
                    data-cy="password"
                />
            </Form.Item>
            <AuthFormErrorItem errors={errors as string[]}/>
            <Form.Item>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={props.processing}
                    style={{width: "100%"}}
                    data-cy="login-button"
                    className="marginBottom16 marginTop24"
                >
                    Log in to Lightrail
                </Button>
            </Form.Item>
            <AllowSubmitOnEnter/>
        </Form>
    );
};