import * as React from "react";
import {Modal} from "antd";
import {TransactionType} from "../../../state/uistate/TransactionUiState";
import {Value} from "lightrail-client/dist/model";
import {BalanceTransactionFormConnected} from "./balance/BalanceTransactionFormConnected";
import {UsesTransactionFormConnected} from "./uses/UsesTransactionFormConnected";
import {AttachesTransactionFormConnected} from "./attaches/AttachesTransactionFormConnected";

export enum TransactionProperty {BALANCE, USES, ATTACHES}

export interface Props {
    modalOpen: boolean;
    value: Value;
    transactionType: TransactionType;
    transactionProperty?: TransactionProperty;
    onClose: () => void;
}

export class CreateTransactionModal extends React.PureComponent<Props, {}> {
    formSubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.formSubmit = submit;
    }

    onOk(): void {
        if (this.formSubmit) {
            this.formSubmit();
        }
    }

    render(): JSX.Element {
        const transactionType = this.props.transactionType;
        const transactionProperty = this.props.transactionProperty || TransactionProperty.BALANCE;

        let modalTypeTitle: string;
        let modalButtonLabel: string;

        let transactionFormComponent: JSX.Element;
        switch (transactionProperty) {
            case TransactionProperty.BALANCE:
                transactionFormComponent = <BalanceTransactionFormConnected
                    value={this.props.value}
                    transactionType={transactionType}
                    getSubmit={this.setSubmit}
                />;
                if (transactionType === TransactionType.DEBIT) {
                    modalTypeTitle = "Debit";
                    modalButtonLabel = "Debit value";
                } else if (transactionType === TransactionType.CREDIT) {
                    modalTypeTitle = "Credit";
                    modalButtonLabel = "Credit Value";
                } else {
                    throw new Error(`Unexpected transactionType ${transactionType}.`);
                }
                break;

            case TransactionProperty.USES:
                transactionFormComponent = <UsesTransactionFormConnected
                    value={this.props.value}
                    transactionType={transactionType}
                    getSubmit={this.setSubmit}
                />;
                if (transactionType === TransactionType.DEBIT) {
                    modalTypeTitle = "Debit uses";
                    modalButtonLabel = "Debit uses";
                } else if (transactionType === TransactionType.CREDIT) {
                    modalTypeTitle = "Credit uses";
                    modalButtonLabel = "Credit uses";
                } else {
                    throw new Error(`Unexpected transactionType ${transactionType}.`);
                }
                break;

            case TransactionProperty.ATTACHES:
                transactionFormComponent = <AttachesTransactionFormConnected
                    value={this.props.value}
                    transactionType={transactionType}
                    getSubmit={this.setSubmit}
                />;
                if (transactionType === TransactionType.DEBIT) {
                    modalTypeTitle = "Debit attaches";
                    modalButtonLabel = "Debit uses";
                } else if (transactionType === TransactionType.CREDIT) {
                    modalTypeTitle = "Credit attaches";
                    modalButtonLabel = "Add attaches";
                } else {
                    throw new Error(`Unexpected transactionType ${transactionType}.`);
                }
                break;

            default:
                throw new Error(`Unexpected transaction property ${transactionProperty}.`);
        }

        return (
            <Modal
                title={modalTypeTitle}
                visible={this.props.modalOpen}
                onCancel={this.props.onClose}
                okText={modalButtonLabel}
                onOk={this.onOk}
            >

                {
                    transactionFormComponent
                }
            </Modal>
        );
    }
}