import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    isOpen: boolean;
    method: string;
}

export const type = "setMFAMethodModalState";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    if (action.payload.method === "App") {
        return sprout.assoc(state,
            ["ui", "MFA", "isMFAMethodModalOpen"], action.payload.isOpen,
            ["ui", "MFA", "MFAMethod"], action.payload.method,
            ["ui", "MFA", "device"], "totp",
        );
    }
    return sprout.assoc(state,
        ["ui", "MFA", "isMFAMethodModalOpen"], action.payload.isOpen,
        ["ui", "MFA", "MFAMethod"], action.payload.method
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);