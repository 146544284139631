import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {actionCreator as updatePasswordField} from "../../../../actions/components/ui/updatePasswordField";

import {PasswordItem} from "../items/PasswordItem";
import {FormInstance} from "antd/es/form";
import {PasswordFieldUiState} from "../../../../state/uistate/ComponentUiState";
import {AuthServerStateErrorMessage} from "../../../../state/serverState/AuthServerState";
import {ChangeEventHandler} from "react";

export interface ExternalProps {
    formId?: string;
    size?: string;
    label?: string;
    className?: string;
    form: FormInstance;
    errors?: string[];
    onChange?: ChangeEventHandler<HTMLInputElement>;
    value?: string;
}

interface StateProps {
    id: string;
    uiState: PasswordFieldUiState;
    authErrors: AuthServerStateErrorMessage;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    id: ownProps.formId || "createPassword",
    uiState: state.ui.components.passwordFields[ownProps.formId] || state.ui.components.passwordFields["createPassword"],
    authErrors: state.server.auth.errorMessage
});

const actionCreators = {
    updatePasswordField
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const PasswordFieldConnected = ReactRedux.connect(mapStateToProps, actionCreators)(PasswordItem);