import * as promisePattern from "../../../promisePattern";
import * as MFA from "../../../../communication/MFA";
import {ResendAuthCodeResponse} from "../../../../communication/dtos/auth/MFA/ResendAuthCode";
//import * as sprout from "sprout-data";
import {rejectionHandler} from "../../../rejectionHandler";

export const type = "resendAuthCode";

export const actionCreator = promisePattern.actionCreator(type, MFA.resendAuthCode);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ResendAuthCodeResponse> =
    (state) => {
        return state;
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return state;
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);