import * as ReactRedux from "react-redux";
import {LogoutNavItem} from "./LogoutNavItem";
import {actionCreator as logout} from "../../actions/auth/server/logout";
import {withRouter} from "react-router-dom";

const actionCreators = {
    logout
};

const connector = ReactRedux.connect<{}, typeof actionCreators, {}>(null, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const LogoutNavItemConnected = connector(withRouter(LogoutNavItem));