import {actionCreator as setIssuanceCreationState} from "../../actions/issuance/ui/setIssuanceCreateState";
import {IssuanceCreationWatcher} from "./IssuanceCreationWatcher";
import {State} from "../../state/State";
import {actionCreator as getProgram} from "../../actions/programs/server/getProgram";
import {actionCreator as listValuesCSV} from "../../actions/values/server/getValuesCSV";
import * as ReactRedux from "react-redux";
import {Issuance, Program} from "lightrail-client/dist/model";
import {IssuancePollingRequest} from "../../state/uistate/IssuanceUiState";

interface StateProps {
    programs: Program[];
    notificationList: Issuance[];
    pollingList: IssuancePollingRequest[];
}

const mapStateToProps = (state: State): StateProps => ({
    programs: state.server.programs.allList,
    notificationList: state.ui.issuance.create.createdNotificationList,
    pollingList: state.ui.issuance.create.pollingList
});

const actionCreators = {setIssuanceCreationState, getProgram, listValuesCSV};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const IssuanceCreationWatcherConnected = connector(IssuanceCreationWatcher);