import * as React from "react";
import {message, Modal} from "antd";
import {SearchFormConnected} from "../../../search/searchForm/SearchFormConnected";
import {SearchId, SearchType} from "../../../../state/serverState/SearchesServerState";
import {Contact} from "lightrail-client/dist/model";
import {SearchResultsList} from "../../../search/searchResults/SearchResultsList";
import {isGenericCodeWithPerContactProperties} from "../../../../util/valueUtils";
import {ExternalProps, ReduxProps} from "./AttachContactModalConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export class AttachContactModal extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    // Add Confirmation Step if value already has a contact
    async onSubmit(contact: Contact): Promise<void> {
        // Call Attach with Contact Id and ValueId

        try {
            await this.props.attachValueToContact({
                contactId: contact.id,
                params: {
                    valueId: this.props.value.id
                }
            });

            message.success(`${contact.email || "Contact " + contact.id} was attached to ${(!!this.props.value.code) ? this.props.value.code : "the Value"}`);
            if (!!this.props.onSuccess) {
                this.props.onSuccess(contact);
                // Attach is often done from the Value Detail Page.
                // Since the Value returned from attaching a generic
                // code is a new Value the original Value needs to
                // be reloaded so that Value Details look correct.
                this.props.getValue({valueId: this.props.value.id});
            }
        } catch (error) {
            if (isGenericCodeWithPerContactProperties(this.props.value) && (error.messageCode === "InsufficientBalance" || error.messageCode === "InsufficientUsesRemaining")) {
                message.error("Insufficient attaches remaining.");
            } else {
                message.error(`${error.message}`);
            }
        }
    }

    render(): JSX.Element {
        const attachedContact = this.props.contactStateServer.list.find((c: Contact) => c.id === this.props.value.contactId);
        let contactIdOrEmail;
        if (!!attachedContact) {
            contactIdOrEmail = (!!attachedContact.email) ? `Replacing: ${attachedContact.email}` : `Replacing: ${this.props.value.contactId}`;
        }
        const modalTitle = (!!contactIdOrEmail) ? contactIdOrEmail : "Attach Contact";

        return (
            <Modal
                title={`${modalTitle}`}
                visible={(this.props.open != null) ? this.props.open : true}
                onCancel={this.props.onClose}
                okText="Attach"
                footer={null}
                destroyOnClose
            >
                <SearchFormConnected
                    id={SearchId.GLOBAL}
                    searchType={SearchType.CONTACTS}
                />
                <SearchResultsList
                    searchState={this.props.searchState}
                    onContactClick={this.onSubmit}
                    actionButtonText="Attach"
                />
            </Modal>
        );
    }
}