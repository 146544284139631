import * as React from "react";
import {TransactionsListConnected} from "./list/TransactionsListConnected";

export interface Props {
}

export class TransactionsPage extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <TransactionsListConnected expandable/>
        );
    }
}