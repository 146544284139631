import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {State} from "../../../state/State";
import {createCurrency} from "lightrail-client/dist/currencies";
import {mergeListObject} from "../../../util/dataUtils";
import {CreateCurrencyResponse} from "lightrail-client/dist/params";

export const type = "createCurrency";
export const actionCreator = promisePattern.actionCreator(type, createCurrency);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateCurrencyResponse> =
    (state, payload) => {
        const newCurrencyList = mergeListObject(state.server.currency.list, payload?.body, "code");
        return sprout.assoc(state,
            ["server", "currency", "list"], newCurrencyList,
            ["server", "currency", "processing"], false
        );
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "currency", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "currency", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);