import * as React from "react";
import {formatCurrency} from "../../../util/currencyUtils";
import { ReduxProps, ExternalProps } from "./FormattedCurrencyConnected";

interface Props extends ExternalProps, ReduxProps {
}

export class InternalCurrencyDisplay extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const currency = this.props.currencyList.find(p => p.code === this.props.code);
        const value = isNaN(this.props.value) ? 0 : this.props.value;

        return (
            <span>
                {
                    this.props.prefix
                }
                {
                    formatCurrency(value, currency)
                }
                {
                    this.props.postfix
                }
            </span>
        );
    }
}