import * as React from "react";
import {Form, Input} from "antd";
import {normalizeValueFromServer} from "../../../../util/currencyUtils";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {NumbersCommasSpacesOnlyRegExp} from "../../../../util/regexp";
import {convertNumberArrayToString} from "../../../../util/formUtils";
import {Program} from "lightrail-client/dist/model";
import {Currency} from "lightrail-client/dist/model/Currency";
import {ProgramCopy} from "../CreateProgramFormCopy";
import {FormInstance} from "antd/lib/form";

export interface Props {
    program: Program;
    currentCurrency: Currency;
    form: FormInstance;
}

export class AdvancedFixedInitialFormItems extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        const program = (!!this.props.program) ? this.props.program : null;
        const currency = this.props.currentCurrency;

        return (
            <Form.Item
                style={{whiteSpace: "normal"}}
                label=" "
                extra={"Multiple comma-separated fixed amounts. " + ProgramCopy.FIXED_AMOUNTS_APPLIES_TO_PER_CONTACT_BALANCE}
                name={FormFieldIds.FixedInitialBalances}
                rules={[
                    {
                        pattern: NumbersCommasSpacesOnlyRegExp,
                        message: "Numbers, decimals, commas and spaces only",
                    },
                    {
                        required: true,
                        message: "Required if setting a Fixed Amount(s) Balance"
                    }
                ]}
                initialValue={
                    (!!program && !!program.fixedInitialBalances && program.fixedInitialBalances.length > 1)
                        ? convertNumberArrayToString(program.fixedInitialBalances.map(f => normalizeValueFromServer(f, currency.decimalPlaces)))
                        : null
                }
            >
                <Input
                    addonAfter={currency.code}
                    placeholder={`${currency.symbol}10.00`}
                    size="large"
                    style={{
                        width: "100%",
                        maxWidth: 340
                    }}
                />
            </Form.Item>
        );
    }
}