import * as React from "react";
import {Button, Col, Form, Input, Row} from "antd";
import {BalanceRule, RedemptionRule} from "lightrail-client/dist/model";
import {RuleFieldset} from "../../../../layout/forms/RuleFieldset";
import {InputProps} from "antd/lib/input";
import {FormInstance, Rule} from "antd/es/form";
import { EditOutlined } from "@ant-design/icons";

export interface State {
    editing: boolean;
}

export interface Props {
    label?: string;
    ruleItemId: string;
    explanationItemId: string;
    form: FormInstance;

    rule?: BalanceRule | RedemptionRule;
    disableEditToggle?: boolean;
    disabledInsteadOfLabels?: boolean;
    ruleProps?: InputProps;
    ruleValidationRules?: Rule[];

    explanationProps?: InputProps;
    explanationValidationRules?: Rule[];
}

export class AdvancedRuleItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {editing: false};
        this.toggleEditingState = this.toggleEditingState.bind(this);
    }

    toggleEditingState(): void {
        this.setState({editing: !this.state.editing});
    }

    render(): JSX.Element {
        const {getFieldValue} = this.props.form;
        const rule = this.props.rule;
        const showEditToggle = !this.props.disableEditToggle && !!this.props.rule;
        const editing = !this.props.rule || this.state.editing;
        const showInputs = (this.props.disableEditToggle || editing || this.props.disabledInsteadOfLabels);

        return (
            <RuleFieldset>
                <Row>
                    {
                        (!!this.props.label) &&
                        <Col><h3>{this.props.label}</h3></Col>
                    }
                    <Col className="alignRight">
                        {
                            (showEditToggle) &&
                            <Button
                                shape="circle"
                                onClick={this.toggleEditingState}
                            >
                                <EditOutlined/>
                            </Button>
                        }
                    </Col>
                </Row>
                <Form.Item
                    label="Explanation"
                    name={this.props.explanationItemId}
                    initialValue={rule?.explanation}
                    rules={this.props.explanationValidationRules}
                >
                    {
                        (!showInputs) ?
                        <div className="setValue">{getFieldValue(this.props.explanationItemId)}</div>
                        :
                        <Input
                            placeholder="Explanation"
                            size="large"
                            disabled={(!editing && !this.props.disableEditToggle)}
                            {...this.props.explanationProps}
                        />
                    }
                </Form.Item>
                <Form.Item
                    label="Syntax"
                    name={this.props.ruleItemId}
                    initialValue={rule?.rule}
                    rules={this.props.ruleValidationRules}
                >
                    {
                        (!showInputs) ?
                        <div
                            className="setValue codeDisplay">{getFieldValue(this.props.ruleItemId)}</div>
                        :
                        <Input className="codeDisplay input"
                               placeholder="Rule"
                               size="large"
                               disabled={(!editing && !this.props.disableEditToggle)}
                               {...this.props.ruleProps}
                        />
                    }
                </Form.Item>
            </RuleFieldset>
        );
    }
}

export namespace AdvancedRuleItem {
    export function getRuleFromSubmitValues(values: any, ruleId: string, explanationId: string): BalanceRule {
        if (!!values[ruleId]) {
            return ({
                rule: values[ruleId] || "",
                explanation: values[explanationId] || ""
            });
        }

        return null;
    }
}