import * as ReactRedux from "react-redux";
import {AttachValueModal} from "./AttachValueModal";
import {State} from "../../../../state/State";
import {SearchId, SearchState} from "../../../../state/serverState/SearchesServerState";
import {actionCreator as attachValueToContact} from "../../../../actions/values/server/attachValueToContact";
import {Contact} from "lightrail-client/dist/model";

export interface ExternalProps {
    contact: Contact;
    onClose: () => void;

    open?: boolean;
    initialValue?: string;
}

interface StateProps {
    searchState: SearchState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    searchState: state.server.search[SearchId.GLOBAL],
    ...props
});

const actionCreators = {attachValueToContact};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AttachValueModalConnected = connector(AttachValueModal);