import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {getProgramStats} from "../../../communication/programStats";
import {GetProgramStatsResponse} from "../../../communication/dtos/program/GetProgramStats";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getProgramStats";

export const actionCreator = promisePattern.actionCreator(type, getProgramStats);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetProgramStatsResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "programs", "stats", "data"], payload,
            ["server", "programs", "stats", "fetching"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => onPendingFetching(state, ["server", "programs", "stats"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);