import * as React from "react";
import {Body1, Subheading} from "../typography/TypographyTags";
import {TextColor} from "../typography/ColoredText";
import {Paper} from "./Paper";

// Disable eslint for legacy styles
import "./creationFormStep.scss"; // eslint-disable-line

export interface Props {
    title: string;
    description: string;
}

export class CreationFormStep extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div className={"container"}>
                <div className={"descriptionSize"}>
                    <Subheading style={{display: "inline-block", marginBottom: 24}}>{this.props.title}</Subheading>
                    <Body1 textColor={TextColor.SECONDARY} style={{whiteSpace: "pre-line"}}>{this.props.description}</Body1>
                </div>
                <Paper style={{width: 360, padding: 24, marginRight: 40}}>
                    {this.props.children}
                </Paper>
            </div>
        );
    }
}