export enum ReportType {
    CONTACT = "contact",
    TRANSACTION = "transaction",
    VALUE = "value"
}

export const reports: Report[] = [
    {
        type: ReportType.CONTACT,
        name: "Contact",
        description: `Lists contacts and their properties such as names, email, and metadata. Date ranges are relative to the date that the contacts were created.`
    },
    {
        type: ReportType.TRANSACTION,
        name: "Transaction",
        description: "Lists transactions and their details, such as transaction type, subtotal, taxes paid, Lightrail discounts (promotions), Lightrail debits and credit card totals paid."
    },
    {
        type: ReportType.VALUE,
        name: "Value",
        description: "Lists current values and their properties, such as balance, remaining uses, and rules. Date ranges are relative to the date that the values were created, not an as-at date."
    }
]

export interface Report {
    type: ReportType;
    name: string;
    description: string;
}

export enum TransactionType {
    INITIAL_BALANCE = "initialBalance",
    ATTACH = "attach",
    CREDIT = "credit",
    DEBIT = "debit",
    CHECKOUT = "checkout",
    TRANSFER = "transfer",
    REVERSE = "reverse",
    CAPTURE = "capture",
    VOID = "void"
}

export function getTransactionTypeLabel(type: TransactionType): string {
    switch (type) {
        case TransactionType.INITIAL_BALANCE:
            return "Initial balance";
        case TransactionType.ATTACH:
            return "Attach";
        case TransactionType.CREDIT:
            return "Credit";
        case TransactionType.DEBIT:
            return "Debit";
        case TransactionType.CHECKOUT:
            return "Checkout";
        case TransactionType.TRANSFER:
            return "Transfer";
        case TransactionType.REVERSE:
            return "Reverse";
        case TransactionType.CAPTURE:
            return "Capture";
        case TransactionType.VOID:
            return "Void";
        default:
            return "All";
    }
}