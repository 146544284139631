import * as React from "react";
import {ValuesListConnected} from "./list/ValuesListConnected";

export interface Props {
}

export class ValuesPage extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div>
                <ValuesListConnected/>
            </div>
        );
    }
}