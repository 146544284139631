import * as React from "react";

export interface Props {
}

export class AllowSubmitOnEnter extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <input type="submit" style={{display: "none"}}/>
        );
    }
}