import * as React from "react";
import {FormEvent, useEffect} from "react";
import {Form, Input, InputNumber, message} from "antd";

import {CreditParams, DebitParams} from "lightrail-client/dist/params";

import {ValueActionStatus} from "../../../../state/uistate/ValueUiState";
import {TransactionType} from "../../../../state/uistate/TransactionUiState";
import {findCurrency} from "../../../../util/currencyUtils";

import {generateId} from "../../../../communication/utils/urlUtils";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";

import {ExternalProps, ReduxProps} from "./AttachesTransactionFormConnected";
import {withCurrencies, WithCurrencies} from "../../../ui/currency/withCurrenciesHOC";
import {getAttachesRemainingForDisplay} from "../../../../util/valueUtils";
import {useForm} from "antd/es/form/Form";

interface InternalProps extends WithCurrencies, ExternalProps, ReduxProps {
}

export const AttachesTransactionForm = withCurrencies((props: InternalProps): JSX.Element => {
    const [form] = useForm();

    const validateAmount = (rule: any, value: any, callback: any): void => {
        if (value <= 0) {
            callback(`Amount must be greater than 0.`);
        }
        callback();
    }

    const closeModalAndResetTransactionType = (): void => {
        // Close the modal
        props.toggleTransactionModal({
            valueId: props.value.id,
            params: {
                transactionModalOpen: false
            }
        });

        // Set the transaction type back to NO_ACTION
        props.setPendingValueAction({
            valueId: props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    }

    const submitCredit = async (): Promise<void> => {
        const values = await form.validateFields();
        const value = props.value;
        const amount = values[FormFieldIds.Amount];

        const creationValues: CreditParams = {
            id: generateId(),
            destination: {
                rail: "lightrail",
                valueId: props.value.id
            },
            currency: props.value.currency,
            metadata: {
                "lightrail_webapp_credit_note": values.notes
            },
        };

        const currency = findCurrency(props.currencies, creationValues.currency);
        if (!currency) {
            return;
        }

        const usesPerContact = value.genericCodeOptions.perContact.usesRemaining;
        if (usesPerContact && value.usesRemaining != null) {
            creationValues.uses = (usesPerContact - (value.usesRemaining % usesPerContact)) + ((amount - 1) * usesPerContact);
        }
        const balancePerContact = value.genericCodeOptions.perContact.balance;
        if (value.genericCodeOptions.perContact.balance && value.balance != null) {
            creationValues.amount = (balancePerContact - (value.balance % balancePerContact)) + ((amount - 1) * balancePerContact);
        }

        closeModalAndResetTransactionType();

        // Make the call and trigger Notification on success
        try {
            await props.creditTransaction(creationValues);

            message.success("Your attach credit is complete.");
            props.getValue({valueId: creationValues.destination.valueId});
        } catch (err) {
            message.error(err.message);
        }
    }

    const submitDebit = async (): Promise<void> => {
        const values = await form.validateFields();
            const value = props.value;
            const amount = values[FormFieldIds.Amount];

            const creationValues: DebitParams = {
                id: generateId(),
                source: {
                    rail: "lightrail",
                    valueId: value.id
                },
                currency: value.currency,
                metadata: {
                    "lightrail_webapp_debit_note": values.notes
                },
            };

            const currency = findCurrency(props.currencies, creationValues.currency);
            if (!currency) {
                return;
            }

            const usesPerContact = value.genericCodeOptions.perContact.usesRemaining;
            if (usesPerContact && value.usesRemaining != null) {
                creationValues.uses = (usesPerContact * amount) + (value.usesRemaining % usesPerContact);
            }
            const balancePerContact = value.genericCodeOptions.perContact.balance;
            if (balancePerContact && value.balance != null) {
                creationValues.amount = (balancePerContact * amount) + (value.balance % balancePerContact);
            }

            closeModalAndResetTransactionType();

            // Make the call, trigger Notification and refresh Value on success
            try {
                props.debitTransaction(creationValues);

                message.success("Your attach debit is complete.");
                props.getValue({valueId: creationValues.source.valueId});
            } catch (err) {
                message.error(err.message);
            }
        }

    const submit = (): void => {
        switch (props.transactionType) {
            case TransactionType.CREDIT:
                submitCredit();
                break;
            case TransactionType.DEBIT:
                submitDebit();
                break;
        }
    }

        const handleSubmit = (e: FormEvent<any>): void => {
            e.preventDefault();
            submit();
        }

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit)
        }
    })

    return (
        <Form onFinish={handleSubmit} className="lr-create-transaction-form" form={form}>
            <h4>Current Attaches Remaining: {getAttachesRemainingForDisplay(props.value)}</h4>
            <Form.Item
                name={FormFieldIds.Amount}
                rules={[
                    GenericRequiredRule,
                    {validator: validateAmount}
                ]}
            >
                <InputNumber
                    style={{width: "100%"}}
                    placeholder={`Enter Amount`}
                    size="large"
                    min={1}
                    autoFocus={true}
                />
            </Form.Item>
            <Form.Item
                name={FormFieldIds.Notes}
            >
                <Input.TextArea placeholder="Note (optional)" autoSize={{minRows: 2, maxRows: 4}}/>
            </Form.Item>
        </Form>
    );
});