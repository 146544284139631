// @NOTE: For large csv downloads (ie: issuances) we should be able to set the href as the full path, create a seperate download function for this
export const download = (filename: string, text: string): void => {
    const csvData = new Blob(["\uFEFF" + text], { type: "text/csv;charset=utf-8" });
    const csvUrl = URL.createObjectURL(csvData);
    const element = document.createElement("a");
    element.setAttribute("href", csvUrl);
    element.setAttribute("download",  filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const downloadExternalFile = (url: string): void => {
    const element = document.createElement("a");
    element.setAttribute("href", url);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};