import * as React from "react";
import {Link, withRouter} from "react-router-dom";
import {LogoWordMark} from "../ui/brand/LogoWordMark";
import {WithRouterProps} from "../router/WithRouterProps";
import {ExternalPaths} from "../../util/routes/routes";

export interface Props extends WithRouterProps<{}> {
    title: string;
    description?: string | JSX.Element;
    descriptionLink?: { label: string, onClick?: () => void, path?: string };
}

class AuthLayoutHeaderInner extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    renderDescription(): string | JSX.Element {
        const description = this.props.description;
        //If string, wrap in tag and return
        if (!!description && typeof description === "string") {
            return (
                <p>{description}</p>
            );
        }

        return description;
    }

    renderLink(): JSX.Element {
        if (!!this.props.descriptionLink) {
            if (!!this.props.descriptionLink.onClick) {
                return (
                    <a onClick={this.props.descriptionLink.onClick}>{this.props.descriptionLink.label}</a>
                );
            }

            if (!!this.props.descriptionLink.path) {
                return (
                    <Link to={this.props.descriptionLink.path}>
                        {this.props.descriptionLink.label}
                    </Link>
                );
            }
        }

        return null;
    }

    render(): JSX.Element {
        return (
            <div className="lr-layout-auth-header">
                <a className="lr-logo-link" href={ExternalPaths.LIGHTRAIL_STATIC_ROOT}>
                    <LogoWordMark height="38"/>
                </a>
                <h2>
                    {
                        this.props.title
                    }
                </h2>
                {
                    this.renderDescription()
                }
                {
                    this.renderLink()
                }
            </div>
        );
    }
}

export const AuthLayoutHeader = withRouter(AuthLayoutHeaderInner);