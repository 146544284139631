import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {updateCurrency} from "lightrail-client/dist/currencies";
import {mergeListObject} from "../../../util/dataUtils";
import {
    UpdateCurrencyParams,
    UpdateCurrencyResponse
} from "lightrail-client/dist/params/currencies/UpdateCurrencyParams";

export interface UpdateCurrencyActionParams {
    code: string;
    params: UpdateCurrencyParams;
}

export const type = "updateCurrency";
export const actionCreator = promisePattern.actionCreator(type, (p: UpdateCurrencyActionParams) => updateCurrency(p.code, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdateCurrencyResponse> =
    (state, payload) => {
        const newCurrencyList = mergeListObject(state.server.currency.list, payload?.body, "code");
        return sprout.assoc(state,
            ["server", "currency", "list"], newCurrencyList,
            ["server", "currency", "processing"], false
        );
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "currency", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "currency", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);