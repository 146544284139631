import * as React from "react";

interface State {
    active: boolean;
}

export interface Props {
    forceActive?: boolean;
    message?: string;
}

export class ClickToAdd extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {active: !!props.forceActive};

        this.activate = this.activate.bind(this);
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.forceActive != this.props.forceActive) {
            this.setState({active: !!this.props.forceActive});
        }
    }

    activate(): void {
        this.setState({active: true});
    }

    render(): JSX.Element {
        if (this.state.active) {
            return (
                <div>
                    {this.props.children}
                </div>
            );
        } else {
            return (
                <p>
                    <a onClick={this.activate}>{this.props.message || "+ Add"}</a>
                </p>
            );
        }
    }
}