import * as React from "react";
import {Button, message, Modal} from "antd";
import * as CopyToClipboard from "copy-to-clipboard";
import {getBackupCodes} from "../../../communication/MFA";
import {BackupCode, GetBackupCodesResponse} from "../../../communication/dtos/auth/MFA/GetBackupCodes";
import {download} from "../../../util/downloadUtils";

export interface Props extends React.HTMLAttributes<any> {
    visible: boolean;
    onClose: () => void;
}

interface InternalState {
    backupCodes: GetBackupCodesResponse;
}

export class MFABackupCodeModal extends React.Component<Props, InternalState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            backupCodes: ["Loading codes"]
        };
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.downloadBackupCodes = this.downloadBackupCodes.bind(this);
    }

    async componentDidMount(): Promise<void> {
        const response = await getBackupCodes();
        this.setState({backupCodes: response});
    }

    copyToClipboard(): void {
        const codes = this.state.backupCodes.join("\n");
        CopyToClipboard(codes);
        message.success("Backup codes were copied to your clipboard", 4);
    }

    downloadBackupCodes(): void {
        const codeString = this.state.backupCodes.join("\n");
        download("lightrail recovery codes", codeString);
        message.success("Backup codes are downloading", 4);
    }

    renderModalFooter(): JSX.Element {
        return (
            <React.Fragment>
                <Button onClick={this.props.onClose}>
                    Close
                </Button>

                <Button
                    onClick={this.copyToClipboard}
                >
                    Copy to Clipboard
                </Button>

                <Button
                    className={"ant-btn ant-btn-primary"}
                    onClick={this.downloadBackupCodes}
                >
                    Download
                </Button>
            </React.Fragment>
        );
    }

    render(): JSX.Element {
        return this.props.visible && (
            <Modal
                visible={this.props.visible}
                title="MFA Backup Codes"
                closable={false}
                footer={this.renderModalFooter()}
            >
                <ul>{
                    this.state.backupCodes.map(
                        (code: BackupCode) => <li key={code} className={"backupCodes codes"}>{code}</li>
                    )
                }</ul>

            </Modal>
        );
    }

}