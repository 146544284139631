import * as React from "react";
import {MainNavItem, MainNavItems} from "./MainNavItems";
import {Divider, Menu} from "antd";
import {Link} from "react-router-dom";
import {AccountSelectConnected} from "./accountChange/AccountSelectConnected";
import {Paths} from "../../util/routes/routes";
import {ToggleTestingConnected} from "../account/ToggleTestingConnected";
import {LogoutNavItemConnected} from "./LogoutNavItemConnected";
import { SettingOutlined } from "@ant-design/icons";

export class SideMenu extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <React.Fragment>
                <AccountSelectConnected/>
                <Menu mode="inline">
                    {
                        MainNavItems.map((navItem: MainNavItem) => (
                            <Menu.Item key={navItem.path}>
                                {navItem.icon}
                                <span>{navItem.label}</span>
                                <Link to={navItem.path}/>
                            </Menu.Item>
                        ))
                    }
                    <Divider/>

                    <Menu.Item>
                        <React.Fragment>
                            <SettingOutlined/>
                            <span>{"Settings"}</span>
                            <Link to={Paths.ACCOUNT_PROFILE}/>
                        </React.Fragment>
                    </Menu.Item>
                    <Menu.Item>
                        <LogoutNavItemConnected/>
                    </Menu.Item>
                </Menu>

                <Divider/>

                <Menu>
                    <Menu.Item disabled={true}>
                        <div className="lr-account-menu-item">
                            <ToggleTestingConnected/>
                        </div>
                    </Menu.Item>
                </Menu>
            </React.Fragment>
        );
    }
}