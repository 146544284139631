import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {EditWebhookForm} from "./EditWebhookForm";
import {actionCreator as updateWebhook} from "../../../../actions/webhooks/server/updateWebhooks";
import {Webhook} from "../../../../communication/dtos/webhooks/Webhook";
import {WebhookServerState} from "../../../../state/serverState/WebhookServerState";

export interface ExternalProps {
    webhook?: Webhook;
    getSubmit?: (submit: () => void) => void;
    onEditResponse?: (response: Webhook) => void;
}

interface StateProps {
    webhookServerState: WebhookServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    webhookServerState: state.server.webhook,
    ...props
});

const actionCreators = {
    updateWebhook
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const EditWebhookFormConnected = connector(EditWebhookForm);