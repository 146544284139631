import * as React from "react";
import {FormInstance} from "antd/lib/form";
import {Program} from "lightrail-client/dist/model";
import Form from "antd/es/form";
import {Checkbox, InputNumber, Select} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";

export interface Props {
    program: Program;
    form: FormInstance;
}

interface InternalState {
    showUsesRemainingInput: boolean;
}

export class LimitUsesFormItem extends React.Component<Props, InternalState> {
    inputRenderFunction: () => JSX.Element;
    inputInitialValue: number;

    constructor(props: Props) {
        super(props);

        switch (this.props.program.fixedInitialUsesRemaining?.length) {
            case undefined:
                this.inputRenderFunction = this.renderNoUseLimitInput;
                break;
            case (1):
                this.inputRenderFunction = this.renderSingleUseLimitInput;
                this.inputInitialValue = this.props.program.fixedInitialUsesRemaining[0];
                break;
            default:
                this.inputRenderFunction = this.renderMultipleUseLimitInput;
                this.inputInitialValue = this.props.program.fixedInitialUsesRemaining[0];
        }

        this.state = {
            showUsesRemainingInput: !!this.props.program.fixedInitialUsesRemaining?.length
        };

        this.onSelectLimitUses = this.onSelectLimitUses.bind(this);
        this.renderNoUseLimitInput = this.renderNoUseLimitInput.bind(this);
        this.renderMultipleUseLimitInput = this.renderMultipleUseLimitInput.bind(this);
    }

    onSelectLimitUses(): void {
        this.setState({showUsesRemainingInput: !this.state.showUsesRemainingInput}, () => {
            this.props.form.setFieldsValue({[FormFieldIds.LimitUsesRemaining]: (this.state.showUsesRemainingInput)});
        });
    }

    renderNoUseLimitInput(): JSX.Element {
        return <InputNumber
            size="large"
            placeholder="1"
            min={1}
            step={1}
        />;
    }

    renderSingleUseLimitInput(): JSX.Element {
        const initialUsesRemaining = this.props.program.fixedInitialUsesRemaining[0];
        return <InputNumber
            size="large"
            placeholder={`${initialUsesRemaining}`}
            disabled
        />;
    }

    renderMultipleUseLimitInput(): JSX.Element {
        return (
            <Select className="width96">
                {
                    this.props.program.fixedInitialUsesRemaining.map(
                        (useLimit: number) => (
                            <Select.Option
                                key={useLimit}
                                value={useLimit}
                            >
                                {useLimit}
                            </Select.Option>
                        )
                    )
                }
            </Select>
        );
    }

    render(): JSX.Element {
        return (
            <div className="marginTop8 marginBottom16">
                <Form.Item
                    name={FormFieldIds.LimitUsesRemaining}
                    initialValue={this.state.showUsesRemainingInput}
                >
                    <Checkbox
                        onChange={this.onSelectLimitUses}
                        disabled={this.props.program.fixedInitialUsesRemaining?.length === 1}
                        checked={this.state.showUsesRemainingInput}
                        className="marginBottom8"
                    >
                        Limit uses per Contact
                    </Checkbox>
                </Form.Item>
                {
                    this.state.showUsesRemainingInput &&
                    <Form.Item
                        name={FormFieldIds.UsesRemaining}
                        initialValue={this.inputInitialValue}
                    >
                        {this.inputRenderFunction()}
                    </Form.Item>
                }
            </div>
        );
    }
}