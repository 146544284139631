import * as ReactRedux from "react-redux";
import {CreateIssuanceForm} from "./CreateIssuanceForm";
import {State} from "../../../state/State";
import {actionCreator as setCreateState} from "../../../actions/issuance/ui/setIssuanceCreateState";
import {actionCreator as createIssuance} from "../../../actions/issuance/server/createIssuance";
import {Program} from "lightrail-client/dist/model";
import {IssuanceCreationUIState} from "../../../state/uistate/IssuanceUiState";

export interface ExternalProps {
    program: Program;
    getSubmit: (submit: () => void) => void;
}

interface StateProps {
    createState: IssuanceCreationUIState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    createState: state.ui.issuance.create,
    ...props
});

const actionCreators = {
    setCreateState,
    createIssuance
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateIssuanceFormConnected = connector(CreateIssuanceForm);