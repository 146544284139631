import * as React from "react";
import {CSSProperties} from "react";

export interface Props {
    key?: string;
    style?: CSSProperties;
    className?: string;
    onClick?: (...args: any[]) => void;
    clickParam?: any;
}

export class InteractiveListItem extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(): void {
        if (!this.props.onClick) {
            return;
        }

        if (this.props.clickParam != undefined && this.props.clickParam != null) {
            this.props.onClick(this.props.clickParam);
        } else {
            this.props.onClick();
        }
    }

    render(): JSX.Element {
        let className = "ant-list-item lr-interactive hover-background";
        if (!!this.props.className) {
            className = this.props.className + " " + className;
        }

        return (
            <div
                key={this.props.key}
                onClick={this.onClick}
                className={className}
                style={this.props.style}
            >
                {this.props.children}
            </div>
        );
    }
}