import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    device: string;
    deviceArea: string;
}

export const type = "setTwoFAPhoneNumber";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state,
        ["ui", "MFA", "device"], action.payload?.device,
        ["ui", "MFA", "deviceArea"], action.payload?.deviceArea
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);