import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {TransactionActionState} from "../../../state/uistate/TransactionUiState";

export interface SetTransactionActionParams {
    transactionId: string;
    actionState?: TransactionActionState;
    deleteNode?: boolean;
}

export const type = "setTransactionActionState";

export const actionCreator: ActionCreator<SetTransactionActionParams, SetTransactionActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<SetTransactionActionParams> = (state, action) => {
    if (action.payload?.deleteNode) {
        const actions = {...state.ui.contacts.actions};
        delete actions[action.payload?.transactionId];

        return sprout.assoc(state, ["ui", "transactions", "actions"], actions);
    } else {
        return sprout.assoc(state, ["ui", "transactions", "actions", action.payload?.transactionId], action.payload?.actionState);
    }
};

export const reducerMap = promisePattern.reducerMap(type, reducer);