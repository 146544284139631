import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {actionCreator as getApiKeys} from "../../../actions/user/server/getApiKeys";
import {actionCreator as createApiKey} from "../../../actions/user/server/createApiKey";
import {actionCreator as deleteApiKey} from "../../../actions/user/server/deleteApiKey";
import {actionCreator as getUser} from "../../../actions/account/server/getAccountUser";

import {actionCreator as uiAction} from "../../../actions/uiAction";
import {IntegrationsLayout} from "./IntegrationsLayout";
import {ApiKeysUiState} from "../../../state/uistate/UserUiState";
import { Jwt } from "../../../state/browserState/BrowserState";
import {ApiKeyState} from "../../../state/serverState/User";
import { User } from "../../../communication/dtos/account/User";

interface StateProps {
    jwt: Jwt;
    uiState: ApiKeysUiState;
    userEmail: string;
    serverState: ApiKeyState;
    users: User[];
}

const mapStateToProps = (state: State): StateProps => ({
    jwt: state.browser.jwt,
    uiState: state.ui.user.apiKeys,
    userEmail: state.server.user.email,
    serverState: state.server.user.apiKeys,
    users: state.server.account.users.list
});

const actionCreators = {
    getApiKeys,
    createApiKey,
    deleteApiKey,
    getUser,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const IntegrationsLayoutConnected = connector(IntegrationsLayout);
