import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {GetAccountResponse} from "../../../communication/dtos/account/GetAccount";
import {getAccountDetails} from "../../../communication/account";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {errorStrings} from "../../../state/serverState/RestError";

export const type = "getAccountDetails";

export const actionCreator = promisePattern.actionCreator(type, getAccountDetails);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetAccountResponse> = (
    state: State, payload: GetAccountResponse
) => sprout.assoc(state,
    ["server", "account", "accountInfo"], payload,
    ["server", "account", "error"], null,
    ["server", "account", "fetching"], false,
);

const on403Reducer: RejectionReducer = {
    status: 403,
    reducer: (state) => sprout.assoc(state,
        ["server", "account", "error"], errorStrings.PERMISSION_ERROR_403,
        ["server", "account", "fetching"], false,
    )
};

const on404Reducer: RejectionReducer = {
    status: 404,
    reducer: (state) => sprout.assoc(state,
        ["server", "account", "error"], "account-not-found",
        ["server", "account", "fetching"], false,
    )
};

const onTeamRejected: RejectionReducer = {
    reducer: (state) => sprout.assoc(state,
        ["server", "account", "fetching"], false
    )
};

const onRejected = rejectionHandler([onTeamRejected, on403Reducer, on404Reducer]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc(state,
        ["server", "account", "fetching"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);