import {
    ContactCustomerSupportRequest,
    ContactCustomerSupportResponse
} from "./dtos/contactCustomerSupport/ContactCustomerSupport";
import {fetchJson} from "./utils/restAccess";

export const customerSupportEndpoint = "/v2/user/contactCustomerSupport";

export const contactCustomerSupport = (values: ContactCustomerSupportRequest): Promise<ContactCustomerSupportResponse> =>
    fetchJson(customerSupportEndpoint, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(values)
    });