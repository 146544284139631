import * as React from "react";
import {Body2} from "../../../ui/typography/TypographyTags";

import "./accountPaymentSignup.scss";

export interface Props {
    onSignUpClick: () => void;
}

export class AccountPaymentSignUp extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div className={"account-payment-container"}>
                <Body2 onClick={this.props.onSignUpClick}>Add Credit Card</Body2>
            </div>
        );
    }
}