import * as React from "react";
import Modal from "antd/es/modal/Modal";
import {CreateValueForContactFormConnected} from "./CreateValueForContactFormConnected";
import {ReduxProps, ExternalProps} from "./ContactCreateValueModalConnected";

export interface Props extends ReduxProps, ExternalProps {
}

export class ContactCreateValueModal extends React.PureComponent<Props, {}> {
    formSubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.formSubmit = submit;
    }

    onOk(): void {
        if (this.formSubmit) {
            this.formSubmit();
        }
    }

    onSuccess(): void {
        this.props.onClose();
    }

    render(): JSX.Element {
        const contactEmail = (this.props.contact) ? this.props.contact.email : "";

        return (
            <Modal
                title={`Attach new value${(!!contactEmail) ? " to " + contactEmail : ""}`}
                visible={this.props.open}
                destroyOnClose
                okText="Attach new value"
                onOk={this.onOk}
                confirmLoading={this.props.processingValue}
                onCancel={this.props.onClose}
            >
                <CreateValueForContactFormConnected
                    contact={this.props.contact}
                    getSubmit={this.setSubmit}
                    onSuccess={this.onSuccess}
                />
            </Modal>
        );
    }
}