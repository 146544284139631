import * as ReactRedux from "react-redux";
import {State} from "../../state/State";
import {ContactDetailPage} from "./ContactDetailPage";
import {actionCreator as getContact} from "../../actions/contacts/server/getContact";
import {actionCreator as setContactActionState} from "../../actions/contacts/ui/setContactActionState";
import {ContactsServerState} from "../../state/serverState/ContactsServerState";
import {ContactUiState} from "../../state/uistate/ContactUiState";

interface StateProps {
    contactServerState: ContactsServerState;
    contactUiState: ContactUiState;
}

const mapStateToProps = (state: State): StateProps => ({
    contactServerState: state.server.contacts,
    contactUiState: state.ui.contacts
});

const actionCreators = {
    getContact,
    setContactActionState
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ContactDetailPageConnected = connector(ContactDetailPage);