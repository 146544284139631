import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {EditCurrencyForm} from "./EditCurrencyForm";
import {actionCreator as updateCurrency} from "../../../../actions/currency/server/updateCurrency";
import {actionCreator as getCurrency} from "../../../../actions/currency/server/getCurrency";
import {Currency} from "lightrail-client/dist/model/Currency";
import {UpdateCurrencyResponse} from "lightrail-client/dist/params/currencies/UpdateCurrencyParams";
import {CurrencyServerState} from "../../../../state/serverState/CurrencyServerState";

export interface ExternalProps {
    currency: Currency;
    getSubmit?: (submit: () => void) => void;
    onEditResponse?: (response: UpdateCurrencyResponse) => void;
}

interface StateProps {
    currencyServerState: CurrencyServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): StateProps => ({
    currencyServerState: state.server.currency,
    ...props
});

const actionCreators = {
    updateCurrency,
    getCurrency
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const EditCurrencyFormConnected = connector(EditCurrencyForm);