import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {AttachesTransactionForm} from "./AttachesTransactionForm";
import {actionCreator as getValue} from "../../../../actions/values/server/getValue";
import {actionCreator as debitTransaction} from "../../../../actions/transactions/server/debitTransaction";
import {actionCreator as creditTransaction} from "../../../../actions/transactions/server/creditTransaction";
import {actionCreator as toggleTransactionModal} from "../../../../actions/values/ui/toggleTransactionModal";
import {actionCreator as setPendingValueAction} from "../../../../actions/values/ui/setPendingValueAction";
import {Value} from "lightrail-client/dist/model";
import {TransactionType} from "../../../../state/uistate/TransactionUiState";
import {TransactionsServerState} from "../../../../state/serverState/TransactionsServerState";
import {ValueUiState} from "../../../../state/uistate/ValueUiState";

export interface ExternalProps {
    value: Value;
    transactionType: TransactionType;
    getSubmit: (submit: () => void) => void;
}

interface StateProps {
    transactionStateServer: TransactionsServerState;
    valueStateUI: ValueUiState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    ...props,
    transactionStateServer: state.server.transactions,
    valueStateUI: state.ui.value
});

const actionCreators = {
    getValue,
    debitTransaction,
    creditTransaction,
    toggleTransactionModal,
    setPendingValueAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AttachesTransactionFormConnected = connector(AttachesTransactionForm);