import * as React from "react";
import {MouseEvent} from "react";
import {ColoredText, ColoredTextProps} from "./ColoredText";
import {dissoc} from "sprout-data";

// Disable eslint for legacy styles
const typeClasses: any = require("../../../styles/legacy/_typography.scss"); // eslint-disable-line

enum TextSizes { DISPLAY4, DISPLAY3, DISPLAY2, DISPLAY1, HEADLINE, TITLE, SUBHEADING, BODY2, BODY1, CAPTION }

const TextSizeToClassMap: { [key: string]: string } = {
    DISPLAY4: typeClasses.display4,
    DISPLAY3: typeClasses.display3,
    DISPLAY2: typeClasses.display2,
    DISPLAY1: typeClasses.display1,
    HEADLINE: typeClasses.headline,
    TITLE: typeClasses.title,
    SUBHEADING: typeClasses.subheading,
    BODY2: typeClasses.body2,
    BODY1: typeClasses.body1,
    CAPTION: typeClasses.caption
    //SMALL: typeClasses.small
};

//////////////////////////////////////
// [ PRIVATE BASE TAGS ]
interface LinkableTextProps extends SizedColoredTextProps {
    onClickValue?: any;
    onClick?: (value?: any) => void;
}

class LinkableText extends React.PureComponent<LinkableTextProps, {}> {
    constructor(props: LinkableTextProps) {
        super(props);

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler(e: MouseEvent<any>): void {
        if (!!e) {
            e.stopPropagation();
            e.bubbles = false;
        }

        this.props.onClick(this.props.onClickValue);
    }

    render(): JSX.Element {
        return (
            <SizedColoredText
                onClick={(!!this.props.onClick) ? this.onClickHandler : null}
                {...dissoc<any>(this.props, ["onClick"], ["onClickValue"])}
            >
                {this.props.children}
            </SizedColoredText>
        );
    }
}

interface SizedColoredTextProps extends ColoredTextProps {
    textSize: TextSizes;
}

class SizedColoredText extends React.PureComponent<SizedColoredTextProps, {}> {
    render(): JSX.Element {
        const sizeClass: string = TextSizeToClassMap[TextSizes[this.props.textSize]];
        return (
            <ColoredText
                className={`${this.props.className} ${sizeClass}`}
                {...dissoc<any>(this.props, ["className"], ["textSize"])}
            >
                {this.props.children}
            </ColoredText>
        );
    }
}

/////////////////////////////////////
// [ EXPORTED TAGS ]
///////////////

export interface TypeTagProps extends ColoredTextProps {
    onClickValue?: any;
    onClick?: (value?: any) => void;
}

/////////////////////////////////////
// [ DISPLAY4 ]
///////////////
export class Display4 extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.DISPLAY4}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ DISPLAY3 ]
///////////////
export class Display3 extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.DISPLAY3}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ DISPLAY2 ]
///////////////
export class Display2 extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.DISPLAY2}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ DISPLAY1 ]
///////////////
export class Display1 extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.DISPLAY1}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ HEADLINE ]
///////////////
export class Headline extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.HEADLINE}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ TITLE ]
///////////////
export class Title extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.TITLE}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ SUBHEADING ]
///////////////
export class Subheading extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.SUBHEADING}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ BODY2 ]
///////////////
export class Body2 extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.BODY2}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ BODY1 ]
///////////////
export class Body1 extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.BODY1}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}

/////////////////////////////////////
// [ CAPTION ]
///////////////
export class Caption extends React.PureComponent<TypeTagProps, {}> {
    render(): JSX.Element {
        return (
            <LinkableText textSize={TextSizes.CAPTION}  {...this.props as any}>{this.props.children}</LinkableText>
        );
    }
}