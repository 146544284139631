import * as promisePattern from "../../promisePattern";
import * as userAccount from "../../../communication/userAccount";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";
import {ToggleModeResponse} from "../../../communication/dtos/auth/ToggleMode";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../rejectionHandler";
import {initialState, State} from "../../../state/State";
import {onAccountCallRejected} from "./accountActionRejectedHandler";

export const type = "toggleTestingMode";

export const actionCreator = promisePattern.actionCreator(type, userAccount.toggleTesting);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ToggleModeResponse> =
    (state, payload) => {
        const testing = payload?.user.mode === UserModes.TEST;
        const authMessage = {
            message: payload?.message,
            messageCode: payload?.messageCode
        };

        return sprout.assoc(initialState,
            ["server", "user", "testing"], testing,
            ["server", "user", "fetching"], false,
            ["server", "user", "email"], payload?.user.email,
            ["server", "user", "id"], payload?.user.id,
            ["server", "account", "authMessage"], authMessage,
            ["server", "MFA", "enabled"], !!payload?.user.mfa,
        );
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([
    onAccountCallRejected
]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                fetching: true
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);