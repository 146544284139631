import * as React from "react";
import {Form, message, Modal} from "antd";
import {Transaction} from "lightrail-client/dist/model";
import {TransactionAction} from "../../../state/uistate/TransactionUiState";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {UiActionParams} from "../../../actions/uiAction";
import {CapturePendingTransactionActionParams} from "../../../actions/transactions/server/capturePendingTransaction";
import {CapturePendingResponse, ReverseResponse, VoidPendingResponse} from "lightrail-client/dist/params";
import {VoidPendingTransactionActionParams} from "../../../actions/transactions/server/voidPendingTransaction";
import {ReverseTransactionActionParams} from "../../../actions/transactions/server/reverseTransaction";
import {GetTransactionChainActionResponse} from "../../../actions/transactions/server/getTransactionChain";
import {MetadataItems} from "../../ui/forms/items/MetadataItems";
import {useForm} from "antd/lib/form/Form";
import {ActionResponse} from "../../../actions/promisePattern";

const LIGHTRAIL_NOTES_KEY = "lightrail_webapp_notes";
const PREFIX = "metadata-";

interface Props {
    transaction: Transaction;
    transactionType: TransactionAction;
    loading: boolean;

    uiAction: (p: UiActionParams) => void;
    capturePendingTransaction: (req: CapturePendingTransactionActionParams) => ActionResponse<CapturePendingResponse>;
    voidPendingTransaction: (req: VoidPendingTransactionActionParams) => ActionResponse<VoidPendingResponse>;
    reverseTransaction: (req: ReverseTransactionActionParams) => ActionResponse<ReverseResponse>;
    getTransactionChain: (req: string) => ActionResponse<GetTransactionChainActionResponse>;
}

export const TransactionActionModal = (props: Props): JSX.Element => {
        const [form] = useForm();

        const onCancel = (): void => {
            props.uiAction({
                uiStatePath: UiActionPaths.TRANSACTION_MODAL_OPEN,
                value: {[props.transaction.id]: TransactionAction.NONE}
            });
        };

        const onSubmit = async (): Promise<void> => {
            const values = await form.validateFields();
            const metadata = values[PREFIX + LIGHTRAIL_NOTES_KEY];

            switch (props.transactionType) {
                case (TransactionAction.REVERSE):
                    try {
                        await props.reverseTransaction({
                            transactionToReverse: props.transaction.id,
                            params: {id: props.transaction.id + "-reversed", metadata: {notes: metadata}}
                        });

                        message.success("TransactionReversed");
                        props.getTransactionChain(props.transaction.id);
                    } finally {
                        onCancel();
                    }
                    break;

                case (TransactionAction.CAPTURE):
                    try {
                        await props.capturePendingTransaction({
                            transactionToCapture: props.transaction.id,
                            params: {id: props.transaction.id + "-captured", metadata: {notes: metadata.value}}
                        });

                        message.success("Transaction captured");
                        props.getTransactionChain(props.transaction.id);
                    } finally {
                        onCancel();
                    }
                    break;

                case (TransactionAction.VOID):
                    try {
                        await props.voidPendingTransaction({
                            transactionToVoid: props.transaction.id,
                            params: {id: props.transaction.id + "-voided", metadata: {notes: metadata.value}}
                        });

                        message.success("Transaction voided");
                        props.getTransactionChain(props.transaction.id);
                    } finally {
                        onCancel();
                    }
            }
        };

        return (
            <Modal
                title={props.transactionType + " Transaction"}
                visible={(!!props.transactionType) && (props.transactionType !== TransactionAction.NONE)}
                onCancel={onCancel}
                onOk={onSubmit}
                okButtonProps={{loading: props.loading}}
            >
                <Form form={form}>
                    <MetadataItems
                        metadata={props.transaction.metadata}
                        form={form}
                        metadataNotesKey={LIGHTRAIL_NOTES_KEY}
                    />
                </Form>
            </Modal>
        );
    }
;