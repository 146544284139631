import {normalizeValueFromServer} from "../../../../util/currencyUtils";
import {Col, Form, Input, Row} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {NumbersCommasDecimalsSpacesOnlyRegExp} from "../../../../util/regexp";
import * as React from "react";
import {Program} from "lightrail-client/dist/model";
import {Currency} from "lightrail-client/dist/model/Currency";
import {ProgramCopy} from "../CreateProgramFormCopy";
import { FormInstance } from "antd/lib/form";

export interface Props {
    program: Program;
    currentCurrency: Currency;
    form: FormInstance;
}

export class AdvancedRangeFormItems extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.validateRangeMin = this.validateRangeMin.bind(this);
        this.validateRangeMax = this.validateRangeMax.bind(this);
    }

    validateRangeMax(rule: any, value: any, callback: any): void {
        const minValue = this.props.form.getFieldValue(FormFieldIds.MinValue);

        if (!!value || (!!minValue && minValue !== 0)) {
            callback();
        } else {
            callback(<div>A min or max is required</div>);
        }
    }

    validateRangeMin(rule: any, value: any, callback: any): void {
        const maxValue = this.props.form.getFieldValue(FormFieldIds.MaxValue);

        if (!!value || (!!maxValue && maxValue !== 0)) {
            callback();
        } else {
            callback(<div>A min or max is required</div>);
        }
    }

    render(): JSX.Element {
        const program = (!!this.props.program) ? this.props.program : null;
        const currency = this.props.currentCurrency;

        return (
            <div>
                <Input.Group size="large" compact>
                    <Form.Item
                        label=" "
                        style={{
                            display: "inline-block",
                            marginBottom: 0,
                            paddingRight: 0,
                            width: "100%",
                            maxWidth: 180,
                            whiteSpace: "normal"
                        }}
                        name={FormFieldIds.MinValue}
                        rules={[
                            {pattern: NumbersCommasDecimalsSpacesOnlyRegExp, message: "#'s, commas and spaces only"},
                            {validator: this.validateRangeMin}
                        ]}
                        initialValue={
                            (!!program && !!program.minInitialBalance)
                                ? normalizeValueFromServer(program.minInitialBalance, currency.decimalPlaces).toString()
                                : null
                        }
                    >
                        <Input
                            className="lr-inputRangeBorderRight"
                            size="large"
                            placeholder={`${currency.symbol}1.00`}
                        />
                    </Form.Item>
                    <Form.Item
                        label=" "
                        style={{
                            display: "inline-block",
                            marginBottom: 0,
                            paddingRight: 0,
                            width: "100%",
                            maxWidth: 30
                        }}
                    >
                        <Input
                            className="lr-inputRangeBorderLeftRight"
                            disabled
                            placeholder="~"
                            style={{
                                width: 30,
                                backgroundColor: "#FFF",
                                pointerEvents: "none"
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label=" "
                        style={{
                            display: "inline-block",
                            marginBottom: 0,
                            paddingRight: 0,
                            width: "100%",
                            maxWidth: 200,
                            top: 1
                        }}
                        name={FormFieldIds.MaxValue}
                        rules={[
                            {pattern: NumbersCommasDecimalsSpacesOnlyRegExp, message: "#'s, commas and spaces only"},
                            {validator: this.validateRangeMax}
                        ]}
                        initialValue={
                            (!!program && !!program.maxInitialBalance)
                                ? normalizeValueFromServer(program.maxInitialBalance, currency.decimalPlaces).toString()
                                : null
                        }
                    >
                        <Input
                            className="lr-inputRangeBorderLeft"
                            size="large"
                            addonAfter={currency.code}
                            placeholder={`${currency.symbol}1000.00`}
                        />
                    </Form.Item>
                </Input.Group>
                <Row>
                    <Col style={{display: "block", marginTop: -4, whiteSpace: "normal"}}>
                        <p style={{
                            color: "rgba(0, 0, 0, 0.45)"
                        }}>{"Provide a min, max, or both. " + ProgramCopy.BALANCE_RANGE_APPLIES_TO_PER_CONTACT_BALANCE}</p>
                    </Col>
                </Row>
            </div>
        );
    }
}