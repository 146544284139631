import {ActionCreator, Reducer} from "../../Action";
import {assoc} from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    contactId: string;
}

export const type = "clearContactError";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = () => ({
    type
});

export const reducer: Reducer<ActionParams> = (state) => {
    return assoc(state,
        ["server", "contacts", "error"], null,
        ["server", "contacts", "status"], null
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);