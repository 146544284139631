import * as React from "react";
import {Button, message} from "antd";
import * as CopyToClipboard from "copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";

interface State {
    copyButtonIsVisible: boolean;
}

interface Props {
    stringToBeCopied: string;
}

export class StringWithCopyButton extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {copyButtonIsVisible: false};

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.toggleShowCopyButtonOn = this.toggleShowCopyButtonOn.bind(this);
        this.toggleShowCopyButtonOff = this.toggleShowCopyButtonOff.bind(this);
    }

    componentDidMount(): void {
        this.toggleShowCopyButtonOff();
    }

    copyToClipboard(): void {
        CopyToClipboard(this.props.stringToBeCopied);
        message.success("Copied to clipboard");
    }

    toggleShowCopyButtonOn(): void {
        this.setState({copyButtonIsVisible: true});
    }

    toggleShowCopyButtonOff(): void {
        this.setState({copyButtonIsVisible: false});
    }

    render(): JSX.Element {
        return (
            <div
                onMouseEnter={this.toggleShowCopyButtonOn}
                onMouseLeave={this.toggleShowCopyButtonOff}
                style={{position: "relative", paddingRight: 40, wordBreak: "break-word", display: "inline-block"}}
            >
                {this.props.stringToBeCopied}
                {
                    (this.state.copyButtonIsVisible) &&
                    <Button
                        onClick={this.copyToClipboard}
                        size={"small"}
                        style={{position: "absolute", right: 0, top: 0}}
                    >
                        <CopyOutlined/>
                    </Button>
                }
            </div>
        );
    }
}