import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {ResetPasswordPage} from "./ResetPasswordPage";
import * as resetPassword from "../../../../actions/auth/server/resetPassword";
import * as setResetPasswordToken from "../../../../actions/auth/server/setResetPasswordToken";
import {actionCreator as resetAuthErrorAndSuccessStates} from "../../../../actions/auth/server/resetAuthErrorAndSuccessStates";
import {withRouter} from "react-router-dom";
import {actionCreator as setRestError} from "../../../../actions/errors/server/setRestError";
import {AuthState} from "../../../../state/serverState/AuthServerState";

export interface ExternalProps {
    onSuccessLogin?: () => {};
    onMFALogin?: () => {};
}

interface StateProps {
    stateHistory: string[];
    auth: AuthState;
    MFA: boolean;
    inTestMode: boolean;
    processing: boolean;
    additionalAuthenticationRequired: boolean;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    stateHistory: state.ui.history,
    auth: state.server.auth,
    MFA: state.server.MFA.enabled,
    inTestMode: state.server.user.testing,
    processing: state.server.auth.processing,
    additionalAuthenticationRequired: state.server.user.additionalAuthenticationRequired
});

const actionCreators = {
    resetPassword: resetPassword.actionCreator,
    setResetPasswordToken: setResetPasswordToken.actionCreator,
    resetAuthErrorAndSuccessStates: resetAuthErrorAndSuccessStates,
    setRestError: setRestError
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ResetPasswordPageConnected = ReactRedux.connect(mapStateToProps, actionCreators)(withRouter(ResetPasswordPage));