import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {getIssuance} from "lightrail-client/dist/programs";
import {GetIssuanceResponse} from "lightrail-client/dist/params";

export interface GetIssuanceActionParams {
    programId: string;
    issuanceId: string;
}

export const type = "getIssuance";
//In order to avoid compile errors, casting this with optional params after the first, anything that calls this should includes the params as an array, ie: [programId, issuanceId]
export const actionCreator = promisePattern.actionCreator(type, (p: GetIssuanceActionParams) => getIssuance(p.programId, p.issuanceId));

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetIssuanceResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "issuance", "list"], [payload?.body, ...state.server.issuance.list],
            ["server", "issuance", "fetching"], false,
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            issuance: {
                fetching: true,
                error: null
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);