import * as React from "react";
import {Button, Modal} from "antd";
import {Colors} from "../../../../styles/legacy/styles";
import {WarningOutlined} from "@ant-design/icons";
import {ExternalProps, ReduxProps} from "./RestErrorDialogConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export class RestErrorDialog extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onCloseRestErrorModal = this.onCloseRestErrorModal.bind(this);
        this.okButtonHandler = this.okButtonHandler.bind(this);
    }

    componentDidUpdate(oldProps: Props): void {
        const error = oldProps.error;
        if (!!error && !!error.status) {
            if (!error.ignoreError) {
                this.props.setRestErrorModalOpen({isOpen: true});
            }
        }
    }

    ///////////////////
    //[ ACTION ]
    onCloseRestErrorModal(): void {
        this.props.setRestError({status: 0, code: "", message: "", ignoreError: true});
        this.props.setRestErrorModalOpen({isOpen: false});
    }

    okButtonHandler(): void {
        this.onCloseRestErrorModal();
    }

    ///////////////////
    //[ RENDER ]
    render(): JSX.Element {
        const message = (!!this.props.error && !!this.props.error.message) ? this.props.error.message : "Unknown Error";

        if (this.props.error?.ignoreError) {
            return null;
        }

        return (
            <Modal
                title={
                    <span>
                        <WarningOutlined style={{color: Colors.alert, marginRight: 16}}/>
                        </span>
                }
                visible={this.props.isOpen}
                onOk={this.okButtonHandler}
                destroyOnClose
                footer={
                    <Button type="primary" onClick={this.okButtonHandler}>Okay</Button>
                }
            >
                {message}
            </Modal>
        );
    }
}