import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ChangeCodeForm} from "./ChangeCodeForm";
import {actionCreator as changeValuesCode} from "../../../actions/values/server/changeValuesCode";
import {actionCreator as updateValue} from "../../../actions/values/server/updateValue";
import {ChangeValuesCodeResponse, UpdateValueResponse} from "lightrail-client/dist/params";
import {ValueServerState} from "../../../state/serverState/ValueServerState";
import {UserState} from "../../../state/serverState/User";

export interface ExternalProps {
    valueId: string;
    initialCode?: string;
    metadata?: {};
    isGeneric?: boolean;
    getSubmit?: (submit: () => void) => void;
    onChangeCodeResponse?: (response: ChangeValuesCodeResponse) => void;
    onUpdateValueResponse?: (response: UpdateValueResponse) => void;
}

interface StateProps {
    valueServerState: ValueServerState;
    userServerState: UserState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    valueServerState: state.server.values,
    userServerState: state.server.user,
    ...props
});

const actionCreators = {
    changeValuesCode,
    updateValue
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ChangeCodeFormConnected = connector(ChangeCodeForm);