import * as React from "react";
import {Paths} from "../../util/routes/routes";
import { SwitcherOutlined, ContactsOutlined, DollarOutlined, BarsOutlined, FileTextOutlined } from "@ant-design/icons";

export interface MainNavItem {
    label: string;
    icon: JSX.Element;
    path: string;
}

export const MENU_ICONS = {
    PROGRAMS: <SwitcherOutlined/>,
    CONTACTS: <ContactsOutlined/>,
    VALUES: <DollarOutlined/>,
    TRANSACTIONS: <BarsOutlined/>,
    REPORTS: <FileTextOutlined/>
};

export const MainNavItems: MainNavItem[] = [
    {
        label: "Programs",
        icon: MENU_ICONS.PROGRAMS,
        path: Paths.PROGRAM_LIST
    },
    {
        label: "Contacts",
        icon: MENU_ICONS.CONTACTS,
        path: Paths.CONTACT_LIST
    },
    {
        label: "Values",
        icon: MENU_ICONS.VALUES,
        path: Paths.VALUE_LIST
    },
    {
        label: "Transactions",
        icon: MENU_ICONS.TRANSACTIONS,
        path: Paths.TRANSACTIONS_LIST
    },
    {
        label: "Reports",
        icon: MENU_ICONS.REPORTS,
        path: Paths.REPORTS_ROOT
    },
];