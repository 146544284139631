import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import ReduxPromise from "redux-promise-middleware";
import ReduxThunk from "redux-thunk";
import * as Redux from "redux";
import "whatwg-fetch";
import {reducer} from "./actions/masterReducer";
import {httpErrorMiddleware} from "./middleware/httpErrorMiddleware";
import {analyticsMiddleware} from "./middleware/analyticsMiddleware";
import {configure} from "lightrail-client";
import {polyfill} from "es6-promise";
import RedBox from "redbox-react";
import "./styles/app.less";
import "./styles/legacy/app.scss";

// Include the favicons in the build.
import "./android-chrome-512x512.png";
import "./android-chrome-192x192.png";
import "./apple-touch-icon.png";
import "./favicon-16x16.png";
import "./favicon-32x32.png";
import "./favicon.ico";
import "./mstile-150x150.png";
import "./safari-pinned-tab.svg";
import "./manifest.json";

polyfill();

//Configure Lightrail Client (API)
configure({
    restRoot: "/v2/",
    isBrowser: true,
    additionalHeaders: {
        "X-Requested-With": "XMLHttpRequest"
    }
});

//Configure Redux Store
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || Redux.compose;
const store = Redux.createStore(
    reducer,
    composeEnhancers(
        Redux.applyMiddleware(
            ReduxPromise,
            ReduxThunk,
            httpErrorMiddleware,
            analyticsMiddleware,
        ),
    )
);

//Setup React
const rootElement = document.getElementById("root");
let render = (): void => {
    // Requiring App here and not importing at the top enables hot reloading.
    const App = (require("./components/AppConnected")).App; // eslint-disable-line
    ReactDOM.render(
        <Provider store={store}>
            <App store={store}/>
        </Provider>,
        rootElement
    );
};

//Configure app for hot swapping
declare const module: any;
if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    const renderApp = render;
    render = () => {
        try {
            renderApp();
        } catch (error) {
            ReactDOM.render(
                <RedBox error={error}/>,
                rootElement
            );
        }
    };
    module.hot.accept("./components/AppConnected", () => {
        setTimeout(render);
    });
}

render();
