import * as React from "react";
import * as CopyToClipboard from "copy-to-clipboard";
import {CreatedApiKey} from "../../../communication/dtos/user/apiKeys/ApiKey";
import {Colors} from "../../../styles/legacy/styles";
import {Button, message, Modal} from "antd";
import { InfoCircleOutlined, CopyOutlined } from "@ant-design/icons";

import "./apiKeysLayout.scss";

export interface Props {
    open: boolean;
    apiKey: CreatedApiKey;

    onRequestClose: () => void;
}

export class NewKeyModal extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
    }

    onCopyToClipboard(): void {
        CopyToClipboard(this.props.apiKey.token);
        message.success("Key copied to Clipboard");
    }

    onRequestClose(): void {
        this.props.onRequestClose();
    }

    render(): JSX.Element {
        if (!this.props.apiKey) {
            return null;
        }

        const key = this.props.apiKey;
        return (
            <Modal
                title={
                    <span>
                        <InfoCircleOutlined style={{color: Colors.primary, marginRight: 16}}/>{`${key.name} Api Key`}
                    </span>
                }
                visible={this.props.open}
                onCancel={this.props.onRequestClose}
                footer={[
                    <Button key="back" onClick={this.props.onRequestClose}>Close</Button>,
                    <Button key="submit" type="primary" onClick={this.onCopyToClipboard}>
                        <CopyOutlined/> Copy to clipboard
                    </Button>
                ]}
            >
                <div className={"newKeyDialogContainer"}>
                    <div className={"infoContainer"}>
                        <p>
                            For security reasons, this is the only time you will be allowed to see this key.
                            <br/>
                            Please save it a secure location before proceeding.
                        </p>
                    </div>
                    <div className="codeDisplay">
                        {key.token}
                    </div>
                </div>
            </Modal>
        );
    }
}