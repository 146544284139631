import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {debit} from "lightrail-client/dist/transactions";
import {DebitResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";

export const type = "debitTransactions";
export const actionCreator = promisePattern.actionCreator(type, debit);

const onFulfilled: promisePattern.PromiseFulfilledHandler<DebitResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? [payload?.body, ...state.server.transactions.list] : state.server.transactions.list;
        return sprout.assoc(state,
            ["server", "transactions", "list"], list,
            ["server", "transactions", "error"], null,
            ["server", "transactions", "processing"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "transactions"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);