import * as React from "react";
import {ReactNode} from "react";
import {getLocationParamsFromRoute, Paths} from "../../../util/routes/routes";
import {Link, matchPath, withRouter} from "react-router-dom";
import {Breadcrumb} from "antd";
import {WithRouterProps} from "../../router/WithRouterProps";
import {prefixIdWithDots} from "../../../util/stringUtils";

export interface Props extends WithRouterProps<{ programId?: string, valueId?: string, contactId?: string }> {}

class BreadcrumbsComponent extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    pushBreadcrumbIfMatch = (url: string, list: JSX.Element[]) => (path: string, content: ReactNode) => {
        if (matchPath(url, {path, exact: false, strict: false})) {
            list.push(<Breadcrumb.Item>{content}</Breadcrumb.Item>);
        }
    }

    renderBreadCrumbs(url: string): JSX.Element {
        const breadCrumbs: JSX.Element[] = [];
        const pushIfMatch = this.pushBreadcrumbIfMatch(url, breadCrumbs);

        pushIfMatch(Paths.ACCOUNT_ROOT, "Account");

        pushIfMatch(Paths.ACCOUNT_WEBHOOK_DETAIL, <Link to={Paths.ACCOUNT_WEBHOOK}>Webhooks</Link>);
        pushIfMatch(Paths.ACCOUNT_WEBHOOK_DETAIL, `Webhook ${prefixIdWithDots(getLocationParamsFromRoute(Paths.ACCOUNT_WEBHOOK_DETAIL, this.props.location.pathname).webhookId)}`);

        pushIfMatch(Paths.PROGRAM_LIST, <Link to={Paths.PROGRAM_LIST}>Programs</Link>);
        pushIfMatch(Paths.PROGRAM_DETAIL, `Program ${prefixIdWithDots(getLocationParamsFromRoute(Paths.PROGRAM_DETAIL, this.props.location.pathname).programId)}`);

        pushIfMatch(Paths.VALUE_LIST, <Link to={Paths.VALUE_LIST}>Values</Link>);
        pushIfMatch(Paths.VALUE_DETAIL, `Value ${prefixIdWithDots(getLocationParamsFromRoute(Paths.VALUE_DETAIL, this.props.location.pathname).valueId)}`);

        pushIfMatch(Paths.CONTACT_LIST, <Link to={Paths.CONTACT_LIST}>Contacts</Link>);
        pushIfMatch(Paths.CONTACT_DETAIL, `Contact ${prefixIdWithDots(getLocationParamsFromRoute(Paths.CONTACT_DETAIL, this.props.location.pathname).contactId)}`);

        pushIfMatch(Paths.TRANSACTIONS_LIST, <Link to={Paths.TRANSACTIONS_LIST}>Transactions</Link>);
        pushIfMatch(Paths.TRANSACTION_DETAIL, `Transaction ${prefixIdWithDots(getLocationParamsFromRoute(Paths.TRANSACTION_DETAIL, this.props.location.pathname).transactionId)}`);

        pushIfMatch(Paths.REPORTS_ROOT, "Reports");

        return (
            <React.Fragment>
                {...breadCrumbs}
            </React.Fragment>
        );
    }

    render(): JSX.Element {
        return (
            <Breadcrumb>
                {
                    this.renderBreadCrumbs(this.props.location.pathname)
                }
            </Breadcrumb>
        );
    }
}

export const Breadcrumbs = withRouter(BreadcrumbsComponent);