import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {createIssuance} from "lightrail-client/dist/programs";
import {CreateIssuanceParams, CreateIssuanceResponse} from "lightrail-client/dist/params";

export interface CreateIssuanceActionParams {
    programId: string;
    params: CreateIssuanceParams;
}

export const type = "createIssuance";
export const actionCreator = promisePattern.actionCreator(type, (p: CreateIssuanceActionParams) => createIssuance(p.programId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateIssuanceResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "issuance", "list"], [payload?.body, ...state.server.issuance.list],
            ["server", "issuance", "processing"], false
        );
    };

//Ignore Timeout for now, codes will still get created
const onRejected = rejectionHandler([
    {
        status: 504,
        overrideGlobal: true,
        reducer: (state) => sprout.deepMerge<State>(state, {
            server: {
                issuance: {
                    processing: false
                }
            }
        })
    }, {
        reducer: (state) => sprout.deepMerge<State>(state, {
            server: {
                issuance: {
                    processing: false
                }
            }
        })
    }
]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            issuance: {
                processing: true
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);