import * as React from "react";
import {ReactNode} from "react";
import {Table} from "antd";
import {defaultDash} from "../../../util/stringUtils";
import {formatDate} from "../../../util/dateUtils";
import {WithRouterProps} from "../../router/WithRouterProps";
import {formatRoute, Paths} from "../../../util/routes/routes";
import {LoadMoreFooter} from "../../ui/pagination/LoadMoreFooter";
import {ListContactsParams} from "lightrail-client/dist/params";
import {ContactActionMenuConnected} from "../contactActions/ContactActionMenuConnected";
import { ColumnProps } from "antd/lib/table";
import {ExternalProps, ReduxProps} from "./ContactsListConnected";

interface TableRow {
    id: string;
    key: string;
    email: string;
    firstName: string;
    lastName: string;
    updatedDate: string;
    actions: ReactNode;
}

interface State {
    highlightRowIds: string[];
}

export interface Props extends WithRouterProps<{}>, ExternalProps, ReduxProps{
}

export class ContactsList extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {highlightRowIds: []};

        this.fetchContacts = this.fetchContacts.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.setupRow = this.setupRow.bind(this);
    }

    componentDidMount(): void {
        this.props.clearContacts({});
        this.fetchContacts();
    }

    componentDidUpdate(oldProps: Props): void {
        if (!!oldProps.contactsState.list.length && oldProps.contactsState.list !== this.props.contactsState.list) {
            const highlightRowIds = this.props.contactsState.list
                .concat()
                .filter(p => oldProps.contactsState.list.indexOf(p) === -1)
                .map(p => p.id);

            this.setState({highlightRowIds});
        }
    }

    fetchContacts(params?: ListContactsParams): void {
        const internalParams = params || {};
        const externalParams = this.props.params || {};
        const fetchParams = {...internalParams, ...externalParams, limit: 50};

        this.props.getContacts(fetchParams);
    }

    onRowClick(record: TableRow, e: any): void {
        const statusClass = e.target.className;
        if (e.target.nodeName == "TD" || (!!statusClass && !!statusClass.match && statusClass.match("ant-badge-status"))) {
            this.props.history.push(formatRoute(Paths.CONTACT_DETAIL, {contactId: record.id}));
        }
    }

    setupRow(record: TableRow): any {
        const highlightThisRow = (this.props.highlightNewRows && this.state.highlightRowIds.indexOf(record.key) !== -1);

        return {
            className: (highlightThisRow) ? "lr-highlight-row" : null,
            onClick: this.onRowClick.bind(this, record)
        };
    }

    ///////////////////
    //[ RENDER ]
    getTableRows(): TableRow[] {
        return this.props.contactsState.list.map(contact => ({
            id: contact.id,
            key: contact.id,
            email: contact.email || defaultDash,
            firstName: contact.firstName || defaultDash,
            lastName: contact.lastName || defaultDash,
            updatedDate: formatDate(contact.updatedDate),
            actions: (
                <ContactActionMenuConnected
                    key={contact.id}
                    contact={contact}
                    menuContext={this.props.menuContext}
                />
            )
        }));
    }

    columns: ColumnProps<TableRow>[] = [
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 200,
            fixed: "left"
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName"
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName"
        },
        {
            title: "Last Updated",
            dataIndex: "updatedDate",
            key: "updatedDate"
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: 60,
            className: "lr-text-align-center",
            fixed: "right"
        }
    ];

    render(): JSX.Element {
        return (
            <div>
                <Table
                    className="lr-table-row-clickable"
                    loading={this.props.contactsState.fetching}
                    columns={this.columns}
                    dataSource={this.getTableRows()}
                    pagination={false}
                    onRow={this.setupRow}
                    sortDirections={["ascend", "descend"]}
                    scroll={{x: 700}}
                />
                <LoadMoreFooter
                    numResults={this.props.contactsState.list.length}
                    limit={this.props.contactsState.pagination.limit}
                    loading={this.props.contactsState.fetching}
                    links={this.props.contactsState.links}
                    onClick={this.props.getContacts}
                />
            </div>
        );
    }
}