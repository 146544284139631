import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {generateReport} from "../../../communication/generateReport";
import {GenerateReportResponse} from "../../../communication/dtos/reports/GenerateReportRequest";

export const type = "generateReport";
export const actionCreator = promisePattern.actionCreator(type, generateReport);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GenerateReportResponse> = (state) => {
    return sprout.deepMerge<State>(state, {
        server: {
            generateReport: {
                processing: false
            }
        }
    });
};

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "generateReport", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler = (state) => sprout.deepMerge<State>(state, {
    server: {
        generateReport: {
            processing: true
        }
    }
});

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
