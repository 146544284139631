import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {HideShowValue} from "./HideShowValue";
import {actionCreator as updatePasswordField} from "../../../../actions/components/ui/updatePasswordField";
import {PasswordFieldUiState} from "../../../../state/uistate/ComponentUiState";

export interface ExternalProps {
    id: string;
    value: string;
}

interface StateProps {
    uiState: PasswordFieldUiState;
}

const mapStateToProps = (state: State, ownProps: ExternalProps): ExternalProps & StateProps => ({
    ...ownProps,
    uiState: state.ui.components.passwordFields[ownProps.id],
});

const actionCreators = {
    updatePasswordField
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const HideShowValueConnected = connector(HideShowValue);