import * as React from "react";

export interface Props {
}

export class RuleFieldset extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <fieldset className="lr-fieldset">
                {this.props.children}
            </fieldset>
        );
    }
}