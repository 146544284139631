import * as React from "react";
import {Modal} from "antd";
import {CreateIssuanceFormConnected} from "./CreateIssuanceFormConnected";
import {Program} from "lightrail-client/dist/model";

export interface Props {
    open: boolean;
    program: Program;

    onClose: () => void;
}

export class CreateIssuanceModal extends React.PureComponent<Props, {}> {
    formSubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.formSubmit = submit;
    }

    onOk(): void {
        if (this.formSubmit) {
            this.formSubmit();
        }
    }

    render(): JSX.Element {
        if (!this.props.program) {
            return null;
        }

        return (
            <Modal
                title="Generate Unique Codes"
                visible={this.props.open}
                onCancel={this.props.onClose}
                okText="Generate Codes"
                maskClosable={false}
                onOk={this.onOk}
                destroyOnClose
            >
                <h3>{`From ${this.props.program.name}`}</h3>
                <CreateIssuanceFormConnected
                    program={this.props.program}
                    getSubmit={this.setSubmit}
                />
            </Modal>
        );
    }
}