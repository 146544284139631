import * as React from "react";
import {StripeModal} from "./stripemodal/StripeModal";
import {AccountPaymentDetails} from "./layouts/AccountPaymentDetails";
import {AccountPaymentSignUp} from "./layouts/AccountPaymentSignUp";
import {Alert, Modal} from "antd";
import {LoadingSpinner} from "../../ui/Loading";
import {ExternalProps, ReduxProps} from "./PaymentLayoutConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export class AccountPaymentSection extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.setDeleteCardModalState = this.setDeleteCardModalState.bind(this);
        this.toggleStripeModalIsOpen = this.toggleStripeModalIsOpen.bind(this);
        this.onRequestSuccessModalClose = this.onRequestSuccessModalClose.bind(this);
    }

    async componentDidMount(): Promise<void> {
        this.props.getCard({});
    }

    componentDidUpdate(nextProps: Props): void {
        this.evalForSuccessfulCardSubmission(nextProps);
    }

    //[ ACTION METHODS ]
    evalForSuccessfulCardSubmission(nextProps: Props): void {
        if (this.props.uiState.stripeModal.isOpen
            && this.props.serverState.processing //Was Processing
            && !nextProps.serverState.processing //Done Processing
            && !nextProps.serverState.error //No Errors
        ) {
            this.props.getSubscriptionTier({});
            this.toggleStripeModalIsOpen();

            Modal.success({
                title: "Success",
                content: "Your Credit Card has been added."
            });
        }
    }

    toggleSuccessModal(): void {
        const isOpen = this.props.uiState.successModalIsOpen;
        this.props.setSuccessModalIsOpen({isOpen: !isOpen});
    }

    onRequestSuccessModalClose(): void {
        this.toggleSuccessModal();
    }

    toggleStripeModalIsOpen(): void {
        const isOpen = this.props.uiState.stripeModal.isOpen;
        this.props.setStripeModalOpen({isOpen: !isOpen});
    }

    setDeleteCardModalState(isOpen: boolean): void {
        this.props.setDeleteCardConfirmIsOpen({isOpen: isOpen});
    }

    //[ RENDERS ]
    render(): JSX.Element {
        //[ PICK COMPONENT TO RENDER]
        let contentComponent: JSX.Element;
        if (this.props.serverState.cardProvided && !this.props.serverState.deleting) {
            contentComponent = (
                <AccountPaymentDetails
                    card={this.props.serverState.creditCard}
                    onUpdateClick={this.toggleStripeModalIsOpen}
                    deleteCardConfirmIsOpen={this.props.uiState.deleteCardConfirmIsOpen}
                    setDeleteCardModalIsOpen={this.setDeleteCardModalState}
                    deleteCard={this.props.deleteCard}
                />
            );
        } else if (!this.props.serverState.cardProvided && !this.props.serverState.deleting) {
            contentComponent = <AccountPaymentSignUp onSignUpClick={this.toggleStripeModalIsOpen}/>;
        } else {
            contentComponent = <LoadingSpinner style={{margin: "8px auto"}}/>;
        }

        //[ RENDER ]
        return (
            <div className={this.props.className}>
                <StripeModal
                    uiState={this.props.uiState.stripeModal}
                    createTokenState={this.props.serverState.createToken}
                    cardDetails={this.props.serverState.creditCard}
                    setIsOpen={this.props.setStripeModalOpen}
                    setCreateTokenState={this.props.setCreateTokenState}
                    addCard={this.props.addCard}
                    processingCard={this.props.serverState.processing}
                    addressError={this.props.serverState.addressError}
                />
                {contentComponent}
                {
                    this.props.restError?.message &&
                    this.props.restError?.status != 404 &&
                    <Alert type="error" message={this.props.restError.message}/>
                }
            </div>
        );
    }
}