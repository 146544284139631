import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {getValue} from "lightrail-client/dist/values";
import {GetValueParams, GetValueResponse} from "lightrail-client/dist/params/values/GetValueParams";
import {mergeListObject, payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export interface GetValueActionParams {
    valueId: string;
    params?: GetValueParams;
}

export const type = "getValue";
export const actionCreator = promisePattern.actionCreator(type, (p: GetValueActionParams) => getValue(p.valueId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetValueResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? mergeListObject(state.server.values.list, payload?.body, "id") : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "fetching"], false,
            ["server", "values", "status"], payload?.status
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);