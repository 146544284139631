import * as React from "react";

export interface Props {
    children: React.ReactNodeArray;
}

interface InternalState {
    error: Error;
}

export class ErrorBoundary extends React.Component<Props, InternalState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: null
        };
    }

    static getDerivedStateFromError(error: Error): InternalState {
        return {error};
    }

    componentDidCatch(error: Error): void {
        console.error(`Error boundary caught error: ${error.message}`);
        this.setState({error});
    }

    render(): JSX.Element {
        if (!!this.state.error) {
            return <h4 className="lr-error-message">Please try reloading the page. If the problem persists, please
                contact us at
                dev@lightrail.com</h4>;
        }

        return <React.Fragment>
            {this.props.children}
        </React.Fragment>;
    }

}