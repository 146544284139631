import * as ReactRedux from "react-redux";
import {Program} from "lightrail-client/dist/model";
import {CreateProgramResponse, UpdateProgramResponse} from "lightrail-client/dist/params";
import {State} from "../../../state/State";
import {actionCreator as createProgram} from "../../../actions/programs/server/createProgram";
import {actionCreator as updateProgram} from "../../../actions/programs/server/updateProgram";
import {CreateProgramForm} from "./CreateProgramForm";
import {ProgramsServerState} from "../../../state/serverState/ProgramsServerState";
import {CurrencyServerState} from "../../../state/serverState/CurrencyServerState";

export interface ExternalProps {
    program?: Program;
    onCancel?: () => void;
    getSubmit?: (submit: () => void) => void;
    onCreateResponse?: (response: CreateProgramResponse) => void;
    onUpdateResponse?: (response: UpdateProgramResponse) => void;
}

interface StateProps {
    programServerState: ProgramsServerState;
    currencyServerState: CurrencyServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    programServerState: state.server.programs,
    currencyServerState: state.server.currency,
    ...props
});

const actionCreators = {
    createProgram,
    updateProgram
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateProgramFormConnected = connector(CreateProgramForm);
