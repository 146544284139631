import * as React from "react";
import {UpdatePasswordForm} from "./UpdatePasswordForm";
import {UpdatePasswordRequest} from "../../../communication/dtos/auth/UpdatePassword";
import {MFA} from "./MFAConnected";
import {Alert, Button, Divider, Modal} from "antd";
import {AccountHeader} from "../../layout/account/AccountHeader";
import {CloseAccount} from "./CloseAccount";
import {ReduxProps} from "./AccountProfilePageConnected";
import {EditAccountFormConnected} from "../../toolbars/accountChange/EditAccountFormConnected";
import {WithRouterProps} from "../../router/WithRouterProps";
import {Paths} from "../../../util/routes/routes";
import {ChangeEmailCompleteConnected} from "./ChangeEmail/ChangeEmailCompleteConnected";
import {AccountLayout} from "../../layout/account/AccountLayout";
import {ChangeEmail, CypressSelectors} from "./ChangeEmail/ChangeEmail";

export interface Props extends WithRouterProps<any>, ReduxProps {
}

interface InternalState {
    editAccountModalVisible: boolean;
    isChangePasswordModalOpen: boolean;
    isChangeEmailModalOpen: boolean;
    passwordChanged: boolean;
    newEmailAddress: string;
    showEmailChangedModal: boolean;
}

export class AccountProfilePage extends React.PureComponent<Props, InternalState> {
    submitUpdatePassword: () => void;
    submitChangeEmail: () => void;
    submitEditAccount: () => void;

    constructor(props: Props) {
        super(props);

        this.state = {
            editAccountModalVisible: false,
            isChangeEmailModalOpen: false,
            isChangePasswordModalOpen: false,
            passwordChanged: false,
            newEmailAddress: "",
            showEmailChangedModal: false
        };

        this.onPasswordUpdated = this.onPasswordUpdated.bind(this);
        this.onSubmitUpdatePasswordForm = this.onSubmitUpdatePasswordForm.bind(this);
        this.onSubmitChangeEmail = this.onSubmitChangeEmail.bind(this);
        this.onSubmitEditAccount = this.onSubmitEditAccount.bind(this);
        this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
        this.closeChangePasswordModal = this.closeChangePasswordModal.bind(this);
        this.updatePassword = this.updatePassword.bind(this);

        this.getSubmitPasswordCB = this.getSubmitPasswordCB.bind(this);
        this.getSubmitChangeEmail = this.getSubmitChangeEmail.bind(this);
        this.getSubmitEditAccount = this.getSubmitEditAccount.bind(this);
        this.setNewEmail = this.setNewEmail.bind(this);
        this.onCloseEmailChangedAlert = this.onCloseEmailChangedAlert.bind(this);
        this.isUserTeamAdmin = this.isUserTeamAdmin.bind(this);

        this.openAccountEditModal = this.openAccountEditModal.bind(this);
        this.closeAccountEditModal = this.closeAccountEditModal.bind(this);
        this.openChangeEmailModal = this.openChangeEmailModal.bind(this);
        this.closeChangeEmailModal = this.closeChangeEmailModal.bind(this);
    }

    componentDidMount(): void {
        this.props.getJwt({});
    }

    ///////////////////
    // [ ACTIONS ]
    onPasswordUpdated(): void {
        if (!this.props.auth.errorMessage || !this.props.auth.errorMessage?.length) {
            // if (!!this.props.auth.errorMessage && !!this.props.auth.errorMessage.length) {
            this.props.resetAuthState({});
        }
    }

    openChangePasswordModal(): void {
        this.setState({isChangePasswordModalOpen: true});
    }

    closeChangePasswordModal(): void {
        this.setState({isChangePasswordModalOpen: false});
        this.props.resetAuthState({});
    }

    async updatePassword(params: UpdatePasswordRequest): Promise<void> {
        await this.props.updatePassword(params);
        this.closeChangePasswordModal();
        this.setState({passwordChanged: true});
    }

    onSubmitUpdatePasswordForm(): void {
        if (this.submitUpdatePassword) {
            this.submitUpdatePassword();
        }
    }

    onSubmitChangeEmail(): void {
        if (this.submitChangeEmail) {
            this.submitChangeEmail();
        }
        this.setState({
            isChangeEmailModalOpen: false
        });
    }

    onCloseEmailChangedAlert(): void {
        this.setState({newEmailAddress: ""});
    }

    onSubmitEditAccount(): void {
        this.submitEditAccount();
        this.closeAccountEditModal();
    }

    setNewEmail(newEmail: string): void {
        this.setState({newEmailAddress: newEmail});
    }

    getSubmitPasswordCB(submitCall: () => void): void {
        this.submitUpdatePassword = submitCall;
    }

    getSubmitChangeEmail(submit: () => void): void {
        this.submitChangeEmail = submit;
    }

    getSubmitEditAccount(submit: () => void): void {
        this.submitEditAccount = submit;
    }

    isUserTeamAdmin(): boolean {
        return !!this.props.teamRoles?.find(role => role == "teamAdmin");
    }

    openAccountEditModal(): void {
        this.setState({editAccountModalVisible: true});
    }

    closeAccountEditModal(): void {
        this.setState({editAccountModalVisible: false});
    }

    openChangeEmailModal(): void {
        this.setState({isChangeEmailModalOpen: true});
    }

    closeChangeEmailModal(): void {
        this.setState({isChangeEmailModalOpen: false});
    }

    closePasswordChangedAlert(): void {
        this.setState({passwordChanged: false});
    }

    showEmailChangeComplete(): JSX.Element {
        if (this.props.location.pathname == Paths.CHANGE_EMAIL_COMPLETED) {
            return (<ChangeEmailCompleteConnected/>);
        }
        return null;
    }

    ///////////////////
    // [ RENDER ]
    render(): JSX.Element {
        return (<AccountLayout>
            <div>
                <AccountHeader title="Profile"/>
                <div>
                    <h4 className="lr-inline-block marginRight8">Username</h4>
                    <p className="lr-inline-block">{this.props.user.email}</p>
                </div>
                <div className="marginTop8">
                    <h4 className="lr-inline-block marginRight8">
                        <a onClick={this.openChangePasswordModal}>
                            Change Password
                        </a>
                    </h4>
                </div>
                <div className="marginTop16">
                    <h4 className="lr-inline-block marginRight8">
                        <a onClick={this.openChangeEmailModal}>
                            Change Email
                        </a>
                    </h4>
                </div>
            </div>
            <Modal
                title="Change Password"
                visible={this.state.isChangePasswordModalOpen}
                okText="Submit"
                confirmLoading={this.props.auth.processing}
                onOk={this.onSubmitUpdatePasswordForm}
                onCancel={this.closeChangePasswordModal}
            >
                <UpdatePasswordForm
                    onUpdate={this.onPasswordUpdated}
                    errors={this.props.auth.errorMessage}
                    getSubmit={this.getSubmitPasswordCB}
                    onSubmit={this.updatePassword}
                />
            </Modal>

            {
                this.state.passwordChanged &&
                <Alert
                    type="success"
                    closable
                    onClose={this.closeChangePasswordModal}
                    message={`Your password has been changed`}
                    className="marginTop8"
                />
            }
            <Modal
                title="New Email Address"
                visible={this.state.isChangeEmailModalOpen}
                okText="Change Email"
                cancelText="Close"
                onOk={this.onSubmitChangeEmail}
                onCancel={this.closeChangeEmailModal}
                okButtonProps={{"id": CypressSelectors.ChangeEmailFormSubmit}}
            >
                <ChangeEmail
                    closeModal={this.closeChangeEmailModal}
                    getSubmit={this.getSubmitChangeEmail}
                    setNewEmail={this.setNewEmail}
                />
            </Modal>
            {
                this.state.newEmailAddress &&
                <Alert
                    type="success"
                    closable
                    onClose={this.onCloseEmailChangedAlert}
                    message={`An email has been sent to ${this.state.newEmailAddress} if it's not already in use.`}
                    className="marginTop8"
                />
            }
            {
                this.isUserTeamAdmin() &&
                <CloseAccount
                    userEmail={this.props.userEmail}
                    userId={this.props.userId}
                    contactCustomerSupport={this.props.contactCustomerSupport}
                    className="marginTop16"
                />
            }

            <Divider/>
            <AccountHeader title="Accounts"/>
            <div>
                <p className="lr-inline-block  marginRight16">{this.props.currentAccount?.name}</p>
                <Button
                    onClick={this.openAccountEditModal}
                    className="lr-inline-block"
                >
                    Edit Account Options
                </Button>
            </div>
            <Modal
                visible={this.state.editAccountModalVisible}
                title={"Change Account Settings"}
                onOk={this.onSubmitEditAccount}
                onCancel={this.closeAccountEditModal}
                okText={"Change"}
            >
                <EditAccountFormConnected
                    onSubmit={this.onSubmitEditAccount}
                    getSubmit={this.getSubmitEditAccount}
                />
            </Modal>

            {this.showEmailChangeComplete()}

            <Divider/>
            <div>
                <AccountHeader title="Multi Factor Authentication"/>
                <MFA/>
            </div>
        </AccountLayout>);
    }
}