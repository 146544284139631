import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
}

export const type = "goToMFADeviceState";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = () => ({
    type,
    payload: {}
});

export const reducer: Reducer<ActionParams> = (state) => {
    return sprout.assoc(state,
        ["ui", "MFA", "phoneNumberSuccess"], false
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);