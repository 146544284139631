import * as React from "react";
import {notification} from "antd";
import {Issuance} from "lightrail-client/dist/model/Issuance";
import {Program} from "lightrail-client/dist/model";
import {IssuanceDownloadButton} from "./IssuanceDownloadButton";
import {getIssuance} from "lightrail-client/dist/programs";
import {ReduxProps} from "./IssuanceCreationWatcherConnected";

export interface Props extends ReduxProps {
}

export class IssuanceCreationWatcher extends React.PureComponent<Props, {}> {
    timer: any;

    constructor(props: Props) {
        super(props);

        this.triggerNotification = this.triggerNotification.bind(this);
    }

    componentDidUpdate(oldProps: Props): void {
        if (this.props.pollingList.length > 0 && this.timer == null) {
            this.timer = setInterval(() => this.pollForIssuances(), 5000);
        } else if (this.props.pollingList.length == 0 && this.timer != null) {
            this.stopIssuancePolling();
        }

        if (oldProps.notificationList !== this.props.notificationList && this.props.notificationList.length) {
            //Trigger a notification for every new Issuance
            this.props.notificationList.forEach(async (issuance) => {
                const program = this.props.programs.find(p => p.id === issuance.programId);

                if (!!program) {
                    this.triggerNotification(issuance, program);
                } else {
                    const response = await this.props.getProgram(issuance.programId).value;
                    this.triggerNotification(issuance, response.body);
                }
            });

            //Clear list
            this.props.setIssuanceCreationState({
                merge: {
                    createdNotificationList: [],
                    pollingList: oldProps.pollingList
                }
            });
        }
    }

    triggerNotification(issuance: Issuance, program: Program): void {
        notification.success({
            message: "Your codes are ready!",
            description: <div>
                <p>{`${issuance.count} codes from ${program.name} are ready for download in the program's Issued Codes tab.`}</p>
                <div style={{textAlign: "right"}}>
                    <IssuanceDownloadButton
                        text="Download"
                        buttonProps={{type: "primary", size: "small"}}
                        listValuesCSV={this.props.listValuesCSV}
                        issuance={issuance}
                    />
                </div>
            </div>
        });
    }

    render(): JSX.Element {
        return null;
    }

    componentWillUnmount(): void {
        this.stopIssuancePolling();
    }

    stopIssuancePolling(): void {
        clearInterval(this.timer);
        this.timer = null;
    }

    pollForIssuances(): void {
        const now = new Date();
        for (const issuance of this.props.pollingList) {
            if ((now.getTime() - issuance.requestDate.getTime()) > 5 * 60 * 1000 /* 5 minutes */) {
                // Issuance creation failed. Remove from list so that app stops polling for it.
                this.removeIssuanceFromPollingList(issuance.issuanceId);
            }
            getIssuance(issuance.programId, issuance.issuanceId).then(r => {
                if (r.status !== 404) {
                    this.removeIssuanceFromPollingList(issuance.issuanceId);
                    this.props.setIssuanceCreationState({merge: {createdNotificationList: [r.body]}});
                }
            });
        }
    }

    removeIssuanceFromPollingList(issuanceId: string): void {
        this.props.setIssuanceCreationState({merge: {pollingList: this.props.pollingList.filter(iss => iss.issuanceId != issuanceId)}});
    }
}