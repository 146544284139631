import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";

import {actionCreator as getContacts} from "../../../actions/contacts/server/getAndAppendContacts";
import {actionCreator as clearContacts} from "../../../actions/contacts/server/clearContacts";
import {ContactsList} from "./ContactsList";
import {withRouter} from "react-router-dom";
import {ListContactsParams} from "lightrail-client/dist/params";
import {ContactMenuContext} from "../contactActions/ContactActionMenu";
import {ContactsServerState} from "../../../state/serverState/ContactsServerState";

export interface ExternalProps {
    params?: ListContactsParams;
    highlightNewRows?: boolean;
    menuContext?: ContactMenuContext;
}

interface StateProps {
    contactsState: ContactsServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    contactsState: state.server.contacts,
    ...props
});

const actionCreators = {
    getContacts,
    clearContacts
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ContactsListConnected = connector(withRouter(ContactsList));