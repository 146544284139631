import * as React from "react";
import * as CopyToClipboard from "copy-to-clipboard";
import {Button, message, Modal, Spin} from "antd";
import {stopPropagation} from "../../../../util/link";
import {CopyOutlined} from "@ant-design/icons";

export interface Props {
    secret: string;
    open: boolean;
    processingSecret: boolean;

    onClose: () => void;
}

export class ShowSecretModal extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
    }

    onCopyToClipboard(e?: any): void {
        stopPropagation(e);

        CopyToClipboard(this.props.secret);
        message.success("Secret copied to Clipboard");
    }

    render(): JSX.Element {
        return (
            <Modal
                title="Secret"
                visible={this.props.open}
                onCancel={this.props.onClose}
                footer={[
                    <Button key="back" onClick={this.props.onClose}>Close</Button>,
                    <Button key="submit" type="primary"
                            onClick={this.onCopyToClipboard}
                    >
                        <CopyOutlined/> Copy to clipboard
                    </Button>
                ]}
                confirmLoading={this.props.processingSecret}
            >
                <div className="textCenter">
                    {
                        (!!this.props.processingSecret)
                            ?
                            <Spin className="lr-modal-spinner"/>
                            :
                            <div className="codeDisplay fullCode">
                                {this.props.secret}
                            </div>
                    }
                </div>
            </Modal>
        );
    }
}