import * as ReactRedux from "react-redux";
import {App} from "./App";
import {State} from "../state/State";

import {actionCreator as addLocationToHistory} from "../actions/history/ui/addLocationToHistory";
import {actionCreator as setUIState} from "../actions/uiAction";
import {actionCreator as ping} from "../actions/auth/server/ping";

interface StateProps {
    state: State;
}

const mapStateToProps = (state: State): StateProps => ({
    state: state
});

const actionCreators = {
    ping,
    setUIState,
    addLocationToHistory
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

const AppConnected = connector(App);

export {AppConnected as App};
