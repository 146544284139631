import * as promisePattern from "../../../promisePattern";
import * as sprout from "sprout-data";
import * as MFA from "../../../../communication/MFA";
import {AuthMFAResponse} from "../../../../communication/dtos/auth/MFA/MFA";
import {rejectionHandler, RejectionReducer} from "../../../rejectionHandler";
import {UserModes} from "../../../../communication/dtos/auth/PingResponse";
import {redirectOnMessageCode} from "../../../../util/routes/redirectOnMessageCode";

export const type = "authMFA";

export const actionCreator = promisePattern.actionCreator(type, MFA.authMFA);

const onFulfilled: promisePattern.PromiseFulfilledHandler<AuthMFAResponse> =
    (state, payload) => {
        const hasMFAEnabled = !!payload?.user.mfa;
        const testing = payload?.user.mode === UserModes.TEST;
        const testingSnackbarMessage = testing ? "You’re in your Test Environment" : "Your account is live";
        const accountMessage = {
            message: payload?.message,
            messageCode: payload?.messageCode
        };

        const user = sprout.assoc(state.server.user,
            ["testing"], testing,
            ["loggedIn"], true,
            ["additionalAuthenticationRequired"], payload.user.additionalAuthenticationRequired
        );

        return sprout.assoc(state,
            ["server", "user", "email"], payload?.user.email,
            ["server", "user"], user,
            ["server", "MFA", "enabled"], hasMFAEnabled,
            ["server", "MFA", "processing"], false,
            ["server", "account", "authMessage"], accountMessage,
            ["ui", "snackbar", "notifications"], [{message: testingSnackbarMessage}],
            ["ui", "history"], redirectOnMessageCode(state.ui.history, accountMessage.messageCode)
        );
    };

const onRejectionHandler: RejectionReducer = {
    reducer: (state: any, error: any) => {
        return sprout.assoc(state,
            ["server", "restError", "message"], error.message,
            ["server", "restError", "status"], error.status,
            ["server", "MFA", "processing"], false
        );
    },
    overrideGlobal: true,
    status: 401
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onRejectionHandler]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc(state,
        ["server", "MFA", "processing"], true,
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);