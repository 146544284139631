import * as React from "react";
import {Button, Modal} from "antd";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {Paths} from "../../../../util/routes/routes";
import {ReduxProps} from "./ChangeEmailCompleteConnected";

export interface Props extends WithRouterProps<any>, ReduxProps {
}

export class ChangeEmailComplete extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onContinue = this.onContinue.bind(this);
    }

    async onContinue(): Promise<void> {
        try {
            const response = await this.props.getUser({});
            if (response?.value?.hasMfa) {
                this.props.history.push(Paths.LOGIN_ROOT);
                return;
            }
        } catch (err) {
            this.props.history.push(Paths.LOGIN_ROOT);
        }

        this.props.history.push(Paths.ROOT);
    }

    render(): JSX.Element {
        return (
            <Modal
                title="Email Changed Successfully"
                closable={false}
                visible={true}
                footer={[
                    <Button
                        key="change-email-complete-button"
                        onClick={this.onContinue}
                        type="primary"
                    >
                        Continue
                    </Button>
                ]}
            >
                The email associated with your account has been successfully
                changed{this.props.email ? ` to ${this.props.email}` : ""}.
            </Modal>
        );
    }

}