import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {listValues} from "lightrail-client/dist/values";
import {ListValuesParams, ListValuesResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getValues";
export const actionCreator = promisePattern.actionCreator(type, (params: ListValuesParams) => listValues(params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListValuesResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? payload?.body : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "pagination"], {limit: payload?.limit, maxLimit: payload?.maxLimit},
            ["server", "values", "fetching"], false,
            ["server", "values", "links"], payload?.links
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(onPendingFetching(state, ["server", "values"]),
        ["server", "values", "list"], []
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);