import * as React from "react";
import {Subheading} from "../ui/typography/TypographyTags";
import {FullAccessRoles, LimitedAccessRoles, Role, User, UserPrivilege} from "../../communication/dtos/account/User";
import {TeamInviteMemberForm} from "./creation/TeamInviteMemberForm";
import {Modal} from "antd";

export interface Props {
    open: boolean;
    accountUser: User;
    processing: boolean;

    onUpdate: (userId: string, roles: Role[]) => void;
    onRequestClose: () => void;
}

export class UpdateAccountUserDialog extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);

        this.setSubmitForm = this.setSubmitForm.bind(this);
        this.onUpdateAction = this.onUpdateAction.bind(this);
        this.onUpdateTeamMember = this.onUpdateTeamMember.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
    }

    setSubmitForm(submit: () => void): void {
        this.submitForm = submit;
    }

    onUpdateTeamMember(values: { email: string, userPrivilegeType: UserPrivilege }): void {
        const roles = (values.userPrivilegeType.toString() === "FULL_ACCESS") ? FullAccessRoles : LimitedAccessRoles;
        this.props.onUpdate(this.props.accountUser.userId, roles);
    }

    onUpdateAction(): void {
        if (!!this.submitForm) {
            this.submitForm();
        }
    }

    onRequestClose(): void {
        this.props.onRequestClose();
    }

    render(): JSX.Element {
        if (!this.props.accountUser) {
            return null;
        }

        return (
            <Modal
                title={`Update ${this.props.accountUser.userDisplayName}`}
                visible={this.props.open}
                cancelText="Close"
                onCancel={this.props.onRequestClose}
                okText="Update"
                onOk={this.onUpdateAction}
                confirmLoading={this.props.processing}
            >
                <div className="marginBottom16">
                    <Subheading>Set {this.props.accountUser.userDisplayName}&apos;s Permissions</Subheading>
                </div>
                <TeamInviteMemberForm
                    getSubmit={this.setSubmitForm}
                    updateMember={true}
                    initialValues={{
                        email: this.props.accountUser.userDisplayName,
                        userPrivilegeType: this.props.accountUser.userPrivilegeType
                    }}
                    onSubmit={this.onUpdateTeamMember}
                />
            </Modal>
        );
    }
}