import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {CreateWebhookForm} from "./CreateWebhookForm";
import {actionCreator as createWebhook} from "../../../../actions/webhooks/server/createWebhook";
import {Webhook} from "../../../../communication/dtos/webhooks/Webhook";
import {WebhookServerState} from "../../../../state/serverState/WebhookServerState";

export interface ExternalProps {
    webhook?: Webhook;
    getSubmit?: (submit: () => void) => void;
    onCreateResponse?: (response: Webhook) => void;
}

interface StateProps {
    webhookServerState: WebhookServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    webhookServerState: state.server.webhook,
    ...props
});

const actionCreators = {
    createWebhook
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateWebhookFormConnected = connector(CreateWebhookForm);