import * as ReactRedux from "react-redux";
import {State} from "../../state/State";
import {ReportsPage} from "./ReportsPage";
import {actionCreator as generateReport} from "../../actions/generateReport/server/generateReport";
import {actionCreator as listPrograms} from "../../actions/programs/server/getPrograms";
import {ProgramsServerState} from "../../state/serverState/ProgramsServerState";
import {GenerateReportServerState} from "../../state/serverState/GenerateReportServerState";

interface StateProps {
    programServerState: ProgramsServerState;
    generateReportState: GenerateReportServerState;
}

const mapStateToProps = (state: State): StateProps => ({
    programServerState: state.server.programs,
    generateReportState: state.server.generateReport
});

const actionCreators = {
    listPrograms,
    generateReport
};

const connector = ReactRedux.connect(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ReportsPageConnected = connector(ReportsPage);
