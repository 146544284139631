import {DiscountSellerLiabilityRule} from "lightrail-client/dist/model";

export enum StandardSellerLiabilityEnum {
    SELLER_0_LIABLE,
    SELLER_100_LIABLE,
    SELLER_SHARED,
    CUSTOM
}

export const seller0Liable: DiscountSellerLiabilityRule = {
    explanation: "Seller 0% liable",
    rule: "0"
};

export const seller100Liable: DiscountSellerLiabilityRule = {
    explanation: "Seller 100% liable",
    rule: "1"
};

export const sellerShared: DiscountSellerLiabilityRule = {
    explanation: "Based on marketplace rate",
    rule: "1-lineItems.marketplaceRate"
};

export const sellerCustom: DiscountSellerLiabilityRule = {
    explanation: "",
    rule: ""
};

function doDiscountSellerRulesEqual(rule1: DiscountSellerLiabilityRule, rule2: DiscountSellerLiabilityRule): boolean {
    if (!rule1) {
        return false;
    }
    return rule1.explanation === rule2.explanation && rule1.rule === rule2.rule;
}

export function getDiscountSellerLiabilityType(rule: DiscountSellerLiabilityRule | null): StandardSellerLiabilityEnum {
    if (!!rule) {
        if (doDiscountSellerRulesEqual(rule, seller0Liable)) {
            return StandardSellerLiabilityEnum.SELLER_0_LIABLE;
        } else if (doDiscountSellerRulesEqual(rule, seller100Liable)) {
            return StandardSellerLiabilityEnum.SELLER_100_LIABLE;
        } else if (doDiscountSellerRulesEqual(rule, sellerShared)) {
            return StandardSellerLiabilityEnum.SELLER_SHARED;
        }
    }
    return StandardSellerLiabilityEnum.CUSTOM;
}