import * as React from "react";
import {Checkbox, Form, Input} from "antd";
import {NumbersCommasSpacesOnlyRegExp} from "../../../../util/regexp";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {FormInstance} from "antd/es/form";

interface State {
    includeUsesRemaining: boolean;
    usesRemaining: number | number[];
}

export interface Props {
    fieldId: string;
    className?: string;
    extraText?: string;
    fixedUsesRemaining?: number | number[];
    isCheckedByDefault: boolean;
    form: FormInstance;
}

export class FixedInitialUsesRemainingItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            includeUsesRemaining: this.props.isCheckedByDefault,
            usesRemaining: !!this.props.fixedUsesRemaining ? this.props.fixedUsesRemaining : 1
        };
        this.onLimitUsesCheckboxChange = this.onLimitUsesCheckboxChange.bind(this);
    }

    onLimitUsesCheckboxChange(): void {
        // state is changing so do opposite of this.state.includeUsesRemaining
        this.props.form.setFieldsValue({[this.props.fieldId]: !this.state.includeUsesRemaining ? this.state.usesRemaining : null});
        this.setState({includeUsesRemaining: !this.state.includeUsesRemaining});
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.isCheckedByDefault != this.props.isCheckedByDefault) {
            this.props.form.setFieldsValue({[this.props.fieldId]: this.props.isCheckedByDefault ? this.state.usesRemaining : null});
            this.setState({includeUsesRemaining: this.props.isCheckedByDefault});
        }
    }

    render(): JSX.Element {
        const className: string = this.props.className;
        const includeUsesRemaining: boolean = this.state.includeUsesRemaining;
        const initialValueInput: number | number[] = !!this.props.fixedUsesRemaining ? this.props.fixedUsesRemaining : 1;
        return (
            <div>
                <p>
                    <Form.Item
                        name={FormFieldIds.LimitUsesRemaining}
                        initialValue={includeUsesRemaining}
                    >
                        <Checkbox
                            checked={includeUsesRemaining}
                            onChange={this.onLimitUsesCheckboxChange}>
                            Limit Uses
                        </Checkbox>
                    </Form.Item>
                </p>
                <Form.Item
                    className={className ? `${className} lr-inset-sub-item` : "lr-inset-sub-item"}
                    label=""
                    extra="The number of times each value can be used. If you’ll be creating a generic code from this Program, uses will apply on a per-contact basis."
                    style={{
                        display: includeUsesRemaining ? "block" : "none"
                    }}
                    name={this.props.fieldId}
                    initialValue={!!this.props.isCheckedByDefault ? initialValueInput : null}
                    rules={[
                        {
                            pattern: NumbersCommasSpacesOnlyRegExp,
                            message: "Must contain only numbers, commas and spaces"
                        }
                    ]}
                >
                    <Input
                        size="large"
                        style={{width: "100%", maxWidth: "100px"}}
                    />
                </Form.Item>
            </div>
        );
    }
}