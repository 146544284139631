import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {WebhookPage} from "./WebhookPage";
import {actionCreator as listWebhooks} from "../../../actions/webhooks/server/listWebhooks";
import {actionCreator as clearWebhooksList} from "../../../actions/webhooks/server/clearWebhooksList";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {WebhookServerState} from "../../../state/serverState/WebhookServerState";
import {WebhookUiState} from "../../../state/uistate/WebhookUiState";

interface StateProps {
    webhookServerState: WebhookServerState;
    webhookUiState: WebhookUiState;
}

const mapStateToProps = (state: State): StateProps => ({
    webhookServerState: state.server.webhook,
    webhookUiState: state.ui.webhook
});

const actionCreators = {
    listWebhooks,
    clearWebhooksList,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const WebhookPageConnected = connector(WebhookPage);