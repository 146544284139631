import React = require("react");
import {useEffect} from "react";
import {Form} from "antd";
import {useForm} from "antd/lib/form/Form";
import {ExternalProps, ReduxProps} from "./ContactCreateAndEditFormConnected";
import {
    CreateContactParams,
    UpdateContactParams,
} from "lightrail-client/dist/params";
import Input from "antd/es/input/Input";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {Alert, Collapse, message} from "antd";
import {MetadataItems} from "../../ui/forms/items/MetadataItems";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {generateId} from "../../../communication/utils/urlUtils";
import {formatParamsError} from "../../../util/stringUtils";
import {EmailItem} from "../../ui/forms/items/EmailItem";
import {cleanNulls} from "../../../util/communicationUtils";

export interface InternalProps extends ExternalProps, ReduxProps {
}

export const ContactCreateAndEditForm = (props: InternalProps): JSX.Element => {
    const [form] = useForm();

    const [suggestedId] = React.useState(generateId());

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        const editValues: CreateContactParams | UpdateContactParams = {};

        editValues.email = values[FormFieldIds.Email] || null;
        editValues.firstName = values[FormFieldIds.FirstName] || null;
        editValues.lastName = values[FormFieldIds.LastName] || null;
        const metadataFromForm = MetadataItems.parseMetadataFromSubmitValues(values);
        if (metadataFromForm != null) {
            editValues.metadata = metadataFromForm;
        }
        if (!props.contact) {
            editValues.id = values[FormFieldIds.Id];
        }

        if (!!props.contact) {
            await props.updateContact({
                contactId: props.contact.id,
                params: editValues
            });

            message.success("Contact Updated");
            props.onClose();
        } else {
            try {
                props.createContact(
                    cleanNulls(editValues) as CreateContactParams
                );

                message.success("Contact Created");
                props.onClose();
            } catch (error) {
                if (error && error.message) {
                    message.error(error.message);
                }
            }
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    useEffect(() => () => props.clearContactError({contactId: props.contact?.id}));

    return (
        <div className="lr-create-form-padding">
            <Form
                onFinish={submit}
                form={form}
                className="lr-create-form"
                hideRequiredMark
                layout="vertical"
            >
                {
                    (!!props.contact) ?
                        <p>Contact ID: {props.contact?.id}</p> :
                        <Form.Item
                            label="ID"
                            name={FormFieldIds.Id}
                            initialValue={suggestedId}
                            rules={[{required: true}]}
                        >
                            <Input size="large"/>
                        </Form.Item>
                }

                <Form.Item
                    label="E-mail"
                >
                    <EmailItem
                        size="large"
                        form={form}
                        initialValue={(!!props.contact) ? props.contact.email : ""}
                        required={false}
                    />
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name={FormFieldIds.FirstName}
                    initialValue={props.contact?.firstName}
                >
                    <Input size="large"/>
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name={FormFieldIds.LastName}
                    initialValue={props.contact?.lastName}
                >
                    <Input size="large"/>
                </Form.Item>
                <Form.Item>
                    <Collapse bordered={false}>
                        <Collapse.Panel key="metadata" header={<h3>Metadata</h3>}>
                            <MetadataItems
                                metadata={(!!props.contact) ? props.contact.metadata : null}
                                form={form}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </Form.Item>
                <Form.Item>
                    {
                        (!!props.contactStateServer.error) &&
                        <Alert
                            message={`Whoops`}
                            description={formatParamsError(props.contactStateServer.error)}
                            type="error"
                            showIcon
                            closable={false}
                        />
                    }
                </Form.Item>
                <AllowSubmitOnEnter/>
            </Form>
        </div>
    );
};