import * as React from "react";
import {ReactNode} from "react";
import * as moment from "moment";
import {DatePicker, Form, Popover} from "antd";
import {DatePickerProps} from "antd/lib/date-picker";
import {FormInstance} from "antd/es/form";
import {InfoCircleOutlined} from "@ant-design/icons";
import {SizeType} from "antd/es/config-provider/SizeContext";

export interface Props {
    fieldId: string;
    label: string;
    initialValue?: string; //ISO Date String
    datePickerProps?: DatePickerProps;
    form: FormInstance;
}

export class DateTimePicker extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {
        const {setFieldsValue, getFieldValue} = this.props.form;

        if (getFieldValue(this.props.fieldId)?.toISOString() != this.props.initialValue) {
            setFieldsValue({[this.props.fieldId]: this.props.initialValue});
        }
    }

    componentDidUpdate(oldProps: Props): void {
        const {setFieldsValue} = this.props.form;

        if (oldProps.initialValue != this.props.initialValue) {
            setFieldsValue({[this.props.fieldId]: moment(this.props.initialValue)});
        }
    }

    renderExtra(): ReactNode {
        return (
            <p>
                <span className={"caption"} style={{fontSize: 14}}>Time shown in UTC</span>
                <Popover
                    title="UTC (Coordinated Universal Time)" content={(<p style={{maxWidth: 260}}>
                    UTC is the time standard commonly used across the world. The world`&apos;s timing centers have agreed
                    to keep their time scales closely synchronized - or coordinated - therefore the name Coordinated
                    Universal Time.
                </p>)}
                >
                    <InfoCircleOutlined style={{fontSize: 14, marginLeft: 4}}/>
                </Popover>
            </p>
        );
    }

    render(): JSX.Element {
        const initialValue = this.props.initialValue || new Date().toISOString();
        const pickerProps = {showTime: true, size: "large" as SizeType, ...this.props.datePickerProps};

        return (
                <Form.Item
                    label={this.props.label}
                    extra={this.renderExtra()}
                    name={this.props.fieldId}
                    initialValue={moment(initialValue)}
                >
                    <DatePicker
                        {...pickerProps}
                    />
                </Form.Item>
        );
    }
}