import * as React from "react";
import {FormattedCurrency} from "../ui/currency/FormattedCurrencyConnected";
import {Transaction} from "lightrail-client/dist/model";
import {defaultDash} from "../../util/stringUtils";
import {
    getBalanceChangeForValue,
    getTotalLightrailBalanceChange,
    getTotalLightrailUsesChange,
    getUsesChangeForValue
} from "../../util/transactionUtils";
import {Spin} from "antd";

interface Props {
    transaction: Transaction;
    valueId?: string;
}

export class TransactionAmountDisplay extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    renderUsesChange(usesChange: number): JSX.Element {
        return (
            <div>
                <b>
                    {
                        (usesChange > 0) ? "+" : ""
                    }
                </b>
                {
                    usesChange
                }
                <span className="lr-title-postfix">Uses</span>
            </div>
        );
    }

    renderBalanceChange(balanceChange: number, currency: string): JSX.Element {
        return (
            <div>
                <b>
                    {
                        (balanceChange > 0) ? "+" : ""
                    }
                    <FormattedCurrency
                        value={balanceChange}
                        code={currency}
                    />
                </b>
                <span className="lr-title-postfix">{currency}</span>
            </div>
        );
    }

    render(): JSX.Element {
        if (!this.props.transaction) {
            return <Spin size={"small"}/>;
        }

        const balanceChange = (this.props.valueId) ? getBalanceChangeForValue(this.props.transaction, this.props.valueId) : getTotalLightrailBalanceChange(this.props.transaction);
        const usesChange = (this.props.valueId) ? getUsesChangeForValue(this.props.transaction, this.props.valueId) : getTotalLightrailUsesChange(this.props.transaction);

        if (!balanceChange && !usesChange) {
            return <span>{defaultDash}</span>;
        }
        return (!!balanceChange) ? this.renderBalanceChange(balanceChange, this.props.transaction.currency) : this.renderUsesChange(usesChange);
    }
}