import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ProgramsList} from "./ProgramsList";
import {actionCreator as getAllPrograms} from "../../../actions/programs/server/getAndAppendPrograms";
import {actionCreator as getActivePrograms} from "../../../actions/programs/server/getAndAppendActivePrograms";
import {actionCreator as getInactivePrograms} from "../../../actions/programs/server/getAndAppendInactivePrograms";
import {actionCreator as clearAllPrograms} from "../../../actions/programs/server/clearAllProgramsList";
import {actionCreator as clearActivePrograms} from "../../../actions/programs/server/clearActiveProgramsList";
import {actionCreator as clearInactivePrograms} from "../../../actions/programs/server/clearInactiveProgramsList";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {withRouter} from "react-router-dom";
import {ProgramsServerState} from "../../../state/serverState/ProgramsServerState";
import {ProgramUiState} from "../../../state/uistate/ProgramUiState";
import {CurrencyServerState} from "../../../state/serverState/CurrencyServerState";

interface StateProps {
    programServerState: ProgramsServerState;
    programUiState: ProgramUiState;
    currencyServerState: CurrencyServerState;
}

const mapStateToProps = (state: State): StateProps => ({
    programServerState: state.server.programs,
    programUiState: state.ui.programs,
    currencyServerState: state.server.currency
});

const actionCreators = {
    getAllPrograms,
    getActivePrograms,
    getInactivePrograms,
    clearAllPrograms,
    clearActivePrograms,
    clearInactivePrograms,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ProgramsListConnected = connector(withRouter(ProgramsList));