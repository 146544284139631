import * as React from "react";
import {Button, Checkbox, Col, Form, Radio, Row, TreeSelect} from "antd";
import {FormFieldIds} from "../../util/forms/AntFormHelpers";
import * as moment from "moment";
import {ProgramsServerState} from "../../state/serverState/ProgramsServerState";
import {GenerateReportResponse} from "../../communication/dtos/reports/GenerateReportRequest";
import {DateTimePicker} from "../ui/forms/items/DateTimePicker";
import {getTransactionTypeLabel, reports, ReportType, TransactionType} from "./ReportsUtils";
import {dateIsInFuture, dateIsInPast} from "../../util/dateUtils";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import {useForm} from "antd/lib/form/Form";
import {RadioChangeEvent} from "antd/es/radio";
import {cleanNulls} from "../../util/communicationUtils";
import {DataNode} from "antd/lib/tree";
import {AllowSubmitOnEnter} from "../ui/forms/AllowSubmitOnEnter";

export interface SubmitValues {
    reportType: string;
    programIds: string[];
    transactionType?: string[];
    utcISOStartDate: string;
    utcISOEndDate: string;
    showCode?: boolean;
}

export interface Props {
    onSubmit: (value: SubmitValues) => void;
    loading: boolean;
    getSubmit?: (submit: () => void) => void;
    onGenerateReportResponse?: (response: GenerateReportResponse) => void;
    programServerState: ProgramsServerState;
}

export const ReportsForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [reportType, setReportType] = React.useState(ReportType.TRANSACTION);

    const validateProgramMax = (rule: any, value: any, callback: any): void => {
        const MAX_COUNT = 60;
        const programIdList = form.getFieldValue(FormFieldIds.Program);
        const listActive = props.programServerState.allList.filter(p => dateIsInFuture(p.endDate));
        const listInactive = props.programServerState.allList.filter(p => dateIsInPast(p.endDate));
        const callbackError = `You must select ${MAX_COUNT} Programs or less. You can select all by selecting both "Active" and "Inactive"`;
        let allActiveProgramsSelected: boolean;
        let allInactiveProgramsSelected: boolean;

        for (let i = 0; i < programIdList.length; i++) {
            if (programIdList[i] === "Active") {
                allActiveProgramsSelected = true;
            } else if (programIdList[i] === "Inactive") {
                allInactiveProgramsSelected = true;
            }
        }

        if (programIdList.length > MAX_COUNT) {
            callback(
                <div>{callbackError}</div>);
        } else if (allActiveProgramsSelected && allInactiveProgramsSelected) {
            callback();
        } else if (allActiveProgramsSelected) {
            if (listActive.length + (programIdList.length - 1) > MAX_COUNT) {
                callback(<div>{callbackError}</div>);
            } else {
                callback();
            }
        } else if (allInactiveProgramsSelected) {
            if (listInactive.length + (programIdList.length - 1) > MAX_COUNT) {
                callback(<div>{callbackError}</div>);
            } else {
                callback();
            }
        } else {
            callback();
        }
    };

    const disabledDate = (current: moment.Moment): boolean => {
        return current && current > moment().endOf("day");
    };

    const handleError = (errorInfo: ValidateErrorEntity): void => {
        form.scrollToField(errorInfo.errorFields[0].name);
    };

    const onTypeChange = (newValue: RadioChangeEvent): void => {
        setReportType(newValue.target.value);
    };

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        // ensure they are reset
        const submitValues: SubmitValues = {
            reportType: values[FormFieldIds.Type],
            programIds: null,
            transactionType: null,
            utcISOStartDate: null,
            utcISOEndDate: null
        };

        submitValues.reportType = values[FormFieldIds.Type];
        submitValues.programIds = values[FormFieldIds.Program];
        submitValues.transactionType = values[FormFieldIds.TransactionType];
        submitValues.utcISOStartDate = !!values[FormFieldIds.StartDate] ? (values[FormFieldIds.StartDate]).utc().toISOString() : null;
        submitValues.utcISOEndDate = !!values[FormFieldIds.EndDate] ? (values[FormFieldIds.EndDate]).utc().toISOString() : null;

        if (submitValues.reportType === ReportType.VALUE && values[FormFieldIds.ShowCode]) {
            submitValues.showCode = true;
        }

        props.onSubmit(cleanNulls(submitValues));
    };

    const renderProgramListTree = (): JSX.Element => {
        const shouldDisplay = (reportType === ReportType.TRANSACTION || reportType === ReportType.VALUE);
        return (
            shouldDisplay &&
            <Row style={{display: "block"}}>
                <Col className="lr-report-form-item">
                    <Form.Item
                        label="Program"
                        name={FormFieldIds.Program}
                        initialValue={["Active"]}
                        rules={[
                            {required: true, message: "Please select at least one Program"},
                            {validator: validateProgramMax}
                        ]}
                    >
                        <TreeSelect
                            dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                            treeDefaultExpandedKeys={[]}
                            treeData={
                                [
                                    {
                                        title: "Active",
                                        value: "Active",
                                        key: "Active",
                                        children: props.programServerState.allList.filter(p => dateIsInFuture(p.endDate)).map(program => ({
                                            title: program.name,
                                            value: program.id,
                                            key: program.id,
                                        }))
                                    },
                                    {
                                        title: "Inactive",
                                        value: "Inactive",
                                        key: "Inactive",
                                        children: props.programServerState.allList.filter(p => dateIsInPast(p.endDate)).map(program => ({
                                            title: program.name,
                                            value: program.id,
                                            key: program.id,
                                        }))
                                    }
                                ]
                            }
                            treeCheckable={true}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            maxTagCount={10}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    const renderTransactionListTree = (): JSX.Element => {
        const shouldDisplay = (reportType === ReportType.TRANSACTION);
        const transactionTypeTreeNodes: DataNode[] = Object.keys(TransactionType)
            .filter(key => isNaN(parseInt(key, 10)))
            .map(
                (key: keyof typeof TransactionType) => ({
                    title: getTransactionTypeLabel(TransactionType[key]),
                    value: TransactionType[key],
                    key: TransactionType[key],
                }));

        return (
            shouldDisplay &&
            <Row style={{display: "block"}}>
                <Col className="lr-report-form-item">
                    <Form.Item
                        label="Transaction type"
                        name={FormFieldIds.TransactionType}
                        initialValue={["All"]}
                        rules={[
                            {required: true, message: `Select at least one (1), or "All" Transaction Types`}
                        ]}
                    >
                        <TreeSelect
                            dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                            treeDefaultExpandedKeys={["All"]}
                            disabled={props.loading}
                            treeData={
                                [{
                                    title: "All",
                                    value: "All",
                                    key: "All",
                                    children: transactionTypeTreeNodes
                                }]
                            }
                            treeCheckable={true}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            maxTagCount={10}
                        >
                            {
                                ...transactionTypeTreeNodes
                            }
                        </TreeSelect>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    const renderShowCode = (): JSX.Element => {
        const shouldDisplay = (reportType === ReportType.VALUE);

        return (
            shouldDisplay &&
            <Row style={{display: "block"}}>
                <Col className="lr-report-form-item">
                    <Form.Item
                        name={FormFieldIds.ShowCode}
                        valuePropName="checked"
                    >
                        <Checkbox>Show full code</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    const renderReportTypes = (): JSX.Element[] => {
        const rows = [];
        for (const report of reports) {
            rows.push(
                <div>
                    <Radio value={report.type}>{report.name}
                        <p className="lr-color-percent-45 lr-report-form-radio">
                            {report.description}
                        </p>
                    </Radio>
                </div>
            );
        }
        return rows;
    };

    return (
        <Form
            form={form}
            method="get"
            action=""
            layout="vertical"
            onFinish={submit}
            onFinishFailed={handleError}
            hideRequiredMark
        >
            <Row>
                <Col sm={24} lg={16}>
                    <Form.Item
                        label="Report type"
                        name={FormFieldIds.Type}
                        initialValue={ReportType.TRANSACTION}
                    >
                        <Radio.Group
                            className="lr-vertical-radio"
                            disabled={props.loading}
                            onChange={onTypeChange}
                        >
                            {renderReportTypes()}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            {renderShowCode()}
            {renderProgramListTree()}
            {renderTransactionListTree()}

            <Row>
                <Col md={16} lg={8} xl={8} xxl={4}>
                    <DateTimePicker
                        fieldId={FormFieldIds.StartDate}
                        form={form}
                        label="Report start date:"
                        initialValue={moment(new Date()).subtract(1, "month").startOf("day").toISOString()}
                        datePickerProps={{
                            className: "marginRight16",
                            disabledDate: disabledDate,
                            disabled: props.loading
                        }}
                    />
                </Col>
                <Col md={16} lg={8} xl={8} xxl={4}>
                    <DateTimePicker
                        data-cy="end-date"
                        fieldId={FormFieldIds.EndDate}
                        form={form}
                        label="Report end date:"
                        initialValue={moment(new Date()).add(1, "day").startOf("day").toISOString()}
                        datePickerProps={{
                            disabledDate: disabledDate,
                            disabled: props.loading
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={props.loading}>
                            Generate and Download Report
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

            <AllowSubmitOnEnter/>

        </Form>
    );
};
