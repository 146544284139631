import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {RestErrorDialog} from "./RestErrorDialog";
import {actionCreator as setRestError} from "../../../../actions/errors/server/setRestError";
import {actionCreator as setRestErrorModalOpen} from "../../../../actions/errors/ui/setRestErrorAlertOpen";
import {RestError} from "../../../../state/serverState/RestError";

export interface ExternalProps {
    title?: string;
}

interface StateProps {
    isOpen: boolean;
    error: RestError;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    error: state.server.restError,
    isOpen: state.ui.errors.restErrorAlertOpen,
    ...props
});

const actionCreators = {
    setRestError,
    setRestErrorModalOpen,
};

const connector =  ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>

export const RestErrorDialogConnected = connector(RestErrorDialog);