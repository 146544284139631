import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionCreatorArgs {
}

export const type = "clearProgramsList";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionCreatorArgs> = () => ({
    type,
    payload: null
});

export const reducer: Reducer<ActionCreatorArgs> = (state) => {
    return sprout.assoc(state, ["server", "programs", "allList"], [], ["server", "programs", "activeList"], [], ["server", "programs", "inactiveList"], []);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);