import * as React from "react";
import {ReactNode} from "react";
import {AccountLayout} from "../../layout/account/AccountLayout";
import {Button, message, Table} from "antd";
import {Currency} from "lightrail-client/dist/model/Currency";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {CreateCurrencyModalConnected} from "./create/CreateCurrencyModalConnected";
import {EditCurrencyModalConnected} from "./edit/EditCurrencyModalConnected";
import {DeleteButton} from "../../ui/buttons/DeleteButton";
import {EditButton} from "../../ui/buttons/EditButton";
import {LightrailRequestError} from "lightrail-client";
import {findCurrency, formatCurrency} from "../../../util/currencyUtils";
import {AccountHeader} from "../../layout/account/AccountHeader";
import {Omit} from "react-router";
import {ColumnProps} from "antd/es/table";
import {ReduxProps} from "./CurrencyPageConnected";
import {PlusOutlined} from "@ant-design/icons";

interface TableRow extends Omit<Currency, "decimalPlaces"> {
    key: string;
    decimalPlaces: ReactNode;
    actions: ReactNode;
}

export interface Props extends ReduxProps {
}

export class CurrencyPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onToggleCreateCurrency = this.onToggleCreateCurrency.bind(this);
    }

    componentDidMount(): void {
        this.props.listCurrencies({});
    }

    ///////////////////
    //[ ACTIONS ]
    onToggleCreateCurrency(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.CURRENCY_MODAL_OPEN,
            value: !this.props.currencyUiState.createModalOpen
        });
    }

    onEdit(code: string): void {
        if (!!code) {
            this.props.setEditCurrency({code, open: true});
        }
    }

    onCloseEdit(): void {
        this.props.setEditCurrency({code: "", open: false});
    }

    async onDelete(code: string): Promise<void> {

        try {
            const response = await this.props.deleteCurrency(code);
            message.success(`${response.value.code} Deleted`);
        } catch (error) {
            message.error(`Error: ${(error as LightrailRequestError).message}`);
        }
    }

    ///////////////////
    //[ RENDER ]
    renderRowActionButtons(currency: Currency): JSX.Element {
        return (
            <Button.Group key={currency.code}>
                <EditButton onEdit={this.onEdit} id={currency.code}/>
                <DeleteButton onDelete={this.onDelete} id={currency.code}/>
            </Button.Group>
        );
    }

    getTableRows(): TableRow[] {
        return this.props.currencyServerState.list.map(
            currency => ({
                key: currency.code,
                ...currency,
                decimalPlaces: (
                    <span>
                        {currency.decimalPlaces}
                        <span
                            className="lr-text-secondary"
                        >
                            &nbsp;&nbsp;&nbsp;({formatCurrency(0, {...currency, symbol: ""})})
                        </span>
                    </span>
                ),
                actions: this.renderRowActionButtons(currency)
            })
        );
    }

    render(): JSX.Element {
        const tableColumns: ColumnProps<TableRow>[] = [
            {
                title: "Code",
                dataIndex: "code",
                key: "code",
                width: 75,
                fixed: "left"
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol"
            },
            {
                title: "Decimal Place",
                dataIndex: "decimalPlaces",
                key: "decimalPlaces",
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name"
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                width: 150,
                fixed: "right"
            }
        ];

        return (
            <AccountLayout>
                <AccountHeader
                    title="Currencies"
                    actionButton={
                        <Button
                            onClick={this.onToggleCreateCurrency}
                        >
                            <PlusOutlined/> Create Currency
                        </Button>
                    }
                />
                <Table
                    className="lr-table-no-hover"
                    loading={this.props.currencyServerState.fetching}
                    columns={tableColumns}
                    dataSource={this.getTableRows()}
                    scroll={{x: 700}}
                    pagination={false}
                    sortDirections={["ascend", "descend"]}
                />
                {
                    this.props.currencyUiState.createModalOpen &&
                    <CreateCurrencyModalConnected
                        onCancel={this.onToggleCreateCurrency}
                    />
                }
                {
                    this.props.currencyUiState.edit.open &&
                    <EditCurrencyModalConnected
                        currency={findCurrency(this.props.currencyServerState.list, this.props.currencyUiState.edit.code)}
                        onCancel={this.onCloseEdit}
                    />
                }
            </AccountLayout>
        );
    }
}