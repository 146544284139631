import * as React from "react";
import {ReactNode} from "react";
import {Col, Divider, Row} from "antd";

export interface Props {
    title?: string;
    rightSideAction?: ReactNode;
}

export class ContentSection extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div className="lr-layout-content-section">
                {
                    (!!this.props.title || !!this.props.rightSideAction) &&
                    <Row className="lr-layout-content-section-header">
                        {
                            (!!this.props.title) &&
                            <Col>
                                {
                                    <h3>
                                        {this.props.title}
                                    </h3>
                                }
                            </Col>
                        }
                        {
                            (!!this.props.rightSideAction) &&
                            <Col className="alignRight">
                                {
                                    this.props.rightSideAction
                                }
                            </Col>
                        }
                    </Row>
                }
                {
                    //Content
                    this.props.children
                }
                <Divider/>
            </div>
        );
    }
}