import * as React from "react";
import {useEffect} from "react";
import {Form, Input} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import { useForm } from "antd/lib/form/Form";
import { LockOutlined } from "@ant-design/icons";

export interface Props {
    processing?: boolean;
    getSubmitHook?: (submit: () => void) => void;
    onSubmit: (password: string) => void;
}

export const SessionWatcherForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = (): void => {
        form.validateFields().then(
            (values: Record<string, any>) => props.onSubmit(values.password)
        );
    }

    useEffect(() => {
        if (props.getSubmitHook) {
            props.getSubmitHook(submit);
        }
    }, []);

    return (
        <Form onFinish={submit} form={form}>
            <Form.Item
                name={FormFieldIds.Password}
                rules={[{required: true, message: "Password Required"}]}
            >
                <Input
                    size="large"
                    prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
        </Form>
    );
};