import * as React from "react";
import {useEffect} from "react";
import {Form, Input, InputNumber, message} from "antd";

import {CreditParams, DebitParams} from "lightrail-client/dist/params";

import {ValueActionStatus} from "../../../../state/uistate/ValueUiState";
import {TransactionType} from "../../../../state/uistate/TransactionUiState";

import {generateId} from "../../../../communication/utils/urlUtils";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";

import {ExternalProps, ReduxProps} from "./UsesTransactionFormConnected";
import {WithCurrencies, withCurrencies} from "../../../ui/currency/withCurrenciesHOC";
import {useForm} from "antd/es/form/Form";

interface InternalProps extends WithCurrencies, ExternalProps, ReduxProps {
}

export const UsesTransactionForm = withCurrencies((props: InternalProps): JSX.Element => {
    const [form] = useForm();

    const closeModalAndResetTransactionType = (): void => {
        props.toggleTransactionModal({
            valueId: props.value.id,
            params: {
                transactionModalOpen: false
            }
        });

        props.setPendingValueAction({
            valueId: props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    };

    const submitCredit = async (): Promise<void> => {
        const values = await form.validateFields();
        const creationValues: CreditParams = {
            id: generateId(),
            destination: {
                rail: "lightrail",
                valueId: props.value.id
            },
            uses: values[FormFieldIds.Amount],
            currency: props.value.currency,
            metadata: {
                "lightrail_webapp_credit_note": values.notes
            },
        };

        closeModalAndResetTransactionType();

        // Make the call and trigger Notification on success
        try {
            await props.creditTransaction(creationValues);

            message.success(`${creationValues.uses} uses added.`);
            // Refresh Value
            props.getValue({valueId: creationValues.destination.valueId});
        } catch (err) {
            message.error(err.message);
        }
    };

    const submitDebit = async (): Promise<void> => {
        const values = await form.validateFields();
        // Setup Creation Params (Credit example)
        const creationValues: DebitParams = {
            id: generateId(),
            source: {
                rail: "lightrail",
                valueId: props.value.id
            },
            uses: values[FormFieldIds.Amount],
            currency: props.value.currency,
            metadata: {
                "lightrail_webapp_debit_note": values.notes
            },
        };

        closeModalAndResetTransactionType();

        try {
            await props.debitTransaction(creationValues);

            // Make the call, trigger Notification and refresh Value on success
            message.success(`${creationValues.uses} uses debited.`);
            // Refresh Value
            props.getValue({valueId: creationValues.source.valueId});
        } catch (err) {
            message.error(err.message);
        }
    };

    const submit = (): void => {
        switch (props.transactionType) {
            case TransactionType.CREDIT:
                submitCredit();
                break;
            case TransactionType.DEBIT:
                submitDebit();
                break;
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    const {TextArea} = Input;
    const max = (props.transactionType === TransactionType.DEBIT) ? props.value.usesRemaining : undefined;

    return (
        <Form onFinish={submit} form={form} className="lr-create-transaction-form">
            <h4>Current Uses Remaining: {props.value.usesRemaining}</h4>
            <Form.Item
                name={FormFieldIds.Amount}
                rules={[
                    {required: true, message: "Number Required"}
                ]}
            >
                <InputNumber
                    style={{width: "100%"}}
                    placeholder="Enter Amount"
                    size="large"
                    min={0}
                    max={max}
                    precision={0}
                    autoFocus={true}
                />
            </Form.Item>
            <Form.Item
                name={FormFieldIds.Notes}
            >
                <TextArea placeholder="Note (optional)" autoSize={{minRows: 2, maxRows: 4}}/>
            </Form.Item>
        </Form>
    );
});