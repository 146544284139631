import * as ReactRedux from "react-redux";
import {withRouter} from "react-router";
import {State} from "../../../../state/State";
import {actionCreator as logout} from "../../../../actions/auth/server/logout";
import {actionCreator as getUser} from "../../../../actions/auth/server/getUser";
import {ChangeEmailComplete} from "./ChangeEmailComplete";

interface StateProps {
    email: string;
    additionalAuthenticationRequired: boolean;
    loggedIn: boolean;
    processingMfa: boolean;
}

const mapStateToProps = (state: State): StateProps => ({
    email: state.server.user.email,
    additionalAuthenticationRequired: state.server.user.additionalAuthenticationRequired,
    loggedIn: state.server.user.loggedIn,
    processingMfa: state.server.user.fetching
});

const actionCreators = {
    logout,
    getUser
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ChangeEmailCompleteConnected = connector(
    withRouter(
        ChangeEmailComplete
    )
);