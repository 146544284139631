import * as React from "react";
import {useEffect} from "react";
import {Col, Form, message, Row} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {DateTimePicker} from "../../ui/forms/items/DateTimePicker";
import {useForm} from "antd/es/form/Form";
import {ExternalProps, ReduxProps} from "./ModifyDatesFormConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export const ModifyDatesForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        try {
            const values = await form.validateFields();
            const updateResponse = await props.updateValue({valueId: props.valueId, params: values});
            message.success(`Date has been modified`);
            if (!!props.onUpdateValueResponse) {
                const responseValue = await updateResponse.value;
                props.onUpdateValueResponse(responseValue);
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    });

    return (
        <Form onFinish={submit} form={form} hideRequiredMark>
            <Row>
                <Col span={12}>
                    <Form.Item
                        className="marginBottom0"
                        name={FormFieldIds.StartDate}
                        initialValue={props.initialStartDate}
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.StartDate}
                            form={form}
                            label="Active from:"
                            initialValue={props.initialStartDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className="marginBottom0 marginLeft16"
                        name={FormFieldIds.EndDate}
                        initialValue={props.initialEndDate}
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.EndDate}
                            form={form}
                            label="Expires on:"
                            initialValue={props.initialEndDate}
                        />
                    </Form.Item>
                </Col>
                <AllowSubmitOnEnter/>
            </Row>
        </Form>
    );
};