import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {PASSWORD_ID, SessionWatcher} from "./SessionWatcher";
import {actionCreator as pingNoRefresh} from "../../../actions/auth/server/pingNoRefresh";
import {actionCreator as ping} from "../../../actions/auth/server/ping";
import {actionCreator as login} from "../../../actions/auth/server/reLogin";
import {actionCreator as logout} from "../../../actions/auth/server/logout";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {withRouter} from "react-router-dom";
import {PasswordFieldUiState, SessionUIState} from "../../../state/uistate/ComponentUiState";
import {AuthState} from "../../../state/serverState/AuthServerState";

interface StateProps {
    password: PasswordFieldUiState;
    sessionState: SessionUIState;
    userEmail: string;
    authState: AuthState;
    inTestMode: boolean;
    stateHistory: string[];
}

const mapStateToProps = (state: State): StateProps => ({
    password: state.ui.components.passwordFields[PASSWORD_ID],
    sessionState: state.ui.components.session,
    userEmail: state.server.user.email,
    authState: state.server.auth,
    inTestMode: state.server.user.testing,
    stateHistory: state.ui.history
});

const actionCreators = {
    login,
    logout,
    ping,
    pingNoRefresh,
    uiAction
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const SessionWatcherConnected = connector(withRouter(SessionWatcher));