import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {CreateWebhookModal} from "./CreateWebhookModal";

export interface ExternalProps {
    onCancel?: () => void;
}

interface StateProps {
    processingWebhook: boolean;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    processingWebhook: state.server.webhook.processing,
    ...props
});

const actionCreators = {};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateWebhookModalConnected = connector(CreateWebhookModal);