import * as React from "react";
import {ReactNode} from "react";
import {withRouter} from "react-router-dom";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {Alert, Button, List, message, Spin, Table} from "antd";
import {decodeUrlMatchParam, formatRoute, Paths} from "../../../../util/routes/routes";
import {GetWebhookResponse} from "../../../../communication/dtos/webhooks/GetWebhook";
import {DetailLayout} from "../../../layout/details/DetailLayout";
import {ContentSection} from "../../../layout/ContentSection";
import {StringWithCopyButton} from "../../../ui/StringWithCopyButton";
import {Secret} from "../../../../communication/dtos/webhooks/Secret";
import {StatusBadge} from "../../../ui/StatusBadge";
import {formatDate} from "../../../../util/dateUtils";
import {DeleteButton} from "../../../ui/buttons/DeleteButton";
import {UiActionPaths} from "../../../../state/uistate/UiActionPaths";
import {EditWebhookModalConnected} from "../edit/EditWebhookModalConnected";
import {LightrailRequestError} from "lightrail-client";
import {RevealSecret} from "./RevealSecret";
import {ShowSecretModal} from "./ShowSecretModal";
import {WebhookEventEnums} from "../create/WebhookEventEnums";
import {ColumnProps} from "antd/es/table";
import {ReduxProps} from "./WebhookDetailPageConnected";
import {EditOutlined} from "@ant-design/icons";

interface State {
    webhookId: string;
    secret?: string;
    updatedSecret?: boolean;
}

interface TableRow {
    key: string;
    id: string;
    secret: string;
    viewSecret: JSX.Element;
    createdDate: string;
    actions: ReactNode;
}

interface Props extends WithRouterProps<{ webhookId: string }>, ReduxProps {
}

class WebhookDetailPageComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const decodedId = decodeUrlMatchParam(this.props.match.params.webhookId);

        this.state = {
            webhookId: decodedId,
            updatedSecret: false
        };

        this.onDeleteWebhook = this.onDeleteWebhook.bind(this);
        this.onDeleteSecret = this.onDeleteSecret.bind(this);
        this.loadWebhook = this.loadWebhook.bind(this);
        this.renderEventsList = this.renderEventsList.bind(this);
        this.renderSecrets = this.renderSecrets.bind(this);
        this.toggleEditWebhookModal = this.toggleEditWebhookModal.bind(this);
        this.toggleCloseEditWebhookModal = this.toggleCloseEditWebhookModal.bind(this);
        this.toggleNewSecretModal = this.toggleNewSecretModal.bind(this);
        this.toggleShowSecretModal = this.toggleShowSecretModal.bind(this);
        this.toggleCloseSecretModal = this.toggleCloseSecretModal.bind(this);
        this.toggleCloseNewSecretModal = this.toggleCloseNewSecretModal.bind(this);
    }

    componentDidMount(): void {
        this.loadWebhook();
    }

    componentDidUpdate(oldProps: Props, oldState: State): void {
        if (this.state.updatedSecret !== oldState.updatedSecret) {
            this.loadWebhook();
        }
    }

    loadWebhook(): void {
        this.props.getWebhook(this.state.webhookId);
    }

    getWebhook(): GetWebhookResponse {
        return this.props.webhookServerState.list.find(w => w.id === this.state.webhookId);
    }

    async onDeleteWebhook(id: string): Promise<void> {
        try {
            const response = await this.props.deleteWebhook(id).value;
            if (response.success) {
                message.success(`${response.id} Deleted`);
            }
        } catch (error) {
            if (error.isLightrailRequestError) {
                message.error(`Error: ${error.message}`);
            }
        }

        this.props.history.push(formatRoute(Paths.ACCOUNT_WEBHOOK, {}));
    }

    async onDeleteSecret(id: string): Promise<void> {
        try {
            await this.props.deleteSecret({webhookId: this.state.webhookId, secretId: id});
            message.success(`Secret has been successfully deleted`);
            this.setState({updatedSecret: !this.state.updatedSecret});
        } catch (error) {
            message.error("Error: " + (error as LightrailRequestError).message);
        }
    }

    toggleEditWebhookModal(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_EDIT_MODAL_OPEN,
            value: !this.props.webhookUiState.editModalOpen
        });
    }

    toggleCloseEditWebhookModal(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_EDIT_MODAL_OPEN,
            value: !this.props.webhookUiState.editModalOpen
        });
    }

    async toggleNewSecretModal(): Promise<void> {
        try {
            const response = await this.props.createSecret(this.state.webhookId);
            message.success(`Secret has been successfully created`);
            this.setState({
                secret: response.value.secret,
                updatedSecret: !this.state.updatedSecret
            });
            this.props.uiAction({
                uiStatePath: UiActionPaths.WEBHOOK_CREATE_NEW_SECRET_MODAL_OPEN,
                value: !this.props.webhookUiState.createSecretModalOpen
            });
        } catch (error) {
            if (error.isLightrailRequestError) {
                message.error("Error: " + (error as LightrailRequestError).message);
            }
        }
    }

    toggleCloseNewSecretModal(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_CREATE_NEW_SECRET_MODAL_OPEN,
            value: !this.props.webhookUiState.createSecretModalOpen
        });
        this.loadWebhook();
    }

    async toggleShowSecretModal(secretId?: string): Promise<void> {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_SHOW_SECRET_MODAL_OPEN,
            value: !this.props.webhookUiState.showSecretModalOpen
        });

        if (!!secretId || !this.state.secret) {
            try {
                const response = await this.props.getSecret({
                    webhookId: this.state.webhookId,
                    secretId: secretId
                });
                this.setState({secret: response.value.secret});
            } catch (error) {
                if (error.isLightrailRequestError) {
                    message.error("Error: " + (error as LightrailRequestError).message);
                }
            }
        }
    }

    toggleCloseSecretModal(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_SHOW_SECRET_MODAL_OPEN,
            value: !this.props.webhookUiState.showSecretModalOpen
        });
    }

    ///////////////////
    //[ RENDER ]
    renderWebhookNotFound(): JSX.Element {
        return (
            <Alert
                message="Webhook Not Found"
                description={`Webhook Id '${this.state.webhookId}' can't be found`}
                showIcon
                type="warning"
                className="detailsNotFoundAlert"
            />
        );
    }

    renderEventsList(events: string[]): ReactNode {
        const webhookEvents = WebhookEventEnums;
        let allEvents = false;
        if (events[0] == "*") {
            allEvents = true;
            events = Object.keys(webhookEvents).map(
                (key: keyof typeof webhookEvents) => (
                    webhookEvents[key]
                ));
        }
        return (
            <div>
                {
                    (allEvents) &&
                    <p>All</p>
                }
                <ul>
                    {
                        events.map(
                            event => <li style={{fontFamily: "monospace"}} key={event}>{event}</li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderSecrets(secrets: Secret): ReactNode {
        return (
            <List.Item>
                title={secrets.secret}
            </List.Item>
        );
    }

    renderRevealSecret(secret: Secret): JSX.Element {
        return (
            <RevealSecret secretId={secret.id}
                          webhookUiState={this.props.webhookUiState}
                          uiAction={this.props.uiAction}
                          toggleShowSecretModal={this.toggleShowSecretModal}
            />
        );
    }

    getTableRows(): TableRow[] {
        const webhook = this.getWebhook();
        return webhook.secrets.map(
            secret => ({
                key: secret.id,
                id: secret.id,
                secret: secret.secret,
                viewSecret: this.renderRevealSecret(secret),
                createdDate: formatDate(secret.createdDate),
                actions: this.renderRowActionButtons(secret)
            })
        );
    }

    renderRowActionButtons(secret: Secret): JSX.Element {
        return (
            <Button.Group key={secret.id}>
                <DeleteButton onDelete={this.onDeleteSecret} id={secret.id} defaultSize={true}/>
            </Button.Group>
        );
    }

    render(): JSX.Element {
        const tableColumns: ColumnProps<TableRow>[] = [
            {
                title: "Secret",
                dataIndex: "secret",
                width: "20%",
                key: "secret"
            },
            {
                title: "Date Created",
                dataIndex: "createdDate",
                width: "50%",
                key: "createdDate"
            },
            {
                title: "",
                dataIndex: "viewSecret",
                key: "viewSecret",
                fixed: "right"
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                fixed: "right"
            }
        ];

        const webhook = this.getWebhook();

        if (this.props.webhookServerState.status === 404) {
            return (
                this.renderWebhookNotFound()
            );
        }

        if (!webhook) {
            return (<Spin/>);
        }
        return (
            <DetailLayout
                loading={this.props.webhookServerState.fetching}
                headerProps={{
                    avatar: "W",
                    title: `URL: ${webhook.url}`,
                    details: [
                        <span key={webhook.id}>Id: <StringWithCopyButton stringToBeCopied={webhook.id}/></span>,
                        <StatusBadge key={webhook.id} active={webhook.active}/>,
                    ],
                    actionButton:
                        <div>
                            <Button.Group key={webhook.id}>
                                <DeleteButton onDelete={this.onDeleteWebhook} id={webhook.id} defaultSize={true}/>
                            </Button.Group>
                            <Button
                                className="marginLeft8"
                                style={{marginLeft: "auto"}}
                                onClick={this.toggleEditWebhookModal}
                            >
                                <EditOutlined/>Edit Details
                            </Button>
                        </div>
                }}
            >
                <ContentSection
                    title="Webhook Event Types"
                >
                    {this.renderEventsList(webhook.events)}
                </ContentSection>
                <ContentSection
                    title="Secrets"
                    rightSideAction={<Button onClick={this.toggleNewSecretModal}>+ New Secret</Button>}
                >
                    {
                        (!this.props.webhookServerState.fetching) &&
                        <Table
                            className="lr-table-no-hover"
                            loading={this.props.webhookServerState.fetching}
                            columns={tableColumns}
                            dataSource={this.getTableRows()}
                            scroll={{x: 700}}
                            pagination={false}
                            sortDirections={["ascend", "descend"]}
                        />
                    }
                </ContentSection>
                {
                    (this.props.webhookUiState.editModalOpen) &&
                    <EditWebhookModalConnected
                        webhook={webhook}
                        onCancel={this.toggleCloseEditWebhookModal}
                    />
                }
                {
                    (this.props.webhookUiState.showSecretModalOpen) &&
                    <ShowSecretModal
                        onClose={this.toggleCloseSecretModal}
                        open={this.props.webhookUiState.showSecretModalOpen}
                        secret={this.state.secret}
                        processingSecret={this.props.webhookServerState.secret.fetching}
                    />
                }
                {
                    (this.props.webhookUiState.createSecretModalOpen) &&
                    <ShowSecretModal
                        onClose={this.toggleCloseNewSecretModal}
                        open={this.props.webhookUiState.createSecretModalOpen}
                        secret={this.state.secret}
                        processingSecret={this.props.webhookServerState.secret.fetching}
                    />
                }
            </DetailLayout>
        );
    }
}

export const WebhookDetailPage = withRouter(WebhookDetailPageComponent);