import * as React from "react";
import {Popover} from "antd";

export const TestBanner = (): JSX.Element => {
    const testDataExplanation = "Test data, including API keys, is completely separate from live (production) data. Test transactions do not count towards billing, nor do they impact allotted free transactions. Data created in test mode is not visible in live mode, and vice versa.";

    return (
        <React.Fragment>
            <div id="lr-test-banner-thin"/>
            <div id="lr-test-banner-container">
                <div id="lr-test-banner-thick">
                    TEST DATA
                    <Popover
                        content={testDataExplanation}
                        trigger="hover"
                        overlayStyle={{maxWidth: 280}}
                    >
                        <div id="lr-test-banner-information">
                            i
                        </div>
                    </Popover>
                </div>
            </div>
        </React.Fragment>
    );
};