import * as React from "react";
import {useEffect, useState} from "react";
import * as moment from "moment";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";
import {ExternalProps, ReduxProps} from "./CreateGenericValueFormConnected";
import {CreateValueParams} from "lightrail-client/dist/params";
import {withCurrencies, WithCurrencies} from "../../../ui/currency/withCurrenciesHOC";
import {MetadataItems} from "../../../ui/forms/items/MetadataItems";
import {DateTimePicker} from "../../../ui/forms/items/DateTimePicker";
import {generateId} from "../../../../communication/utils/urlUtils";
import {findCurrency, normalizeValueForServer} from "../../../../util/currencyUtils";
import {formatParamsError} from "../../../../util/stringUtils";
import {DiscountItem} from "../../../ui/forms/items/DiscountItem";
import {PretaxItem} from "../../../ui/forms/items/PretaxItem";
import {EmojiInputItem} from "../../../ui/forms/EmojiInputItem";
import {ClickToAdd} from "../../../ui/forms/ClickToAdd";
import {AdvancedRuleItem} from "../../../ui/forms/items/rules/AdvancedRuleItem";
import {ActiveItem} from "../../../ui/forms/items/ActiveItem";
import {AllowSubmitOnEnter} from "../../../ui/forms/AllowSubmitOnEnter";
import {ProgramValueItem} from "../../../ui/forms/items/ProgramValueItem";
import {LimitAttachesItem} from "../../../ui/forms/items/LimitAttachesItem";
import {BalanceAndBalanceRuleButton} from "../../../ui/forms/items/BalanceAndBalanceRuleButton";
import {LimitUsesFormItem} from "../../../ui/forms/items/LimitUsesFormItem";
import {Alert, Collapse, Divider, Form, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {cleanNulls} from "../../../../util/communicationUtils";

const VALUE_METADATA_NOTES_KEY = "lightrail_webapp_value_notes";

interface InternalProps extends ExternalProps, ReduxProps, WithCurrencies {
}

export const CreateGenericValueForm = withCurrencies((props: InternalProps): JSX.Element => {
    const {program, currencies} = props;
    const [form] = useForm();

    const [isBalance, setIsBalance] = useState(true);
    const [showLiabilityTab, setShowLiabilityTab] = useState(false);

    const sendCreationRequest = async (creationParams: CreateValueParams): Promise<void> => {
        try {
            await props.createValue(cleanNulls(creationParams));
            message.success(`${creationParams.code} Created `);

            if (props.onSuccess) {
                props.onSuccess();
            }
        } catch (error) {
            if (!!error && !!error.message) {
                message.error(error.message);
            }
        }
    };

    const submit = (values: Record<string, any>): void => {
        //Setup Params
        const creationParams: CreateValueParams = {
            id: generateId(),
            isGenericCode: true,
            code: values[FormFieldIds.Code],
            programId: program.id,
            currency: program.currency,
            balance: null,
            genericCodeOptions: {
                perContact: {
                    balance: null,
                    usesRemaining: null
                }
            },
            balanceRule: AdvancedRuleItem.getRuleFromSubmitValues(values, FormFieldIds.BalanceRule, FormFieldIds.BalanceRuleExplanation),
            redemptionRule: AdvancedRuleItem.getRuleFromSubmitValues(values, FormFieldIds.RedemptionRule, FormFieldIds.RedemptionRuleExplanation),
            active: values[FormFieldIds.Active],
            pretax: values[FormFieldIds.Pretax],
            discount: values[FormFieldIds.Discount],
            discountSellerLiabilityRule: values[FormFieldIds.DiscountSellerLiabilityRule],
        };

        const metadataFromForm = MetadataItems.parseMetadataFromSubmitValues(values, VALUE_METADATA_NOTES_KEY);
        if (metadataFromForm != null) {
            creationParams.metadata = metadataFromForm;
        }

        const currency = findCurrency(currencies, program.currency);
        if (!currency) {
            throw Error("Cannot find definition for currency " + program.currency);
        }

        if (values[FormFieldIds.DiscountSellerLiabilityRule] != null) {
            const rule = values[FormFieldIds.DiscountSellerLiabilityRule].toString();
            const formattedRule = rule.replace(/^((\.\d+)?|)$/, `0${rule}`);

            creationParams.discountSellerLiabilityRule = {
                rule: formattedRule,
                explanation: values[FormFieldIds.DiscountSellerLiabilityExplanation]
            };
        }

        if (values[FormFieldIds.StartDate]) {
            creationParams.startDate = values[FormFieldIds.StartDate].toISOString();
        }

        if (values[FormFieldIds.EndDate]) {
            creationParams.endDate = values[FormFieldIds.EndDate].toISOString();
        }

        if (values[FormFieldIds.Balance] != undefined) {
            creationParams.genericCodeOptions.perContact.balance = normalizeValueForServer(values.balance, currency.decimalPlaces);
        }

        if (!!values[FormFieldIds.FixedUsesRemaining]) {
            creationParams.usesRemaining = parseInt(values[FormFieldIds.FixedUsesRemaining], 10);
        }

        if (values[FormFieldIds.UsesRemaining] && values[FormFieldIds.LimitUsesRemaining]) {
            creationParams.genericCodeOptions.perContact.usesRemaining = parseInt(values[FormFieldIds.UsesRemaining], 10);
        }

        if (values[FormFieldIds.AttachesRemaining] != null) {
            if (creationParams.genericCodeOptions.perContact.balance) {
                creationParams.balance = values.attachesRemaining * creationParams.genericCodeOptions.perContact.balance;
            } else if (values[FormFieldIds.LimitUsesRemaining]) {
                creationParams.usesRemaining = values.attachesRemaining * creationParams.genericCodeOptions.perContact.usesRemaining;
            }
        }

        sendCreationRequest(creationParams);
    };

    const submitHandler = (): void => {
        form.validateFields().then(
            (values: Record<string, any>) => submit(values)
        );
    };

    const getInitialEndDate = (): string => {
        const startDateValue = form.getFieldValue(FormFieldIds.StartDate);
        const startDate = (!!startDateValue) ? startDateValue.toISOString() : new Date().toISOString();
        return moment(startDate).add(6, "month").endOf("day").toISOString();
    };

    const onBalanceTypeChange = (): void => {
        setIsBalance(!isBalance);
    };

    const shouldShowLiabilityTab = (): boolean => {
        const areUsesLimited = !!form.getFieldValue(FormFieldIds.LimitUsesRemaining);
        const isBalance = form.getFieldValue(FormFieldIds.Balance) != null;
        return areUsesLimited || isBalance;
    };

    /* REACT HOOK EXAMPLES - these are documented here but used throughout the application.*/

    /*
     * This useEffect replicates ComponentDidMount by having an empty array of dependencies.
     * This means that the effect should only run after a dependency has changed, which is
     * impossible when there are no dependencies.
     */
    useEffect(
        () => {
            if (props.getSubmit) {
                props.getSubmit(submitHandler);
            }
        },
        []
    );

    /*
     * This useEffect mimics ComponentDidUpdate and runs on every render cycle since there are no dependencies.
     * In most cases using multiple useEffects is recommended to separate out concerns.
     */
    useEffect(
        () => setShowLiabilityTab(shouldShowLiabilityTab())
    );

    /*
     * This useEffect mimics ComponentWillUnmount by returning a function. Any function returned by useEffect is run
     * on unmount.
     */
    useEffect(
        () => () => props.clearValueError({})
    );

    ///////////////////
    //[ RENDER ]
    return (
        <Form
            form={form}
            onFinish={submit}
            className="lr-create-form"
            layout="vertical"
            hideRequiredMark
        >
            <EmojiInputItem
                fieldId={FormFieldIds.Code}
                form={form}
                formItemProps={{
                    label: "Code"
                }}
                inputProps={{
                    placeholder: "PROMO_CODE",
                    size: "large",
                    autoFocus: true
                }}
                rules={[
                    {
                        pattern: /^\S*$/,
                        message: "Spaces are not allowed"
                    },
                    GenericRequiredRule
                ]}
            />
            <Divider/>
            {
                (!program.balanceRule && !program.fixedInitialBalances && !program.minInitialBalance && !program.maxInitialBalance) &&
                <BalanceAndBalanceRuleButton program={program} form={form}
                                             onChange={onBalanceTypeChange}
                                             isBalance={isBalance}
                                             isGeneric={true}/>
            }
            {
                (!!program.balanceRule) &&
                <div>
                    <p className="lr-text-secondary">Default Value Details were set by the program. Any changes
                        below
                        will
                        override the program values.</p>
                    <AdvancedRuleItem
                        label="Balance Rule"
                        rule={program.balanceRule}
                        explanationItemId={FormFieldIds.BalanceRuleExplanation}
                        ruleItemId={FormFieldIds.BalanceRule}
                        form={form}
                        explanationProps={{placeholder: "50% off"}}
                        ruleProps={{placeholder: "currentLineItem.lineTotal.subtotal * 0.5"}}
                        ruleValidationRules={[{required: true, message: "Balance rule Required"}]}
                        disableEditToggle
                    />
                </div>
            }
            {
                (!!program.fixedInitialBalances || !!program.minInitialBalance || !!program.maxInitialBalance) &&
                <div>
                    <p className="lr-text-secondary">These Value Details were set by the program. Any changes below
                        will override the program for this code generation.</p>
                    <ProgramValueItem
                        isForCreatingGenericCode={true}
                        program={program}
                        form={form}
                    />
                </div>
            }

            <LimitUsesFormItem
                program={program}
                form={form}
            />

            <ClickToAdd
                forceActive={!!program.redemptionRule}
                message="+ Add Redemption Rule"
            >
                <AdvancedRuleItem
                    label="Redemption Rule"
                    rule={program.redemptionRule}
                    explanationItemId={FormFieldIds.RedemptionRuleExplanation}
                    ruleItemId={FormFieldIds.RedemptionRule}
                    form={form}
                    explanationProps={{placeholder: "Can only be applied to Red hats"}}
                    ruleProps={{placeholder: "currentLineItem.productId == 'red-hat'"}}
                    disableEditToggle
                />
            </ClickToAdd>
            <Divider/>
            <Collapse
                bordered={false}
            >
                <Collapse.Panel
                    key="details"
                    header={<h3>Active Period</h3>}
                    forceRender
                >
                    <ClickToAdd
                        forceActive={!!program.startDate}
                        message="+ Add Start Date"
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.StartDate}
                            form={form}
                            label="Codes active from:"
                            initialValue={program.startDate || moment().startOf("day").toISOString()}
                        />
                    </ClickToAdd>
                    <ClickToAdd
                        forceActive={!!program.endDate}
                        message="+ Add Expiry Date"
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.EndDate}
                            form={form}
                            label="Codes expire on:"
                            initialValue={program.endDate || getInitialEndDate()}
                        />
                    </ClickToAdd>
                </Collapse.Panel>
                <Collapse.Panel
                    key="advanced"
                    header={<h3>Advanced</h3>}
                    forceRender
                >
                    <div className="lr-checkbox-group">
                        <ActiveItem
                            fieldId={FormFieldIds.Active}
                            form={form}
                            initialValue={program.active}
                            description="Values will be active when created"
                        />
                        <DiscountItem
                            fieldId={FormFieldIds.Discount}
                            form={form}
                            discountCheckedInitialValue={program.discount}
                            discountSellerLiabilityRule={!!program.discountSellerLiabilityRule ? program.discountSellerLiabilityRule : null}
                        />
                        <PretaxItem
                            fieldId={FormFieldIds.Pretax}
                            form={form}
                            initialValue={program.pretax}
                        />
                    </div>
                </Collapse.Panel>
                {
                    showLiabilityTab &&
                    <Collapse.Panel
                        key="liability"
                        header={<h3>Liability</h3>}
                        forceRender
                    >
                        <LimitAttachesItem
                            fieldId={FormFieldIds.AttachesRemaining}
                            form={form}
                        />
                    </Collapse.Panel>
                }
                <Collapse.Panel
                    key="metadata"
                    header={<h3>Metadata</h3>}
                    forceRender
                >
                    <MetadataItems
                        metadata={program.metadata}
                        form={form}
                        metadataNotesKey={VALUE_METADATA_NOTES_KEY}
                    />
                </Collapse.Panel>
            </Collapse>
            {
                (props.valueServerState.error) &&
                <Alert
                    message={`Whoops`}
                    description={formatParamsError(props.valueServerState.error)}
                    type="error"
                    showIcon
                    closable={false}
                />
            }
            <AllowSubmitOnEnter/>
        </Form>
    );
});