import * as React from "react";
import {ReactNode} from "react";
import {Col, Popover, Row, Spin} from "antd";
import {defaultDash, getReadableKey, pluralize} from "../../../util/stringUtils";
import {formatDate} from "../../../util/dateUtils";
import {DetailValues} from "../../ui/layout/details/DetailValues";
import {RulePopover} from "../../ui/rules/RulePopover";
import {Program} from "lightrail-client/dist/model";
import {FormattedCurrency} from "../../ui/currency/FormattedCurrencyConnected";
import {MetadataDetailValue} from "../../ui/layout/details/MetadataDetailValue";
import {StringWithCopyButton} from "../../ui/StringWithCopyButton";
import { InfoCircleOutlined } from "@ant-design/icons";

export interface Props {
    loading?: boolean;
    program: Program;
}

export class ProgramOverview extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    renderKeyPopOverWithLabel(key: string): ReactNode {
        return (
            <p>
                {getReadableKey(key)}
                <Popover
                    content={(<p>{key}</p>)}
                >
                    <InfoCircleOutlined />
                </Popover>
            </p>
        );
    }

    render(): JSX.Element {
        const program = this.props.program;

        if (this.props.loading) {
            return (<Spin/>);
        }

        if (!program) {
            return (
                <DetailValues
                    title="Program Details"
                    data={[[
                        {label: defaultDash, value: ""}
                    ]]}
                />
            );
        }

        return (
            <Row>
                <Col span={24}>
                    <DetailValues
                        title="Program Details"
                        data={[
                            [

                                {
                                    label: "Program Id",
                                    value: <StringWithCopyButton stringToBeCopied={program.id}/>,
                                    key: "programId"
                                },
                                {
                                    label: "Default value status",
                                    value: ((program.active) ? "Active" : "Inactive"),
                                    key: "status"
                                },
                                (program.minInitialBalance) && {
                                    label: "Minimum Initial Balance",
                                    value: <FormattedCurrency value={program.minInitialBalance}
                                                              code={program.currency}/>,
                                    key: "minInitialBalance"
                                },
                                (program.maxInitialBalance) && {
                                    label: "Maximum Initial Balance",
                                    value: <FormattedCurrency value={program.maxInitialBalance}
                                                              code={program.currency}/>,
                                    key: "maxInitialBalance"
                                },
                                (program.fixedInitialBalances) && {
                                    label: pluralize(program.fixedInitialBalances.length, "Initial Balance"),
                                    value: (
                                        <span>
                                            {
                                                program.fixedInitialBalances.map((p: number, i: number) => (
                                                    <FormattedCurrency
                                                        key={p.toString()}
                                                        value={p}
                                                        code={program.currency}
                                                        postfix={(program.fixedInitialBalances.length > 1 && i < program.fixedInitialBalances.length - 1) ? ", " : ""}
                                                    />
                                                ))
                                            }
                                        </span>
                                    ),
                                    key: "fixedInitialBalance"
                                },
                                (program.fixedInitialUsesRemaining) && {
                                    label: pluralize(program.fixedInitialUsesRemaining.length, "Initial Use"),
                                    value: (
                                        <span>
                                            {program.fixedInitialUsesRemaining.join(", ")}
                                        </span>
                                    ),
                                    key: "fixedInitialUsesRemaining"
                                },
                                (program.balanceRule) && {
                                    label: "Balance Rule",
                                    value: (
                                        <span>
                                            {program.balanceRule.explanation}&nbsp;
                                            <RulePopover
                                                title="Balance Rule"
                                                content={program.balanceRule}
                                            />
                                        </span>
                                    ),
                                    key: "balanceRule"
                                },
                                {
                                    label: "Discount",
                                    value: (program.discount) ? "Yes" : "No",
                                    key: "discount"
                                },
                                {
                                    label: "Currency",
                                    value: program.currency,
                                    key: "currency"
                                },
                                {
                                    label: "Tax",
                                    value: (program.pretax) ? "Pre-tax" : "Post-tax",
                                    key: "pretax"
                                },
                                (!!program.discountSellerLiabilityRule) && {
                                    label: "Discount Seller Liability",
                                    value: (
                                        <span>
                                            {program.discountSellerLiabilityRule.explanation}
                                            &nbsp;
                                            <RulePopover
                                                title="Discount Seller Liability Rule"
                                                content={program.discountSellerLiabilityRule}
                                            />
                                        </span>
                                    ),
                                    key: "discountSellerLiability"
                                },
                                (!!program.redemptionRule) &&
                                {
                                    label: "Redemption Rule",
                                    value: (
                                        <span>
                                            {program.redemptionRule.explanation}&nbsp;
                                            <RulePopover
                                                title="Redemption Rule"
                                                content={program.redemptionRule}
                                            />
                                        </span>
                                    ),
                                    key: "redemptionRule"
                                },
                            ],
                            [
                                {
                                    label: "Active Period",
                                    value: (
                                        <div>
                                            {`Start: ${(!!program.startDate) ? formatDate(program.startDate) : "No Start Date"}`}
                                            <br/>
                                            {`End: ${(!!program.endDate) ? formatDate(program.endDate) : "No End Date"}`}
                                        </div>
                                    ),
                                    key: "activePeriod"
                                },
                                {label: "Created Date", value: formatDate(program.createdDate), key: "createDate"},
                                {label: "Update Date", value: formatDate(program.updatedDate), key: "updatedDate"}
                            ]
                        ]}/>
                </Col>
                <Col sm={24}>
                    {
                        (!!program.metadata && !!Object.keys(program.metadata).length) &&
                        <MetadataDetailValue metadata={program.metadata} key={"metadata"}/>
                    }
                </Col>
            </Row>
        );
    }
}