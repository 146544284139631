import * as React from "react";
import {useEffect} from "react";
import {supportedPhoneRegions} from "../../util/forms/Countries";
import {AllowSubmitOnEnter} from "../ui/forms/AllowSubmitOnEnter";
import {Form, Input, Select} from "antd";
import {FormFieldIds} from "../../util/forms/AntFormHelpers";
import {useForm} from "antd/lib/form/Form";

export interface Props {
    numberError?: string;
    disableButtons?: boolean;
    initialNumber?: string;
    initialCountry?: string;
    getSubmitHook: (hook: () => void) => void;
    onSubmit: (data: { number: string, countryCode: string }) => void;
}

export const EnableMFANumberForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        props.onSubmit({
            number: values[FormFieldIds.Phone],
            countryCode: values[FormFieldIds.Country]
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            country: props.initialCountry || "United States",
            phone: props.initialNumber
        });

        if (props.getSubmitHook) {
            props.getSubmitHook(submit);
        }
    }, []);

    const countrySelector = (
        <div className="lr-dropdown">
            <Form.Item name={FormFieldIds.Country}>
                <Select>
                    {
                        Object.keys(supportedPhoneRegions).map(key => (
                            <Select.Option value={key}
                                           key={key}>{`${key} ${supportedPhoneRegions[key]}`}</Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
        </div>
    );

    return (
        <Form
            onFinish={submit}
            form={form}
        >
            <div className="bodyDialogClass">
                <div className="column columnLeft">
                    <p>Enter your mobile phone number below. We’ll send a security code to this phone number
                        whenever you sign in to the Lightrail web app or link a new device.
                    </p>
                    <Form.Item
                        name={FormFieldIds.Phone}
                        initialValue={props.initialNumber}
                        rules={[
                            {required: true, message: "Please input your phone number"}
                        ]}
                    >
                        <Input autoFocus={true} addonBefore={countrySelector} placeholder="Phone Number"/>
                    </Form.Item>
                </div>
            </div>
            <AllowSubmitOnEnter/>
        </Form>
    );
};