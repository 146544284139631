import * as React from "react";
import {User} from "../../communication/dtos/account/User";
import {Button, Popconfirm, Popover, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import "./teams.scss"

export interface Props {
    accountUser: User;
    hideButtons?: boolean;
    isSelf: boolean;

    onDelete: (userId: string) => void;
    onEdit?: (userId: string) => void;
}

export class ActiveAccountUserRow extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onEdit(): void {
        this.props.onEdit(this.props.accountUser.userId);
    }

    onDelete(): void {
        this.props.onDelete(this.props.accountUser.userId);
    }

    render(): JSX.Element {
        return (
            <div className={"memberRow"} key={this.props.accountUser.userId}>
                <div>{this.props.accountUser.userDisplayName}</div>
                {
                    !this.props.hideButtons &&
                    <div className={"lastRowItem"}>
                        <Button.Group>
                            <Tooltip
                                title="Edit"
                                placement="left"
                            >
                                {this.props.isSelf ?
                                    <Popover
                                        content={(
                                            <p style={{maxWidth: 360}}>You can&apos;t edit your own permissions.</p>)}
                                        trigger="hover"
                                    >
                                        <Button size="large" onClick={this.onEdit}
                                                disabled={true}><EditOutlined/></Button>
                                    </Popover> :
                                    <Button size="large" onClick={this.onEdit}><EditOutlined/></Button>
                                }

                            </Tooltip>
                            <Popconfirm title="Are you sure?" okText="Delete" cancelText="No" onConfirm={this.onDelete}>
                                <Tooltip
                                    title="Delete"
                                    placement="left"
                                >
                                    {this.props.isSelf ?
                                        <Popover
                                            content={(<p style={{maxWidth: 360}}>You can&apos;t delete yourself.</p>)}
                                            trigger="hover"
                                        >
                                            <Button size="large" disabled={true}><DeleteOutlined/></Button>
                                        </Popover> :
                                        <Button size="large"><DeleteOutlined/></Button>
                                    }
                                </Tooltip>
                            </Popconfirm>
                        </Button.Group>
                    </div>
                }
            </div>
        );
    }
}