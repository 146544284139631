import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { PasswordFieldUiState } from "../../../state/uistate/ComponentUiState";

export interface ActionCreatorArgs {
    id: string;
    state: PasswordFieldUiState;
}

export const type = "updatePasswordField";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionCreatorArgs> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionCreatorArgs> = (state, action) => {
    return sprout.assoc(state, ["ui", "components", "passwordFields", action.payload?.id], action.payload?.state);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);