import {GetIntercomUserHashResponse} from "./dtos/userInformation/GetIntercomUserHash";
import {fetchJson} from "./utils/restAccess";
import {GetSubscriptionTierResponse} from "./dtos/userInformation/GetSubscriptionTier";

export const getIntercomUserHash = (): Promise<GetIntercomUserHashResponse> =>

    fetchJson("/v2/user/intercom", {}, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
        });

export const getSubscriptionTier = (): Promise<GetSubscriptionTierResponse> => fetchJson("/v1/userInformation/subscriptionTier");