import * as React from "react";
import {Paths} from "../../util/routes/routes";
import {Redirect, Route, Switch} from "react-router-dom";
import {RegisterPageConnected} from "./pages/register/RegisterPageConnected";
import {ForgotPasswordPageConnected} from "./pages/forgotPassword/ForgotPasswordPageConnected";
import {LoginConnected} from "./pages/login/LoginPageConnected";
import {MFAAuth} from "./pages/MFA/MFAPageConnected";
import {AuthSidebarLayout} from "../layout/auth/AuthSidebarLayout";
import {AuthLayout} from "../layout/auth/AuthLayout";
import {AuthCodeHandlerPageConnected} from "./pages/authMessage/AuthCodeHandlerPageConnected";

export interface AuthLayoutProps {
}

export class AuthMain extends React.PureComponent<AuthLayoutProps, {}> {
    constructor(props: AuthLayoutProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <Switch>
                <Route exact path={Paths.LOGIN_ROOT}>
                    <AuthLayout>
                        <LoginConnected/>
                    </AuthLayout>
                </Route>
                <Route exact path={Paths.AUTH_NEXT_STEP}>
                    <AuthLayout>
                        <AuthCodeHandlerPageConnected/>
                    </AuthLayout>
                </Route>
                <Route exact path={Paths.MULTI_FA}>
                    <AuthLayout>
                        <MFAAuth/>
                    </AuthLayout>
                </Route>
                <Route exact path={Paths.REGISTER_ROOT}>
                    <AuthSidebarLayout>
                        <RegisterPageConnected/>
                    </AuthSidebarLayout>
                </Route>
                <Route exact path={Paths.FORGOT_PASSWORD}>
                    <AuthSidebarLayout>
                        <ForgotPasswordPageConnected/>
                    </AuthSidebarLayout>
                </Route>
                <Redirect to={Paths.LOGIN_ROOT}/>
            </Switch>
        );
    }
}