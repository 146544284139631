import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {deleteCurrency} from "lightrail-client/dist/currencies";

export interface DeleteCurrencyResponse {
    success: boolean;
    code: string;
}

export const type = "deleteCurrency";
export const actionCreator = promisePattern.actionCreator(type, (code: string) => {
    return deleteCurrency(code).then(response => ({
            success: response.body.success,
            code
        })
    );
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteCurrencyResponse> =
    (state, payload) => {
        //Not successful?
        if (!payload?.success) {
            return sprout.assoc(state, ["server", "currency", "processing"], false);
        }

        const newList = state.server.currency.list.concat();
        const deleteIndex = newList.findIndex(p => p.code === payload?.code);
        if (deleteIndex > -1) {
            newList.splice(deleteIndex, 1);
        }
        return sprout.assoc(state,
            ["server", "currency", "list"], newList,
            ["server", "currency", "processing"], false
        );
    };

const onRejected = rejectionHandler([
    {
        reducer: (state) => sprout.assoc<State>(state,
            ["server", "currency", "processing"], false
        )
    },
    {
        status: 409,
        overrideGlobal: true,
        reducer: (state, error) => sprout.assoc<State>(state,
            ["server", "currency", "error"], error.message,
            ["server", "currency", "processing"], false
        )
    }
]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "currency", "error"], "",
        ["server", "currency", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);