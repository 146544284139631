import * as React from "react";
import {FormattedCurrency} from "../../../../ui/currency/FormattedCurrencyConnected";
import {StatsTable} from "../../../../ui/data/StatsTable";
import {ReduxProps, ExternalProps} from "./ValuePerformanceConnected";

export interface Props extends ExternalProps, ReduxProps{
}

export class ValuePerformance extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {
        this.props.getStats({valueId: this.props.value.id});
    }

    componentDidUpdate(oldProps: Props): void {
        if ((!oldProps.value && !!this.props.value) || (!!oldProps.value && oldProps.value.id != this.props.value.id)) {
            this.props.getStats({valueId: this.props.value.id});
        }
    }

    render(): JSX.Element {
        const redemptionData = [];
        const overageData = [];
        const contactsData = [];

        if (!!this.props.statsState.data) {
            const data = this.props.statsState.data;

            // Redemption
            redemptionData.push({
                key: "balance",
                label: "Total Redeemed",
                value: (
                    <FormattedCurrency
                        code={this.props.value.currency}
                        value={data.redeemed.balance}
                    />
                )
            });

            // Overage Data
            overageData.push({
                key: "ovSpend",
                label: "Total Overspend",
                value: (
                    <FormattedCurrency
                        code={this.props.value.currency}
                        value={data.checkout.overspend}
                    />
                )
            });

            overageData.push({
                key: "ovsAvg",
                label: "Average Overspend",
                value: (
                    <FormattedCurrency
                        code={this.props.value.currency}
                        value={Math.round(data.checkout.overspend / data.checkout.transactionCount)}
                    />
                )
            });

            overageData.push({
                key: "ovsTrans",
                label: "Total Checkout Transactions",
                value: data.checkout.transactionCount
            });

            // Contacts Data
            contactsData.push({
                key: "attachedContacts",
                label: "Attached Contacts",
                value: data.attachedContacts.count
            });
        }

        return (
            <div>
                <StatsTable loading={this.props.statsState.fetching} dataSource={redemptionData}/>
                <StatsTable loading={this.props.statsState.fetching} dataSource={overageData}/>
                <StatsTable loading={this.props.statsState.fetching} dataSource={contactsData}/>
            </div>
        );
    }
}