import * as React from "react";
import {CreateProgramFormConnected} from "./CreateProgramFormConnected";
import {Drawer} from "antd";
import {CreateFormDrawerFooter} from "../../layout/forms/CreateFormDrawerFooter";
import {ExternalProps, ReduxProps} from "./CreateProgramDrawerConnected";

export interface Props extends ExternalProps, ReduxProps{
}

export class CreateProgramDrawer extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    onOk(): void {
        if (!!this.submitForm) {
            this.submitForm();
        }
    }

    onCancel(): void {
        this.props.onCancel();
    }

    render(): JSX.Element {
        return (
            <Drawer
                title={(!!this.props.program) ? `Update your Program` : `Create a new Program`}
                maskClosable={false}
                visible={this.props.open}
                destroyOnClose
                className="lr-program-drawer"
                width={800}
                onClose={this.onCancel}
            >
                <CreateProgramFormConnected
                    onCancel={this.onCancel}
                    onCreateResponse={this.onCancel}
                    onUpdateResponse={this.onCancel}
                    getSubmit={this.setSubmit}
                    program={this.props.program}
                />
                <CreateFormDrawerFooter
                    onCancelClick={this.onCancel}
                    onSubmitClick={this.onOk}
                    loading={this.props.processingProgram}
                    submitButtonLabel={(!!this.props.program) ? "Update" : null}
                />
            </Drawer>
        );
    }
}