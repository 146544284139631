import * as React from "react";
import {ForgotPasswordForm} from "./ForgotPasswordForm";
import {AuthLayoutHeader} from "../../AuthLayoutHeader";
import {Alert} from "antd";
import {ReduxProps} from "./ForgotPasswordPageConnected";

// disable eslint for legacy styles
const loginStyles: any = require("../../auth.scss"); // eslint-disable-line

export interface ForgotPasswordProps extends ReduxProps {
}

export class ForgotPasswordPage extends React.PureComponent<ForgotPasswordProps, {}> {
    constructor(props: ForgotPasswordProps) {
        super(props);

        this.onPasswordUpdated = this.onPasswordUpdated.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillUnmount(): void {
        this.props.resetAuthErrorAndSuccessStates({});
    }

    onPasswordUpdated(): void {
        if (!!this.props.auth.errorMessage && !!this.props.auth.errorMessage.length) {
            this.props.resetAuthErrorAndSuccessStates({});
        }
    }

    onSubmit(email: string): void {
        this.props.resendPassword({email: email});
    }

    render(): JSX.Element {
        return (
            <div>
                <AuthLayoutHeader
                    title="Forgot your password?"
                    description="Enter your email address to reset your password"
                />
                {
                    (!this.props.auth.successMessage)
                        ?
                        <div className={loginStyles.form}>
                            <ForgotPasswordForm
                                errorMessage={this.props.auth.errorMessage}
                                processing={this.props.auth.processing}
                                onUpdate={this.onPasswordUpdated}
                                onSubmit={this.onSubmit}
                            />
                        </div>
                        : <div>
                            <Alert
                                className="fadeIn1Delay75"
                                message="Success"
                                description={this.props.auth.successMessage}
                                type="success"
                                showIcon
                            />
                        </div>
                }
            </div>
        );
    }
}