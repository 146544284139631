import * as React from "react";
import {Paths} from "../util/routes/routes";
import {Subnav} from "./toolbars/Subnav";
import {ReduxProps} from "./MainConnected";
import {SessionWatcherConnected} from "./auth/sessionWatcher/SessionWatcherConnected";
import {PaddedLoadingSpinner} from "./ui/Loading";
import {RestErrorDialogConnected} from "./ui/dialogs/restErrorDialog/RestErrorDialogConnected";
import {IntercomUserHashLoaderConnected} from "./intercom/IntercomUserHashLoaderConnected";
import {IssuanceCreationWatcherConnected} from "./issuance/IssuanceCreationWatcherConnected";
import {Redirect, Route, Switch} from "react-router-dom";
import {
    AccountBillingPage,
    AccountProfilePage,
    AccountSecurityPage,
    AccountTeamPage,
    CurrencyPage,
    WebhookDetailPage,
    WebhookPage,
} from "./account/";
import {IntegrationsLayoutConnected} from "./teams/integrations/IntegrationsLayoutConnected";
import {WithRouterProps} from "./router/WithRouterProps";
import {ProgramsPage} from "./programs/ProgramsPage";
import {ProgramDetailPageConnected} from "./programs/ProgramDetailPageConnected";
import {ValueDetailPageConnected} from "./values/detailPages/ValueDetailPageConnected";

import {Layout, Row} from "antd";
import {ValuesPage} from "./values/ValuesPage";
import {ContactDetailPageConnected} from "./contacts/ContactDetailPageConnected";
import {Breadcrumbs} from "./ui/header/Breadcrumbs";
import {GlobalSearchDropdownConnected} from "./search/global/GlobalSearchDropdownConnected";
import {ReportsPageConnected} from "./reports/ReportsPageConnected";
import {TransactionsPage} from "./transactions/TransactionsPage";
import {TransactionDetailPageConnected} from "./transactions/details/TransactionDetailPageConnected";
import {CurrencyWatcherConnected} from "./ui/currency/currencyNotification/CurrencyWatcherConnected";
import {RouteNotFoundPage} from "./router/RouteNotFoundPage";
import {ContactsPageConnected} from "./contacts/ContacsPageConnected";
import {MenuUnfoldOutlined, MenuFoldOutlined} from "@ant-design/icons";
import {SideMenu} from "./toolbars/SideMenu";
import {TestBanner} from "./ui/TestBanner";
import {ErrorBoundary} from "./ui/ErrorBoundary";
import {reloadPage} from "../util/navigation";
import {AccountMessageWatcherConnected} from "./toolbars/accountChange/AccountMessageWatcherConnected";

// ONLY NEED TO IMPORT THIS HERE - These are the Globals
require("../styles/legacy/app.scss");

export interface Props extends WithRouterProps<{ codeId?: string }>, ReduxProps {
}

export class MainLayout extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.redirectIfUnauthenticated = this.redirectIfUnauthenticated.bind(this);
        this.onCollapse = this.onCollapse.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(): void {
        if (this.props.accountMessageCode) {
            reloadPage(this.props.history);
        }

        this.redirectIfUnauthenticated();

        this.loadInitialData();

    }

    componentDidUpdate(oldProps: Props): void {
        if (!(this.props.location.pathname === oldProps.location.pathname)) {
            this.props.ping({});
        }

        if (oldProps.location !== this.props.location) {
            window.scrollTo(0, 0);
        }

        if (
            (!oldProps.loggedIn && this.props.loggedIn) ||
            (!oldProps.additionalAuthenticationRequired && this.props.additionalAuthenticationRequired) ||
            (oldProps.testing !== this.props.testing)
        ) {
            this.loadInitialData();
        }
    }

    async loadInitialData(): Promise<void> {
        if (this.props.testing === false) {
            this.props.getSubscriptionTier({});
        }

        if (this.props.loggedIn && !this.props.additionalAuthenticationRequired) {
            await Promise.all([
                this.props.getKVStorageKeys({}),
                this.props.getJwt({}),
                this.props.getCurrencies({}),
            ]);
        }
    }

    async redirectIfUnauthenticated(): Promise<void> {
        try {
            await this.props.ping({});
            const user = await this.props.getUser({});

            if (user.value.additionalAuthenticationRequired) {
                this.props.history.push(Paths.MULTI_FA);
            }
        } catch {
            this.props.history.push(Paths.LOGIN_ROOT);
        }
    }

    state = {
        collapsed: false,
        pageTitle: ""
    };

    toggle(): void {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    onCollapse(collapsed: boolean): void {
        this.setState({collapsed});
    }

    //[ RENDER ]
    render(): JSX.Element {
        if (!this.props.loggedIn) {
            return (
                <Layout className="lr-app-main">
                    <PaddedLoadingSpinner/>
                </Layout>
            );
        }

        return (
            <Layout className="lr-app-main">
                <ErrorBoundary>
                    <Layout.Sider
                        collapsible
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}
                        trigger={this.state.collapsed ? <MenuUnfoldOutlined className="toggle"/> :
                            <MenuFoldOutlined className="toggle"/>}
                    >
                        <SideMenu/>
                    </Layout.Sider>
                    <Layout className="lr-layout-main-container">
                        {this.props.testing && <TestBanner/>}
                        <Layout.Header className="lr-layout-main-header">
                            <Row>
                                <Breadcrumbs/>
                                <div className="alignRight">
                                    <GlobalSearchDropdownConnected/>
                                </div>
                            </Row>
                        </Layout.Header>
                        <Layout.Content className="lr-layout-main-content">
                            <Subnav
                                location={this.props.location}
                                match={this.props.match}
                                history={this.props.history}
                            />
                            <Switch>
                                <Redirect exact from={Paths.ACCOUNT_ROOT} to={Paths.ACCOUNT_PROFILE}/>
                                <Route exact path={Paths.ACCOUNT_PROFILE} component={AccountProfilePage}/>
                                <Route exact path={Paths.ACCOUNT_SECURITY} component={AccountSecurityPage}/>
                                <Route exact path={Paths.ACCOUNT_BILLING} component={AccountBillingPage}/>
                                <Route exact path={Paths.ACCOUNT_TEAM} component={AccountTeamPage}/>
                                <Route exact path={Paths.ACCOUNT_API} component={IntegrationsLayoutConnected}/>
                                <Route exact path={Paths.ACCOUNT_CURRENCY} component={CurrencyPage}/>
                                <Route exact path={Paths.ACCOUNT_WEBHOOK} component={WebhookPage}/>
                                <Route exact path={Paths.ACCOUNT_WEBHOOK_DETAIL} component={WebhookDetailPage}/>

                                <Route path={Paths.CHANGE_EMAIL_COMPLETED} component={AccountProfilePage}/>

                                <Route exact path={Paths.PROGRAM_LIST} component={ProgramsPage}/>
                                <Route exact path={Paths.PROGRAM_DETAIL} component={ProgramDetailPageConnected}/>

                                <Route exact path={Paths.CONTACT_LIST} component={ContactsPageConnected}/>
                                <Route exact path={Paths.CONTACT_DETAIL} component={ContactDetailPageConnected}/>

                                <Route exact path={Paths.TRANSACTIONS_LIST} component={TransactionsPage}/>
                                <Route exact path={Paths.TRANSACTION_DETAIL}
                                       component={TransactionDetailPageConnected}/>

                                <Route exact path={Paths.VALUE_LIST} component={ValuesPage}/>
                                <Route exact path={Paths.VALUE_DETAIL} component={ValueDetailPageConnected}/>

                                <Route exact path={Paths.REPORTS_ROOT} component={ReportsPageConnected}/>

                                <Redirect exact from={Paths.ROOT} to={Paths.PROGRAM_LIST}/>
                                <Route component={RouteNotFoundPage}/>
                            </Switch>
                        </Layout.Content>
                    </Layout>
                    <IntercomUserHashLoaderConnected/>
                    <SessionWatcherConnected/>
                    <IssuanceCreationWatcherConnected/>
                    <CurrencyWatcherConnected/>
                    <AccountMessageWatcherConnected/>
                    {
                        !this.props.accountMessageCode &&
                        <RestErrorDialogConnected/>
                    }
                </ErrorBoundary>
            </Layout>
        );
    }
}