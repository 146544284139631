import * as React from "react";
import {Link, withRouter} from "react-router-dom";
import {LoginForm} from "./LoginForm";
import {
    LoginRequest, LoginResponse,
} from "../../../../communication/dtos/auth/Login";
import {Paths} from "../../../../util/routes/routes";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {onSuccessLogin} from "../../../../util/routes/onSuccessLogin";
import {Row} from "antd";
import {ExternalProps, ReduxProps} from "./LoginPageConnected";
import {ActionResponse} from "../../../../actions/promisePattern";

export interface Props extends WithRouterProps<{}>, ExternalProps, ReduxProps {
}

export class LoginPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.onRegisterClick = this.onRegisterClick.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount(): void {
        this.redirectIfAuthenticated();
    }

    componentWillUnmount(): void {
        this.props.resetAuthErrorAndSuccessStates({});
    }

    onRegisterClick(): void {
        this.props.history.push(Paths.REGISTER_ROOT);
    }

    async redirectIfAuthenticated(): Promise<void> {
        try {
            const response = await this.props.ping({});
            if (response &&
                this.props.userId &&
                !this.props.additionalAuthenticationRequired &&
                !this.props.authMessage
            ) {
                onSuccessLogin(this.props.stateHistory, this.props.history);
            }
        } catch (errResponse) {
            return;
        }
    }

    async login(params: LoginRequest): Promise<void> {
        this.props.setRestError({status: 0, code: "", message: "", ignoreError: true});

        let loginResponse: ActionResponse<LoginResponse>;
        try {
            loginResponse = await this.props.login(params);
        } catch (err) {
            // this is just a typo for username or password and doesn't need to be handled
        }

        if (loginResponse) {
            onSuccessLogin(this.props.stateHistory, this.props.history);
        }
    }

    render(): JSX.Element {
        return (
            <div>
                <h2 className="lr-auth-header">Log in</h2>
                <LoginForm
                    processing={this.props.processing}
                    errorMessage={this.props.auth.errorMessage}
                    onSubmit={this.login}
                />
                <Row>
                    {/*<Link to={Paths.REGISTER_ROOT}><UserAddOutlined/> Register</Link>*/}
                    <Link className="alignRight" to={Paths.FORGOT_PASSWORD}>Forgot password?</Link>
                </Row>
            </div>
        );
    }
}

export const AuthRouted = withRouter(LoginPage);
