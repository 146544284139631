import {
    InternalTransactionStep,
    LightrailTransactionStep,
    StripeTransactionStep,
    Transaction,
    TransactionStep
} from "lightrail-client/dist/model";

export function getBalanceChangeForValue(transaction: Transaction, valueId: string): number {
    let balanceChange = 0;
    transaction.steps.some(step => {
        if (step.rail == "lightrail" && (step as LightrailTransactionStep).valueId == valueId) {
            balanceChange = step.balanceChange;
            return true;
        }

        return false;
    });

    return balanceChange;
}

export function getUsesChangeForValue(transaction: Transaction, valueId: string): number {
    let usesChange = 0;
    transaction.steps.some(step => {
        if (step.rail == "lightrail" && (step as LightrailTransactionStep).valueId == valueId) {
            usesChange = (step as any).usesRemainingChange;
            return true;
        }

        return false;
    });

    return usesChange;
}

export function getTotalLightrailBalanceChange(transaction: Transaction): number {
    let balanceChange = 0;
    transaction.steps.forEach(step => {
        if (step.rail == "lightrail") {
            balanceChange = step.balanceChange + balanceChange;
            return true;
        }

        return false;
    });

    return balanceChange;
}

export function getTotalLightrailUsesChange(transaction: Transaction): number {
    let usesChange = 0;
    transaction.steps.forEach(step => {
        if (step.rail == "lightrail") {
            usesChange = (step as any).usesRemainingChange + usesChange;
            return true;
        }

        return false;
    });

    return usesChange;
}

/**
 * Sum of the steps in a transaction
 *
 * @param {Transaction} transaction
 * @returns {number}
 */
export const getTransactionTotal = (transaction: Transaction, filterType?: "lightrail" | "stripe" | "internal"): number => transaction.steps.reduce((val: number, step: TransactionStep) => {
        if (!!filterType && step.rail != filterType) {
            return val;
        }

        let stepVal = 0;

        if (step.rail == "lightrail") {
            stepVal = (step as LightrailTransactionStep).balanceChange;
        }
        if (step.rail == "stripe") {
            stepVal = (step as StripeTransactionStep).amount;
        }
        if (step.rail == "internal") {
            stepVal = (step as InternalTransactionStep).balanceChange;
        }

        return val + stepVal;
    },
    0
);