import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {GetProgramStatsResponse} from "../../../communication/dtos/program/GetProgramStats";
import {onPendingFetching} from "../../reducerHelpers";
import {getValueStats} from "../../../communication/values";

export const type = "getValueStats";

export const actionCreator = promisePattern.actionCreator(type, getValueStats);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetProgramStatsResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "values", "stats", "data"], payload,
            ["server", "values", "stats", "fetching"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => onPendingFetching(state, ["server", "values", "stats"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);