import * as promisePattern from "../../promisePattern";
import {getSubscriptionTier} from "../../../communication/userInformation";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {GetSubscriptionTierResponse} from "../../../communication/dtos/userInformation/GetSubscriptionTier";
import {assoc} from "sprout-data";

export const type = "getSubscriptionTier";

export const actionCreator = promisePattern.actionCreator(type, getSubscriptionTier);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetSubscriptionTierResponse> = (state, payload) => {
    const fetchCount = state.server.user.subscription.fetchCount + 1;
    return assoc(state,
        ["server", "user", "subscription", "subscriptionTier"], payload?.subscriptionTier,
        ["server", "user", "subscription", "fetchCount"], fetchCount,
        ["server", "user", "subscription", "fetching"], false,
    );
};

const onRejection: RejectionReducer = {
    reducer: (state) => {
        const fetchCount = state.server.user.subscription.fetchCount + 1;
        return assoc(state,
            ["server", "user", "subscription", "fetching"], false,
            ["server", "user", "subscription", "fetchCount"], fetchCount,
        );
    },
    overrideGlobal: true
};

const onRejected = rejectionHandler([onRejection]);

const onPending: promisePattern.PromisePendingHandler = (state) => assoc(state,
    ["server", "user", "subscription", "fetching"], true,
);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);