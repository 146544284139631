import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {getProgram} from "lightrail-client/dist/programs";
import {getContact, listContacts} from "lightrail-client/dist/contacts";
import {getValue, listValues} from "lightrail-client/dist/values";
import {Contact, Program, Transaction, Value} from "lightrail-client/dist/model";
import {LightrailResponse} from "lightrail-client/dist/params";
import {SearchId, SearchResults, SearchType} from "../../../state/serverState/SearchesServerState";
import {getTransaction} from "lightrail-client/dist/transactions";

const getBody = (r: LightrailResponse<any>): any => (!!r && !!r.body) ? r.body : null;

export interface SearchActionParams {
    value: string;
    searchType: SearchType;
}

export interface SearchActionResponse {
    searchValue: string;
    searchType: SearchType;
    program?: Program;
    programs?: Program[];
    contact?: Contact;
    contacts?: Contact[];
    value?: Value;
    values?: Value[];
    transaction?: Transaction;
}

export const type = "search";

export const actionCreator = promisePattern.actionCreator(type, (params: SearchActionParams) => {
    return new Promise((resolve, reject) => {
        const searchList: Promise<any>[] = [];
        const searchValue = params.value.trimLeft();

        // Program Search
        if (params.searchType == SearchType.EVERYTHING || params.searchType == SearchType.PROGRAMS) {
            searchList.push(
                getProgram(searchValue).then(r => ({program: getBody(r)}))
            );
        }
        // Contacts Search
        if (params.searchType == SearchType.EVERYTHING || params.searchType == SearchType.CONTACTS) {
            searchList.push(
                getContact(searchValue).then(r => ({contact: getBody(r)})),
                listContacts({email: {eq: searchValue}}).then(r => ({contacts: getBody(r)}))
            );
        }
        // Values Search
        if (params.searchType == SearchType.EVERYTHING || params.searchType == SearchType.VALUES) {
            searchList.push(
                getValue(searchValue).then(r => ({value: getBody(r)})),
                listValues({code: {eq: searchValue}}).then(r => ({values: getBody(r)}))
            );
        }
        // Transaction Search
        if (params.searchType == SearchType.EVERYTHING || params.searchType == SearchType.TRANSACTIONS) {
            searchList.push(
                getTransaction(searchValue).then(r => ({transaction: getBody(r)}))
            );
        }

        // Execute SearchList and combine results into single object
        Promise.all(searchList).then(responseArray => {
            //Combine results into object
            let responseObject = {searchValue: params.value, searchType: params.searchType};
            responseArray.forEach(response => {
                responseObject = {...responseObject, ...response};
            });

            resolve(responseObject);
        }, (e) => {
            reject(e);
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<SearchActionResponse> =
    (state, payload) => {
        // Convert results object into state object

        // Start with list results to populate list state
        const results: SearchResults = {
            programs: [],
            contacts: (!!payload?.contacts) ? [...payload?.contacts] : [],
            values: (!!payload?.values) ? [...payload?.values] : [],
            transactions: []
        };

        // Next Push individual search results (getById call results)
        if (!!payload?.program) {
            results.programs.push(payload?.program);
        }

        if (!!payload?.contact) {
            results.contacts.push(payload?.contact);
        }
        if (!!payload?.value) {
            results.values.push(payload?.value);
        }
        if (!!payload?.transaction) {
            results.transactions.push(payload?.transaction);
        }

        // Update State
        return sprout.assoc(state,
            ["server", "search", SearchId.GLOBAL, "results"], results,
            ["server", "search", SearchId.GLOBAL, "searchedValue"], payload?.searchValue,
            ["server", "search", SearchId.GLOBAL, "searchedType"], payload?.searchType,
            ["server", "search", SearchId.GLOBAL, "fetchCount"], state.server.search[SearchId.GLOBAL].fetchCount + 1,
            ["server", "search", SearchId.GLOBAL, "fetching"], false,
        );
    };

const onRejected = rejectionHandler([{
    overrideGlobal: true,
    reducer: (state) => sprout.assoc(state,
        ["server", "search", SearchId.GLOBAL, "fetching"], false,
        ["server", "search", SearchId.GLOBAL, "fetchCount"], state.server.search[SearchId.GLOBAL].fetchCount + 1
    )
}]);

const onPending: promisePattern.PromisePendingHandler = (state) => sprout.assoc(state, ["server", "search", SearchId.GLOBAL, "fetching"], true);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);