import * as React from "react";
import {ProgramsListConnected} from "./list/ProgramsListConnected";

export interface Props {
    doesntexist?: {
        message: string;
    };
}

export class ProgramsPage extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div>
                <ProgramsListConnected/>
            </div>
        );
    }
}