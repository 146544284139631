import {State} from "../../../state/State";
import * as ReactRedux from "react-redux";
import {InternalCurrencyDisplay} from "./FormattedCurrency";
import { Currency } from "lightrail-client/dist/model/Currency";

export interface ExternalProps {
    prefix?: string;
    postfix?: string;
    code: string;
    value: number;
}

interface StateProps {
    currencyList: Currency[];
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    ...props,
    currencyList: state.server.currency.list
});

const connector = ReactRedux.connect<StateProps, {}, ExternalProps>(mapStateToProps);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const FormattedCurrency = connector(InternalCurrencyDisplay);