import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {contactCustomerSupport} from "../../../communication/contactCustomerSupport";
import {ContactCustomerSupportResponse} from "../../../communication/dtos/contactCustomerSupport/ContactCustomerSupport";

export const type = "contactCustomerSupport";
export const actionCreator = promisePattern.actionCreator(type, contactCustomerSupport);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ContactCustomerSupportResponse> =
    (state) => {
        const updatedRequestCount = state.server.contactUs.contactCustomerSupport.requestsFulfilled + 1;
        return sprout.assoc(state,
            ["server", "contactUs", "contactCustomerSupport", "processing"], false,
            ["server", "contactUs", "contactCustomerSupport", "requestsFulfilled"], updatedRequestCount,
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            contactUs: {
                contactCustomerSupport: {
                    processing: true
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);