import * as React from "react";
import {Modal} from "antd";
import {EditCurrencyFormConnected} from "./EditCurrencyFormConnected";
import {ExternalProps, ReduxProps} from "./EditCurrencyModalConnected";
import {Currency} from "lightrail-client/dist/model/Currency";

export interface Props extends ExternalProps, ReduxProps{
    currency: Currency;
    processingCurrency: boolean;
    onCancel?: () => void;
}

export class EditCurrencyModal extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);

        this.setSubmit = this.setSubmit.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    onOk(): void {
        if (!!this.submitForm) {
            this.submitForm();
        }
    }

    onCancel(): void {
        this.props.onCancel();
    }

    render(): JSX.Element {
        if (!this.props.currency) {
            return null;
        }

        return (
            <Modal
                title={`Edit ${this.props.currency.code}`}
                maskClosable={false}
                visible={true}
                okText="Save"
                onOk={this.onOk}
                onCancel={this.onCancel}
                confirmLoading={this.props.processingCurrency}
            >
                <EditCurrencyFormConnected
                    currency={this.props.currency}
                    onEditResponse={this.onCancel}
                    getSubmit={this.setSubmit}
                />
            </Modal>
        );
    }
}