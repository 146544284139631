import * as React from "react";
import {Form, Input, message} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {defaultDash, prefixIdWithDots} from "../../../util/stringUtils";
import {formatDate} from "../../../util/dateUtils";
import moment = require("moment");
import {useForm} from "antd/lib/form/Form";
import {ExternalProps, ReduxProps} from "./ChangeCodeFormConnected";

export interface Props extends ReduxProps, ExternalProps {
}

export const ChangeCodeForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const updateValueMetadata = (values: any): void => {
        props.updateValue({
            valueId: props.valueId,
            params: {metadata: values}
        });
    };

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        let metadataUpdated: { [k: string]: any };
        if (props.metadata) {
            metadataUpdated = {...props.metadata};
            metadataUpdated["lightrail_webapp_code_changed_from"] = props.initialCode ? props.initialCode : defaultDash;
            metadataUpdated["lightrail_webapp_code_changed_to"] = props.isGeneric ? values.code : prefixIdWithDots(Array.from(values.code).slice(-4).join(""));
            metadataUpdated["lightrail_webapp_code_changed_on"] = formatDate(moment(new Date()).toISOString());
            metadataUpdated["lightrail_webapp_code_changed_by"] = props.userServerState.email;
        }
        const response = await props.changeValuesCode({valueId: props.valueId, params: values}).value;

        message.success(`Code has been updated`);
        // set up exisiting Value metadata
        updateValueMetadata(metadataUpdated);

        if (!!props.onChangeCodeResponse) {
            props.onChangeCodeResponse(response);
        }
    };

    React.useEffect(() => props.getSubmit(submit), []);

    return (
        <Form onFinish={submit} form={form} hideRequiredMark>
            <Form.Item
                label="New Code"
                name={FormFieldIds.Code}
                initialValue={props.initialCode}
            >
                <Input
                    size="large"
                    type="text"
                    autoFocus
                />
            </Form.Item>
            <AllowSubmitOnEnter/>
        </Form>
    );
};