import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {voidPending} from "lightrail-client/dist/transactions";
import {VoidPendingParams, VoidPendingResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";

export interface VoidPendingTransactionActionParams {
    transactionToVoid: string;
    params: VoidPendingParams;
}

export const type = "capturePendingTransactions";
export const actionCreator = promisePattern.actionCreator(type, (r: VoidPendingTransactionActionParams) => voidPending(r.transactionToVoid, r.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<VoidPendingResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? [payload?.body, ...state.server.transactions.list] : state.server.transactions.list;
        return sprout.assoc(state,
            ["server", "transactions", "list"], list,
            ["server", "transactions", "error"], null,
            ["server", "transactions", "processing"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "transactions"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);