import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {initialState} from "../../../state/State";

export interface ActionParams {
    toggle: boolean;
}

export const type = "toggleMFAVerify";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: {toggle: args.toggle}
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    if (action.payload?.toggle) {
        return sprout.assoc(state,
            ["ui", "MFA", "isMFAVerifyOpen"], action.payload?.toggle,
            ["ui", "MFA", "CodeOneAccepted"], false,
            ["ui", "MFA", "QRCodeSuccess"], false,
            ["ui", "MFA", "phoneNumberSuccess"], false,
            ["ui", "MFA", "QRCodeModalOpen"], false,
            ["ui", "MFA", "isMFAMethodModalOpen"], false,
        );
    }
    return sprout.assoc(state, ["ui", "MFA"], initialState.ui.MFA);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);