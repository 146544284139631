import * as React from "react";
import {AccountLayout} from "../../layout/account/AccountLayout";
import {Button, Table} from "antd";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {AccountHeader} from "../../layout/account/AccountHeader";
import {CreateWebhookModalConnected} from "./create/CreateWebhookModalConnected";
import {formatDate} from "../../../util/dateUtils";
import {formatRoute, Paths} from "../../../util/routes/routes";
import {WithRouterProps} from "../../router/WithRouterProps";
import {ReduxProps} from "./WebhookPageConnected";
import {PlusOutlined} from "@ant-design/icons";

interface TableRow {
    key: string;
    id: string;
    url: string;
    active: string;
    createdDate: string;
}

export interface Props extends WithRouterProps<{}>, ReduxProps {
}

export class WebhookPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.onToggleCreateWebhook = this.onToggleCreateWebhook.bind(this);
        this.setupRow = this.setupRow.bind(this);
    }

    componentDidMount(): void {
        this.props.clearWebhooksList({});
        this.props.listWebhooks({});
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.webhookServerState.list.length !== this.props.webhookServerState.list.length) {
            this.props.listWebhooks({});
        }
    }

    ///////////////////
    //[ ACTIONS ]
    setupRow(record: TableRow): { onClick: () => void } {
        return {
            onClick: () => this.props.history.push(formatRoute(Paths.ACCOUNT_WEBHOOK_DETAIL, {webhookId: record.id}))
        };
    }

    onToggleCreateWebhook(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_CREATE_MODAL_OPEN,
            value: !this.props.webhookUiState.createModalOpen
        });
    }

    ///////////////////
    //[ RENDER ]
    getTableRows(): TableRow[] {
        return this.props.webhookServerState.list.map(
            webhook => ({
                key: webhook.id,
                id: webhook.id,
                url: webhook.url,
                active: (!!webhook.active) ? "Active" : "Inactive",
                createdDate: formatDate(webhook.createdDate),
            })
        );
    }

    render(): JSX.Element {
        const tableColumns = [
            {
                title: "URL",
                dataIndex: "url",
                key: "url"
            },
            {
                title: "Status",
                dataIndex: "active",
                key: "active"
            },
            {
                title: "Date Created",
                dataIndex: "createdDate",
                key: "createdDate",
            }
        ];

        return (
            <AccountLayout>
                <AccountHeader
                    title="Webhooks"
                    actionButton={<Button onClick={this.onToggleCreateWebhook}>
                        <PlusOutlined/> Create Webhook</Button>}
                />
                <Table
                    className="lr-table-no-hover"
                    loading={this.props.webhookServerState.fetching}
                    columns={tableColumns}
                    dataSource={this.getTableRows()}
                    onRow={this.setupRow}
                    scroll={{x: 700}}
                    pagination={false}
                    sortDirections={["ascend", "descend"]}
                />
                {
                    this.props.webhookUiState.createModalOpen &&
                    <CreateWebhookModalConnected
                        onCancel={this.onToggleCreateWebhook}
                    />
                }
            </AccountLayout>
        );
    }
}