import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    editing: boolean;
}

export interface ActionCreatorArgs extends ActionParams {
}

export const type = "updateTurnKeyEditMode";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    const payload = action.payload;

    return sprout.assoc(state,
        ["ui", "turnKey", "editing"], payload?.editing
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);