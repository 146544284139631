import * as React from "react";
import {useEffect, useState} from "react";
import {Alert, Button, Form} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {LoginRequest} from "../../../../communication/dtos/auth/Login";
import {EmailItem} from "../../../ui/forms/items/EmailItem";
import {AuthServerStateErrorMessage} from "../../../../state/serverState/AuthServerState";
import {PasswordFieldConnected} from "../../../ui/forms/passwordField/PasswordFieldConnected";
import {AuthFormErrorItem} from "../../forms/AuthFormErrorItem";
import {useForm} from "antd/lib/form/Form";

const FormItem = Form.Item;

export interface Props {
    onUpdate?: () => void;
    errorMessage?: AuthServerStateErrorMessage;
    processing?: boolean;
    onSubmit: (params: LoginRequest) => void;
    getSubmit: (submit: () => void) => void;
}

export const RegisterForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const {getFieldError} = form;

    const values = form.getFieldsValue([FormFieldIds.Email, FormFieldIds.Password]) as { [index: string]: any };

    const [errors, setErrors] = useState([
        ...(props.errorMessage || [])
    ]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const haveValues = Object.keys(values).every((k) => !!values[k]);

    const submit = (): void => {
        form.validateFields().then(
            (values: LoginRequest) => props.onSubmit(values)
        );
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    useEffect(() => {
        const emailError = form.isFieldTouched(FormFieldIds.Email) && getFieldError(FormFieldIds.Email);
        const passwordError = form.isFieldTouched(FormFieldIds.Password) && getFieldError(FormFieldIds.Password);

        setErrors([
            ...Array.from(emailError || []),
            ...Array.from(passwordError || []),
            ...(props.errorMessage || [])
        ]);
    }, [email, password]);

    useEffect(() => {
        if (props.onUpdate && props.errorMessage?.length) {
            props.onUpdate();
        }

    });

    const renderSuccess = (): JSX.Element => (
        <Form.Item>
            <Alert
                message="You're all set!"
                showIcon
                type="success"
            />
        </Form.Item>
    );

    return (
        <Form onFinish={submit} form={form}>
            <EmailItem
                form={form}
                size="large"
                hideHelp={true}
                onChange={(event) => setEmail(event.target.value)}
                value={email}
            />
            <PasswordFieldConnected
                form={form}
                size="large"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
            />
            {
                (errors?.length) ?
                    <AuthFormErrorItem errors={errors as string[]}/> : null
            }
            {
                (!errors.length && haveValues) ?
                    renderSuccess() : null
            }
            <FormItem>
                <Button
                    type="primary"
                    size="large"
                    style={{width: "100%"}}
                    htmlType="submit"
                    disabled={!!errors.length || !(email.length && password.length)}
                    loading={props.processing}
                >
                    Create Lightrail Account
                </Button>
            </FormItem>
        </Form>
    );
};