import {decode} from "jsonwebtoken";

export const getJwtSessionCookie = (): string => {
    const jwtCookie = document.cookie.match(/gb_jwt_session=([A-z0-9.]*);/);
    return (jwtCookie && jwtCookie.length) ? jwtCookie[1] : "";
};

export const getParsedJwt = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const jwtAddition = ".asdf";
        let jwt = getJwtSessionCookie();
        if (jwt) {
            const jwtToParse = jwt + jwtAddition;
            resolve(decode(jwtToParse));
            return;
        }

        const maxAttempts = 100;
        let attempts = 0;
        let pollInterval: number = null;
        const checkSessionCookie = (): void => {
            jwt = getJwtSessionCookie();
            if (jwt) {
                const jwtToParse = jwt + jwtAddition;
                window.clearInterval(pollInterval);
                resolve(decode(jwtToParse));
                return;
            } else {
                attempts++;
                if (attempts > maxAttempts) {
                    window.clearInterval(pollInterval);
                    reject();
                }
            }
        };

        pollInterval = window.setInterval(checkSessionCookie, 100);
    });
};