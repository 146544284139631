import * as React from "react";
import {ReactNode} from "react";
import {Avatar, Col, Divider, Row} from "antd";
import {BackButton} from "../../ui/buttons/BackButton";
import {ProgramExpiredTag} from "../../programs/ProgramExpiredTag";

export interface DetailHeaderProps {
    avatarColor?: string;
    title: ReactNode;
    avatar?: ReactNode;
    icon?: JSX.Element;
    details?: ReactNode[];
    actionButton?: ReactNode;
    showBackButton?: boolean;
    displayExpiredBadge?: boolean;
}

export class DetailHeader extends React.PureComponent<DetailHeaderProps, {}> {
    render(): JSX.Element {
        return (
            <div>
                <Row className="lr-layout-header">
                    {
                        // Back Button
                    }
                    {
                        (!!this.props.showBackButton) &&
                        <Col>
                            <BackButton/>
                        </Col>
                    }
                    {
                        // Avatar
                    }
                    {
                        (!!this.props.avatar || !!this.props.icon) &&
                        <Col>

                            {
                                <Avatar shape="square"
                                        className="lr-avatar-xl"
                                        style={{backgroundColor: (!!this.props.avatarColor) ? this.props.avatarColor : null}}
                                >
                                    {this.props.avatar}
                                    {this.props.icon}
                                </Avatar>
                            }

                        </Col>
                    }
                    {
                        // Title & Details
                    }
                    <Col span={12}>
                        <h2 className="lr-title" data-cy="detail-header-title">
                            {
                                this.props.title
                            }
                        </h2>
                        {
                            (!!this.props.displayExpiredBadge) &&
                            <ProgramExpiredTag expired={this.props.displayExpiredBadge}/>
                        }
                        {
                            (!!this.props.details) &&
                            <ul className="details">
                                {
                                    //Could be list?
                                    this.props.details.map((detail, i) => (<li key={"detail" + i}>{detail}</li>))
                                }
                            </ul>
                        }
                    </Col>
                    {
                        // Action Button
                    }
                    {
                        (!!this.props.actionButton) &&
                        <Col style={{marginLeft: "auto"}}>
                            {
                                this.props.actionButton
                            }
                        </Col>
                    }
                </Row>
                <Divider/>
            </div>
        );
    }
}