import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {ActionResponse} from "../../promisePattern";
import {State} from "../../../state/State";
import {listAccounts} from "../../../communication/account";
import {ListAccountsResponse} from "../../../communication/dtos/account/ListAccounts";
import {Account} from "../../../communication/dtos/account/Account";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";

export const type = "listAccounts";

export type ListAccountsActionResponse = ActionResponse<ListAccountsResponse>;

export const actionCreator = promisePattern.actionCreator(type, listAccounts);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListAccountsResponse> = (state: State, payload: ListAccountsResponse) => {
    const accountList: Account[] = payload ? payload.map(
        (account) => ({
            id: account.accountId,
            name: account.accountDisplayName
        })
    ) : [];

    return sprout.assoc(state,
        ["server", "account", "accounts"], accountList,
        ["server", "account", "fetching"], false
    );
};

const onPending: promisePattern.PromisePendingHandler = (state: State) => {
    return sprout.assoc(state,
        ["server", "account", "fetching"], true
    );
};

const rejectionReducer: RejectionReducer = {
    reducer: (state: State) => sprout.assoc(state,
        ["server", "account", "fetching"], [],
    )
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([rejectionReducer]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);