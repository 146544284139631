import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {deleteSecret} from "../../../communication/webhooks";
import {Webhook} from "../../../communication/dtos/webhooks/Webhook";

export const type = "deleteSecret";
export const actionCreator = promisePattern.actionCreator(type, deleteSecret);

const onFulfilled: promisePattern.PromiseFulfilledHandler<Webhook> =
    (state) => {
        return sprout.assoc(state,
            ["server", "webhook", "secret", "processing"], false
        );
    };

const onRejected = rejectionHandler([{
    reducer: state => sprout.assoc<State>(state,
        ["server", "webhook", "secret", "processing"], false
    )
}]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "webhook", "secret", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);