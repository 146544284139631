import * as React from "react";
import {Paths} from "../../../util/routes/routes";
import {Link} from "react-router-dom";

export interface Props {
}

export class AuthSidebarLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div style={{height: "100%", backgroundColor: "#c9fcfb"}}>
                <div style={{
                    display: "flex", flexDirection: "column",
                    padding: "16px 48px",
                    maxWidth: 480,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white"
                }}>
                    <div>
                        {this.props.children}
                    </div>
                    <div style={{marginTop: "auto", textAlign: "right"}}>
                        <p>Return to <Link to={Paths.LOGIN_ROOT}>Log in</Link></p>
                    </div>
                </div>
            </div>
        );
    }
}