import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {actionCreator as globalSearch} from "../../../actions/search/server/globalSearch";
import {actionCreator as clearSearch} from "../../../actions/search/server/clearSearch";
import {SearchForm} from "./SearchForm";
import {SearchId, SearchServerState, SearchType} from "../../../state/serverState/SearchesServerState";

export interface ExternalProps {
    id: SearchId; //Unique id for results set in state
    defaultSearchType?: SearchType;
    searchType?: SearchType;
    dropdownClassName?: string;
    initialValue?: string;
}

export type StateProps = {
    serverState: SearchServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    serverState: state.server.search,
    ...props
});

const actionCreators = {
    globalSearch,
    clearSearch
};

const connector = ReactRedux.connect(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const SearchFormConnected = connector(SearchForm);