// disable eslint to import legacy styles
const giftbitColors: any = require("../styles/legacy/_colors.scss"); // eslint-disable-line

export const getColorFromId = (id: string): any => {
    const avatarColors = [
        giftbitColors.avatarColor0,
        giftbitColors.avatarColor1,
        giftbitColors.avatarColor2,
        giftbitColors.avatarColor3,
        giftbitColors.avatarColor4,
        giftbitColors.avatarColor5,
        giftbitColors.avatarColor6,
        giftbitColors.avatarColor7,
        giftbitColors.avatarColor8,
        giftbitColors.avatarColor9,
        giftbitColors.avatarColor10,
        giftbitColors.avatarColor11
    ];
    const charCodesSum = id.split("").reduce((sum, char) => sum + char.charCodeAt(0), 0);
    return avatarColors[charCodesSum % 12];
};