import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {CreateContactResponse} from "lightrail-client/dist/params";
import {createContact} from "lightrail-client/dist/contacts";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";

export const type = "createContact";
export const actionCreator = promisePattern.actionCreator(type, createContact);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateContactResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? [payload?.body, ...state.server.contacts.list] : state.server.contacts.list;

        return sprout.assoc(state,
            ["server", "contacts", "list"], list,
            ["server", "contacts", "processing"], false
        );
    };

const rejectionReducer: RejectionReducer = {
    overrideGlobal: true,
    reducer: (state, error) => sprout.assoc(state,
        ["server", "contacts", "processing"], false,
        ["server", "contacts", "error"], error.message
    )
};
const onRejected = rejectionHandler([rejectionReducer]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "contacts"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);