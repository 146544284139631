import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../rejectionHandler";
import {User} from "../../../communication/dtos/auth/User";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";

export const type = "getUser";

export const actionCreator = promisePattern.actionCreator(type, auth.getUser);

const onFulfilled: promisePattern.PromiseFulfilledHandler<User> =
    (state, payload) => {
        const testing = payload?.mode === UserModes.TEST;
        return sprout.assoc(state,
            ["server", "user", "fetching"], false,
            ["server", "user", "email"], payload?.email,
            ["server", "user", "id"], payload?.id,
            ["server", "user", "testing"], testing,
            ["server", "user", "additionalAuthenticationRequired"], payload?.additionalAuthenticationRequired,
            ["server", "MFA", "enabled"], !!payload?.mfa
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc(state, ["server", "user", "fetching"], true);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);