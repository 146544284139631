import * as React from "react";
import {ReactNode} from "react";
import {
    PaginationParams
} from "lightrail-client/dist/params";
import {Popover, Table, Tooltip} from "antd";
import {LoadMoreFooter} from "../ui/pagination/LoadMoreFooter";
import {dateTimeSecondsFormat, formatDate, formatedDateOrDash} from "../../util/dateUtils";
import {truncate} from "../../util/stringUtils";
import {IssuanceDownloadButtonConnected} from "./IssuanceDownloadButtonConnected";
import {BalanceRule} from "lightrail-client/dist/model";
import {DetailValues} from "../ui/layout/details/DetailValues";
import {FormattedCurrency} from "../ui/currency/FormattedCurrencyConnected";
import {MetadataDetailValue} from "../ui/layout/details/MetadataDetailValue";
import {ExternalProps, ReduxProps} from "./IssuanceListConnected";

interface TableRow {
    key: string;
    id: string;
    name: string;
    count: number;
    balance: JSX.Element | number;
    createdOn: string;
}

export interface Props extends ExternalProps, ReduxProps{
}

export class IssuanceList extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.getTableRows = this.getTableRows.bind(this);
        this.fetchIssuances = this.fetchIssuances.bind(this);
        this.renderIssuancesDetail = this.renderIssuancesDetail.bind(this);
    }

    componentDidMount(): void {
        this.props.clearIssuances({});
        this.fetchIssuances();
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.program !== this.props.program) {
            this.fetchIssuances();
        }
    }

    fetchIssuances(params?: PaginationParams): void {
        if (!!this.props.program) {
            this.props.getIssuances({programId: this.props.program.id, params: {...params, limit: 50}});
        }
    }

    ///////////////////
    //[ RENDER ]
    getTableRows(): TableRow[] {
        return this.props.issuanceServerState.list.map(
            issuance => ({
                key: issuance.id,
                id: issuance.id,
                name: issuance.name || `Issuance_${issuance.id}`,
                count: issuance.count,
                balance: (this.renderBalanceColValue(issuance.balance, issuance.balanceRule)),
                createdOn: formatedDateOrDash(issuance.createdDate),
                actions: (
                    <Tooltip title="Download Codes">
                        <IssuanceDownloadButtonConnected issuance={issuance}/>
                    </Tooltip>
                )
            })
        );
    }

    renderBalanceColValue(balance: number, balanceRule: BalanceRule): JSX.Element {
        if (!!balance) {
            return (
                <FormattedCurrency value={balance} code={this.props.program.currency}/>
            );
        } else if (!!balanceRule) {
            const truncatedContent = truncate(balanceRule.explanation, 30);
            const popOverLink =
                <Popover
                    title="Code Balance Rule Explanation"
                    content={(<p style={{maxWidth: 360}}>{balanceRule.explanation}</p>)}
                    trigger="click"
                >
                    <a className="lr-link-normal">View</a>
                </Popover>;

            return (
                <div>
                    {truncatedContent} {balanceRule.explanation.length > 30 ? popOverLink : ""}
                </div>
            );
        }
        return (
            <div>No Balance found</div>
        );
    }

    renderIssuancesDetail(record: TableRow): ReactNode {
        const issuance = this.props.issuanceServerState.list.find(issuance => (issuance.id === record.id));
        return (
            <div>
                <DetailValues
                    title="Code Issuance Details"
                    data={[
                        [
                            {
                                label: "Start Date",
                                value: issuance.startDate ? formatDate(issuance.startDate, dateTimeSecondsFormat) : "Not set"
                            },
                            {
                                label: "End Date",
                                value: issuance.endDate ? formatDate(issuance.endDate, dateTimeSecondsFormat) : "Not set"
                            }
                        ]
                    ]}
                />
                {
                    (!!issuance.metadata && !!Object.keys(issuance.metadata).length) &&
                    <MetadataDetailValue metadata={issuance.metadata}/>
                }
            </div>
        );
    }

    render(): JSX.Element {
        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name"
            },
            {
                title: "Quantity",
                dataIndex: "count",
                key: "count"
            },
            {
                title: (!!this.props.program && this.props.program.balanceRule !== null) ? "Code Balance Rule" : "Code Initial Balance",
                dataIndex: "balance",
                key: "balance"
            },
            {
                title: "Created On",
                dataIndex: "createdOn",
                key: "createdOn"
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions"
            }
        ];

        return (
            <div>
                <Table
                    loading={this.props.issuanceServerState.fetching}
                    columns={columns}
                    dataSource={this.getTableRows()}
                    expandedRowRender={(this.props.expandable) ? this.renderIssuancesDetail : null}
                    pagination={false}
                    sortDirections={["ascend", "descend"]}
                    scroll={{x: 1000}}
                />
                <LoadMoreFooter
                    numResults={this.props.issuanceServerState.list.length}
                    limit={this.props.issuanceServerState.pagination.limit}
                    loading={this.props.issuanceServerState.fetching}
                    links={this.props.issuanceServerState.links}
                    onClick={this.fetchIssuances}
                />
            </div>
        );
    }
}