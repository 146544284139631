import {Value} from "lightrail-client/dist/model";
import {defaultDash, prefixIdWithDots} from "./stringUtils";

export const getFormattedValueCode = (value: Value): string => {
    if (!value || !value.code) {
        return defaultDash;
    }

    return (value.isGenericCode) ? value.code : prefixIdWithDots(value.code, 3, 4);
};

export function isGenericCodeWithPerContactProperties(value: Value): boolean {
    return value.isGenericCode && value.genericCodeOptions && value.genericCodeOptions.perContact &&
        (value.genericCodeOptions.perContact.balance != null || value.genericCodeOptions.perContact.usesRemaining != null);
}

/**
 * returns null if unlimited
 */
export function getAttachesRemaining(value: Value): number {
    if (isGenericCodeWithPerContactProperties(value) && value.genericCodeOptions.perContact.balance > 0 && value.balance != null) {
        return Math.floor(value.balance / value.genericCodeOptions.perContact.balance);
    } else if (isGenericCodeWithPerContactProperties(value) && value.genericCodeOptions.perContact.usesRemaining > 0 && value.usesRemaining != null) {
        return Math.floor(value.usesRemaining / value.genericCodeOptions.perContact.usesRemaining);
    } else {
        // unlimited
        return null;
    }
}

export function getAttachesRemainingForDisplay(value: Value): string {
    const attachesRemaining = getAttachesRemaining(value);
    if (attachesRemaining != null) {
        return attachesRemaining.toString();
    } else {
        return "Unlimited";
    }
}