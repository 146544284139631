import * as React from "react";
import {WithRouterProps} from "../../router/WithRouterProps";
import {Paths} from "../../../util/routes/routes";
import {ReduxProps} from "./AccountMessageWatcherConnected";

export interface Props extends WithRouterProps<any>, ReduxProps {
}

export class AccountMessageWatcher extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {
        if (this.props.messageCode) {
            this.props.history.push(Paths.AUTH_NEXT_STEP);
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if ((prevProps.messageCode != this.props.messageCode) && this.props.messageCode) {
            this.props.history.push(Paths.AUTH_NEXT_STEP);
        }
    }

    render(): JSX.Element {
        return <React.Fragment/>;
    }
}