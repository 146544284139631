import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {listCurrencies} from "lightrail-client/dist/currencies";
import {ListCurrenciesResponse} from "lightrail-client/dist/params";

export const type = "getCurrencies";
export const actionCreator = promisePattern.actionCreator(type, listCurrencies);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListCurrenciesResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "currency", "list"], payload?.body,
            ["server", "currency", "fetching"], false,
            ["server", "currency", "fetchCount"], state.server.currency.fetchCount + 1
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "currency", "fetching"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);