import * as ReactRedux from "react-redux";
import {State} from "../../state/State";
import {actionCreator as getIntercomUserHash} from "../../actions/account/server/getIntercomUserHash";
import {IntercomUserHashLoader} from "./IntercomUserHashLoader";
import {IntercomState} from "../../state/serverState/IntercomState";
import {UserState} from "../../state/serverState/User";

interface StateProps {
    intercom: IntercomState;
    user: UserState;
}

const mapStateToProps = (state: State): StateProps => ({
    intercom: state.server.intercom,
    user: state.server.user
});

const actionCreators = {
    getIntercomUserHash
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const IntercomUserHashLoaderConnected = connector(IntercomUserHashLoader);