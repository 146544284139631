import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {actionCreator as updateValue} from "../../../actions/values/server/updateValue";
import {UpdateValueResponse} from "lightrail-client/dist/params";
import {ModifyDatesForm} from "./ModifyDatesForm";
import {ValueServerState} from "../../../state/serverState/ValueServerState";
import {UserState} from "../../../state/serverState/User";

export interface ExternalProps {
    valueId: string;
    initialStartDate?: string;
    initialEndDate?: string;
    getSubmit?: (submit: () => void) => void;
    onUpdateValueResponse?: (response: UpdateValueResponse) => void;
}

interface StateProps {
    processing?: boolean;
    valueServerState: ValueServerState;
    userServerState: UserState;
}

const mapStateToProps = (state: State, props: ExternalProps): StateProps & ExternalProps => ({
    valueServerState: state.server.values,
    userServerState: state.server.user,
    valueId: props.valueId,
    ...props
});

const actionCreators = {
    updateValue
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ModifyDatesFormConnected = connector(ModifyDatesForm);