import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { TurnkeyImageUpload } from "../../../state/uistate/TurnkeyUiState";

export interface ActionParams extends TurnkeyImageUpload {
}

export interface ActionCreatorArgs extends ActionParams {
}

export const type = "updateTurnkeyImageUpload";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    const payload = action.payload;

    return sprout.assoc(state,
        ["ui", "turnKey", "imageUpload"], payload
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);