import * as numeral from "numeral";
import {Currency} from "lightrail-client/dist/model/Currency";
import {defaultDash} from "./stringUtils";
import {message} from "antd";
import {CurrencyServerState} from "../state/serverState/CurrencyServerState";
import {Blank_Currency, DefaultCurrencies} from "../components/account/currency/create/DefaultCurrencies";

/**
 * Get Multiplier based on decimal count, ie: 1=1, 2=>100, 3=>1000, 4=>10000
 * @param {number} decimals
 * @returns {number}
 */
export const decimalsMultiplier = (decimals: number): number => Number("1e" + decimals);

export const formatCurrency = (value: number, currency: Currency): string => {
    if (!currency) {
        return defaultDash;
    }

    //Catch undefined symbols
    const currencySymbol = currency.symbol || "";
    //Negative Number formatting as -$1
    const symbol = (value < 0) ? "-" + currencySymbol : currencySymbol;
    value = Math.abs(value);

    const baseFormat = "0,0";
    const format = (!currency.decimalPlaces) ? baseFormat : baseFormat + "." + (Array(currency.decimalPlaces).fill("0").join(""));

    const normalizedValue = normalizeValueFromServer(value, currency.decimalPlaces);

    return `${symbol}${numeral(normalizedValue).format(format)}`;
};

/**
 * Normalize value into a whole number based on isoCurrency type
 *
 * @param value
 * @param decimalPlaces
 * @returns {number}
 */
export const normalizeValueFromServer = (value: number, decimalPlaces: number): number => {
    const multiplier = decimalsMultiplier(decimalPlaces);
    return (value / multiplier);
};

/**
 * Normalize a currency for the server
 * ie: Form where user enters 100 needs to be sent as 10000 if in currency with 2 decimal places
 * @param {number}value
 * @param {number}decimalPlaces
 * @returns {number}
 */
export const normalizeValueForServer = (value: number, decimalPlaces: number): number => {
    const multiplier = decimalsMultiplier(decimalPlaces);
    return (Math.round(value * multiplier));
};

/**
 * Whole number formatting with commas, optional decimals
 *
 * @param {number}value
 * @returns {string} - Formatted string ie: `200,000`
 */
export const formatWholeNumber = (value: number): string => numeral(value).format("0,0.[00000000000]");

export const findCurrency = (currencies: Currency[], currencyCode: string, fallback?: Currency): Currency => {
    const currency = currencies.find(p => p.code === currencyCode);

    if (!currency) {
        message.warn("Error locating " + currencyCode + " currency definition.");

        if (!!fallback) {
            return fallback;
        }
    }

    return currency;
};

/**
 * Gets the current currency from a Program
 *
 * @param {string}code
 * @param {CurrencyServerState}currencyList
 * @returns {CurrencyServerState}currencyList
 */
export const getCurrentCurrency = (code: string, currencyList: CurrencyServerState): Currency => {
    return (
        currencyList.list.find(p => p.code == code) || DefaultCurrencies.find(p => p.code === code) || Blank_Currency
    );
};