import * as React from "react";
import {message, Modal} from "antd";
import {SearchFormConnected} from "../../../search/searchForm/SearchFormConnected";
import {SearchId, SearchType} from "../../../../state/serverState/SearchesServerState";
import {Value} from "lightrail-client/dist/model";
import {SearchResultsList} from "../../../search/searchResults/SearchResultsList";
import {ExternalProps, ReduxProps} from "./AttachValueModalConnected";

export interface Props extends ExternalProps, ReduxProps {
}

export class AttachValueModal extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    // Add Confirmation Step if value already has a contact
    async onSubmit(value: Value): Promise<void> {
        const contact = this.props.contact;

        // Call Attach with Contact Id and ValueId
        try {
            await this.props.attachValueToContact({
                contactId: contact.id,
                params: {
                    valueId: value.id
                }
            });

            message.success(`${contact.email || "Contact " + contact.id} attached to ${(!!value.code) ? value.code : "the Value"}`);
            this.props.onClose();
        } catch (error) {
            message.error(error.message);
        }
    }

    render(): JSX.Element {
        return (
            <Modal
                title="Attach Value"
                visible={(this.props.open != null) ? this.props.open : true}
                onCancel={this.props.onClose}
                okText="Attach"
                footer={null}
                destroyOnClose
            >
                <SearchFormConnected
                    id={SearchId.GLOBAL}
                    searchType={SearchType.VALUES}
                    initialValue={this.props.initialValue}
                />
                <SearchResultsList
                    searchState={this.props.searchState}
                    onValueClick={this.onSubmit}
                    actionButtonText="Attach"
                />
            </Modal>
        );
    }
}