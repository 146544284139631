import * as React from "react";
import {Button} from "antd";
import {UiActionPaths} from "../../../../state/uistate/UiActionPaths";
import {UiActionParams} from "../../../../actions/uiAction";
import {WebhookUiState} from "../../../../state/uistate/WebhookUiState";

interface Props {
    secretId: string;
    uiAction: (p: UiActionParams) => void;
    webhookUiState: WebhookUiState;
    toggleShowSecretModal: (secretId: string) => void;
}

interface State {
    secret: string;
}

export class RevealSecret extends React.PureComponent <Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            secret: this.props.secretId
        };
        this.toggleShowSecretModal = this.toggleShowSecretModal.bind(this);
    }

    toggleShowSecretModal(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.WEBHOOK_SHOW_SECRET_MODAL_OPEN,
            value: !this.props.webhookUiState.showSecretModalOpen
        });

        this.props.toggleShowSecretModal(this.props.secretId);
    }

    render(): JSX.Element {
        return (
            <Button key={this.props.secretId} onClick={this.toggleShowSecretModal}>Reveal Secret</Button>
        );
    }
}