import * as ReactRedux from "react-redux";
import {CreateValueForContactForm} from "./CreateValueForContactForm";
import {State} from "../../../state/State";
import {actionCreator as createValue} from "../../../actions/values/server/createValue";
import {actionCreator as attachValueToContact} from "../../../actions/values/server/attachValueToContact";
import {actionCreator as getActivePrograms} from "../../../actions/programs/server/getAndAppendActivePrograms";
import {actionCreator as deleteValue} from "../../../actions/values/server/deleteValue";
import {Contact, Program} from "lightrail-client/dist/model";
import {ValueServerState} from "../../../state/serverState/ValueServerState";

export interface ExternalProps {
    contact: Contact;
    getSubmit: (submit: () => void) => void;
    onSuccess: () => void;
}

interface StateProps {
    fetchingActivePrograms: boolean;
    programs: Program[];
    valueServerState: ValueServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    fetchingActivePrograms: state.server.programs.fetchingActive,
    programs: state.server.programs.activeList,
    valueServerState: state.server.values,
    ...props
});

const actionCreators = {
    createValue,
    deleteValue,
    attachValueToContact,
    getActivePrograms
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const CreateValueForContactFormConnected = connector(CreateValueForContactForm);