import * as React from "react";
import {ContactsListConnected} from "./list/ContactsListConnected";
import {ListLayout} from "../layout/list/ListLayout";
import {Button} from "antd";
import {UiActionPaths} from "../../state/uistate/UiActionPaths";
import {CreateContactDrawer} from "./create/CreateContactDrawer";
import { PlusOutlined } from "@ant-design/icons";
import {ReduxProps} from "./ContacsPageConnected";

export interface Props extends ReduxProps {
}

export class ContactsPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.toggleCreateContact = this.toggleCreateContact.bind(this);
    }

    toggleCreateContact(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.CONTACT_CREATE_OPEN,
            value: !this.props.createContactOpen
        });
    }

    render(): JSX.Element {
        return (
            <ListLayout headerProps={{
                createButton:
                    <Button
                        type="primary"
                        onClick={this.toggleCreateContact}
                    >
                        <PlusOutlined/>
                        Create Contact
                    </Button>
            }}>
                <ContactsListConnected highlightNewRows/>
                <CreateContactDrawer
                    onCancel={this.toggleCreateContact}
                    open={this.props.createContactOpen}
                    contactsProcessing={this.props.contactsProcessing}
                />
            </ListLayout>
        );
    }
}