import * as React from "react";
import {useEffect, useState} from "react";
import * as moment from "moment";
import {Collapse, Divider, Form, InputNumber, message} from "antd";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {IssuancePollingRequest} from "../../../state/uistate/IssuanceUiState";
import {findCurrency, normalizeValueForServer} from "../../../util/currencyUtils";
import {ExternalProps, ReduxProps} from "./CreateIssuanceFormConnected";
import {generateId} from "../../../communication/utils/urlUtils";
import {CreateIssuanceParams} from "lightrail-client/dist/params";
import {withCurrencies, WithCurrencies} from "../../ui/currency/withCurrenciesHOC";
import {MetadataItems} from "../../ui/forms/items/MetadataItems";
import {DateTimePicker} from "../../ui/forms/items/DateTimePicker";
import {UsesRemainingItem} from "../../ui/forms/items/UsesRemainingItem";
import {EmojiInputItem} from "../../ui/forms/EmojiInputItem";
import {ClickToAdd} from "../../ui/forms/ClickToAdd";
import {FixedInitialUsesRemainingItem} from "../../ui/forms/items/FixedInitialUsesRemaining";
import {BalanceAndBalanceRuleButton} from "../../ui/forms/items/BalanceAndBalanceRuleButton";
import {AdvancedRuleItem} from "../../ui/forms/items/rules/AdvancedRuleItem";
import {ProgramValueItem} from "../../ui/forms/items/ProgramValueItem";
import {ActiveItem} from "../../ui/forms/items/ActiveItem";
import {useForm} from "antd/es/form/Form";
import {generateRandomString} from "../../../util/stringUtils";

export const MAXIMUM_ISSUANCE_SIZE = 5000; // Important: this is the same as the max limit when listing values as a CSV from the API.
const ISSUANCE_METADATA_NOTES_KEY = "lightrail_webapp_issuance_notes";

interface InternalProps extends WithCurrencies, ExternalProps, ReduxProps {
}

export const CreateIssuanceForm = withCurrencies((props: InternalProps): JSX.Element => {
    const [form] = useForm();

    const ALPHANUMERIC_CHARSET = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
    const sampleCode = generateRandomString(50, ALPHANUMERIC_CHARSET);

    const [isBalance, setIsBalance] = useState(true);
    const [codeLength, setCodeLength] = useState(16);
    const [codePrefix, setCodePrefix] = useState("");
    const [codeSuffix, setCodeSuffix] = useState("");

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();

        //Setup Creation Params
        const creationValues: CreateIssuanceParams = {
            id: generateId(),
            name: values.name,
            count: values.count,
            generateCode: {
                length: values.length,
                prefix: values.prefix,
                suffix: values.suffix
            },
            active: values.active,
            balanceRule: props.program.balanceRule
        };

        const metadataFromForm = MetadataItems.parseMetadataFromSubmitValues(values, ISSUANCE_METADATA_NOTES_KEY);
        if (metadataFromForm != null) {
            creationValues.metadata = metadataFromForm;
        }

        const currency = findCurrency(props.currencies, props.program.currency);
        if (!currency) {
            throw Error("Cannot find definition for currency " + props.program.currency);
        }

        if (values[FormFieldIds.Balance] != undefined) {
            creationValues.balance = normalizeValueForServer(values.balance, currency.decimalPlaces);
        }

        if (!!values[FormFieldIds.StartDate]) {
            creationValues.startDate = values[FormFieldIds.StartDate].toISOString();
        }

        if (!!values[FormFieldIds.EndDate]) {
            creationValues.endDate = values[FormFieldIds.EndDate].toISOString();
        }

        if (values[FormFieldIds.UsesRemaining] != undefined) {
            creationValues.usesRemaining = values[FormFieldIds.UsesRemaining];
        }

        //Trigger Start Message and Close Modal
        message.loading("Making your codes. We'll notify you when they're ready.");
        props.setCreateState({merge: {open: false}});

        //Add issuance id to issuance polling list
        const issuancePollingRequest: IssuancePollingRequest = {
            programId: props.program.id,
            issuanceId: creationValues.id,
            requestDate: new Date()
        };
        props.setCreateState({merge: {pollingList: [...props.createState.pollingList, issuancePollingRequest]}});

        //Make the call
        try {
            props.createIssuance({programId: props.program.id, params: creationValues});
        } catch (err) {
            if (err.status !== 504) {
                props.setCreateState({merge: {pollingList: props.createState.pollingList.filter(iss => iss.issuanceId != creationValues.id)}});
            }
        }
    };

    const onBalanceTypeChange = (): void => {
        setIsBalance(!isBalance);
    };

    ///////////////////
    //[ UTIL ]

    const getCodePreview = (): string => {
        return codePrefix + sampleCode.substr(0, codeLength) + codeSuffix;
    };

    const validateCount = (rule: any, value: any, callback: any): void => {
        if (value > MAXIMUM_ISSUANCE_SIZE) {
            callback(`Maximum of 5,000 codes allowed.`);
        } else if (value < 1) {
            callback(`Must request at least 1 code.`);
        }

        callback();
    };

    const validateLength = (rule: any, value: any, callback: any): void => {
        if (value < 6) {
            callback("Must be at least 6 characters");
        }

        callback();
    };

    const getInitialEndDate = (): string => {
        const startDateValue = form.getFieldValue(FormFieldIds.StartDate);
        const startDate = (!!startDateValue) ? startDateValue.toISOString() : new Date().toISOString();
        return moment(startDate).add(6, "month").endOf("day").toISOString();
    };

    const onCodeChange = (setter: (value: any) => void) => async (event: React.ChangeEvent): Promise<void> => {
        const formValue = await form.getFieldValue(event.target.id);
        setter(formValue);

        getCodePreview();
    };

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    return (
        <Form
            onFinish={submit}
            form={form}
            className="lr-create-form"
            layout="vertical"
            hideRequiredMark
        >
            <EmojiInputItem
                fieldId={FormFieldIds.Name}
                form={form}
                initialValue={props.program.name + " Unique Codes"}
                rules={[
                    {required: true, message: "Name Required"}
                ]}
                inputProps={{size: "large"}}
            />
            <Form.Item
                label="Quantity"
                extra={<span>Maximum of 5,000 codes can be created at once. Need more? <a
                    className="lr-color-percent-60" href="mailto:hello@lightrail.com"
                    target="_blank"
                    rel="noreferrer"
                > Contact us</a></span>}
                name={FormFieldIds.Count}
                initialValue={1}
                rules={[
                    {required: true, message: "Quantity Required"},
                    {validator: validateCount}
                ]}
            >
                <InputNumber
                    size="large"
                    autoFocus
                    style={{width: "100%"}}
                    min={1}
                    max={MAXIMUM_ISSUANCE_SIZE}
                />
            </Form.Item>
            <Divider/>
            {
                (!!props.program.fixedInitialBalances || !!props.program.minInitialBalance || !!props.program.maxInitialBalance) &&
                <div>
                    <p className="lr-text-secondary">These Value Details were set by the program. Any changes below
                        will override the program for this code generation.</p>
                    <ProgramValueItem program={props.program} form={form}/>
                </div>
            }
            {
                (!!props.program.balanceRule) &&
                <div>
                    <p className="lr-text-secondary">Default Value Details were set by the program. Any
                        changes
                        below
                        will
                        override the program values.</p>
                    <AdvancedRuleItem
                        label="Balance Rule"
                        rule={props.program.balanceRule}
                        explanationItemId={FormFieldIds.BalanceRuleExplanation}
                        ruleItemId={FormFieldIds.BalanceRule}
                        form={form}
                        explanationProps={{placeholder: "50% off"}}
                        ruleProps={{placeholder: "currentLineItem.lineTotal.subtotal * 0.5"}}
                        ruleValidationRules={[
                            {required: true, message: "Balance rule Required"}
                        ]}
                        disableEditToggle
                    />
                </div>
            }
            {
                (!props.program.balanceRule && !props.program.fixedInitialBalances && !props.program.minInitialBalance && !props.program.maxInitialBalance) &&
                <BalanceAndBalanceRuleButton program={props.program} form={form}
                                             onChange={onBalanceTypeChange}
                                             isBalance={isBalance}
                                             isGeneric={false}/>
            }
            {
                (!!isBalance || !!props.program.fixedInitialUsesRemaining) &&
                <UsesRemainingItem
                    fieldId={FormFieldIds.UsesRemaining}
                    form={form}
                    program={props.program}
                />
            }
            {
                (!isBalance && !props.program.fixedInitialUsesRemaining) &&
                <FixedInitialUsesRemainingItem
                    fieldId={FormFieldIds.FixedUsesRemaining}
                    form={form}
                    isCheckedByDefault={true}
                />
            }
            <ActiveItem
                fieldId={FormFieldIds.Active}
                form={form}
                initialValue={props.program.active}
                description="Values will be active when created"
            />
            <ClickToAdd
                forceActive={!!props.program.redemptionRule}
                message="+ Add Redemption Rule"
            >
                <AdvancedRuleItem
                    label="Redemption Rule"
                    rule={props.program.redemptionRule}
                    explanationItemId={FormFieldIds.RedemptionRuleExplanation}
                    ruleItemId={FormFieldIds.RedemptionRule}
                    form={form}
                    explanationProps={{placeholder: "Can only be applied to Red hats"}}
                    ruleProps={{placeholder: "currentLineItem.productId == 'red-hat'"}}
                    disableEditToggle
                />
            </ClickToAdd>
            <Divider/>
            <Collapse
                bordered={false}
            >
                <Collapse.Panel
                    key="details"
                    header={<h3>Active Period</h3>}
                    forceRender
                >
                    <ClickToAdd
                        forceActive={!!props.program.startDate}
                        message="+ Add Start Date"
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.StartDate}
                            form={form}
                            label="Codes active from:"
                            initialValue={props.program.startDate || moment().startOf("day").toISOString()}
                        />
                    </ClickToAdd>
                    <ClickToAdd
                        forceActive={!!props.program.endDate}
                        message="+ Add Expiry Date"
                    >
                        <DateTimePicker
                            fieldId={FormFieldIds.EndDate}
                            form={form}
                            label="Codes expire on:"
                            initialValue={props.program.endDate || getInitialEndDate()}
                        />
                    </ClickToAdd>
                </Collapse.Panel>
                <Collapse.Panel
                    key="metadata"
                    header={<h3>Metadata</h3>}
                >
                    <MetadataItems
                        metadata={props.program.metadata} form={form}
                        metadataNotesKey={ISSUANCE_METADATA_NOTES_KEY}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    key="customize"
                    header={<h3>Customize your code</h3>}
                    forceRender
                >
                    <ClickToAdd
                        message="+ Add Prefix"
                    >
                        <EmojiInputItem
                            fieldId={FormFieldIds.Prefix}
                            form={form}
                            formItemProps={{label: "Prefix"}}
                            inputProps={{autoFocus: true}}
                            onChange={onCodeChange(setCodePrefix)}
                        />
                    </ClickToAdd>
                    <ClickToAdd
                        message="+ Add Suffix"
                    >
                        <EmojiInputItem
                            fieldId={FormFieldIds.Suffix}
                            form={form}
                            formItemProps={{label: "Suffix"}}
                            inputProps={{autoFocus: true}}
                            onChange={onCodeChange(setCodeSuffix)}
                        />
                    </ClickToAdd>
                    <Form.Item
                        label="Generated Code Length"
                        extra="At least 6 characters"
                        name={FormFieldIds.Length}
                        initialValue={16}
                        rules={[
                            {validator: validateLength}
                        ]}
                    >
                        <InputNumber size="large" onChange={value => setCodeLength(value as number)}/>
                    </Form.Item>
                </Collapse.Panel>
            </Collapse>
            <Form.Item
                className="lr-code-preview-item"
                label="Code Preview"
            >
                <p className="codeDisplay fullCode textCenter width100Perc">
                    {
                        getCodePreview()
                    }
                </p>
            </Form.Item>
        </Form>
    );
});
