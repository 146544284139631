import * as React from "react";
import {withRouter} from "react-router-dom";
import {WithRouterProps} from "../router/WithRouterProps";
import {Alert, Button, Dropdown, Menu, Spin, Tabs} from "antd";

import {ProgramTabs} from "../../state/uistate/SelectedTabsUiState";
import {UiActionPaths} from "../../state/uistate/UiActionPaths";

import {ProgramOverview} from "./details/ProgramOverview";
import {ProgramPerformanceConnected} from "./details/ProgramPerformanceConnected";
import {CreateProgramDrawerConnected} from "./create/CreateProgramDrawerConnected";

import {CreateIssuanceModal} from "../issuance/create/CreateIssuanceModal";
import {IssuanceListConnected} from "../issuance/IssuanceListConnected";

import {ValuesListConnected} from "../values/list/ValuesListConnected";
import {CreateGenericValueModal} from "../values/create/generic/CreateGenericValueModal";
import {Program} from "lightrail-client/dist/model";
import {MenuInfo} from "rc-menu/es/interface";
import {DetailLayout} from "../layout/details/DetailLayout";
import {decodeUrlMatchParam} from "../../util/routes/routes";
import {dateIsInPast} from "../../util/dateUtils";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import {EditOutlined} from "@ant-design/icons/lib";
import {ReduxProps} from "./ProgramDetailPageConnected";

enum CreateMenuItem {
    CREATE_GENERIC_CODE = "createGeneric",
    CREATE_UNIQUE_CODE = "createUnique"
}

export interface State {
    programId: string;
}

export interface Props extends WithRouterProps<{ programId: string }>, ReduxProps {
}

class ProgramDetailPageComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {programId: decodeUrlMatchParam(this.props.match.params.programId)};

        this.onCreateValueSuccess = this.onCreateValueSuccess.bind(this);
        this.loadProgram = this.loadProgram.bind(this);
        this.toggleCreateGenericCodeModal = this.toggleCreateGenericCodeModal.bind(this);
        this.toggleCreateIssuanceModal = this.toggleCreateIssuanceModal.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onCreateMenuClick = this.onCreateMenuClick.bind(this);
        this.onToggleUpdateProgram = this.onToggleUpdateProgram.bind(this);
    }

    ///////////////////
    //[ Lifecycle ]
    componentDidMount(): void {
        this.loadProgram();
        this.onTabChange(ProgramTabs.OVERVIEW);
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.match.params.programId != this.props.match.params.programId) {
            this.setState({programId: decodeUrlMatchParam(this.props.match.params.programId)});
            this.loadProgram();
        }
    }

    ///////////////////
    //[ Doing Stuff ]
    loadProgram(): void {
        this.props.getProgram(this.state.programId);
    }

    toggleCreateGenericCodeModal(open?: boolean): void {
        const value = (typeof open === "boolean") ? open : !this.props.createGenericCodeUiState.open;

        this.props.uiAction({
            uiStatePath: UiActionPaths.VALUE_CREATE_GENERIC_OPEN,
            value
        });
    }

    toggleCreateIssuanceModal(open?: boolean): void {
        const value = (typeof open === "boolean") ? open : !this.props.issuanceUIState.create.open;

        this.props.uiAction({
            uiStatePath: UiActionPaths.ISSUANCE_CREATE_OPEN,
            value
        });
    }

    onToggleUpdateProgram(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.PROGRAM_CREATE_DRAWER_OPEN,
            value: !this.props.programUiState.createDrawerOpen
        });
    }

    ///////////////////
    //[ ACTIONS ]
    onCreateMenuClick(p: MenuInfo): void {
        switch (p.key) {
            case CreateMenuItem.CREATE_GENERIC_CODE:
                this.toggleCreateGenericCodeModal();
                break;
            case CreateMenuItem.CREATE_UNIQUE_CODE:
                this.toggleCreateIssuanceModal();
                break;
        }
    }

    onTabChange(activeTab: string): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.SET_SELECTED_PROGRAM_TAB,
            value: activeTab
        });
    }

    onCreateValueSuccess(): void {
        this.toggleCreateGenericCodeModal(false);
        this.toggleCreateIssuanceModal(false);
        this.onTabChange(ProgramTabs.VALUES);
    }

    ///////////////////
    //[ RENDER ]
    renderCreateDropdown(program: Program): JSX.Element {
        const actionMenuItems = (
            <Menu
                onClick={this.onCreateMenuClick}
            >
                {
                    <Menu.Item
                        key={CreateMenuItem.CREATE_GENERIC_CODE}
                    >
                        <PlusCircleOutlined/>Create Generic Code
                    </Menu.Item>
                }
                <Menu.Divider/>
                <Menu.Item
                    key={CreateMenuItem.CREATE_UNIQUE_CODE}
                >
                    <PlusCircleOutlined/>Generate Unique Codes
                </Menu.Item>
            </Menu>
        );

        return (
            <div>
                <Button
                    onClick={this.onToggleUpdateProgram}
                    className="lr-layout-header-btn"
                >
                    <EditOutlined/>
                    Edit Program
                </Button>
                <Dropdown overlay={actionMenuItems} trigger={["click"]}
                          disabled={!program || dateIsInPast(program.endDate)}>
                    <Button
                        type="primary"
                        className="lr-layout-header-btn"
                    >
                        Generate Codes
                        <DownOutlined/>
                    </Button>
                </Dropdown>
            </div>
        );
    }

    render(): JSX.Element {
        const id = this.state.programId;
        const program = this.props.programsState.allList.find(p => p.id === id);
        const activeTab = this.props.tab;
        const displayProgramExpiredBadge = (!!program) ? dateIsInPast(program.endDate) : false;

        if (this.props.programsState.status === 404) {
            return (
                <div>
                    <Alert
                        message="Program Not Found"
                        description={`Program Id '${id}' not found`}
                        showIcon
                        type="warning"
                        className="detailsNotFoundAlert"
                    />
                </div>
            );
        }

        return (
            <DetailLayout
                headerProps={{
                    title: (!program) ? <Spin/> : program.name,
                    actionButton: this.renderCreateDropdown(program),
                    displayExpiredBadge: displayProgramExpiredBadge
                }}
            >
                <Tabs activeKey={activeTab} animated={false} onChange={this.onTabChange}>
                    <Tabs.TabPane tab="Overview" key={ProgramTabs.OVERVIEW}>
                        <ProgramOverview program={program} loading={this.props.programsState.fetching}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="All Values" key={ProgramTabs.VALUES}>
                        {
                            (activeTab == ProgramTabs.VALUES) &&
                            <ValuesListConnected
                                params={(!!program) ? {programId: {eq: program.id}} : {}}/>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Generic Codes" key={ProgramTabs.GENERIC}>
                        {
                            (activeTab == ProgramTabs.GENERIC) &&
                            <ValuesListConnected
                                params={(!!program) ? {programId: {eq: program.id}, isGenericCode: true} : {}}/>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Issued Codes" key={ProgramTabs.CODES}>
                        <IssuanceListConnected expandable program={program}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Performance" key={ProgramTabs.PERFORMANCE}>
                        {
                            (activeTab == ProgramTabs.PERFORMANCE) &&
                            <ProgramPerformanceConnected program={program}/>
                        }
                    </Tabs.TabPane>
                </Tabs>
                {
                    // Creation Modals
                }
                <CreateIssuanceModal
                    open={this.props.issuanceUIState.create.open}
                    onClose={this.toggleCreateIssuanceModal}
                    program={program}
                />
                <CreateGenericValueModal
                    open={this.props.createGenericCodeUiState.open}
                    program={program}
                    processing={this.props.processingValue}
                    onClose={this.toggleCreateGenericCodeModal}
                    onSuccess={this.onCreateValueSuccess}
                />
                <CreateProgramDrawerConnected
                    program={program}
                    open={this.props.programUiState.createDrawerOpen}
                    onCancel={this.onToggleUpdateProgram}
                />
            </DetailLayout>
        );
    }
}

export const ProgramDetailPage = withRouter(ProgramDetailPageComponent);