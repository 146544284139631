import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    isOpen: boolean;
}

export const type = "SetQRCodeModalOpen";

export const actionCreator: ActionCreator<ActionParams, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state,
        ["ui", "MFA", "QRCodeModalOpen"], action.payload.isOpen
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);