import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {mergeListObject} from "../../../util/dataUtils";
import {GetWebhookResponse} from "../../../communication/dtos/webhooks/GetWebhook";
import {getWebhook} from "../../../communication/webhooks";

export const type = "getWebhook";
export const actionCreator = promisePattern.actionCreator(type, getWebhook);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetWebhookResponse> =
    (state, payload) => {
        const newWebhookList = mergeListObject(state.server.webhook.list, payload, "id");
        return sprout.assoc(state,
            ["server", "webhook", "list"], newWebhookList,
            ["server", "webhook", "fetching"], false
        );
    };

const onRejected = rejectionHandler([{
        reducer: state => sprout.assoc<State>(state,
            ["server", "webhook", "fetching"], false
        )
    }]
);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "webhook", "fetching"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);