import * as ReactRedux from "react-redux";
import {State} from "../../../../state/State";
import {RegisterPage} from "./RegisterPage";
import {actionCreator as register} from "../../../../actions/auth/server/register";
import {actionCreator as resetAuthErrorAndSuccessStates} from "../../../../actions/auth/server/resetAuthErrorAndSuccessStates";
import {withRouter} from "react-router-dom";
import {RegisterState} from "../../../../state/serverState/AuthServerState";

interface StateProps {
    registerState: RegisterState;
}

const mapStateToProps = (state: State): StateProps => ({
    registerState: state.server.register,
});

const actionCreators = {
    register,
    resetAuthErrorAndSuccessStates
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const RegisterPageConnected = connector(withRouter(RegisterPage));