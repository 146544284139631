import {Transaction} from "lightrail-client/dist/model";
import * as ReactRedux from "react-redux";
import {TransactionActionButtonGroup} from "./TransactionActionButtonGroup";
import {State} from "../../../state/State";
import {actionCreator as getTransaction} from "../../../actions/transactions/server/getTransaction";
import {actionCreator as capturePendingTransaction} from "../../../actions/transactions/server/capturePendingTransaction";
import {actionCreator as voidPendingTransaction} from "../../../actions/transactions/server/voidPendingTransaction";
import {actionCreator as reverseTransaction} from "../../../actions/transactions/server/reverseTransaction";
import {actionCreator as setTransactionActionState} from "../../../actions/transactions/ui/setTransactionActionState";
import {actionCreator as uiAction} from "../../../actions/uiAction";
import {actionCreator as getTransactionChain} from "../../../actions/transactions/server/getTransactionChain";
import {TransactionsServerState} from "../../../state/serverState/TransactionsServerState";
import {TransactionUiState} from "../../../state/uistate/TransactionUiState";

export interface ExternalProps {
    transaction: Transaction;
}

interface StateProps {
    transactionsServerState: TransactionsServerState;
    transactionUiState: TransactionUiState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    ...props,
    transactionsServerState: state.server.transactions,
    transactionUiState: state.ui.transactions
});

const actionCreators = {
    getTransaction,
    capturePendingTransaction,
    voidPendingTransaction,
    reverseTransaction,
    setTransactionActionState,
    uiAction,
    getTransactionChain
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const TransactionActionButtonGroupConnected = connector(TransactionActionButtonGroup);
