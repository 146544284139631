import * as React from "react";
import {Form, Input} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {AllowSubmitOnEnter} from "../../../ui/forms/AllowSubmitOnEnter";
import {changeEmail} from "../../../../communication/auth";
import {useForm} from "antd/es/form/Form";

export interface Props {
    getSubmit: (submit: () => Promise<void>) => void;
    closeModal: () => void;
    setNewEmail: (newEmail: string) => void;
}

export const CypressSelectors = {
    ChangeEmailFormSubmit: "lr-cy-change-email-submit",
    NewEmailInput: "new-email-input"
};

export const ChangeEmail = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        await changeEmail(values[FormFieldIds.NewEmail]);
        props.setNewEmail(values[FormFieldIds.NewEmail]);

        props.closeModal();
    };

    React.useEffect(() => {
        props.getSubmit(submit);
    }, []);

    return (
        <Form
            onFinish={submit}
            form={form}
            hideRequiredMark={true}
        >
            <Form.Item
                name={FormFieldIds.NewEmail}
                label="New Email Address"
                rules={[
                    {required: true, message: "Please enter a new email address", type: "email"}
                ]}
            >
                <Input
                    size="large"
                    placeholder="email@example.com"
                />
            </Form.Item>
            <AllowSubmitOnEnter/>
        </Form>
    );
};
