import {Colors} from "../../../styles/legacy/styles";

// GLOBAL DEFINITION!
// All instances of dialog width should only come from here
const dialogMaxWidth = `600px`;

export const dialogStyles = {
    // MUI SPECIFIC OVERRIDES
    // Overrides the inline-styles of the actions container's root element.
    actionsContainerStyle: {
        padding: `18px 16px`,
        fontSize: 18
    },
    // Overrides the inline-styles of the content's root element under the title.
    bodyStyle: {
        padding: `24px 24px 16px`,
        fontSize: `18px`,
        lineHeight: `24px`,
        color: Colors.black
    },
    // Overrides the inline-styles of the content container.
    contentStyle: {
        maxWidth: dialogMaxWidth,
        borderRadius: 5
    },
    // Overrides the inline-styles of the title's root container element.
    titleStyle: {
        padding: `16px 24px`,
        fontSize: `28px`,
        lineHeight: `32px`
    },
    // END MUI SPECIFIC OVERRIDES

    // Dialog Text Fields
    textField: {
        width: "100%",
        margin: "8px 0"
    },
    // Dialog Force full width
    fullWidth: {
        width: "100%"
    },
    // Dialog Checkboxes
    checkbox: {
        margin: "10px 0"
    },
    // Dialog Links
    linkStyle: {
        fontSize: "inherit"
    },
    // Dialog Hide
    hide: {
        display: "none"
    }
};
