import * as React from "react";
import {Popover} from "antd";
import {RuleDisplay} from "./RuleDisplay";

export interface Props {
    title: string;
    content: object;
    link?: string;
}

export class RulePopover extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        const ruleDisplay = <RuleDisplay content={this.props.content}/>;
        return (
            <Popover
                title={this.props.title}
                content={ruleDisplay}
                trigger="click"
            >
                <a className="lr-link-normal">{(!!this.props.link) ? this.props.link : "View"}</a>
            </Popover>
        );
    }
}