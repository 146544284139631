import * as React from "react";
import {InputProps} from "antd/lib/input";
import {Button, Form, Input, Popover, Tooltip} from "antd";
import {Picker} from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import {BaseEmoji} from "emoji-mart";
import {FormItemProps, Rule} from "antd/lib/form";
import {PopoverProps} from "antd/lib/popover";
import {FormInstance} from "antd/es/form";
import {SmileOutlined} from "@ant-design/icons";

interface State {
    emojiPickerOpen: boolean;
}

export interface Props {
    fieldId: string;
    form: FormInstance;
    formItemProps?: FormItemProps;
    inputProps?: InputProps;
    initialValue?: string;
    required?: boolean;
    rules?: Rule[];
    emojiPopoverProps?: PopoverProps;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string;
}

export class EmojiInputItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {emojiPickerOpen: false};

        this.onPopoverVisibleChange = this.onPopoverVisibleChange.bind(this);
        this.onEmojiPicked = this.onEmojiPicked.bind(this);
    }

    onEmojiPicked(emojiData: BaseEmoji): void {
        const curValue = this.props.form.getFieldValue(this.props.fieldId) || "";
        const newValue = curValue + emojiData.native;

        this.props.form.setFieldsValue({[this.props.fieldId]: newValue});
        this.onPopoverVisibleChange(false);
    }

    onPopoverVisibleChange(visible: boolean): void {
        this.setState({emojiPickerOpen: visible});
    }

    render(): JSX.Element {
        const {initialValue, required, rules, formItemProps, fieldId} = this.props;

        return (
            <Form.Item
                {...formItemProps}
                name={fieldId}
                initialValue={initialValue}
                required={required}
                rules={rules}
                className="lr-emoji-input-item"
            >
                <Input
                    {...this.props.inputProps}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    suffix={
                        <Popover
                            {...this.props.emojiPopoverProps}
                            title=""
                            trigger="click"
                            placement="topRight"
                            visible={this.state.emojiPickerOpen}
                            onVisibleChange={this.onPopoverVisibleChange}
                            content={
                                <Picker
                                    title="Pick your emoji..."
                                    native
                                    defaultSkin={2}
                                    include={["search", "people", "nature", "foods", "activity", "places", "objects", "symbols", "flags"]}
                                    onClick={this.onEmojiPicked}
                                />
                            }
                        >
                            <Tooltip
                                title="Emoji Picker"
                            >
                                <Button
                                    size="small"
                                    type="dashed"
                                    shape="circle"
                                    className="lr-emoji-picker"
                                >
                                    <SmileOutlined/>
                                </Button>
                            </Tooltip>
                        </Popover>
                    }
                />
            </Form.Item>
        );
    }
}