import * as React from "react";
import {find, get} from "lodash";
import {ApiKey} from "../../../communication/dtos/user/apiKeys/ApiKey";
import {ApiKeyRow} from "./ApiKeyRow";
import {PaddedLoadingSpinner} from "../../ui/Loading";
import {Caption, Subheading} from "../../ui/typography/TypographyTags";
import {SingleEntryForm} from "../../ui/forms/SingleEntryForm";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {TextColor} from "../../ui/typography/ColoredText";
import {NewKeyModal} from "./NewKeyModal";
import {StripeOAuthConnected} from "../../account/stripeOAuth/StripeOAuthConnected";
import {TurnkeyTokensConnected} from "./turnkeyTokens/TurkeyTokensConnected";
import {Button, Divider, message, Popover} from "antd";
import {AccountHeader} from "../../layout/account/AccountHeader";
import {AccountLayout} from "../../layout/account/AccountLayout";
import {ReduxProps} from "./IntegrationsLayoutConnected";
import {PlusOutlined} from "@ant-design/icons";

import "./apiKeysLayout.scss";
import {stripUserIdTestMode} from "../../../util/userUtil";

export interface Props extends ReduxProps {
}

export class IntegrationsLayout extends React.PureComponent<Props, {}> {
    newKeySubmit: () => void;

    constructor(props: Props) {
        super(props);

        this.onToggleCreateKey = this.onToggleCreateKey.bind(this);
        this.getApiKeySubmit = this.getApiKeySubmit.bind(this);
        this.renderApiKeyRow = this.renderApiKeyRow.bind(this);
        this.onDeleteApiKey = this.onDeleteApiKey.bind(this);
        this.onNewKeyPress = this.onNewKeyPress.bind(this);
        this.onNewKeySubmit = this.onNewKeySubmit.bind(this);
        this.onToggleNewKeyDialog = this.onToggleNewKeyDialog.bind(this);
    }

    ///////////////////
    //[ LIFECYCLE ]
    async componentDidMount(): Promise<void> {
        const response = await this.props.getApiKeys({});
        this.loadUserInfo(response.value);
    }

    componentDidUpdate(newProps: Props): void {
        if (newProps.serverState.keys !== this.props.serverState.keys) {
            this.loadUserInfo(newProps.serverState.keys);
        }
    }

    ///////////////////
    //[ OTHER STUFF ]
    loadUserInfo(apiKeys: ApiKey[]): void {
        if (!!apiKeys) {
            apiKeys.forEach((key: ApiKey) => {
                const userId = stripUserIdTestMode(key.userId);
                if (!find(this.props.users, {userId: userId})) {
                    this.props.getUser({userId: userId});
                }
            });
        }
    }

    getApiKeySubmit(submit: () => void): void {
        this.newKeySubmit = submit;
    }

    ///////////////////
    //[ ACTIONS ]
    onToggleCreateKey(): void {
        const value = !this.props.uiState.createKeyOpen;
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_GENERATE_KEY_OPEN, value});
    }

    onNewKeyPress(): void {
        if (!!this.newKeySubmit) {
            this.newKeySubmit();
        }
    }

    async onNewKeySubmit(value: string): Promise<void> {
        try {
            this.props.createApiKey({name: value});
            this.onToggleCreateKey();
            this.onToggleNewKeyDialog();
        } catch (err) {
            this.onToggleCreateKey();
        }
    }

    onToggleNewKeyDialog(): void {
        const value = !this.props.uiState.viewNewKeyOpen;
        this.props.uiAction({uiStatePath: UiActionPaths.TEAM_VIEW_KEY_OPEN, value});
    }

    async onDeleteApiKey(tokenId: string): Promise<void> {
        await this.props.deleteApiKey({tokenId});
        message.success("Api Key Deleted");
    }

    ///////////////////
    //[ RENDERING ]
    renderApiKeyRow(key: ApiKey, i: number): JSX.Element {
        //Look for the team member who created this key
        const userId = key.userId.replace("-TEST", "");
        const accountUser = find(this.props.users, {userId: userId});

        //Set creator using teamMembers username, else check if it's current user and use the email, else display the user id
        const creator = (!!accountUser) ? accountUser.userDisplayName : (userId === get(this.props.jwt, ["data", "g", "tmi"]) && !!this.props.userEmail) ? this.props.userEmail : key.userId;

        return (
            <ApiKeyRow
                key={"k9" + i}
                apiKey={key}
                creator={creator}
                onDelete={this.onDeleteApiKey}
            />
        );
    }

    renderNewKeyButton(): JSX.Element {
        return (
            <div className={"generateKeyContainer"}>
                <Popover
                    content={
                        <div>
                            <Subheading className="marginBottom8">Generate API Key</Subheading>
                            <SingleEntryForm
                                hint="Key Name"
                                autoFocus={true}
                                required={true}
                                getSubmit={this.getApiKeySubmit}
                                onSubmit={this.onNewKeySubmit}
                            />
                            <div className="marginTop16">
                                <Button
                                    type="primary"
                                    loading={this.props.serverState.processing}
                                    onClick={this.onNewKeyPress}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    }
                    placement="left"
                    visible={this.props.uiState.createKeyOpen}
                    onVisibleChange={this.onToggleCreateKey}
                    trigger="click"
                >
                    <Button>
                        <PlusOutlined/>Generate Key
                    </Button>
                </Popover>
            </div>
        );
    }

    render(): JSX.Element {
        if (this.props.serverState.fetching && !this.props.serverState.fetchCount) {
            return (
                <PaddedLoadingSpinner/>
            );
        }

        return (
            <AccountLayout>
                <div>
                    <div className={"keyContainer"}>
                        <AccountHeader title="API Keys" actionButton={this.renderNewKeyButton()}/>
                        {
                            // Render API Key List
                            (!!this.props.serverState.keys && this.props.serverState.keys.length)
                                ? this.props.serverState.keys.map(this.renderApiKeyRow)
                                : <Caption textColor={TextColor.SECONDARY}>No Keys Found</Caption>
                        }
                    </div>
                    <NewKeyModal
                        open={this.props.uiState.viewNewKeyOpen}
                        apiKey={this.props.serverState.lastCreatedKey}
                        onRequestClose={this.onToggleNewKeyDialog}
                    />
                </div>
                <Divider/>
                <TurnkeyTokensConnected/>
                <Divider/>
                <StripeOAuthConnected/>
            </AccountLayout>
        );
    }
}