import * as React from "react";
import {SearchId, SearchType} from "../../../state/serverState/SearchesServerState";
import {Button, Dropdown} from "antd";
import {Paper} from "../../ui/layout/Paper";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {SearchFormConnected} from "../searchForm/SearchFormConnected";
import {Contact, Program, Transaction, Value} from "lightrail-client/dist/model";
import {WithRouterProps} from "../../router/WithRouterProps";
import {formatRoute, Paths} from "../../../util/routes/routes";
import {getLocationRoot} from "../../../util/location";
import {SearchResultsList} from "../searchResults/SearchResultsList";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import {ReduxProps} from "./GlobalSearchDropdownConnected";

export interface Props extends WithRouterProps<{}>, ReduxProps {
}

export const IGNORE_CLOSE = "LR_CLICK_TEST";

export class GlobalSearchDropdown extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onProgramClick = this.onProgramClick.bind(this);
        this.onContactClick = this.onContactClick.bind(this);
        this.onValueClick = this.onValueClick.bind(this);
        this.onTransactionClick = this.onTransactionClick.bind(this);

        this.getSearchType = this.getSearchType.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener("mousedown", this.onDocumentClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.onDocumentClick);
    }

    //
    static getLocationString(type: SearchType): "All" | "Values" | "Programs" | "Contacts" | "Transactions" {
        switch (type) {
            case SearchType.PROGRAMS:
                return "Programs";
            case SearchType.CONTACTS:
                return "Contacts";
            case SearchType.VALUES:
                return "Values";
            case SearchType.TRANSACTIONS:
                return "Transactions";
            default:
                return "All";
        }
    }

    getSearchType(): SearchType {
        const locationRoot = getLocationRoot(this.props.location.pathname);

        if (locationRoot.match("programs")) {
            return SearchType.PROGRAMS;
        }
        if (locationRoot.match("values")) {
            return SearchType.VALUES;
        }
        if (locationRoot.match("contacts")) {
            return SearchType.CONTACTS;
        }
        if (locationRoot.match("transactions")) {
            return SearchType.TRANSACTIONS;
        }

        return SearchType.EVERYTHING;
    }

    toggleOpen(): void {
        const open = !this.props.globalSearchStateUI.open;

        this.props.uiAction({
            uiStatePath: UiActionPaths.GLOBAL_SEARCH_OPEN,
            value: open
        });

        if (open) {
            this.props.clearSearch({id: SearchId.GLOBAL});
        }
    }

    ///////////////////
    //[ ACTIONS ]
    onDocumentClick(event: MouseEvent): void {
        if (this.props.globalSearchStateUI.open) {
            let outsideClickTests = true;
            const clickTestElements = document.getElementsByClassName(IGNORE_CLOSE);
            for (let i = 0; i < clickTestElements.length; i++) {
                if (clickTestElements[i].contains(event.target as Node)) {
                    outsideClickTests = false;
                    break;
                }
            }

            if (outsideClickTests) {
                this.toggleOpen();
            }
        }
    }

    onItemSelect(path: string): void {
        this.props.history.push(path);
        this.toggleOpen();
    }

    onContactClick(contact: Contact): void {
        this.onItemSelect(formatRoute(Paths.CONTACT_DETAIL, {contactId: contact.id}));
    }

    onProgramClick(program: Program): void {
        this.onItemSelect(formatRoute(Paths.PROGRAM_DETAIL, {programId: program.id}));
    }

    onValueClick(value: Value): void {
        this.onItemSelect(formatRoute(Paths.VALUE_DETAIL, {valueId: value.id}));
    }

    onTransactionClick(transaction: Transaction): void {
        this.onItemSelect(formatRoute(Paths.TRANSACTION_DETAIL, {transactionId: transaction.id}));
    }

    ///////////////////
    //[ RENDER ]
    renderSearchOverlay(): JSX.Element {
        return (
            <Paper className={IGNORE_CLOSE} style={{minWidth: 400}}>
                <h3>Search</h3>
                <SearchFormConnected
                    id={SearchId.GLOBAL}
                    defaultSearchType={this.getSearchType()}
                    dropdownClassName={IGNORE_CLOSE}
                />
                <SearchResultsList
                    searchState={this.props.globalSearchState}
                    onProgramClick={this.onProgramClick}
                    onContactClick={this.onContactClick}
                    onTransactionClick={this.onTransactionClick}
                    onValueClick={this.onValueClick}
                    actionButtonText="GO"
                />
            </Paper>
        );
    }

    render(): JSX.Element {
        return (
            <Dropdown
                visible={this.props.globalSearchStateUI.open}
                overlay={this.renderSearchOverlay()}
                trigger={["click"]}
                placement="bottomRight"
            >
                <Button
                    style={{fontSize: "1em"}}
                    className={IGNORE_CLOSE}
                    shape="circle"
                    onClick={this.toggleOpen}
                >
                    {
                        this.props.globalSearchStateUI.open ? <CloseOutlined/> : <SearchOutlined/>
                    }
                </Button>
            </Dropdown>
        );
    }
}