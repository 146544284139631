import * as React from "react";
import {Button, message} from "antd";
import {download} from "../../util/downloadUtils";
import {ExternalProps, ReduxProps} from "./IssuanceDownloadButtonConnected";
import {MAXIMUM_ISSUANCE_SIZE} from "./create/CreateIssuanceForm";
import {formatDate} from "../../util/dateUtils";
import {DownloadOutlined} from "@ant-design/icons";

export interface InternalProps extends ExternalProps, ReduxProps {
}

interface State {
    fetching: boolean;
}

export class IssuanceDownloadButton extends React.PureComponent<InternalProps, State> {
    constructor(props: InternalProps) {
        super(props);

        this.state = {
            fetching: false
        };

        this.onClick = this.onClick.bind(this);
    }

    async onClick(): Promise<void> {
        this.setState({fetching: true});
        //Fetch Values
        try {
            const response = await this.props.listValuesCSV({
                    issuanceId: this.props.issuance.id,
                    showCode: true,
                    limit: MAXIMUM_ISSUANCE_SIZE,
                    formatCurrencies: true
                }
            );

            this.setState({fetching: false});

            let csvName = this.props.issuance.name || `Issuance_${this.props.issuance.id}`;

            if (!!this.props.issuance.createdDate) {
                csvName += " " + formatDate(this.props.issuance.createdDate);
            }
            message.success("Downloading Codes");
            download(`${csvName}.csv`, response.value.text);
        } catch {
            this.setState({fetching: false});
        }
    }

    render(): JSX.Element {
        const overrideProps = this.props.buttonProps || {};

        return (
            <Button
                {...overrideProps}
                loading={this.state.fetching}
                onClick={this.onClick}
            >
                <DownloadOutlined/>
                {
                    (!!this.props.text) ? this.props.text : null
                }
            </Button>
        );
    }
}