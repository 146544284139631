import {filterLightrailNamespace, getReadableKey} from "./stringUtils";
import {isJSON, tryParseJSON} from "./dataUtils";
import {Props} from "../components/ui/data/SimpleStat";

/**
 * Used for displaying metadata in the app. Automatically formats lightrail_webapp namespaced keys for cleaner display in app.
 *
 * Example, input:
 * {
 *   "status": "new-sign-up",
 *   "lightrail_webapp_contact_notes": "notes about new sign-up"
 * }
 *
 * Returns:
 * [
 *   {
 *     "label": "Status",
 *     "value": "new-sign-up"
 *   },
 *   {
 *     "label": "Lightrail Contact Notes", // this is formatted for display in the app.
 *     "value": "notes about new sign-up"
 *   }
 * ]
 */
export function formatMetadataForDetailsDisplay(metadata: Record<string, any>): Props[] {
    return Object.keys(metadata).map(key => {
        const mdValue = (metadata as { [key: string]: any })[key];

        const label = getReadableKey(filterLightrailNamespace(key));
        return ({
            key: label,
            label: label,
            value: isJSON(mdValue) ? JSON.stringify(tryParseJSON(mdValue), null, 4) : mdValue,
            className: isJSON(mdValue) ? "codeDisplay" : ""
        });
    });
}