import * as React from "react";
import {ReactNode} from "react";
import {Col, Form, Input, Popover, Radio} from "antd";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {RadioChangeEvent} from "antd/lib/radio";
import {
    getDiscountSellerLiabilityType,
    seller0Liable,
    seller100Liable,
    sellerShared,
    StandardSellerLiabilityEnum
} from "./DiscountSellerLiabilityEnumsAndUtils";
import {DiscountSellerLiabilityRule} from "lightrail-client/dist/model";
import {FormInstance} from "antd/es/form";
import {InfoCircleOutlined} from "@ant-design/icons";

export interface State {
    discountSellerLiabilityRule: DiscountSellerLiabilityRule;
    discountSellerLiabilityType: StandardSellerLiabilityEnum;
    isCustom: boolean;
}

export interface Props {
    form: FormInstance;
    extraText?: string;
    disabled?: boolean;
    discountSellerLiabilityRule?: DiscountSellerLiabilityRule;
}

export class DiscountSellerLiabilityRadioGroup extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const discountSellerLiabilityValue = !!this.props.discountSellerLiabilityRule ? this.props.discountSellerLiabilityRule : seller0Liable;
        this.state = {
            discountSellerLiabilityRule: discountSellerLiabilityValue,
            discountSellerLiabilityType: getDiscountSellerLiabilityType(discountSellerLiabilityValue),
            isCustom: !!(this.props.discountSellerLiabilityRule && (getDiscountSellerLiabilityType(discountSellerLiabilityValue) === StandardSellerLiabilityEnum.CUSTOM))
        };
        this.onSellerChange = this.onSellerChange.bind(this);
    }

    onSellerChange(e: RadioChangeEvent): void {
        let rule: DiscountSellerLiabilityRule;
        const type: StandardSellerLiabilityEnum = e.target.value;
        switch (type) {
            case StandardSellerLiabilityEnum.SELLER_0_LIABLE:
                rule = seller0Liable;
                break;
            case StandardSellerLiabilityEnum.SELLER_100_LIABLE:
                rule = seller100Liable;
                break;
            case StandardSellerLiabilityEnum.SELLER_SHARED:
                rule = sellerShared;
                break;
            case StandardSellerLiabilityEnum.CUSTOM:
                rule = {
                    rule: "0.5",
                    explanation: "Seller is 50% liable"
                }
                break;
            default:
                throw Error("This cannot happen");
        }
        this.setState({
            discountSellerLiabilityType: type,
            discountSellerLiabilityRule: rule
        }, () => {
            this.props.form.setFieldsValue({[FormFieldIds.DiscountSellerLiabilityExplanation]: (this.state.discountSellerLiabilityRule.explanation.toString())});
            this.props.form.setFieldsValue({[FormFieldIds.DiscountSellerLiabilityRule]: (this.state.discountSellerLiabilityRule.rule.toString())});
        });
    }

    renderCustom(): JSX.Element {
        const isCustom: boolean = this.state.discountSellerLiabilityType === StandardSellerLiabilityEnum.CUSTOM;
        return (
            <Col style={{display: isCustom ? "block" : "none"}}>
                <Form.Item
                    className={"marginTop8"}
                    label="Explanation"
                    name={FormFieldIds.DiscountSellerLiabilityExplanation}
                    initialValue={this.state.discountSellerLiabilityRule.explanation}
                >
                    <Input
                        placeholder="Seller is 50% liable"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    className={"marginTop8"}
                    label="Syntax"
                    name={FormFieldIds.DiscountSellerLiabilityRule}
                    initialValue={this.state.discountSellerLiabilityRule.rule}
                >
                    <Input
                        placeholder="0.5"
                        size="large"
                    />
                </Form.Item>
            </Col>);
    }

    sellerSharedButton(): ReactNode {
        return (
            <span>
                Based on marketplace rate&nbsp;
                <Popover
                    content={"Requires that the marketplace rate is set on line items in checkout requests"}
                >
                    <InfoCircleOutlined/>
                </Popover>
            </span>
        );
    }

    render(): JSX.Element {
        const type = this.state.discountSellerLiabilityType;

        return (
            <div>
                {
                    (!this.props.disabled) &&
                    <Col span={16}>
                        <Form.Item
                            className={"marginBottom0"}
                            name={FormFieldIds.DiscountSellerLiabilityType}
                            initialValue={type}
                        >
                            <Radio.Group
                                name="DiscountSellerLiabilityRateSelectGroup"
                                onChange={this.onSellerChange}
                                style={{
                                    display: "block",
                                    marginLeft: 8
                                }}
                            >
                                <fieldset className="lr-fieldset">
                                    <Radio
                                        name="Seller0Liable"
                                        value={StandardSellerLiabilityEnum.SELLER_0_LIABLE}
                                    >
                                        Seller 0% liable
                                    </Radio>
                                    <br/>
                                    <Radio
                                        name="Seller100Liable"
                                        value={StandardSellerLiabilityEnum.SELLER_100_LIABLE}
                                    >
                                        Seller 100% liable
                                    </Radio>
                                    <br/>
                                    <Radio
                                        name="SellerShared"
                                        value={StandardSellerLiabilityEnum.SELLER_SHARED}
                                    >
                                        {this.sellerSharedButton()}
                                    </Radio>
                                    <br/>
                                    <Radio
                                        name="Custom"
                                        value={StandardSellerLiabilityEnum.CUSTOM}
                                    >
                                        Custom
                                    </Radio>
                                    {this.renderCustom()}
                                    <br/>
                                </fieldset>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                }
            </div>
        );
    }
}