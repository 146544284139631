import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import {LoginResponse} from "../../../communication/dtos/auth/Login";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../rejectionHandler";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";
import {setSentryUserContext} from "../../../util/sentry";
import {onPasswordRejected} from "./passwordRejectionHandler";
import {redirectOnMessageCode} from "../../../util/routes/redirectOnMessageCode";

export const type = "reAuth";

export const actionCreator = promisePattern.actionCreator(type, auth.login);

const onFulfilled: promisePattern.PromiseFulfilledHandler<LoginResponse> =
    (state, payload) => {
        const MFAEnabled = !!payload?.user.mfa;
        const testing = payload?.user.mode === UserModes.TEST;

        if (payload?.user.email) {
            setSentryUserContext(payload?.user.email);
        }

        const user = sprout.assoc(state.server.user,
            ["id"], payload?.user.id,
            ["email"], payload?.user.email,
            ["testing"], testing,
            ["loggedIn"], true,
        );

        const accountMessage = {
            message: payload?.message,
            messageCode: payload?.messageCode
        };

        return sprout.assoc(state,
            ["server", "auth", "processing"], false,
            ["server", "user"], user,
            ["server", "MFA", "enabled"], MFAEnabled,
            ["server", "auth", "errorMessage"], [],
            ["server", "account", "authMessage"], accountMessage,
            ["ui", "history"], redirectOnMessageCode(state.ui.history, accountMessage.messageCode)
        );
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onPasswordRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => ({
        ...state,
        server: {
            ...state.server,
            auth: {
                ...state.server.auth,
                processing: true
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);