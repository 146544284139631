import * as React from "react";
import {Button, Col, Row, Select, Table} from "antd";
import {formatDate} from "../../../util/dateUtils";
import {WithRouterProps} from "../../router/WithRouterProps";
import {formatRoute, Paths} from "../../../util/routes/routes";
import {LoadMoreFooter} from "../../ui/pagination/LoadMoreFooter";
import {ListProgramsParams} from "lightrail-client/dist/params";
import {UiActionPaths} from "../../../state/uistate/UiActionPaths";
import {CreateProgramDrawerConnected} from "../create/CreateProgramDrawerConnected";
import {ListLayout} from "../../layout/list/ListLayout";
import {ProgramListType} from "../create/ProgramEnums";
import {Program} from "lightrail-client/dist/model";
import {ProgramCopy} from "../create/CreateProgramFormCopy";
import {PlusOutlined} from "@ant-design/icons";
import {ReduxProps} from "./ProgramsListConnected";

interface TableRow {
    key: string;
    id: string;
    name: string;
    status?: JSX.Element;
    pretax?: string;
    createdOn: string;
    endOn: string;
}

export interface State {
    programDisplayType?: string;
}

export interface Props extends WithRouterProps<{}>, ReduxProps {
}

export class ProgramsList extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            programDisplayType: ProgramListType.ACTIVE
        };

        this.fetchAllPrograms = this.fetchAllPrograms.bind(this);
        this.fetchActivePrograms = this.fetchActivePrograms.bind(this);
        this.fetchInactivePrograms = this.fetchInactivePrograms.bind(this);
        this.fetchProgramByActiveType = this.fetchProgramByActiveType.bind(this);
        this.setupRow = this.setupRow.bind(this);
        this.onToggleCreateProgram = this.onToggleCreateProgram.bind(this);
        this.onDisplayProgramByActiveType = this.onDisplayProgramByActiveType.bind(this);
        this.clearAllProgramLists = this.clearAllProgramLists.bind(this);
        this.mapProgramDetails = this.mapProgramDetails.bind(this);
        this.programListLength = this.programListLength.bind(this);
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (prevState.programDisplayType != this.state.programDisplayType) {
            this.clearAllProgramLists();
            this.fetchProgramByActiveType();
        }
    }

    componentDidMount(): void {
        this.clearAllProgramLists();
        this.fetchProgramByActiveType();
    }

    clearAllProgramLists(): void {
        this.props.clearAllPrograms({});
        this.props.clearActivePrograms({});
        this.props.clearInactivePrograms({});
    }

    fetchAllPrograms(params?: ListProgramsParams): void {
        this.props.getAllPrograms({...params, limit: 50});
    }

    fetchActivePrograms(params?: ListProgramsParams): void {
        this.props.getActivePrograms({
            ...params,
            limit: 50
        });
    }

    fetchInactivePrograms(params?: ListProgramsParams): void {
        this.props.getInactivePrograms({
            ...params,
            limit: 50
        });
    }

    fetchProgramByActiveType(params?: ListProgramsParams): void {
        switch (this.state.programDisplayType) {
            case ProgramListType.ALL:
                this.fetchAllPrograms(params);
                break;
            case ProgramListType.INACTIVE:
                this.fetchInactivePrograms(params);
                break;
            default:
                this.fetchActivePrograms(params);
        }
    }

    ///////////////////
    //[ ACTIONS ]
    setupRow(record: TableRow): { onClick: () => void } {
        return {
            onClick: () => this.props.history.push(formatRoute(Paths.PROGRAM_DETAIL, {programId: record.id}))
        };
    }

    onToggleCreateProgram(): void {
        this.props.uiAction({
            uiStatePath: UiActionPaths.PROGRAM_CREATE_DRAWER_OPEN,
            value: !this.props.programUiState.createDrawerOpen
        });
    }

    ///////////////////
    //[ RENDER ]
    mapProgramDetails(program: Program): TableRow {
        return ({
            key: program.id,
            id: program.id,
            name: program.name,
            createdOn: formatDate(program.createdDate),
            endOn: formatDate(program.endDate)
        });
    }

    getTable(): TableRow[] {
        const active = this.props.programServerState.activeList.map(
            program => this.mapProgramDetails(program)
        );
        const inactive = this.props.programServerState.inactiveList.map(
            program => this.mapProgramDetails(program)
        );
        const all = this.props.programServerState.allList.map(
            program => this.mapProgramDetails(program)
        );
        switch (this.state.programDisplayType) {
            case ProgramListType.ALL:
                return all;
            case ProgramListType.INACTIVE:
                return inactive;
            default:
                return active;
        }
    }

    onDisplayProgramByActiveType(p: string): void {
        switch (p) {
            case "inactive":
                this.setState({
                    programDisplayType: ProgramListType.INACTIVE
                });
                break;
            case "all":
                this.setState({
                    programDisplayType: ProgramListType.ALL
                });
                break;
            default:
                this.setState({
                    programDisplayType: ProgramListType.ACTIVE
                });
        }
    }

    programListLength(): number {
        switch (this.state.programDisplayType) {
            case ProgramListType.ALL:
                return this.props.programServerState.allList.length;
            case ProgramListType.INACTIVE:
                return this.props.programServerState.inactiveList.length;
            default:
                return this.props.programServerState.activeList.length;
        }
    }

    renderProgramOffering(): JSX.Element {
        const links: { title: string, link: string }[] = [
            {title: "Gift Cards", link: ProgramCopy.GIFT_CARDS_DOCS_LINK_URL},
            {title: "Referrals", link: ProgramCopy.REFERRALS_DOCS_LINK_URL},
            {title: "Promotions", link: ProgramCopy.PROMOTIONS_DOCS_LINK_URL},
            {title: "Points", link: ProgramCopy.ACCOUNTS_AND_POINTS_DOCS_LINK_URL}
        ];

        return (
            <Row justify="center">
                {
                    links.map(link => (
                        <Col key={link.link} className={"marginLeft8 marginRight8"}>
                            <a className={"ant-btn ant-btn-primary"}
                               href={link.link}
                               target="_blank"
                               rel="noreferrer"
                            >
                                {link.title}
                            </a>
                        </Col>
                    ))
                }
            </Row>
        );
    }

    render(): JSX.Element {
        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Created On",
                dataIndex: "createdOn",
                key: "createdOn"
            },
            {
                title: "End On",
                dataIndex: "endOn",
                key: "endOn"
            }
        ];
        // CREATE PROGRAM BUTTON IS TEMPORARY
        const fetching = this.props.programServerState.fetching || this.props.programServerState.fetchingInactive || this.props.programServerState.fetchingActive;
        return (
            <ListLayout headerProps={{
                headerActions: (this.fetchProgramByActiveType.length > 0) && (
                    <Select
                        className="marginRight16"
                        id="lr-program-filter-select"
                        style={{width: 100}}
                        defaultValue={ProgramListType.ACTIVE}
                        onChange={this.onDisplayProgramByActiveType}
                    >
                        <Select.Option
                            value={ProgramListType.ACTIVE}
                        >
                            Active
                        </Select.Option>
                        <Select.Option
                            value={ProgramListType.INACTIVE}
                        >
                            Inactive
                        </Select.Option>
                        <Select.Option
                            value={ProgramListType.ALL}
                        >
                            All
                        </Select.Option>
                    </Select>
                ),
                createButton:
                    (
                        <Button type="primary"
                                onClick={this.onToggleCreateProgram}
                                data-cy="program-create-button"
                        >
                            <PlusOutlined/>
                            Create Program
                        </Button>
                    )
            }}
            >
                <Table
                    className="lr-table-row-clickable"
                    loading={fetching}
                    columns={columns}
                    dataSource={this.getTable()}
                    onRow={this.setupRow}
                    pagination={false}
                    sortDirections={["ascend", "descend"]}
                    scroll={{x: 700}}
                />
                <LoadMoreFooter
                    numResults={this.programListLength()}
                    limit={this.props.programServerState.pagination.limit}
                    loading={fetching}
                    links={this.props.programServerState.links}
                    onClick={this.fetchProgramByActiveType}
                />
                <Row justify="center">
                    <Col>
                        <p className={"lr-text-secondary"}>Learn about some of the other types of Programs you can
                            use.</p>
                    </Col>
                </Row>
                {this.renderProgramOffering()}
                <CreateProgramDrawerConnected
                    open={this.props.programUiState.createDrawerOpen}
                    onCancel={this.onToggleCreateProgram}
                />
            </ListLayout>
        );
    }
}