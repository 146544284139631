import * as React from "react";
import {UpdatePasswordRequest} from "../../../communication/dtos/auth/UpdatePassword";
import {Form, Input} from "antd";
import {PasswordFieldConnected} from "../../ui/forms/passwordField/PasswordFieldConnected";
import {FormFieldIds} from "../../../util/forms/AntFormHelpers";
import {AuthFormErrorItem} from "../../auth/forms/AuthFormErrorItem";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {useForm} from "antd/es/form/Form";
import {LockOutlined} from "@ant-design/icons";

export interface Props {
    errors: string[];

    onUpdate?: () => void;
    getSubmit?: (submit: () => void) => void;
    onSubmit: (params: UpdatePasswordRequest) => void;
}

export const UpdatePasswordForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [oldPasswordFieldValue, setOldPasswordFieldValue] = React.useState("");
    const [newPasswordFieldValue, setNewPasswordFieldValue] = React.useState("");
    const [oldPasswordErrors, setOldPasswordErrors] = React.useState([]);
    const [newPasswordErrors, setNewPasswordErrors] = React.useState([]);
    const [asyncErrors, setAsyncErrors] = React.useState([]);

    const submit = async (): Promise<void> => {
        try {
            setAsyncErrors([]);
            const values = await form.validateFields();
            props.onSubmit({
                oldPassword: values[FormFieldIds.OldPassword],
                newPassword: values[FormFieldIds.NewPassword]
            } as UpdatePasswordRequest);
        } catch (err) {
            const errorArray: string[] = [];
            (err as any).errorFields.forEach((errorField: any) => errorArray.push(...errorField.errors));
            setAsyncErrors(errorArray);
        }
    };

    React.useEffect(() => {
        props.getSubmit(submit);
    }, []);

    React.useEffect(() => {
        if (props.onUpdate && props.errors?.length) {
            props.onUpdate();
        }
    }, [props.onUpdate, props.errors]);

    React.useEffect(() => {
        const oldErrors = form.getFieldError(FormFieldIds.OldPassword);
        setOldPasswordErrors(oldErrors);
    }, [oldPasswordFieldValue]);

    React.useEffect(() => {
        const newErrors = form.getFieldError(FormFieldIds.NewPassword);
        setNewPasswordErrors(newErrors);
    }, [newPasswordFieldValue]);

    return (
        <Form onFinish={submit} form={form}>
            <Form.Item
                label="Current Password"
                help=""
                name={FormFieldIds.OldPassword}
                rules={[
                    {required: true, message: "Please enter your current password"}
                ]}
                validateStatus={oldPasswordErrors.length ? "error" : "success"}
            >
                <Input
                    prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                    type="password"
                    size="large"
                    placeholder="Current Password"
                    onChange={(event) => setOldPasswordFieldValue(event.currentTarget.nodeValue)}
                />
            </Form.Item>

            <PasswordFieldConnected
                form={form}
                formId={FormFieldIds.NewPassword}
                label="New Password"
                errors={newPasswordErrors}
                onChange={(event) => setNewPasswordFieldValue(event.currentTarget.nodeValue)}
            />
            <AuthFormErrorItem errors={[...asyncErrors, ...(props.errors || [])]}/>
            <AllowSubmitOnEnter/>
        </Form>
    );
};