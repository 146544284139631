import {UiColors} from "./CSSTypes";

// disable eslint to import legacy styles
const ColorsCss = require("./_colors.scss") as UiColors; // eslint-disable-line

export const displayInlineBlock = {display: "inline-block"};

//TODO Depricated, remove all uses and replace with _colors.scss
export const Colors = {
    black: ColorsCss.black,
    white: ColorsCss.white,
    green: ColorsCss.green,
    blue: ColorsCss.blue,
    red: ColorsCss.red,

    primary: ColorsCss.primary,
    secondary: ColorsCss.secondary,
    secondaryLabel: ColorsCss.secondaryLabel,

    text: ColorsCss.text,
    textSecondary: ColorsCss.textSecondary,
    textDisabled: ColorsCss.textDisabled,

    active: ColorsCss.active,
    accent: ColorsCss.accent,
    warn: ColorsCss.warn,
    alert: ColorsCss.alert,

    greyLight: ColorsCss.greyLight,
    greyMedium: ColorsCss.greyMedium,
    grey: ColorsCss.grey,
    greyDark: ColorsCss.greyDark,
    greenLight: ColorsCss.greenLight,
    beige: ColorsCss.beige,
    blueLight: ColorsCss.blueLight,
    redCancelled: ColorsCss.redCancelled,

    textInverted: ColorsCss.textInverted,
    textSecondaryInverted: ColorsCss.textSecondaryInverted,
    textDisabledInverted: ColorsCss.textDisabledInverted,

    avatarColor0: ColorsCss.avatarColor0,
    avatarColor1: ColorsCss.avatarColor1,
    avatarColor2: ColorsCss.avatarColor2,
    avatarColor3: ColorsCss.avatarColor3,
    avatarColor4: ColorsCss.avatarColor4,
    avatarColor5: ColorsCss.avatarColor5,
    avatarColor6: ColorsCss.avatarColor6,
    avatarColor7: ColorsCss.avatarColor7,
    avatarColor8: ColorsCss.avatarColor8,
    avatarColor9: ColorsCss.avatarColor9,
    avatarColor10: ColorsCss.avatarColor10,
    avatarColor11: ColorsCss.avatarColor11,
};

export const FormStyles = {
    blockTextField: {
        display: "block",
        margin: "4px 0",
        maxWidth: 360,
        color: ColorsCss.textSecondary,
    }
};