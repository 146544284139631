export enum WebhookEventEnums {
    LIGHTRAIL_CONTACT_CREATED = "lightrail.contact.created",
    LIGHTRAIL_CONTACT_DELETED = "lightrail.contact.deleted",
    LIGHTRAIL_CONTACT_UPDATED = "lightrail.contact.updated",
    LIGHTRAIL_CURRENCY_CREATED = "lightrail.currency.created",
    LIGHTRAIL_CURRENCY_DELETED = "lightrail.currency.deleted",
    LIGHTRAIL_CURRENCY_UPDATED = "lightrail.currency.updated",
    LIGHTRAIL_PROGRAM_CREATED = "lightrail.program.created",
    LIGHTRAIL_PROGRAM_DELETED = "lightrail.program.deleted",
    LIGHTRAIL_PROGRAM_UPDATED = "lightrail.program.updated",
    LIGHTRAIL_TRANSACTION_CREATED = "lightrail.transaction.created",
    LIGHTRAIL_VALUE_CREATED = "lightrail.value.created",
    LIGHTRAIL_VALUE_DELETED = "lightrail.value.deleted",
    LIGHTRAIL_VALUE_UPDATED = "lightrail.value.updated"
}

export enum InsecureUrlErrorMessage {
    URL_ERROR = "Must be a secure url beginning with https."
}