import * as React from "react";
import {Button, Dropdown, Menu, message, Modal} from "antd";

import {Contact, Value} from "lightrail-client/dist/model";
import {ValueActionStatus} from "../../../state/uistate/ValueUiState";
import {TransactionType} from "../../../state/uistate/TransactionUiState";

import {CreateTransactionModal, TransactionProperty} from "../../transactions/create/CreateTransactionModal";
import {isBeforeToday} from "../../../util/dateUtils";
import {ReduxProps, ExternalProps} from "./ValueActionMenuConnected";

import {getAttachesRemaining, isGenericCodeWithPerContactProperties} from "../../../util/valueUtils";

import {ShowFullCodeModal} from "./ShowFullCodeModal";
import {ChangeCodeModal} from "./ChangeCodeModal";

import {UndoLink} from "../../ui/undo/UndoLink";
import {ActionMenu} from "../../ui/menus/ActionMenu";
import {ModifyDatesModal} from "./ModifyDatesModal";
import {DownOutlined, EllipsisOutlined} from "@ant-design/icons/lib";
import {MenuInfo} from 'rc-menu/lib/interface';
import {AttachContactModalConnected} from "./attachContactModal/AttachContactModalConnected";

export enum ValueActionMenuContextType {DEFAULT, VALUE_DETAIL, VALUE_LIST_PAGE, CONTACT_DETAIL}

export interface ValueActionMenuContextData {
    contact?: Contact;
}

export interface ValueActionMenuContext extends ActionMenu.MenuContext<ValueActionMenuContextType, ValueActionMenuContextData> {
}

interface Props extends ReduxProps, ExternalProps {
}

export class ValueActionMenu extends React.PureComponent<Props, {}> {
    cancelHandler: () => void;
    activateHandler: () => void;

    constructor(props: Props) {
        super(props);

        this.cancelHandler = this.updateValueStatus.bind(this, ValueActionStatus.CANCEL);
        this.activateHandler = this.updateValueStatus.bind(this, ValueActionStatus.ACTIVATE);

        this.onContactAttached = this.onContactAttached.bind(this);
        this.toggleConfirmActionModal = this.toggleConfirmActionModal.bind(this);
        this.toggleShowFullCodeModalHandler = this.toggleShowFullCodeModalHandler.bind(this);
        this.toggleChangeCodeModalHandler = this.toggleChangeCodeModalHandler.bind(this);
        this.closeChangeCodeModal = this.closeChangeCodeModal.bind(this);
        this.toggleModifyDateModalHandler = this.toggleModifyDateModalHandler.bind(this);
        this.closeModifyDateModal = this.closeModifyDateModal.bind(this);
        this.toggleTransactionModalHandler = this.toggleTransactionModalHandler.bind(this);
        this.closeTransactionModal = this.closeTransactionModal.bind(this);
        this.updateValueStatus = this.updateValueStatus.bind(this);
        this.getDisabledStatus = this.getDisabledStatus.bind(this);
        this.getValueStatus = this.getValueStatus.bind(this);
        this.resetPendingValueAction = this.resetPendingValueAction.bind(this);
        this.onActionMenuItemClicked = this.onActionMenuItemClicked.bind(this);
        this.onDetach = this.onDetach.bind(this);
    }

    componentDidMount(): void {
        this.props.createValueDropDown({
            valueId: this.props.value.id
        });
    }

    componentWillUnmount(): void {
        this.props.clearValueDropDown({
            valueId: this.props.value.id
        });
    }

    ///////////////////
    //[ DOING STUFF ]
    getValue(): Value {
        return this.props.valueStateServer.list.find(v => v.id === this.props.value.id);
    }

    // Set the modal back to NO_ACTION
    resetPendingValueAction(): void {
        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    }

    async updateValueStatus(valueActionStatus: ValueActionStatus): Promise<void> {
        let valueActionParams: { [key: string]: boolean } = {};
        let valueActionMessage: string;

        switch (valueActionStatus) {
            case ValueActionStatus.CANCEL:
                this.resetPendingValueAction();
                valueActionParams = {canceled: true};
                valueActionMessage = "canceled";
                break;
            case ValueActionStatus.UNCANCEL:
                this.resetPendingValueAction();
                valueActionParams = {canceled: false};
                valueActionMessage = "uncanceled";
                break;
            case ValueActionStatus.ACTIVATE:
                this.resetPendingValueAction();
                valueActionParams = {active: true};
                valueActionMessage = "active";
                break;
            case ValueActionStatus.FREEZE:
                valueActionParams = {frozen: true};
                valueActionMessage = "frozen";
                break;
            case ValueActionStatus.UNFREEZE:
                valueActionParams = {frozen: false};
                valueActionMessage = "unfrozen";
                break;
        }

        await this.props.updateValue({
            valueId: this.props.value.id,
            params: valueActionParams
        });

        message.info(
            `Value is now ${valueActionMessage}`
        );
    }

    toggleTransactionModalHandler(valueActionStatus: ValueActionStatus): void {
        this.props.toggleTransactionModal({
            valueId: this.props.value.id,
            params: {
                transactionModalOpen: !this.props.valueStateUI.transactionModalOpen
            }
        });

        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: valueActionStatus
            }
        });
    }

    closeTransactionModal(): void {
        this.props.toggleTransactionModal({
            valueId: this.props.value.id,
            params: {
                transactionModalOpen: !this.props.valueStateUI.transactionModalOpen
            }
        });
        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    }

    toggleModifyDateModalHandler(valueActionStatus: ValueActionStatus): void {
        this.resetPendingValueAction();
        this.props.getValue({
            valueId: this.props.value.id,
        });

        this.props.toggleModifyDateModal({
            valueId: this.props.value.id,
            params: {
                modifyDatesModalOpen: !this.props.valueStateUI.modifyDatesModalOpen
            }
        });

        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: valueActionStatus
            }
        });
    }

    closeModifyDateModal(): void {
        this.props.toggleModifyDateModal({
            valueId: this.props.value.id,
            params: {
                modifyDatesModalOpen: !this.props.valueStateUI.modifyDatesModalOpen
            }
        });
        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    }

    toggleChangeCodeModalHandler(valueActionStatus: ValueActionStatus): void {
        this.resetPendingValueAction();

        // request full code from value endpoint if needed
        if (!!this.props.value.code) {
            this.props.getValue({
                valueId: this.props.value.id,
                params: {
                    showCode: !this.props.valueStateUI.changeCodeModalOpen
                }
            });
        }

        this.props.toggleChangeCodeModal({
            valueId: this.props.value.id,
            params: {
                changeCodeModalOpen: !this.props.valueStateUI.changeCodeModalOpen
            }
        });

        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: valueActionStatus
            }
        });
    }

    closeChangeCodeModal(): void {
        this.props.toggleChangeCodeModal({
            valueId: this.props.value.id,
            params: {
                changeCodeModalOpen: !this.props.valueStateUI.changeCodeModalOpen
            }
        });
        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    }

    // set the UI to show the Full Code Modal
    toggleShowFullCodeModalHandler(): void {
        this.resetPendingValueAction();

        // request full code from value endpoint
        this.props.getValue({
            valueId: this.props.value.id,
            params: {
                showCode: !this.props.valueStateUI.showFullCodeModalOpen
            }
        });

        this.props.toggleFullCodeModal({
            valueId: this.props.value.id,
            params: {
                showFullCodeModalOpen: !this.props.valueStateUI.showFullCodeModalOpen
            }
        });
    }

    // show the Confirm Modal
    toggleConfirmActionModal(valueActionStatus: ValueActionStatus): void {
        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: valueActionStatus
            }
        });
    }

    toggleAttachContactModal(): void {
        this.props.setPendingValueAction({
            valueId: this.props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.ATTACH_CONTACT
            }
        });
    }

    isValueExpired(): boolean {
        return isBeforeToday(this.props.value.endDate);
    }

    getDisabledStatus(disabledByState: boolean, disabledWhenCanceled: boolean = true): boolean {
        return disabledByState || this.isValueExpired() || (disabledWhenCanceled && this.props.value.canceled);
    }

    getValueStatus(): string {
        if (this.props.value.frozen) {
            return "Frozen";
        }
        if (this.props.value.active) {
            return "Active";
        }
        return "Inactive";
    }

    ///////////////////
    //[ ACTIONS ]
    onContactAttached(contact: Contact): void {
        this.resetPendingValueAction();

        if (this.props.onContactAttached) {
            this.props.onContactAttached(contact.id);
        }
    }

    async onDetach(): Promise<void> {
        this.resetPendingValueAction();

        let contactId = this.props.value.contactId;
        if (!!this.props.menuContext) {
            if (!!this.props.menuContext.data && !!this.props.menuContext.data.contact) {
                contactId = this.props.menuContext.data.contact.id;
            }
        }

        const valueId = this.props.value.id;

        try {
            await this.props.detachContact({contactId, params: {valueId}, removeValue: true});
            message.info((
                <span>
                {
                    `${(!!this.props.value.code) ? this.props.value.code : "value"} removed.`
                }
                    <UndoLink
                        onUndo={this.props.attachContact}
                        params={{contactId, params: {valueId}}}
                        successMessage="Remove undone"
                    />
            </span>
            ));
        } catch (err) {
            message.error(err.message);
        }
    }

    onActionMenuItemClicked(p: MenuInfo): void {
        switch (p.key) {
            case ValueActionStatus.FREEZE:
            case ValueActionStatus.UNFREEZE:
            case ValueActionStatus.CANCEL:
            case ValueActionStatus.UNCANCEL:
                this.updateValueStatus(p.key);
                break;
            case ValueActionStatus.ACTIVATE:
                this.toggleConfirmActionModal(p.key);
                break;
            case ValueActionStatus.DEBIT:
            case ValueActionStatus.CREDIT:
            case ValueActionStatus.DEBIT_USES:
            case ValueActionStatus.CREDIT_USES:
            case ValueActionStatus.DEBIT_ATTACHES:
            case ValueActionStatus.CREDIT_ATTACHES:
                this.toggleTransactionModalHandler(p.key);
                break;
            case ValueActionStatus.SHOW_FULLCODE:
                this.toggleShowFullCodeModalHandler();
                break;
            case ValueActionStatus.CHANGE_CODE:
                this.toggleChangeCodeModalHandler(p.key);
                break;
            case ValueActionStatus.ATTACH_CONTACT:
                this.toggleAttachContactModal();
                break;
            case ValueActionStatus.DETACH_CONTACT:
                this.onDetach();
                break;
            case ValueActionStatus.MODIFY_DATES:
                this.toggleModifyDateModalHandler(p.key);
                break;
        }
    }

    ///////////////////
    //[ RENDERING ]
    renderActionButtons(): JSX.Element {
        const value = this.props.value;

        //Default Show/Hides
        let showCreditValue = (value.balance != null);
        let showDebitValue = (!value.isGenericCode && !!value.balance);

        let showCreditUses = (value.usesRemaining != null);
        let showDebitUses = (showCreditUses || value.usesRemaining > 0);

        let showAttach = (!value.contactId);
        let showDetach = (!!value.contactId);

        const showFullCode = (!value.isGenericCode && !!value.code);

        // Don't allow changeCode for Values that are attached. There's no reason for the Values to ever have a code.
        const showChangeCode = !value.attachedFromValueId;

        let showCreditAttaches = false;
        let showDebitAttaches = false;
        if (isGenericCodeWithPerContactProperties(value) && (value.usesRemaining != null || value.balance != null)) {
            showCreditValue = false;
            showDebitValue = false;
            showCreditUses = false;
            showDebitUses = false;

            showCreditAttaches = true;
            showDebitAttaches = getAttachesRemaining(value) > 0;
        }

        //Context Overrides
        if (!!this.props.menuContext) {
            const contextType = this.props.menuContext.type;
            if (contextType === ValueActionMenuContextType.CONTACT_DETAIL) {
                showCreditValue = false;
                showDebitValue = false;
                showCreditUses = false;
                showDebitUses = false;
                showAttach = false;
                showDetach = true;
            }

            if (contextType === ValueActionMenuContextType.VALUE_DETAIL) {
                showAttach = false;
                showDetach = false;
            }

            if (contextType === ValueActionMenuContextType.VALUE_LIST_PAGE) {
                showAttach = false;
                showDetach = false;
            }
        }

        const actionMenuItems = (
            <Menu
                onClick={this.onActionMenuItemClicked}
                className="lr-menu"
            >
                <Menu.Item
                    key={ValueActionStatus.ACTIVATE}
                    disabled={this.getDisabledStatus(value.active)}>
                    Activate
                </Menu.Item>
                <Menu.Item
                    key={ValueActionStatus.FREEZE}
                    disabled={this.getDisabledStatus(value.frozen)}>
                    Freeze
                </Menu.Item>
                <Menu.Item
                    key={ValueActionStatus.UNFREEZE}
                    disabled={this.getDisabledStatus(!value.frozen)}>
                    Unfreeze
                </Menu.Item>
                <Menu.Item
                    key={ValueActionStatus.CANCEL}
                    disabled={this.getDisabledStatus(value.canceled)}
                >
                    <span
                        className={(!value.canceled && !this.isValueExpired()) ? " cancel" : ""}
                    >
                        Cancel
                    </span>
                </Menu.Item>
                <Menu.Item
                    key={ValueActionStatus.UNCANCEL}
                    disabled={this.getDisabledStatus(!value.canceled, false)}>
                    Uncancel
                </Menu.Item>
                {
                    (showCreditValue && showDebitValue) && <Menu.Divider/>
                }
                {
                    (showCreditValue) &&
                    <Menu.Item
                        key={ValueActionStatus.CREDIT}
                        disabled={this.getDisabledStatus(value.canceled)}
                    >
                        Add credit
                    </Menu.Item>
                }
                {
                    (showDebitValue) &&
                    <Menu.Item
                        key={ValueActionStatus.DEBIT}
                        disabled={this.getDisabledStatus(value.canceled)}
                    >
                        Debit value
                    </Menu.Item>
                }
                {
                    (showCreditUses || showDebitUses) && <Menu.Divider/>
                }
                {
                    (showCreditUses) &&
                    <Menu.Item
                        key={ValueActionStatus.CREDIT_USES}
                        disabled={this.getDisabledStatus(value.canceled)}
                    >
                        Add uses
                    </Menu.Item>
                }
                {
                    (showDebitUses) &&
                    <Menu.Item
                        key={ValueActionStatus.DEBIT_USES}
                        disabled={this.getDisabledStatus(value.canceled)}
                    >
                        Debit uses
                    </Menu.Item>
                }
                {
                    (showCreditAttaches || showDebitAttaches) && <Menu.Divider/>
                }
                {
                    (showCreditAttaches) &&
                    <Menu.Item
                        key={ValueActionStatus.CREDIT_ATTACHES}
                        disabled={this.getDisabledStatus(value.canceled)}
                    >
                        Add attaches
                    </Menu.Item>
                }
                {
                    (showDebitAttaches) &&
                    <Menu.Item
                        key={ValueActionStatus.DEBIT_ATTACHES}
                        disabled={this.getDisabledStatus(value.canceled)}
                    >
                        Debit attaches
                    </Menu.Item>
                }
                {
                    (showAttach || showDetach) && <Menu.Divider/>
                }
                {
                    (showAttach) &&
                    <Menu.Item
                        key={ValueActionStatus.ATTACH_CONTACT}
                    >
                        Attach Contact
                    </Menu.Item>
                }
                {
                    (showDetach) &&
                    <Menu.Item
                        key={ValueActionStatus.DETACH_CONTACT}
                    >
                        Remove
                    </Menu.Item>
                }
                {
                    (showFullCode || showChangeCode) &&
                    <Menu.Divider/>
                }
                {
                    (showFullCode) &&
                    <Menu.Item
                        key={ValueActionStatus.SHOW_FULLCODE}
                    >
                        Show Full Code
                    </Menu.Item>
                }
                {
                    (showChangeCode) &&
                    <Menu.Item
                        key={ValueActionStatus.CHANGE_CODE}
                    >
                        {!!value.code ? "Change " : "Add "} Code
                    </Menu.Item>
                }
                {
                    <Menu.Item
                        key={ValueActionStatus.MODIFY_DATES}
                    >
                        Modify Dates
                    </Menu.Item>
                }
            </Menu>
        );

        return (
            <Dropdown overlay={actionMenuItems} trigger={["click"]}>
                <Button
                    className={(!this.props.label) ? "lr-smallerAntBtnPadding" : ""}
                >
                    {this.props.label ? this.props.label : null}
                    {
                        this.props.label ?
                            <DownOutlined/> :
                            <EllipsisOutlined/>
                    }
                </Button>
            </Dropdown>
        );
    }

    renderShowFullCodeModal(value: Value): JSX.Element {
        return (
            <ShowFullCodeModal
                open={this.props.valueStateUI.showFullCodeModalOpen}
                fetching={this.props.valueStateServer.fetching}
                value={value}
                onClose={this.toggleShowFullCodeModalHandler}
            />
        );
    }

    renderTransactionModal(value: Value, valueActionStatus: ValueActionStatus): JSX.Element {
        let transactionType: TransactionType;
        let transactionProperty: TransactionProperty;
        switch (valueActionStatus) {
            case ValueActionStatus.DEBIT:
                transactionType = TransactionType.DEBIT;
                transactionProperty = TransactionProperty.BALANCE;
                break;
            case ValueActionStatus.DEBIT_USES:
                transactionType = TransactionType.DEBIT;
                transactionProperty = TransactionProperty.USES;
                break;
            case ValueActionStatus.DEBIT_ATTACHES:
                transactionType = TransactionType.DEBIT;
                transactionProperty = TransactionProperty.ATTACHES;
                break;
            case ValueActionStatus.CREDIT:
                transactionType = TransactionType.CREDIT;
                transactionProperty = TransactionProperty.BALANCE;
                break;
            case ValueActionStatus.CREDIT_USES:
                transactionType = TransactionType.CREDIT;
                transactionProperty = TransactionProperty.USES;
                break;
            case ValueActionStatus.CREDIT_ATTACHES:
                transactionType = TransactionType.CREDIT;
                transactionProperty = TransactionProperty.ATTACHES;
                break;
            default:
                throw new Error(`Unexpected valueActionStatus ${valueActionStatus}.`);
        }

        return (
            <CreateTransactionModal
                modalOpen={this.props.valueStateUI.transactionModalOpen}
                value={value}
                transactionType={transactionType}
                transactionProperty={transactionProperty}
                onClose={this.closeTransactionModal}
            />
        );
    }

    renderConfirmationModal(valueActionStatus: ValueActionStatus): JSX.Element {
        let confirmTitle: string;
        let messageBody: string;
        let acceptActionButtonLabel: string;
        let declineActionButtonLabel: string;
        let handleButtonAction: () => void;

        switch (valueActionStatus) {
            case ValueActionStatus.ACTIVATE:
                confirmTitle = "Activate";
                messageBody = "This will activate the code. This action can't be undone. Are you sure?";
                acceptActionButtonLabel = "Activate Value";
                declineActionButtonLabel = `Keep ${this.getValueStatus()}`;
                handleButtonAction = this.activateHandler;
                break;
            default:
                throw new Error(`Value action status ${valueActionStatus} does not require a confirmation model.`);
        }

        return (
            <Modal
                visible={true}
                title={confirmTitle}
                okText={acceptActionButtonLabel}
                cancelText={declineActionButtonLabel}
                onOk={handleButtonAction}
                onCancel={this.resetPendingValueAction}
            >
                {messageBody}
            </Modal>
        );
    }

    renderAttachContactModal(value: Value): JSX.Element {
        return (
            <AttachContactModalConnected
                value={value}
                onClose={this.resetPendingValueAction}
                onSuccess={this.onContactAttached}
            />
        );
    }

    renderChangeCodeModal(value: Value): JSX.Element {
        return (
            <ChangeCodeModal
                open={this.props.valueStateUI.changeCodeModalOpen}
                fetching={this.props.valueStateServer.fetching}
                value={value}
                onCancel={this.closeChangeCodeModal}
            />
        );
    }

    renderModal(value: Value, valueActionStatus: ValueActionStatus): JSX.Element {
        switch (valueActionStatus) {
            case ValueActionStatus.DEBIT:
            case ValueActionStatus.CREDIT:
            case ValueActionStatus.DEBIT_USES:
            case ValueActionStatus.CREDIT_USES:
            case ValueActionStatus.DEBIT_ATTACHES:
            case ValueActionStatus.CREDIT_ATTACHES:
                return this.renderTransactionModal(value, this.props.valueStateUI.pendingValueAction);
            case ValueActionStatus.ACTIVATE:
                return this.renderConfirmationModal(valueActionStatus);
            case ValueActionStatus.ATTACH_CONTACT:
                return this.renderAttachContactModal(value);
            default:
                return null;
        }
    }

    renderModifyDatesModal(value: Value): JSX.Element {
        return (
            <ModifyDatesModal
                open={this.props.valueStateUI.modifyDatesModalOpen}
                fetching={this.props.valueStateServer.fetching}
                value={value}
                onCancel={this.closeModifyDateModal}
            />
        );
    }

    render(): JSX.Element {
        const value = this.getValue();

        if (!value) {
            return null;
        }

        return (
            <span>
                {
                    this.renderActionButtons()
                }
                {
                    (this.props.valueStateUI.pendingValueAction !== ValueActionStatus.NO_ACTION) &&
                    this.renderModal(value, this.props.valueStateUI.pendingValueAction)
                }
                {
                    (this.props.valueStateUI.showFullCodeModalOpen) &&
                    this.renderShowFullCodeModal(value)
                }
                {
                    (this.props.valueStateUI.changeCodeModalOpen) &&
                    this.renderChangeCodeModal(value)
                }
                {
                    (this.props.valueStateUI.modifyDatesModalOpen) &&
                    this.renderModifyDatesModal(value)
                }
            </span>
        );
    }
}
