import {ListHeaderProps} from "./ListHeader";
import * as React from "react";
import {ListHeader} from "../list/ListHeader";

export interface Props {
    headerProps: ListHeaderProps;
}

export class ListLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div>
                <ListHeader {...this.props.headerProps}/>
                {
                    this.props.children
                }
            </div>
        );
    }
}