import * as ReactRedux from "react-redux";
import {AttachContactModal} from "./AttachContactModal";
import {State} from "../../../../state/State";
import {SearchId, SearchState} from "../../../../state/serverState/SearchesServerState";
import {Contact, Value} from "lightrail-client/dist/model";
import {actionCreator as attachValueToContact} from "../../../../actions/values/server/attachValueToContact";
import {actionCreator as getValue} from "../../../../actions/values/server/getValue";
import {ContactsServerState} from "../../../../state/serverState/ContactsServerState";

export interface ExternalProps {
    value: Value;
    onClose: () => void;
    onSuccess?: (contact: Contact) => void;
    open?: boolean;
}

export interface StateProps {
    searchState: SearchState;
    contactStateServer: ContactsServerState;
}

const mapStateToProps = (state: State, props: ExternalProps): ExternalProps & StateProps => ({
    searchState: state.server.search[SearchId.GLOBAL],
    contactStateServer: state.server.contacts,
    ...props
});

const actionCreators = {
    attachValueToContact,
    getValue
}

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AttachContactModalConnected = connector(AttachContactModal);