import * as React from "react";
import {FormItemProps} from "antd/lib/form";
import {Checkbox, Form} from "antd";
import {CheckboxProps} from "antd/lib/checkbox";
import {FormInstance} from "antd/es/form";

export interface Props {
    fieldId: string;

    formItemProps?: FormItemProps;
    checkboxProps?: CheckboxProps;
    initialValue?: boolean;
    description?: string;
    form: FormInstance;
}

export class ActiveItem extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const formItemDescription = this.props.description || "Value will be active when created";
        return (
            <Form.Item
                {...this.props.formItemProps}
                name={this.props.fieldId}
                valuePropName={"checked"}
                initialValue={this.props.initialValue}
            >
                <Checkbox
                    data-cy="form-item-active"
                    {...this.props.checkboxProps}
                >
                    {formItemDescription}
                </Checkbox>
            </Form.Item>
        );
    }
}