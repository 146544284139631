import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {Account} from "../../../communication/dtos/account/Account";
import {switchAccounts} from "../../../communication/account";
import {SwitchAccountsRequest} from "../../../communication/dtos/account/SwitchAccount";
import {GetAccountResponse} from "../../../communication/dtos/account/GetAccount";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";

export const type = "switchAccounts";

export interface SwitchAccountsActionResponse {
    user: GetAccountResponse;
    message?: string;
    messageCode?: string;
}

export const actionCreator = promisePattern.actionCreator(type,
    (requestParams: SwitchAccountsRequest) => switchAccounts(requestParams)
);

const onFulfilled: promisePattern.PromiseFulfilledHandler<SwitchAccountsActionResponse> = (
    state: State, payload: SwitchAccountsActionResponse
) => {
    const accountMessage = {
        message: payload?.message,
        messageCode: payload?.messageCode
    };

    return sprout.assoc(state,
        ["server", "account", "authMessage"], accountMessage,
        ["server", "account", "fetching"], false,
    );
};

const onPending: promisePattern.PromisePendingHandler = (state: State) => sprout.assoc(state,
    ["server", "account", "fetching"], true
);

const rejectionReducer: RejectionReducer = {
    reducer: (state: State) => {
        const blankAccount: Account = {
            id: "",
            name: ""
        };

        return sprout.assoc(state,
            ["server", "account", "accountInfo"], blankAccount,
            ["server", "account", "fetching"], false
        );
    }
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([rejectionReducer]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);