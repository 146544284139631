import * as React from "react";
import {Col, Divider, notification, Row, Spin} from "antd";
import {GenerateReportRequest} from "../../communication/dtos/reports/GenerateReportRequest";
import {ReportsForm, SubmitValues} from "./ReportsForm";
import {ReportType} from "./ReportsUtils";
import {download} from "../../util/downloadUtils";
import {dateIsInFuture, dateIsInPast} from "../../util/dateUtils";
import {ReduxProps} from "./ReportsPageConnected";
import {PaginatedRequestStatus} from "../../communication/utils/PaginatedRequestStatus";
import moment = require("moment");

export interface Props extends ReduxProps {
}

export class ReportsPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.requestLiveReport = this.requestLiveReport.bind(this);
        this.buildReportRequest = this.buildReportRequest.bind(this);
    }

    componentDidMount(): void {
        this.props.listPrograms({limit: 1000});
    }

    buildReportRequest(values: SubmitValues): GenerateReportRequest {
        const reportRequestLimit = 10000;
        let programIdList: string;

        let isActive: boolean;
        let isInactive: boolean;

        if (values.programIds) {
            for (let i = 0; i < values.programIds.length; i++) {
                if (values.programIds[i] === "Active") {
                    isActive = true;
                    programIdList = this.props.programServerState.allList.filter(p => dateIsInFuture(p.endDate)).map(program => program.id).join().toString();
                    values.programIds[i] = programIdList;
                } else if (values.programIds[i] === "Inactive") {
                    isInactive = true;
                    programIdList = this.props.programServerState.allList.filter(p => dateIsInPast(p.endDate)).map(program => program.id).join().toString();
                    values.programIds[i] = programIdList;
                }
            }
            if (isActive && isInactive) {
                programIdList = null;
            } else {
                programIdList = values.programIds.join().toString();
            }
        }

        let generateReportParams: GenerateReportRequest;

        switch (values.reportType) {
            case ReportType.TRANSACTION:
                generateReportParams = {
                    reportType: ReportType.TRANSACTION,
                    programId: programIdList,
                    transactionType: (values.transactionType[0] !== "All") ? values.transactionType.join().toString() : null,
                    startDate: !!values.utcISOStartDate ? values.utcISOStartDate : null,
                    endDate: !!values.utcISOEndDate ? values.utcISOEndDate : null,
                    formatCurrencies: true
                };
                break;
            case ReportType.VALUE:
                generateReportParams = {
                    reportType: ReportType.VALUE,
                    programId: programIdList,
                    startDate: !!values.utcISOStartDate ? values.utcISOStartDate : null,
                    endDate: !!values.utcISOEndDate ? values.utcISOEndDate : null,
                    formatCurrencies: true,
                    showCode: !!values.showCode
                };
                break;
            case ReportType.CONTACT:
                generateReportParams = {
                    reportType: ReportType.CONTACT,
                    startDate: !!values.utcISOStartDate ? values.utcISOStartDate : null,
                    endDate: !!values.utcISOEndDate ? values.utcISOEndDate : null,
                };
                break;
        }

        generateReportParams.limit = reportRequestLimit;

        return generateReportParams;
    }

    ///////////////////
    //[ SEND REQUESTS ]
    async requestLiveReport(values: SubmitValues): Promise<void> {
        const generateReportParams: GenerateReportRequest = this.buildReportRequest(values);
        const infoMessageKey = "reportInfoMessageKey";

        generateReportParams.statusCallback = (status: PaginatedRequestStatus): void => {
            notification.info({
                key: infoMessageKey,
                message: "Generating Your Report",
                description: <span>Generating part <strong>{status.pageCount}</strong> of your report.  Please keep your browser open.</span>,
                icon: <Spin size={"small"}/>,
                duration: 0
            });
        };

        try {
            const response = (await this.props.generateReport(generateReportParams)).value;

            const csvName = `${values.reportType}-report-${moment().format("YYYY-MM-DD_HH_mm_ss_a")}`;
            notification.close(infoMessageKey);

            if (response !== "") {
                download(`${csvName}.csv`, response.toString());
            } else {
                notification.warning({
                    message: `Report Generated`,
                    description: "There were zero results. Try modifying your request."
                });
            }
        } catch {
            notification.close(infoMessageKey);
        }
    }

    ///////////////////
    //[ RENDER ]
    render(): JSX.Element {
        return (
            <div>
                <Row>
                    <Col sm={24} lg={16}>
                        <h3>Download a report</h3>
                        <p>Large reports may take a few minutes to generate and download. If you&rsquo;re experiencing
                            trouble with a report that may be extremely large, please contact <a
                                href="mailto:hello@lightrail.com" target="_blank"
                                rel="noreferrer">Lightrail</a> support.</p>
                        <Divider/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReportsForm
                            programServerState={this.props.programServerState}
                            loading={this.props.generateReportState.processing}
                            onSubmit={this.requestLiveReport}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
