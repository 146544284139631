import * as React from "react";
import {useEffect, useState} from "react";
import {Alert, Col, Divider, Form, InputNumber, message, Radio, Row, Select} from "antd";
import {generateId} from "../../../communication/utils/urlUtils";
import {BALANCE_RULE_AMOUNT_REGEX, BALANCE_RULE_PERCENT_REGEX, PROGRAM_METADATA_NOTES_KEY} from "../../../util/regexp";
import {FormFieldIds, GenericRequiredRule} from "../../../util/forms/AntFormHelpers";
import {CreateProgramParams, UpdateProgramParams,} from "lightrail-client/dist/params";
import {ActiveItem} from "../../ui/forms/items/ActiveItem";
import {PretaxItem} from "../../ui/forms/items/PretaxItem";
import {DiscountItem} from "../../ui/forms/items/DiscountItem";
import {DateTimePicker} from "../../ui/forms/items/DateTimePicker";
import {AllowSubmitOnEnter} from "../../ui/forms/AllowSubmitOnEnter";
import {getCurrentCurrency, normalizeValueForServer, normalizeValueFromServer} from "../../../util/currencyUtils";
import {MetadataItems} from "../../ui/forms/items/MetadataItems";
import {FixedInitialUsesRemainingItem} from "../../ui/forms/items/FixedInitialUsesRemaining";
import {EmojiInputItem} from "../../ui/forms/EmojiInputItem";
import {ProgramCopy} from "./CreateProgramFormCopy";
import {BalanceRule, Program} from "lightrail-client/dist/model";
import {AdvancedRuleItem} from "../../ui/forms/items/rules/AdvancedRuleItem";
import {
    convertFormInputStringToNumberArray,
    convertNumberArrayToString,
    removeExtraZeroes,
} from "../../../util/formUtils";
import {AdvancedFixedInitialFormItems} from "./formItemGroups/AdvancedFixedInitialFormItems";
import {AdvancedRangeFormItems} from "./formItemGroups/AdvancedRangeFormItems";
import {ProgramAdvancedFormType, ProgramBalanceType, ProgramBalanceTypeString, ProgramValueType} from "./ProgramEnums";
import {cleanNulls} from "../../../util/communicationUtils";
import {FormLayout, useForm} from "antd/es/form/Form";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import {ExternalProps, ReduxProps} from "./CreateProgramFormConnected";
import moment = require("moment");

export interface Props extends ExternalProps, ReduxProps {
}

export const CypressSelectors = {
    BalanceRuleExplanation: "lr-cy-balance-rule-explanation",
    BalanceRuleSyntax: "lr-cy-balance-rule-syntax",
    ProgramName: "lr-cy-program-name",
    RedemptionRuleExplanation: "lr-cy-redemption-rule-explanation",
    RedemptionRuleSyntax: "lr-cy-redemption-rule-syntax",

    ProgramBasicValue: "program-basic-value",
    BalanceType: "balance-type",
    BalanceTypeCurrency: "balance-type-currency",
    BalanceTypePercent: "balance-type-percent",
    EndDate: "end-date",
    ToggleAdvancedBasic: "toggle-advanced-basic"
};

export const CreateProgramForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const [isBasic, setIsBasic] = useState(true);
    const [isPretax, setIsPretax] = useState(true);
    const [isBalanceRule, setIsBalanceRule] = useState(true);
    const [isRangeBalance, setIsRangeBalance] = useState(false);
    const [isPercent, setIsPercent] = useState(false);
    const [isCredit, setIsCredit] = useState(props.program?.fixedInitialBalances != null || props.program?.minInitialBalance != null || props.program?.maxInitialBalance != null);
    const [selectedProgramCurrency, setSelectedProgramCurrency] = useState(
        props.program ? getCurrentCurrency(props.program.currency, props.currencyServerState) : props.currencyServerState.list[0]
    );

    const handleReset = (): void => {
        form.resetFields();
    };

    ///////////////////
    //[ SUBMIT ]
    const submit = async (): Promise<void> => {
        const values = await form.validateFields();
        const programValues: UpdateProgramParams = {};
        const isBasicForm = values[FormFieldIds.isBasicForm];
        const isBalanceCredit = values[FormFieldIds.isCredit];

        programValues.name = values[FormFieldIds.Name];
        programValues.startDate = null;
        programValues.endDate = null;
        programValues.metadata = null;

        programValues.active = values[FormFieldIds.Active];
        programValues.pretax = values[FormFieldIds.Pretax];
        programValues.discount = values[FormFieldIds.Discount];
        programValues.discountSellerLiabilityRule = null;

        programValues.fixedInitialBalances = null;
        programValues.fixedInitialUsesRemaining = null;
        programValues.minInitialBalance = null;
        programValues.maxInitialBalance = null;

        programValues.balanceRule = null;
        programValues.redemptionRule = null;

        if (values[FormFieldIds.StartDate]) {
            programValues.startDate = values[FormFieldIds.StartDate].toISOString();
        }

        if (values[FormFieldIds.EndDate]) {
            programValues.endDate = values[FormFieldIds.EndDate].toISOString();
        }
        if (!!programValues.discount) {
            if (!!values[FormFieldIds.DiscountSellerLiabilityRule]) {
                const rule = values[FormFieldIds.DiscountSellerLiabilityRule];
                const formattedRule = rule.replace(/^((\.\d+)?|)$/, `0${rule}`);

                programValues.discountSellerLiabilityRule = {
                    rule: formattedRule,
                    explanation: values[FormFieldIds.DiscountSellerLiabilityExplanation]
                };
            }
        }

        if (values[FormFieldIds.FixedUsesRemaining]) {
            // Ensure the form value is a string, to prevent failure from `initialValue` being set to a Number
            const fixedUses: number[] = convertFormInputStringToNumberArray(values[FormFieldIds.FixedUsesRemaining].toString());
            const noMoreZeros = removeExtraZeroes(fixedUses);
            if (!!noMoreZeros) {
                // Using `Array.from(new Set(...))` to remove duplicates
                programValues.fixedInitialUsesRemaining = Array.from(new Set(noMoreZeros));
            }
        }

        if (isBasicForm) {
            const programValueType = form.getFieldValue(FormFieldIds.ProgramValueType);
            const isBalanceProgram = (isBalanceCredit && programValueType !== ProgramValueType.PERCENT_SYMBOL) || (isBalanceCredit && props.program?.fixedInitialBalances);

            if (isBalanceProgram) {
                programValues.fixedInitialBalances = [normalizeValueForServer(values[FormFieldIds.Amount], selectedProgramCurrency.decimalPlaces)];
            } else {
                // it's a BALANCE RULE - convert the number into a server value
                const amount = values[FormFieldIds.Amount];

                const amountForRule = (programValueType == ProgramValueType.PERCENT_SYMBOL)
                    ? normalizeValueFromServer(parseFloat(amount), 2) // 2 is hard coded for percents (%)
                    : normalizeValueForServer(amount, selectedProgramCurrency.decimalPlaces);

                const rule = (programValueType == ProgramValueType.PERCENT_SYMBOL)
                    ? `${ProgramBalanceTypeString.PERCENT_AMOUNT} * ${amountForRule}`
                    : `${amountForRule} + ${ProgramBalanceTypeString.CURRENCY_AMOUNT}`;

                const explanationValue = (programValueType == ProgramValueType.PERCENT_SYMBOL)
                    ? `${amount}% off item's subtotal`
                    : `${selectedProgramCurrency.symbol}${parseFloat(amount).toFixed(selectedProgramCurrency.decimalPlaces)} off order`;

                programValues.balanceRule = {
                    rule: rule,
                    explanation: explanationValue
                };
            }

        } else {
            if (values[FormFieldIds.BalanceRuleExplanation] || values[FormFieldIds.BalanceRule]) {
                programValues.balanceRule = {
                    rule: values[FormFieldIds.BalanceRule],
                    explanation: values[FormFieldIds.BalanceRuleExplanation]
                };
            } else {
                if (values[FormFieldIds.MinValue] || values[FormFieldIds.MaxValue]) {
                    programValues.minInitialBalance = (values[FormFieldIds.MinValue]) ? normalizeValueForServer(values[FormFieldIds.MinValue], selectedProgramCurrency.decimalPlaces) : null;
                    programValues.maxInitialBalance = (values[FormFieldIds.MaxValue]) ? normalizeValueForServer(values[FormFieldIds.MaxValue], selectedProgramCurrency.decimalPlaces) : null;
                } else {
                    const fixedBal = convertFormInputStringToNumberArray(values[FormFieldIds.FixedInitialBalances]);
                    const uniqueFixedBalances = Array.from(new Set(fixedBal));
                    programValues.fixedInitialBalances = uniqueFixedBalances.map(f => normalizeValueForServer(f, selectedProgramCurrency.decimalPlaces));
                }
            }
        }

        // redemptionRule requires BOTH Rule and Explanation, set to || so server can catch
        if (values[FormFieldIds.RedemptionRuleExplanation] || values[FormFieldIds.RedemptionRule]) {
            const redemptionRuleExplanation = (!!values[FormFieldIds.RedemptionRuleExplanation]) && values[FormFieldIds.RedemptionRuleExplanation] !== "" ? values[FormFieldIds.RedemptionRuleExplanation] : "";
            programValues.redemptionRule = {
                rule: values[FormFieldIds.RedemptionRule],
                explanation: redemptionRuleExplanation
            };
        }

        const metadataFromForm = MetadataItems.parseMetadataFromSubmitValues(values, PROGRAM_METADATA_NOTES_KEY);
        if (metadataFromForm != null) {
            programValues.metadata = metadataFromForm;
        }

        if (props.program) {
            try {
                const response = await props.updateProgram({
                    programId: props.program.id,
                    params: cleanNulls(programValues)
                });
                message.success(`${programValues.name} Updated`, 3);
                handleReset();

                if (props.onUpdateResponse) {
                    props.onUpdateResponse(response.value);
                }
            } catch (err) {
                message.error(err.message);
            }
        } else {
            const createProgramValues: CreateProgramParams = cleanNulls({
                ...programValues,
                id: generateId(),
                currency: values[FormFieldIds.Currency],
                name: programValues.name
            });
            try {
                const response = await props.createProgram(createProgramValues);

                message.success(`${programValues.name} Created`, 3);
                handleReset();

                if (props.onCreateResponse) {
                    props.onCreateResponse(response.value);
                }
            } catch (err) {
                message.error(err.message);
            }
        }
    };

    const onFinishFailed = (errors: ValidateErrorEntity): void => {
        form.scrollToField(errors.errorFields[0].name);
    };

    const getValueFromBalancesAndDisplayForm = (program: Program): void => {
        if (program.minInitialBalance || program.maxInitialBalance || (!!program.fixedInitialBalances && program.fixedInitialBalances.length > 1)) {
            onToggleBasicAdvanced();
        } else if (!!program.fixedInitialBalances && program.fixedInitialBalances.length === 1) {
            form.setFieldsValue({
                amount: normalizeValueFromServer(parseInt(convertNumberArrayToString(program.fixedInitialBalances), 10), selectedProgramCurrency.decimalPlaces),
                programValueType: selectedProgramCurrency.symbol,
                programBalanceType: ProgramBalanceType.CREDIT
            });
        }
    };

    const getValueFromBalanceRuleAndDisplayForm = (balanceRule: BalanceRule, programValueType?: ProgramValueType): void => {
        const currencyAmount = normalizeValueFromServer(parseInt(balanceRule.rule.split(" ")[0], 10), selectedProgramCurrency.decimalPlaces);
        const percent = (parseFloat(balanceRule.rule.split(" ")[2]) * 100);

        switch (programValueType) {
            // IF AMOUNT (currencySymbol type) LOOK FOR VALUE AT THE BEGINNING UNTIL THE FIRST SPACE
            // IF PERCENT (percentSymbol type) LOOK FOR VALUE AT THE END, AFTER THE SECOND SPACE
            case ProgramValueType.CURRENCY_SYMBOL:
                form.setFieldsValue({amount: currencyAmount});
                break;
            case ProgramValueType.PERCENT_SYMBOL:
                form.setFieldsValue(
                    {
                        amount: percent,
                        programValueType: ProgramValueType.PERCENT_SYMBOL
                    });
                break;
        }
    };

    ///////////////////
    //[ ACTIONS ]

    const onBalanceTypeChange = (): void => {
        setIsBalanceRule(!isBalanceRule);
    };

    const onAdvancedValueTypeChange = (): void => {
        setIsRangeBalance(!isRangeBalance);
    };

    const setPretax = (value: string): void => {
        setIsPretax(value !== ProgramBalanceType.CREDIT);
    };

    const toggleCreditChange = (value: string): void => {
        setPretax(value);
        form.setFieldsValue({[FormFieldIds.isCredit]: !isCredit});
        setIsCredit(!isCredit);
    };

    const togglePercentChange = (): void => {
        setIsPercent(!isPercent);
    };

    const onCurrencySelectChange = (value: string): void => {
        const currency = getCurrentCurrency(value, props.currencyServerState);
        setSelectedProgramCurrency(currency);
    };

    const setupUpdateForm = (program: Program): void => {
        if (!!program.balanceRule) {
            if (program.balanceRule.rule.match(BALANCE_RULE_AMOUNT_REGEX)) {
                setIsBasic(true);
                setIsPercent(false);
                getValueFromBalanceRuleAndDisplayForm(program.balanceRule, ProgramValueType.CURRENCY_SYMBOL);

            } else if (program.balanceRule.rule.match(BALANCE_RULE_PERCENT_REGEX)) {
                setIsBasic(true);
                setIsPercent(true);
                getValueFromBalanceRuleAndDisplayForm(program.balanceRule, ProgramValueType.PERCENT_SYMBOL);

            } else {
                setIsBasic(false);
            }
        } else {
            setIsPercent(false);
            setIsBalanceRule(false);

            if (!!program.fixedInitialBalances) {
                setIsCredit(true);
            }

            if (!!program.minInitialBalance || !!program.maxInitialBalance) {
                setIsRangeBalance(true);
            }
            getValueFromBalancesAndDisplayForm(program);
        }
    };

    const resetAdvancedDefaults = (): void => {
        setIsBalanceRule(!!props.program?.balanceRule);
        setIsPercent(!!props.program?.balanceRule);
        setIsCredit(!!props.program?.fixedInitialBalances);
    };

    const resetBasicDefaults = (): void => {
        setIsBalanceRule(!!props.program?.fixedInitialBalances);
        setIsPercent(!!props.program?.balanceRule?.rule.match(BALANCE_RULE_PERCENT_REGEX));
        setIsCredit(!!props.program?.fixedInitialBalances);
    };

    const onToggleBasicAdvanced = (): void => {
        isBasic ? resetAdvancedDefaults() : resetBasicDefaults();
        setIsBasic(!isBasic);
    };

    useEffect((): void => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }

        if (props.program) {
            setupUpdateForm(props.program);
        }
    }, []);

    useEffect(() => {
        form.setFieldsValue({[FormFieldIds.isBasicForm]: isBasic});
    }, [isBasic])

    const renderBasicFormFields = (): JSX.Element => {
        const isPercent = form.getFieldValue(FormFieldIds.ProgramValueType) === ProgramValueType.PERCENT_SYMBOL;
        const currency = selectedProgramCurrency;
        const initialProgramValueType = selectedProgramCurrency.symbol;
        const initialProgramBalanceType = (!!props.program && isCredit) ? ProgramBalanceType.CREDIT : ProgramBalanceType.OFF;

        return (
            <fieldset className="lr-fieldset">
                <Form.Item
                    label=" "
                    style={{
                        display: "inline-block",
                        marginBottom: 0,
                        width: "100%",
                        maxWidth: 110
                    }}
                    name={FormFieldIds.Amount}
                    rules={[
                        isBasic ?
                            GenericRequiredRule :
                            {required: false}
                    ]}
                >
                    <InputNumber
                        style={{width: 100, maxWidth: "100%"}}
                        size="large"
                        placeholder="10"
                        precision={isPercent ? 0 : currency.decimalPlaces}
                        min={0}
                        max={isPercent ? 100 : undefined}
                        step={isPercent ? 1 : 10}
                        disabled={!isBasic}
                        data-cy={CypressSelectors.ProgramBasicValue}
                    />
                </Form.Item>
                <Form.Item
                    label=" "
                    style={{
                        display: "inline-block",
                        margin: "0",
                        width: "100%",
                        maxWidth: 100
                    }}
                    name={FormFieldIds.ProgramValueType}
                    initialValue={initialProgramValueType}
                >
                    <Select size="large"
                            onChange={togglePercentChange}
                            disabled={!isBasic}
                            data-cy={CypressSelectors.BalanceType}
                    >
                        <Select.Option
                            data-cy-select-option={CypressSelectors.BalanceTypeCurrency}
                            key={ProgramValueType.CURRENCY_SYMBOL}
                            value={selectedProgramCurrency.symbol}
                        >
                            {(!!props.program) ? currency.symbol : selectedProgramCurrency.symbol}
                        </Select.Option>
                        <Select.Option
                            data-cy-select-option={CypressSelectors.BalanceTypePercent}
                            key={ProgramValueType.PERCENT_SYMBOL}
                            value={ProgramValueType.PERCENT_SYMBOL}
                            disabled={isCredit}
                        >
                            %
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label=" "
                    style={{
                        display: "inline-block",
                        margin: "0 10px",
                        width: "100%",
                        maxWidth: 100
                    }}
                    name={FormFieldIds.ProgramBalanceType}
                    initialValue={initialProgramBalanceType}
                >
                    <Select size="large"
                            onChange={toggleCreditChange}
                            disabled={!isBasic}>
                        <Select.Option
                            key={`${ProgramBalanceType.OFF}`}
                            value={`${ProgramBalanceType.OFF}`}
                        >
                            Off
                        </Select.Option>
                        <Select.Option
                            key={`${ProgramBalanceType.CREDIT}`}
                            value={`${ProgramBalanceType.CREDIT}`}
                            disabled={isPercent}
                        >
                            Credit
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Row>
                    <Col style={{display: (isCredit) ? "block" : "none"}}>
                        <p style={{
                            color: "rgba(0, 0, 0, 0.45)"
                        }}>{ProgramCopy.CREDIT_APPLIES_TO_PER_CONTACT_BALANCE}</p>
                    </Col>
                </Row>
            </fieldset>
        );
    };

    const renderBalanceRuleFormField = (): JSX.Element => {
        const isAdvanced = !isBasic;
        const program = props.program;

        if (isBalanceRule && isAdvanced) {
            return (
                <AdvancedRuleItem
                    ruleItemId={FormFieldIds.BalanceRule}
                    rule={(!!program) ? program.balanceRule : null}
                    explanationItemId={FormFieldIds.BalanceRuleExplanation}
                    form={form}
                    explanationProps={
                        {
                            placeholder: ProgramCopy.BALANCE_RULE_EXPLANATION_PLACEHOLDER,
                            size: "large",
                            id: CypressSelectors.BalanceRuleExplanation
                        }
                    }
                    ruleProps={
                        {
                            placeholder: ProgramCopy.BALANCE_RULE_SYNTAX_PLACEHOLDER,
                            size: "large",
                            id: CypressSelectors.BalanceRuleSyntax
                        }
                    }
                    disableEditToggle
                />
            );
        } else {
            return null;
        }
    };

    const program = (!!props.program) ? props.program : null;
    const defaultAdvancedDisplay: ProgramAdvancedFormType = !!program ? ((!!program.minInitialBalance || !!program.maxInitialBalance) ? ProgramAdvancedFormType.RANGE : ProgramAdvancedFormType.FIXED) : ProgramAdvancedFormType.FIXED;

    return (
        <Form
            form={form}
            layout={"vertical" as FormLayout}
            onFinish={submit}
            onFinishFailed={onFinishFailed}
            hideRequiredMark
        >
            <Row>
                <Col>
                    <h2>Set up the basics</h2>
                </Col>
            </Row>
            <Row>
                <EmojiInputItem
                    formItemProps={{label: "Name your Program"}}
                    fieldId={FormFieldIds.Name}
                    form={form}
                    initialValue={(!!program) ? program.name : ""}
                    rules={[
                        {required: true, message: "Name Required"}
                    ]}
                    inputProps={{
                        size: "large",
                        autoFocus: true,
                        id: CypressSelectors.ProgramName
                    }}
                />
            </Row>
            <Row>
                <Form.Item
                    label="Select Program Currency"
                    name={FormFieldIds.Currency}
                    rules={[
                        GenericRequiredRule
                    ]}
                    initialValue={(!!program) ? program.currency : selectedProgramCurrency.code}
                >
                    <Select size="large" onChange={onCurrencySelectChange} disabled={!!program}>
                        {
                            props.currencyServerState.list.map(p => (
                                <Select.Option key={p.code} value={p.code}>
                                    {p.name} ({p.code})
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Row>
            <Row>
                <Col span={8}>
                    <DateTimePicker
                        fieldId={FormFieldIds.StartDate}
                        form={form}
                        label="Values will be active from:"
                        initialValue={(!!program) ? program.startDate : moment(new Date()).startOf("day").toISOString()}
                    />
                </Col>
                <Col span={8}>
                    <DateTimePicker
                        data-cy={CypressSelectors.EndDate}
                        fieldId={FormFieldIds.EndDate}
                        form={form}
                        label="Values will expire on:"
                        initialValue={(!!program) ? program.endDate : moment(new Date()).add(6, "month").endOf("day").toISOString()}
                    />
                </Col>
            </Row>
            <Row>
                <MetadataItems
                    metadata={(!!program) ? program.metadata : null}
                    form={form}
                    metadataNotesKey={PROGRAM_METADATA_NOTES_KEY}
                />
            </Row>

            <Divider/>

            <Row>
                <Col span={18}>
                    <h2 id="cy-values-header" style={{marginTop: 8}}>Define the value</h2>
                </Col>
                <Col span={6}>
                    <a
                        data-cy={CypressSelectors.ToggleAdvancedBasic}
                        style={{marginTop: 12, display: "block", textAlign: "right"}}
                        onClick={onToggleBasicAdvanced}>{(isBasic) ? "Advanced" : "Basic"}</a>
                </Col>
            </Row>
            {
                (!!program) &&
                <Row>
                    <Col>
                        <Alert
                            message="Updates applied here will only apply to new values"
                            type="info"
                            showIcon
                            closable
                        />
                    </Col>
                </Row>
            }
            <Row style={{paddingBottom: "24px", display: (isBasic) ? "block" : "none"}}>
                <Col span={16}>
                    <Row style={{padding: "0 8px"}}>
                        <Col>
                            {
                                renderBasicFormFields()
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{paddingBottom: "24px", display: (isBasic) ? "none" : "block"}}>
                <Col>
                    <Radio.Group name="balanceSelect"
                                 size={"large"}
                                 buttonStyle="solid"
                                 defaultValue="balance"
                                 onChange={onBalanceTypeChange}
                                 disabled={isBasic}
                                 value={isBalanceRule}
                    >
                        <Radio.Button value={false}>Balance</Radio.Button>
                        <Radio.Button value={true}>Balance Rule</Radio.Button>
                    </Radio.Group>
                </Col>
                {
                    !isBalanceRule && !isBasic &&
                    <Col>
                        <p style={{marginTop: 16, color: "rgba(0, 0, 0, 0.54)"}}>{ProgramCopy.BALANCE_INTRO}</p>
                        <Row style={{padding: "0 8px"}}>
                            <Col span={16}>
                                <Radio.Group
                                    name="valueTypeSelect"
                                    defaultValue={defaultAdvancedDisplay}
                                    onChange={onAdvancedValueTypeChange}
                                    style={{display: "block"}}
                                    disabled={isBasic}
                                >
                                    <fieldset className="lr-fieldset">
                                        <Radio value={ProgramAdvancedFormType.FIXED}
                                               disabled={isBalanceRule}
                                               name="fixedAmount"
                                        >
                                            Fixed Amount(s)
                                            {
                                                !isRangeBalance && !isBasic &&
                                                <AdvancedFixedInitialFormItems
                                                    program={program}
                                                    currentCurrency={selectedProgramCurrency}
                                                    form={form}
                                                />
                                            }
                                        </Radio>
                                        <br/>
                                        <Radio
                                            value={ProgramAdvancedFormType.RANGE}
                                            disabled={isBalanceRule}
                                            style={{marginTop: 0, marginBottom: 0}}
                                            name="rangeAmount"
                                        >
                                            Balance Range
                                            {
                                                isRangeBalance && !isBasic &&
                                                <AdvancedRangeFormItems
                                                    program={program}
                                                    currentCurrency={selectedProgramCurrency}
                                                    form={form}
                                                />
                                            }
                                        </Radio>
                                    </fieldset>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                }
                {
                    isBalanceRule && !isBasic &&
                    <Col>
                        <p style={{
                            marginTop: 16,
                            color: "rgba(0, 0, 0, 0.54)"
                        }}>{ProgramCopy.BALANCE_RULE_INTRO}&nbsp;
                            <a href={ProgramCopy.REDEMPTION_RULES_DOCS_LINK_URL}
                               target="_blank"
                               rel="noreferrer"
                            >{ProgramCopy.DOCS_LINK_LABEL}</a>
                        </p>
                        <Row style={{padding: "0 8px 24px"}}>
                            <Col span={16}>
                                {
                                    renderBalanceRuleFormField()
                                }
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <ActiveItem
                        formItemProps={{className: "marginBottom8"}}
                        fieldId={FormFieldIds.Active}
                        form={form}
                        initialValue={(!!program) ? program.active : true}
                    />
                    <DiscountItem
                        fieldId={FormFieldIds.Discount}
                        form={form}
                        discountCheckedInitialValue={(!!program) ? program.discount : true}
                        discountSellerLiabilityRule={(!!program && !!program.discountSellerLiabilityRule) ? program.discountSellerLiabilityRule : null}
                    />
                    <PretaxItem
                        className={"marginBottom8"}
                        fieldId={FormFieldIds.Pretax}
                        form={form}
                        initialValue={(!!program) ? program.pretax : isPretax}
                    />
                    <FixedInitialUsesRemainingItem
                        fieldId={FormFieldIds.FixedUsesRemaining}
                        form={form}
                        isCheckedByDefault={!!program ? !!program.fixedInitialUsesRemaining : !isCredit}
                        fixedUsesRemaining={!!program && !!program.fixedInitialUsesRemaining ? program.fixedInitialUsesRemaining : 1}
                    />
                </Col>
            </Row>

            <Divider/>

            <Row style={{padding: "0 8px 54px"}}>
                <h2 style={{marginTop: 8}}>Redemption Rules</h2>
                <p className="lr-form-paragraph">{ProgramCopy.REDEMPTION_RULE_INTRO}&nbsp;
                    <a href={ProgramCopy.REDEMPTION_RULES_DOCS_LINK_URL}
                       target="_blank"
                       rel="noreferrer"
                    >{ProgramCopy.DOCS_LINK_LABEL}</a></p>
                <Col span={16}>
                    <AdvancedRuleItem
                        ruleItemId={FormFieldIds.RedemptionRule}
                        rule={(!!program) ? program.redemptionRule : null}
                        explanationItemId={FormFieldIds.RedemptionRuleExplanation}
                        form={form}
                        explanationProps={
                            {
                                placeholder: ProgramCopy.REDEMPTION_RULE_EXPLANATION_PLACEHOLDER,
                                size: "large",
                                id: CypressSelectors.RedemptionRuleExplanation
                            }
                        }
                        ruleProps={
                            {
                                placeholder: ProgramCopy.REDEMPTION_RULE_SYNTAX_PLACEHOLDER,
                                size: "large",
                                id: CypressSelectors.RedemptionRuleSyntax
                            }
                        }
                        disableEditToggle
                    />
                </Col>
            </Row>
            <Form.Item
                style={{display: "none"}}
                name={FormFieldIds.isBasicForm}
                initialValue={isBasic}
            >
            </Form.Item>
            <Form.Item
                style={{display: "none"}}
                name={FormFieldIds.isCredit}
                initialValue={isCredit}
            >
            </Form.Item>

            <AllowSubmitOnEnter/>

        </Form>
    );
};