import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {createValue} from "lightrail-client/dist/values";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingProcessing} from "../../reducerHelpers";
import {CreateValueResponse} from "lightrail-client/dist/params";

export const type = "createValue";
export const actionCreator = promisePattern.actionCreator(type, createValue);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateValueResponse> =
    (state, payload) => {
        const list = (payloadHasBody) ? [payload?.body, ...state.server.values.list] : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "processing"], false,
            ["server", "values", "status"], payload?.status
        );
    };

const onRejected = rejectionHandler([
    {
        overrideGlobal: true,
        reducer: (state, error) => {
            return sprout.assoc(state,
                ["server", "values", "processing"], false,
                ["server", "values", "error"], error.message,
                ["server", "values", "status"], error.status
            );
        }
    }
]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);