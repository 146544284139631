import {ActionCreator, Reducer} from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {ContactActionsState} from "../../../state/uistate/ContactUiState";

export interface SetContactActionParams {
    contactId: string;
    actionState?: ContactActionsState;
    deleteNode?: boolean;
}

export const type = "setContactActionState";

export const actionCreator: ActionCreator<SetContactActionParams, SetContactActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<SetContactActionParams> = (state, action) => {
    if (action.payload?.deleteNode) {
        const actions = {...state.ui.contacts.actions};
        delete actions[action.payload?.contactId];

        return sprout.assoc(state, ["ui", "contacts", "actions"], actions);
    } else {
        return sprout.assoc(state, ["ui", "contacts", "actions", action.payload?.contactId], action.payload?.actionState);
    }
};

export const reducerMap = promisePattern.reducerMap(type, reducer);