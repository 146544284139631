import * as React from "react";
import {Button, Col, message, notification, Row} from "antd";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {UiActionPaths} from "../../../../state/uistate/UiActionPaths";
import {Paths} from "../../../../util/routes/routes";
import {Currency} from "lightrail-client/dist/model/Currency";
import {CAD_Default, USD_Default} from "../../../account/currency/create/DefaultCurrencies";
import {ReduxProps} from "./CurrencyWatcherConnected";

export interface Props extends WithRouterProps<{}>, ReduxProps {
}

const NOTIFICATION_KEY = "NoCurrencyNotification";

export class CurrencyWatcher extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onUSD = this.onUSD.bind(this);
        this.onCAD = this.onCAD.bind(this);
        this.onOther = this.onOther.bind(this);
        this.onViewCurrencies = this.onViewCurrencies.bind(this);
        this.renderDescription = this.renderDescription.bind(this);
    }

    componentDidMount(): void {
        this.checkCurrencies();
    }

    componentDidUpdate(): void {
        this.checkCurrencies();
    }

    async createCurrency(currency: Currency): Promise<void> {
        await this.props.createCurrency(currency)
        message.success(
            <span>
                {`${currency.code} Created `}
                <a onClick={this.onViewCurrencies}>View</a>
            </span>
        );
        this.closeNotification();
    }

    componentWillUnmount(): void {
        this.closeNotification();
    }

    ///////////////////
    //[ ACTIONS ]
    onViewCurrencies(): void {
        this.props.history.push(Paths.ACCOUNT_CURRENCY);
    }

    onUSD(): void {
        this.createCurrency(USD_Default);
    }

    onCAD(): void {
        this.createCurrency(CAD_Default);
    }

    onOther(): void {
        this.props.uiAction({uiStatePath: UiActionPaths.CURRENCY_MODAL_OPEN, value: true});
        this.props.history.push(Paths.ACCOUNT_CURRENCY);
    }

    ///////////////////
    //[ MANAGE NOTIFICATIONS ]
    checkCurrencies(): void {
        if (!this.props.currencyState.list.length && !!this.props.currencyState.fetchCount && this.props.location.pathname != Paths.ACCOUNT_CURRENCY) {
            this.triggerNotification();
        } else if (!!this.props.currencyState.list.length || this.props.location.pathname == Paths.ACCOUNT_CURRENCY) {
            this.closeNotification();
        }
    }

    triggerNotification(): void {
        notification.open({
            key: NOTIFICATION_KEY,
            duration: 0,
            message: "Welcome to Lightrail ⚡️",
            description: this.renderDescription()
        });
    }

    closeNotification(): void {
        notification.close(NOTIFICATION_KEY);
    }

    ///////////////////
    //[ RENDER ]
    renderDescription(): JSX.Element {
        return (
            <div>
                <p>Which currency will you be using?</p>
                <Row gutter={8}>
                    <Col>
                        <Button
                            disabled={this.props.currencyState.processing}
                            onClick={this.onUSD}
                        >
                            USD
                        </Button>
                        </Col>
                    <Col>
                        <Button
                            disabled={this.props.currencyState.processing}
                            onClick={this.onCAD}
                        >
                            CAD
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={this.props.currencyState.processing}
                            onClick={this.onOther}
                        >
                            Other...
                        </Button>
                    </Col>
                </Row>
                <p className="lr-caption">You can change this anytime in your account settings.</p>
            </div>
        );
    }

    render(): JSX.Element {
        return null;
    }
}