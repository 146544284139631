import * as React from "react";
import {Button, Tooltip} from "antd";
import { EditOutlined } from "@ant-design/icons";

export interface Props {
    id?: string | number;
    onEdit: (id?: string | number) => void;
}

export class EditButton extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onEdit = this.onEdit.bind(this);
    }

    onEdit(): void {
        if (!!this.props.id) {
            this.props.onEdit(this.props.id);
        } else {
            this.props.onEdit();
        }
    }

    render(): JSX.Element {
        return (
            <Tooltip
                title="Edit"
            >
                <Button size="large" onClick={this.onEdit}>
                    <EditOutlined/>
                </Button>
            </Tooltip>
        );
    }
}