import {
    ContactReportRequestParams,
    GenerateReportRequest,
    GenerateReportResponse,
    TransactionReportRequestParams,
    ValueReportRequestParams
} from "./dtos/reports/GenerateReportRequest";
import {fetchPaginatedCsv} from "./utils/restAccess";
import {QueryParamObject} from "./utils/QueryParamObject";
import {cleanNulls} from "../util/communicationUtils";
import {ReportType} from "../components/reports/ReportsUtils";

export const generateReport = (params: GenerateReportRequest): Promise<GenerateReportResponse> => {
    switch (params.reportType) {
        case ReportType.CONTACT:
            return generateContactReport(cleanNulls(params));
        case ReportType.TRANSACTION:
            return generateTransactionReport(cleanNulls(params));
        case ReportType.VALUE:
            return generateValueReport(cleanNulls(params));
        default:
            throw new Error("This can't happen!");
    }
};

const generateContactReport = (params: ContactReportRequestParams): Promise<GenerateReportResponse> =>
    fetchPaginatedCsv(`/v2/contacts`, constructContactReportQueryString(cleanNulls(params)), null, params.statusCallback);

const generateTransactionReport = (params: TransactionReportRequestParams): Promise<GenerateReportResponse> =>
    fetchPaginatedCsv(`/v2/reports/transactions`, constructTransactionReportQueryString(cleanNulls(params)), null, params.statusCallback);

const generateValueReport = (params: ValueReportRequestParams): Promise<GenerateReportResponse> =>
    fetchPaginatedCsv(`/v2/reports/values`, constructValueReportQueryString(cleanNulls(params)), null, params.statusCallback);

export function constructContactReportQueryString(params: ContactReportRequestParams): QueryParamObject {
    const ContactParamsObject: QueryParamObject = {};
    if (!!params.startDate) {
        ContactParamsObject["createdDate.gte"] = params.startDate;
    }
    if (!!params.endDate) {
        ContactParamsObject["createdDate.lte"] = params.endDate;
    }
    if (!!params.limit) {
        ContactParamsObject["limit"] = params.limit;
    }
    return ContactParamsObject;
}

export function constructTransactionReportQueryString(params: TransactionReportRequestParams): QueryParamObject {
    const TransactionParamsObject: QueryParamObject = {};
    if (!!params.transactionType) {
        TransactionParamsObject["transactionType.in"] = params.transactionType;
    }
    if (!!params.programId) {
        TransactionParamsObject["programId.in"] = params.programId;
    }
    if (!!params.startDate) {
        TransactionParamsObject["createdDate.gte"] = params.startDate;
    }
    if (!!params.endDate) {
        TransactionParamsObject["createdDate.lte"] = params.endDate;
    }
    if (!!params.formatCurrencies) {
        TransactionParamsObject["formatCurrencies"] = params.formatCurrencies.toString();
    }
    if (!!params.limit) {
        TransactionParamsObject["limit"] = params.limit;
    }

    return TransactionParamsObject;
}

export function constructValueReportQueryString(params: ValueReportRequestParams): QueryParamObject {
    const ValueParamsObject: QueryParamObject = {};
    if (!!params.valueId) {
        ValueParamsObject["valueId.eq"] = params.valueId;
    }
    if (!!params.programId) {
        ValueParamsObject["programId.in"] = params.programId;
    }
    if (!!params.startDate) {
        ValueParamsObject["createdDate.gte"] = params.startDate;
    }
    if (!!params.endDate) {
        ValueParamsObject["createdDate.lte"] = params.endDate;
    }
    if (!!params.formatCurrencies) {
        ValueParamsObject["formatCurrencies"] = params.formatCurrencies.toString();
    }
    if (!!params.showCode) {
        ValueParamsObject["showCode"] = params.showCode.toString();
    }
    if (!!params.limit) {
        ValueParamsObject["limit"] = params.limit;
    }

    return ValueParamsObject;
}
