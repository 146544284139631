import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {listPrograms} from "lightrail-client/dist/programs";
import {ListProgramsResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getAndAppendPrograms";
export const actionCreator = promisePattern.actionCreator(type, listPrograms);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListProgramsResponse> =
    (state, payload) => {
        const allList = payloadHasBody(payload) ? [...state.server.programs.allList, ...payload?.body] : state.server.programs.allList;
        return sprout.assoc(state,
            ["server", "programs", "allList"], allList,
            ["server", "programs", "links"], payload?.links,
            ["server", "programs", "pagination"], {maxLimit: payload?.maxLimit, limit: payload?.limit},
            ["server", "programs", "fetching"], false,
            ["server", "programs", "error"], null
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "programs"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);