import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionCreatorArgs {
    path: string;
}

export const type = "addLocationToHistory";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionCreatorArgs> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionCreatorArgs> = (state, action) => {
    return sprout.assoc(state, ["ui", "history"], [...state.ui.history, action.payload?.path]);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);