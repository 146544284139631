import * as React from "react";
import * as ReactRedux from "react-redux";
import {ToggleTesting} from "./ToggleTesting";
import {State} from "../../state/State";
import {withRouter} from "react-router-dom";
import {actionCreator as toggleTestingMode} from "../../actions/account/server/toggleTestingMode";
import {actionCreator as getUser} from "../../actions/auth/server/getUser";
import {actionCreator as getAccountDetails} from "../../actions/account/server/getAccountDetails";
import {actionCreator as listAccounts} from "../../actions/account/server/listAccounts";

export interface ExternalProps {
    label?: string;
    className?: string;
    style?: React.CSSProperties;
    toggleStyle?: React.CSSProperties;
    onToggleSuccess?: () => void;
}

interface StateProps {
    testing: boolean;
    accountId: string;
    loading: boolean;
}

const mapStateToProps = (state: State, externalProps: ExternalProps): StateProps & ExternalProps => ({
    ...externalProps,
    testing: state.server.user.testing,
    loading: state.server.user.fetching,
    accountId: state.server.account.accountInfo?.id,
});

const actionCreators = {
    toggleTestingMode,
    getUser,
    getAccountDetails,
    listAccounts
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ToggleTestingConnected = connector(withRouter(ToggleTesting));