import * as React from "react";
import {Modal} from "antd";
import {EditWebhookFormConnected} from "./EditWebhookFormConnected";
import {ExternalProps, ReduxProps} from "./EditWebhookModalConnected";

export interface Props extends ExternalProps, ReduxProps{
}

export class EditWebhookModal extends React.PureComponent<Props, {}> {
    submitForm: () => void;

    constructor(props: Props) {
        super(props);

        this.setSubmit = this.setSubmit.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    setSubmit(submit: () => void): void {
        this.submitForm = submit;
    }

    onOk(): void {
        if (!!this.submitForm) {
            this.submitForm();
        }
    }

    onCancel(): void {
        this.props.onCancel();
    }

    render(): JSX.Element {
        if (!this.props.webhook) {
            return null;
        }

        return (
            <Modal
                title={"Edit Webhook"}
                maskClosable={false}
                visible={true}
                okText="Save"
                onOk={this.onOk}
                onCancel={this.onCancel}
                confirmLoading={this.props.processingWebhook}
            >
                <EditWebhookFormConnected
                    webhook={this.props.webhook}
                    onEditResponse={this.onCancel}
                    getSubmit={this.setSubmit}
                />
            </Modal>
        );
    }
}