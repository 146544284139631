import {AccountProfilePageConnected} from "./profile/AccountProfilePageConnected";
import {AccountSecurityLayout} from "./security/AccountSecurityLayout";
import {AccountUsersPageConnected} from "./team/AccountUsersPageConnected";
import {AccountBillingPageConnected} from "./billing/AccountBillingPageConnected";
import {CurrencyPageConnected} from "./currency/CurrencyPageConnected";
import {WebhookPageConnected} from "./webhook/WebhookPageConnected";
import {WebhookDetailPageConnected} from "./webhook/details/WebhookDetailPageConnected";
import {ChangeEmailCompleteConnected} from "./profile/ChangeEmail/ChangeEmailCompleteConnected";

export {
    AccountBillingPageConnected as AccountBillingPage,
    AccountProfilePageConnected as AccountProfilePage,
    AccountSecurityLayout as AccountSecurityPage,
    AccountUsersPageConnected as AccountTeamPage,
    CurrencyPageConnected as CurrencyPage,
    WebhookPageConnected as WebhookPage,
    WebhookDetailPageConnected as WebhookDetailPage,
    ChangeEmailCompleteConnected as ChangeEmailComplete
};