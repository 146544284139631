import * as promisePattern from "../../../promisePattern";
import * as MFA from "../../../../communication/MFA";
import {GetBackupCodesResponse} from "../../../../communication/dtos/auth/MFA/GetBackupCodes";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../../rejectionHandler";

export const type = "getMFABackupCodes";

export const actionCreator = promisePattern.actionCreator(type, MFA.getBackupCodes);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetBackupCodesResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "MFA", "backupCodes"], payload,
            ["server", "MFA", "pending"], false
        );
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return sprout.assoc(state,
            ["server", "MFA", "pending"], true
        );
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);