import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ContactCreateValueModal} from "./ContactCreateValueModal";
import {Contact} from "lightrail-client/dist/model";

export interface ExternalProps {
    open: boolean;
    contact: Contact;

    onClose: () => void;
}

interface StateProps {
    processingValue: boolean;
}

const mapStateToProps = (state: State, props: ExternalProps): StateProps & ExternalProps => ({
    processingValue: state.server.values.processing,
    ...props
});

const actionCreators = {};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, ExternalProps>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ContactCreateValueModalConnected = connector(ContactCreateValueModal);