import * as React from "react";
import {Button, Popconfirm, Tooltip} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { DeleteOutlined } from "@ant-design/icons";

export interface Props {
    confirm?: boolean;
    id?: string | number;
    onDelete: (id?: string | number) => void;
    defaultSize?: boolean;
}

export class DeleteButton extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(): void {
        if (!!this.props.id) {
            this.props.onDelete(this.props.id);
        } else {
            this.props.onDelete();
        }
    }

    render(): JSX.Element {
        if (this.props.confirm === false) {
            return (
                <Button size="large" onClick={this.onDelete}>
                    <DeleteOutlined/>
                </Button>
            );
        }

        return (
            <Popconfirm title="Are you sure?" placement="leftBottom" okText="Delete" cancelText="No"
                        onConfirm={this.onDelete}>
                <Tooltip
                    title="Delete"
                >
                    <Button size={((!!this.props.defaultSize) ? "default" : "large") as SizeType}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </Popconfirm>
        );
    }
}