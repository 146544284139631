import * as React from "react";
import {Button, message, Row} from "antd";
import {download} from "../../../util/downloadUtils";
import * as CopyToClipboard from "copy-to-clipboard";
import {formatDate} from "../../../util/dateUtils";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";

export interface Props {
    text: string;
    copy?: boolean;
    copyTitle?: string;
    download?: boolean;
    downloadName?: string;
    visible?: boolean;
}

export class JsonViewer extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.copyJsonToClipboard = this.copyJsonToClipboard.bind(this);
        this.downloadJsonFile = this.downloadJsonFile.bind(this);
    }

    copyJsonToClipboard(): void {
        CopyToClipboard(this.props.text);
        message.success(`${(!!this.props.copyTitle) ? this.props.copyTitle : "Json"} Copied to Clipboard`);
    }

    downloadJsonFile(): void {
        download(`${(!!this.props.downloadName) ? this.props.downloadName : formatDate(new Date()) + "Json_File"}.json`, this.props.text);
    }

    render(): JSX.Element {
        return (
            <div className={`json ${(this.props.visible === false) ? "closed" : ""}`}>
                        <pre className="json">
                            {
                                this.props.text
                            }
                        </pre>
                {
                    (this.props.copy || this.props.download) &&
                    <Row className="footer">
                        {
                            (this.props.copy) &&
                            <Button
                                size="small"
                                type="primary"
                                onClick={this.copyJsonToClipboard}
                            >
                                <CopyOutlined/>Copy JSON
                            </Button>
                        }
                        {
                            (this.props.download) &&
                            <Button
                                size="small"
                                onClick={this.downloadJsonFile}
                            >
                                <DownloadOutlined/>Download
                            </Button>
                        }

                    </Row>
                }
            </div>
        );
    }
}