import * as React from "react";
import {Contact, Program, Transaction, Value} from "lightrail-client/dist/model";
import {capitalizeFirstLetter, getContactName, prefixIdWithDots} from "../../../util/stringUtils";
import {GlobalSearchDropdown} from "../global/GlobalSearchDropdown";
import {SearchState, SearchType} from "../../../state/serverState/SearchesServerState";
import {MENU_ICONS} from "../../toolbars/MainNavItems";
import {InteractiveListItem} from "../../ui/InteractiveListItem";
import {Avatar, Button, List, Tag} from "antd";
import {ProgramInitialBalanceDisplay} from "../../programs/ProgramInitialBalanceDisplay";
import {ValueBalanceDisplay} from "../../values/balance/ValueBalanceDisplay";
import {PaddedSpin} from "../../ui/Loading";
import { UserOutlined } from "@ant-design/icons";

export interface Props {
    searchState: SearchState;

    onContactClick?: (contact: Contact) => void;
    onProgramClick?: (program: Program) => void;
    onValueClick?: (value: Value) => void;
    onTransactionClick?: (transaction: Transaction) => void;

    actionButtonText?: string; // Currently assuming all actions to be the same and have same text ie: GO | ATTACH | VISIT despite type
}

export class SearchResultsList extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.renderListItem = this.renderListItem.bind(this);
        this.renderProgramItem = this.renderProgramItem.bind(this);
        this.renderContactItem = this.renderContactItem.bind(this);
        this.renderValueItem = this.renderValueItem.bind(this);
        this.renderTransactionItem = this.renderTransactionItem.bind(this);
    }

    ///////////////////
    //[ RENDER ]
    renderListItem(id: string, meta: JSX.Element, clickParams: { onClick?: (...args: any[]) => void, param?: any }): JSX.Element {
        return (
            <InteractiveListItem
                key={id}
                className="lr-interactive-list-item"
                onClick={clickParams.onClick}
                clickParam={clickParams.param}
            >
                {
                    meta
                }
                {
                    (!!this.props.actionButtonText) && <Button>
                        {
                            this.props.actionButtonText
                        }
                    </Button>
                }
            </InteractiveListItem>
        );
    }

    // Program Item
    renderProgramItem(program: Program): JSX.Element {
        return (
            this.renderListItem(
                program.id,
                <List.Item.Meta
                    avatar={<Avatar size="large" icon={MENU_ICONS.PROGRAMS}/>}
                    title={`${program.name}`}
                    description={<ProgramInitialBalanceDisplay program={program} notSet="No Initial Balances"/>}
                />,
                {
                    onClick: this.props.onProgramClick,
                    param: program
                }
            )
        );
    }

    // Contact Item
    renderContactItem(contact: Contact): JSX.Element {
        return (
            this.renderListItem(
                contact.id,
                <List.Item.Meta
                    avatar={<Avatar size="large"><UserOutlined/></Avatar>}
                    title={contact.email}
                    description={getContactName(contact)}
                />,
                {
                    onClick: this.props.onContactClick,
                    param: contact
                }
            )
        );
    }

    // Value Item
    renderValueItem(value: Value): JSX.Element {
        return (
            this.renderListItem(
                value.id,
                <List.Item.Meta
                    avatar={<Avatar size="large" icon={MENU_ICONS.VALUES}/>}
                    title={<ValueBalanceDisplay value={value}/>}
                    description={`${!!value.code ? value.code : ""}`}
                />,
                {
                    onClick: this.props.onValueClick,
                    param: value
                }
            )
        );
    }

    // Transaction Item
    renderTransactionItem(transaction: Transaction): JSX.Element {
        return (
            this.renderListItem(
                transaction.id,
                <List.Item.Meta
                    avatar={<Avatar size="large" icon={MENU_ICONS.TRANSACTIONS}/>}
                    title={`Transaction ${prefixIdWithDots(transaction.id)}`}
                    description={<Tag>{capitalizeFirstLetter(transaction.transactionType)}</Tag>}
                />,
                {
                    onClick: this.props.onTransactionClick,
                    param: transaction
                }
            )
        );
    }

    // Nothing Found
    renderNoResultsFound(): JSX.Element {
        const locationString = GlobalSearchDropdown.getLocationString(this.props.searchState.searchedType || SearchType.EVERYTHING);
        return (
            <p className="lr-layout-padded-spin lr-color-percent-60" style={{marginBottom: 0}}>
                {"- " + ((locationString === "All") ? "Nothing Found" : "No " + locationString + " Found") + " -"}
            </p>
        );
    }

    // Results
    render(): JSX.Element {
        const results = this.props.searchState.results;
        const combinedResults = [...results.contacts, ...results.values, ...results.programs, ...results.transactions];

        // Fetching Spinner
        if (this.props.searchState.fetching) {
            return (<PaddedSpin/>);
        }

        // No Results
        if (!!this.props.searchState.fetchCount && !combinedResults.length) {
            return this.renderNoResultsFound();
        }

        // We Have Results
        return (
            <div>
                {
                    (!!results.programs.length) &&
                    <List
                        dataSource={results.programs}
                        renderItem={this.renderProgramItem}
                    />
                }
                {
                    (!!results.contacts.length) &&
                    <List
                        dataSource={results.contacts}
                        renderItem={this.renderContactItem}
                    />
                }
                {
                    (!!results.values.length) &&
                    <List
                        dataSource={results.values}
                        renderItem={this.renderValueItem}
                    />
                }
                {
                    (!!results.transactions.length) &&
                    <List
                        dataSource={results.transactions}
                        renderItem={this.renderTransactionItem}
                    />
                }
            </div>
        );
    }
}