import * as promisePattern from "../../../promisePattern";
import * as MFA from "../../../../communication/MFA";
import {AddDeviceResponse} from "../../../../communication/dtos/auth/MFA/AddDevice";
import * as sprout from "sprout-data";
import {rejectionHandler, RejectionReducer} from "../../../rejectionHandler";

export const type = "addMFADevice";

export const actionCreator = promisePattern.actionCreator(type, MFA.addDevice);

const onFulfilled: promisePattern.PromiseFulfilledHandler<AddDeviceResponse> =
    (state, payload) => {
        if (!!payload.uri) {
            return sprout.assoc(state,
                ["ui", "MFA", "QRCodeSuccess"], true,
                ["ui", "MFA", "illegalDeviceMessage"], "",
                ["server", "MFA", "pending"], false,
                ["server", "MFA", "uri"], payload.uri,
                ["server", "MFA", "secret"], payload.secret,
            );
        }
        return sprout.assoc(state,
            ["ui", "MFA", "phoneNumberSuccess"], true,
            ["ui", "MFA", "illegalDeviceMessage"], "",
            ["server", "MFA", "pending"], false
        );
    };

export const onRejectionHandler: RejectionReducer = {
    reducer: (state: any, error: any) => {
        return sprout.assoc(state,
            ["ui", "MFA", "illegalDeviceMessage"], error.message,
            ["server", "MFA", "pending"], false
        );
    },
    overrideGlobal: true,
    status: 422
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onRejectionHandler]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return sprout.assoc(state,
            ["server", "MFA", "pending"], true
        );
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);