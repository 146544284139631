import * as React from "react";
import {useEffect} from "react";
import {Form, Input, InputNumber, message} from "antd";

import {CreditParams, DebitParams} from "lightrail-client/dist/params";

import {ValueActionStatus} from "../../../../state/uistate/ValueUiState";
import {TransactionType} from "../../../../state/uistate/TransactionUiState";
import {findCurrency, normalizeValueForServer} from "../../../../util/currencyUtils";

import {generateId} from "../../../../communication/utils/urlUtils";
import {FormFieldIds, GenericRequiredRule} from "../../../../util/forms/AntFormHelpers";

import {ExternalProps, ReduxProps} from "./BalanceTransactionFormConnected";
import {WithCurrencies, withCurrencies} from "../../../ui/currency/withCurrenciesHOC";
import {useForm} from "antd/es/form/Form";

interface InternalProps extends WithCurrencies, ExternalProps, ReduxProps{
}

// class BalanceTransactionFormComponent extends React.PureComponent<InternalProps, {}> {
export const BalanceTransactionForm = withCurrencies( (props: InternalProps): JSX.Element => {
    const [form] = useForm();

    const currency = findCurrency(props.currencies, props.value.currency);
    const currencySymbol = currency ? currency.symbol : "$";
    const {TextArea} = Input;

    const validateAmount = (rule: any, value: any, callback: any): void => {
        if (value <= 0) {
            callback(`Amount must be greater than 0.`);
        }

        callback();
    }

    const closeModalAndResetTransactionType = (): void => {
        // Close the modal
        props.toggleTransactionModal({
            valueId: props.value.id,
            params: {
                transactionModalOpen: false
            }
        });

        // Set the transaction type back to NO_ACTION
        props.setPendingValueAction({
            valueId: props.value.id,
            params: {
                pendingValueAction: ValueActionStatus.NO_ACTION
            }
        });
    }

    const submitCredit = async (): Promise<void> => {
        const values = await form.validateFields()

        const creationValues: CreditParams = {
            id: generateId(),
            destination: {
                rail: "lightrail",
                valueId: props.value.id
            },
            amount: 0,
            currency: props.value.currency,
            metadata: {
                "lightrail_webapp_credit_note": values.notes
            },
        };

        const currency = findCurrency(props.currencies, creationValues.currency);
        if (!currency) {
            return;
        }

        if (values[FormFieldIds.Amount] != undefined) {
            creationValues.amount = normalizeValueForServer(values.amount, currency.decimalPlaces);
        }

        closeModalAndResetTransactionType();

        // Make the call and trigger Notification on success
        await props.creditTransaction(creationValues);
        message.success("Your credit has been added.");
        props.getValue({valueId: creationValues.destination.valueId});
    }

    const submitDebit = async (): Promise<void> => {
        const values = await form.validateFields();

        const creationValues: DebitParams = {
            id: generateId(),
            source: {
                rail: "lightrail",
                valueId: props.value.id
            },
            amount: 0,
            currency: props.value.currency,
            metadata: {
                "lightrail_webapp_debit_note": values.notes
            },
        };

        const currency = findCurrency(props.currencies, creationValues.currency);
        if (!currency) {
            return;
        }

        if (values[FormFieldIds.Amount] != undefined) {
            creationValues.amount = normalizeValueForServer(values.amount, currency.decimalPlaces);
        }

        closeModalAndResetTransactionType();

        // Make the call, trigger Notification and refresh Value on success
        await props.debitTransaction(creationValues);
        message.success("Your debit is complete.");
        props.getValue({valueId: creationValues.source.valueId});
    }

    ///////////////////
    //[ SUBMIT ]
    const submit = (): void => {
        switch (props.transactionType) {
            case TransactionType.CREDIT:
                submitCredit();
                break;
            case TransactionType.DEBIT:
                submitDebit();
                break;
            }
}

    useEffect(() => {
        if (props.getSubmit) {
            props.getSubmit(submit);
        }
    }, []);

    return (
        <Form onFinish={submit} className="lr-create-transaction-form" form={form}>
            <Form.Item
                name={FormFieldIds.Amount}
                rules={[
                    GenericRequiredRule,
                    {validator: validateAmount}
                ]}
            >
                <InputNumber
                    style={{width: "100%"}}
                    placeholder={`${currencySymbol} Enter Amount`}
                    size="large"
                    min={0}
                    precision={currency ? currency.decimalPlaces : 2}
                    autoFocus={true}
                />
            </Form.Item>
            <Form.Item
                name={FormFieldIds.Notes}
            >
                <TextArea placeholder="Note (optional)" autoSize={{ minRows: 2, maxRows: 4 }} />
            </Form.Item>
        </Form>
    );

});