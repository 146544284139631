import * as React from "react";
import {Body1, Display2} from "../ui/typography/TypographyTags";
import {Logo} from "../ui/brand/Logo";

import "./maintenanceLayout.scss";

export interface Props {
}

export class MaintenanceModeLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div className="maintenance-layout-container">
                <div className={"maintenance-layout-inner-container"}>
                    <div className={"maintenance-layout-logo-container"}>
                        <Logo height="120px"/>
                    </div>
                    <div className={"maintenance-layout-text-container"}>
                        <Display2 className={"maintenance-layout-title"}>Lightrail is currently undergoing maintenance right
                            now.</Display2>
                        <Body1>The web app will be accessible again shortly. Thank you for your patience.</Body1>
                    </div>
                </div>
            </div>
        );
    }
}