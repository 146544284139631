import * as ReactRedux from "react-redux";
import {MFAAuthPage} from "./MFAAuthPage";
import {actionCreator as verifyMfa} from "../../../../actions/auth/server/MFA/authMFA";
import {actionCreator as getDevice} from "../../../../actions/auth/server/MFA/getDeviceForLogin";
import {actionCreator as resendAuthCode} from "../../../../actions/auth/server/MFA/resendAuthCode";
import {actionCreator as logout} from "../../../../actions/auth/server/logout";
import {actionCreator as resetAuthErrorAndSuccessStates} from "../../../../actions/auth/server/resetAuthErrorAndSuccessStates";
import {actionCreator as setRestError} from "../../../../actions/errors/server/setRestError";
import {State} from "../../../../state/State";
import {withRouter} from "react-router-dom";
import {MFAState} from "../../../../state/serverState/MFAState";
import {RestError} from "../../../../state/serverState/RestError";

interface StateProps {
    stateHistory: string[];
    MFAServerState: MFAState;
    restError: RestError;
}

const mapStateToProps = (state: State): StateProps => ({
    stateHistory: state.ui.history,
    MFAServerState: state.server.MFA,
    restError: state.server.restError
});

const actionCreators = {
    verifyMfa,
    logout,
    resendAuthCode,
    getDevice,
    resetAuthErrorAndSuccessStates,
    setRestError,
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

const MFAAuthConnected = connector(withRouter(MFAAuthPage));

export {MFAAuthConnected as MFAAuth};