import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {listValues} from "lightrail-client/dist/values";
import {ListValuesResponse} from "lightrail-client/dist/params";
import {payloadHasBody} from "../../../util/dataUtils";
import {onPendingFetching} from "../../reducerHelpers";

export const type = "getAndAppendValues";
export const actionCreator = promisePattern.actionCreator(type, listValues);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ListValuesResponse> =
    (state, payload) => {
        const list = payloadHasBody(payload) ? [...state.server.values.list, ...payload?.body] : state.server.values.list;

        return sprout.assoc(state,
            ["server", "values", "list"], list,
            ["server", "values", "pagination"], {limit: payload?.limit, maxLimit: payload?.maxLimit},
            ["server", "values", "fetching"], false,
            ["server", "values", "links"], payload?.links
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingFetching(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);