import {Transaction} from "lightrail-client/dist/model";
import * as React from "react";
import {Button, Col, Menu, Row} from "antd";
import {TransactionAction, TransactionActionState} from "../../../state/uistate/TransactionUiState";
import {TransactionActionModal} from "./TransactionActionModal";
import {MenuInfo} from "rc-menu/es/interface";
import {ExternalProps, ReduxProps} from "./TransactionActionButtonGroupConnected";

interface Props extends ExternalProps, ReduxProps{
}

export class TransactionActionButtonGroup extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.getTransaction = this.getTransaction.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.toggleTransactionModal = this.toggleTransactionModal.bind(this);
        this.toggleCapture = this.toggleCapture.bind(this);
        this.toggleVoid = this.toggleVoid.bind(this);
        this.toggleReverse = this.toggleReverse.bind(this);
        this.isLastInChain = this.isLastInChain.bind(this);
    }

    componentDidMount(): void {
        this.getTransaction();
        this.props.setTransactionActionState({
            transactionId: this.props.transaction.id,
            actionState: {currentAction: TransactionAction.NONE}
        });
        this.isLastInChain();
    }

    componentDidUpdate(): void {
        this.isLastInChain();
    }

    getTransaction(): Transaction {
        return this.props.transactionsServerState.list.find(t => t.id == this.props.transaction.id);
    }

    isLastInChain(): boolean {
        const transaction = this.getTransaction();
        if (!!transaction) {
            const transactionChain = this.props.transactionsServerState.transactionChains.chains[transaction.id];
            if (!!transactionChain) {
                const len = transactionChain.length;
                const index = transactionChain.findIndex(t => (
                    (t.id === transaction.id)
                ));
                return (len - 1 === index);
            } else {
                this.props.getTransactionChain(transaction.id);
            }
        }
        return false;
    }

    toggleTransactionModal(value: TransactionActionState): void {
        this.props.setTransactionActionState({
            transactionId: this.props.transaction.id,
            actionState: value
        });
    }

    toggleCapture(): void {
        this.toggleTransactionModal({currentAction: TransactionAction.CAPTURE});
    }

    toggleVoid(): void {
        this.toggleTransactionModal({currentAction: TransactionAction.VOID});
    }

    toggleReverse(): void {
        this.toggleTransactionModal({currentAction: TransactionAction.REVERSE});
    }

    onMenuClick(e: MenuInfo): void {
        this.toggleTransactionModal({currentAction: e.key as TransactionAction});
    }

    menu(): JSX.Element {
        return (
            <Menu onClick={this.onMenuClick}>
                {
                    (this.props.transaction.pending) &&
                    <Menu.Item key={TransactionAction.CAPTURE}>Capture</Menu.Item>
                }
                {
                    (this.props.transaction.pending) &&
                    <Menu.Item key={TransactionAction.VOID}>Void</Menu.Item>
                }
                {
                    (!this.props.transaction.pending && (this.props.transaction.transactionType === "capture")) &&
                    <Menu.Item key={TransactionAction.REVERSE}>Reverse</Menu.Item>
                }
            </Menu>
        );
    }

    render(): JSX.Element {
        const transaction = this.getTransaction();
        const isLastInChain = this.isLastInChain();

        if (!transaction) {
            return null;
        }

        return (
            <span>
                {
                    (isLastInChain && transaction.pending) &&
                    <Row gutter={8}>
                        <Col><Button onClick={this.toggleCapture}>Capture</Button></Col>
                        <Col><Button onClick={this.toggleVoid}>Void</Button></Col>
                    </Row>
                }
                {
                    (isLastInChain && !transaction.pending && (transaction.transactionType !== "reverse") && (transaction.transactionType !== "void")) &&
                    <Button onClick={this.toggleReverse}>Reverse</Button>
                }
                {
                    ((!!this.props.transactionUiState.actions[transaction.id])) &&
                    <TransactionActionModal
                        transaction={transaction}
                        transactionType={this.props.transactionUiState.actions[transaction.id].currentAction}
                        uiAction={this.props.uiAction}
                        capturePendingTransaction={this.props.capturePendingTransaction}
                        voidPendingTransaction={this.props.voidPendingTransaction}
                        reverseTransaction={this.props.reverseTransaction}
                        getTransactionChain={this.props.getTransactionChain}
                        loading={this.props.transactionsServerState.processing}
                    />
                }
            </span>
        );
    }
}