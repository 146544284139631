import * as React from "react";
import {Checkbox, Form} from "antd";
import {DiscountSellerLiabilityItem} from "./DiscountSellerLiabilityItem";
import {FormFieldIds} from "../../../../util/forms/AntFormHelpers";
import {DiscountSellerLiabilityRule} from "lightrail-client/dist/model";
import {FormInstance} from "antd/es/form";

export interface State {
    discountChecked: boolean;
}

export interface Props {
    fieldId: string;
    className?: string;
    discountCheckedInitialValue?: boolean;
    discountSellerLiabilityRule?: DiscountSellerLiabilityRule;
    form: FormInstance;
}

export class DiscountItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {discountChecked: this.props.discountCheckedInitialValue};
        this.onDiscountCheckboxChange = this.onDiscountCheckboxChange.bind(this);
    }

    onDiscountCheckboxChange(): void {
        this.setState({discountChecked: !this.state.discountChecked}, () => {
            this.props.form.setFieldsValue({[this.props.fieldId]: (this.state.discountChecked)});
        });
    }

    render(): JSX.Element {
        const permanentClassNames = "lr-inset-sub-item marginBottom0 marginTop0";
        const className = this.props.className ? `${this.props.className} ${permanentClassNames}` : permanentClassNames;
        const formItemDescription = "Mark this value as a discount in checkout";

        return (
            <div>
                <p className="marginBottom0 marginTop0">
                    <Form.Item
                        name={this.props.fieldId}
                        initialValue={this.state.discountChecked}
                        valuePropName="checked"
                        className="marginBottom0 marginTop0"
                    >
                        <div className="marginBottom0">
                            <Checkbox
                                data-cy="form-item-discount"
                                onChange={this.onDiscountCheckboxChange}
                                checked={this.state.discountChecked}
                            >
                                {formItemDescription}
                            </Checkbox>
                        </div>
                    </Form.Item>
                </p>
                {
                    this.state.discountChecked &&
                    <Form.Item
                        className={className}
                        name={"discount-seller-liability-wrapper"}
                        label=""
                        extra=""
                    >
                        <DiscountSellerLiabilityItem
                            form={this.props.form}
                            fieldId={FormFieldIds.DiscountSellerLiabilityRule}
                            disabled={!this.state.discountChecked}
                            discountSellerLiabilityRule={(!!this.props.discountSellerLiabilityRule) ? this.props.discountSellerLiabilityRule : null}
                            className={"marginBottom0"}
                        />
                    </Form.Item>
                }
            </div>
        );
    }
}