import * as React from "react";
import {AllowSubmitOnEnter} from "../ui/forms/AllowSubmitOnEnter";
import {Form, Popover} from "antd";
import * as QRCode from "qrcode.react";
import {useForm} from "antd/es/form/Form";

export interface Props {
    secret?: string;
    uri?: string;
    disableButtons?: boolean;
    getSubmitHook: (hook: () => void) => void;
    onSubmit: (values: Record<string, any>) => void;
}

export const EnableMFAQrCodeForm = (props: Props): JSX.Element => {
    const [form] = useForm();

    const submit = async (): Promise<void> => {
        const values = form.validateFields();
        props.onSubmit(values);
    }

    React.useEffect(() => {
        if (props.getSubmitHook) {
            props.getSubmitHook(submit);
        }
    }, []);

    return (
        <Form
            onFinish={submit}
            form={form}
        >
            <div className="bodyDialogClass">
                <Form.Item>
                    {
                        <p>Use Google Authenticator (or similar) app to scan the barcode below, or enter your
                            <Popover
                                title={"Secret Key"}
                                content={(<p style={{maxWidth: 360}}>{props.secret}</p>)}
                                trigger="click"
                            >
                                <a className="lr-link-normal"> secret key </a>
                            </Popover>
                            manually.
                        </p>
                    }
                    {
                        <QRCode
                            className={"marginTop48 qrCode"}
                            value={props.uri}
                        />
                    }
                </Form.Item>
            </div>
            <AllowSubmitOnEnter/>
        </Form>
    );
}