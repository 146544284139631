import * as React from "react";
import {MFAAuthForm} from "../../forms/MFAAuthForm";
import {AuthMFARequest} from "../../../../communication/dtos/auth/MFA/MFA";
import {mfaType} from "../../../../state/serverState/MFAState";
import {Paths} from "../../../../util/routes/routes";
import {Body1, Subheading} from "../../../ui/typography/TypographyTags";
import {WithRouterProps} from "../../../router/WithRouterProps";
import {onSuccessLogin} from "../../../../util/routes/onSuccessLogin";
import {ReduxProps} from "./MFAPageConnected";

import "../../auth.scss";

export interface Props extends WithRouterProps<{}>, ReduxProps {
}

export class MFAAuthPage extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.verifyMfa = this.verifyMfa.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount(): void {
        try {
            this.props.getDevice({});
        } finally {
            this.props.resetAuthErrorAndSuccessStates({});
            this.props.setRestError({status: 0, message: "", code: "", ignoreError: true});
        }
    }

    componentDidUpdate(): void {
        this.checkRestError();
    }

    checkRestError(): void {
        if (!!this.props.restError && !!this.props.restError.status) {
            const error = this.props.restError;
            switch (error.status) {
                case 401:
                    this.props.resetAuthErrorAndSuccessStates({});
                    break;
            }
        }
    }

    async verifyMfa(data: AuthMFARequest): Promise<void> {
        try {
            this.props.setRestError({status: 0, code: "", message: "", ignoreError: true});

            const mfaResponse = await this.props.verifyMfa(data);

            if (mfaResponse.value) {
                onSuccessLogin(this.props.stateHistory, this.props.history);
            }
        } catch (err) {
            this.props.setRestError({
                status: err.status,
                code: err.code,
                message: err.message
            });
        }
    }

    async logout(): Promise<void> {
        this.props.setRestError({status: 0, code: "", message: "", ignoreError: true});
        await this.props.logout({});
        this.props.history.push(Paths.LOGIN_ROOT);
    }

    render(): JSX.Element {
        const isSMS = this.props.MFAServerState.mfa === mfaType.SMS;
        return (
            <div>
                <h1>Multi-factor authentication</h1>
                <Subheading className="marginTop24">
                    {
                        (isSMS)
                            ? "A code has been sent to your phone number"
                            : "Enter the code from the authenticator app"
                    }
                </Subheading>
                <div>
                    <MFAAuthForm
                        errorText={this.props.restError ? this.props.restError.message : ""}
                        processing={this.props.MFAServerState.processing}
                        onSubmit={this.verifyMfa}
                        logout={this.logout}
                    />
                </div>
                {
                    (isSMS) &&
                    <div>
                        <Body1>Didn&rsquo;t get a code?&nbsp;&nbsp;</Body1>
                        < a onClick={this.props.resendAuthCode}>Send me a new one</a>
                    </div>
                }
            </div>
        );
    }
}