import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {UpdateContactParams, UpdateContactResponse} from "lightrail-client/dist/params";
import {updateContact} from "lightrail-client/dist/contacts";
import {mergeListObject} from "../../../util/dataUtils";

export interface UpdateContactActionParams {
    contactId: string;
    params?: UpdateContactParams;
}

export const type = "updateContact";
export const actionCreator = promisePattern.actionCreator(type, (p: UpdateContactActionParams) => updateContact(p.contactId, p.params));

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdateContactResponse> =
    (state, payload) => {
        const newContactList = mergeListObject(state.server.contacts.list, payload?.body, "id");
        return sprout.assoc(state,
            ["server", "contacts", "list"], newContactList,
            ["server", "contacts", "processing"], false
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "contacts", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);