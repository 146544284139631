import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {AccountProfilePage} from "./AccountProfilePage";

import {actionCreator as setChangePasswordModalState} from "../../../actions/account/ui/setChangePasswordModalState";
import {actionCreator as updatePassword} from "../../../actions/auth/server/updatePassword";
import {actionCreator as resetAuthState} from "../../../actions/auth/server/resetAuthErrorAndSuccessStates";
import {actionCreator as contactCustomerSupport} from "../../../actions/contactCustomerSupport/server/contactCustomerSupport";
import {actionCreator as getJwt} from "../../../actions/jwt/getJwt";
import {UserState} from "../../../state/serverState/User";
import {AuthState} from "../../../state/serverState/AuthServerState";
import {AccountUiState} from "../../../state/uistate/AccountUiState";
import {Account} from "../../../communication/dtos/account/Account";

interface StateProps {
    user: UserState;
    account: AccountUiState;
    auth: AuthState;
    userEmail: string;
    userId: string;
    teamRoles: string[];
    currentAccount: Account;
}

const mapStateToProps = (state: State): StateProps => ({
    user: state.server.user,
    account: state.ui.account,
    auth: state.server.auth,
    userEmail: state.server.user.email,
    userId: state.browser.jwt.data.g.gui,
    teamRoles: state.browser.jwt.data.roles,
    currentAccount: state.server.account.accountInfo
});

const actionCreators = {
    contactCustomerSupport,
    setChangePasswordModalState,
    updatePassword,
    resetAuthState,
    getJwt
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const AccountProfilePageConnected = ReactRedux.connect(mapStateToProps, actionCreators)(AccountProfilePage);