import * as React from "react";
import {CSSProperties} from "react";
import {Button} from "antd";
import {WithRouterProps} from "../../router/WithRouterProps";
import {withRouter} from "react-router-dom";
import {SizeType} from "antd/es/config-provider/SizeContext";
import {ArrowLeftOutlined} from "@ant-design/icons/lib";

export interface Props extends WithRouterProps<{}> {
    size?: SizeType;
    style?: CSSProperties;
    className?: string;
}

class BackButtonComp extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        const className = (!!this.props.className) ? "lr-back-button " + this.props.className : "lr-back-button";
        const style = {...this.props.style, border: "none"};

        return (
            <Button
                className={className}
                style={style}
                size={((!!this.props.size) ? this.props.size : "large") as SizeType}
                onClick={this.props.history.goBack}
            >
                <ArrowLeftOutlined/>
            </Button>
        );
    }
}

export const BackButton = withRouter(BackButtonComp);