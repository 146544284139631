import * as React from "react";
import {UserMode, UserModes} from "../../communication/dtos/auth/PingResponse";
import {Switch} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {WithRouterProps} from "../router/WithRouterProps";
import {Subheading} from "../ui/typography/TypographyTags";
import {reloadPage} from "../../util/navigation";
import {ReduxProps, ExternalProps} from "./ToggleTestingConnected";

export interface Props extends WithRouterProps<any>, ReduxProps, ExternalProps {
}

export class ToggleTesting extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(): void {
        this.props.getUser({});
    }

    async onChange(checked: boolean): Promise<void> {
        const mode = checked ? UserModes.LIVE : UserModes.TEST;
        await this.props.toggleTestingMode({mode: mode as UserMode});
        await this.onToggleSuccess();
    }

    async onToggleSuccess(): Promise<void> {
        try {
            this.props.getAccountDetails({});
            this.props.listAccounts({});
        } finally {
            reloadPage(this.props.history);
        }

        if (this.props.onToggleSuccess) {
            this.props.onToggleSuccess();
        }
    }

    render(): JSX.Element {
        return (
            <div id="lr-toggle-testing-container">
                <Subheading className="marginRight16">Live data</Subheading>
                <div style={this.props.style} className={this.props.className}>
                    <Switch
                        checked={!this.props.testing || false}
                        className="lr-toggle-switch"
                        onChange={this.onChange}
                        loading={this.props.loading}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>
            </div>
        );
    }
}