import * as React from "react";
import {Checkbox, Form, InputNumber} from "antd";
import {Program} from "lightrail-client/dist/model";
import {FormInstance} from "antd/es/form";

export interface State {
    usesRemainingChecked: boolean;
    programRequiresUsesRemaining: boolean;
}

export interface Props {
    fieldId: string;
    program?: Program;
    extraText?: string;
    limitUsesTitleOverride?: JSX.Element | string;
    limitUsesDescriptionOverride?: string;
    form: FormInstance;
}

export class UsesRemainingItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const programRequiresUsesRemaining = (!!props.program && props.program.fixedInitialUsesRemaining && !!props.program.fixedInitialUsesRemaining.length);

        this.state = {
            programRequiresUsesRemaining: programRequiresUsesRemaining,
            usesRemainingChecked: programRequiresUsesRemaining
        };

        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.onLimitUsesCheckboxChange = this.onLimitUsesCheckboxChange.bind(this);
        this.validateUsesRemaining = this.validateUsesRemaining.bind(this);
        this.setFieldValueOnProgramChange = this.setFieldValueOnProgramChange.bind(this);
    }

    componentDidMount(): void {
        this.setFieldValueOnProgramChange();
    }

    componentDidUpdate(oldProps: Props): void {
        if (oldProps.program.id !== this.props.program.id) {
            this.setFieldValueOnProgramChange();
        }
    }

    setFieldValueOnProgramChange(): void {
        const {fieldId, form, program} = this.props;
        const programRequiresUsesRemaining = !!program?.fixedInitialUsesRemaining?.length;

        this.setState({
            programRequiresUsesRemaining: programRequiresUsesRemaining,
            usesRemainingChecked: programRequiresUsesRemaining
        });

        if (programRequiresUsesRemaining) {
            const values: Record<string, number> = {};
            values[fieldId] = program?.fixedInitialUsesRemaining[0];
            form.setFieldsValue(values);
        }
    }

    getDefaultValue(): number {
        return (!!this.props.program && this.props.program.fixedInitialUsesRemaining && !!this.props.program.fixedInitialUsesRemaining.length) ? this.props.program.fixedInitialUsesRemaining[0] : 1;
    }

    onLimitUsesCheckboxChange(): void {
        const includeUsesRemaining = !this.state.usesRemainingChecked;

        this.props.form.setFieldsValue({[this.props.fieldId]: (includeUsesRemaining) ? this.getDefaultValue() : null});
        this.setState({usesRemainingChecked: !this.state.usesRemainingChecked});
    }

    validateUsesRemaining(rule: any, value: any, callback: any): void {
        if (this.state.programRequiresUsesRemaining || this.state.usesRemainingChecked) {
            if (typeof value === "string" || value < 1 || value == undefined) {
                callback(`Must have at least 1 use.`);
            } else if (this.state.programRequiresUsesRemaining && !this.props.program.fixedInitialUsesRemaining.find(v => v == value)) {
                callback(`Must match uses defined by program: ${this.props.program.fixedInitialUsesRemaining.join(", ")}.`);
            }
        }
        callback();
    }

    render(): JSX.Element {
        const limitUsesTitle = this.props.limitUsesTitleOverride ? this.props.limitUsesTitleOverride : "Limit Uses";
        const limitUsesDescription = this.props.limitUsesDescriptionOverride ? this.props.limitUsesDescriptionOverride : "The number of times each code can be used.";

        return (
            <div>
                {
                    (!this.state.usesRemainingChecked) &&
                    <p>
                        <Checkbox
                            checked={this.state.usesRemainingChecked}
                            onChange={this.onLimitUsesCheckboxChange}
                        >
                            {limitUsesTitle}
                        </Checkbox>
                    </p>
                }
                <Form.Item
                    label={this.state.usesRemainingChecked ? limitUsesTitle : null}
                    extra={
                        <span>{limitUsesDescription} {this.state.usesRemainingChecked && this.props.program.fixedInitialUsesRemaining?.length === 1 ? "This has been set by the program and cannot be changed." : ""}</span>
                    }
                    style={{display: (this.state.usesRemainingChecked) || this.state.usesRemainingChecked ? "" : "none"}}
                    name={this.props.fieldId}
                    rules={[
                        {required: this.state.usesRemainingChecked, message: "Required by Program."},
                        {validator: this.validateUsesRemaining}
                    ]}
                >
                    <InputNumber
                        size="large"
                        min={1}
                        disabled={this.state.usesRemainingChecked && this.props.program.fixedInitialUsesRemaining?.length === 1}
                        precision={0}
                    />
                </Form.Item>
            </div>
        );
    }
}