import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {EditAccountRequest, EditAccountResponse} from "../../../communication/dtos/account/EditAccount";
import {ActionCreator} from "redux";
import {editAccount} from "../../../communication/account";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";

export const type = "editAccount";

export type EditAccountActionResponse = ActionCreator<EditAccountResponse>;

export const actionCreator = promisePattern.actionCreator(type,
    (requestParams: EditAccountRequest) => editAccount(requestParams)
);

const onFulfilled: promisePattern.PromiseFulfilledHandler<EditAccountResponse> = (
    state: State, payload: EditAccountResponse
) => sprout.assoc(state,
    ["server", "account", "accountInfo"], payload,
    ["server", "account", "fetching"], false
);

const onPending: promisePattern.PromisePendingHandler = (state: State) => sprout.assoc(state,
    ["server", "account", "fetching"], true
);

const rejectionReducer: RejectionReducer = {
    reducer: (state: State) => sprout.assoc(state,
        ["server", "account", "accountInfo"], null,
        ["server", "account", "fetching"], false
    )
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([rejectionReducer]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);