import * as ReactRedux from "react-redux";
import {State} from "../../../state/State";
import {ValueDetailPage} from "./ValueDetailPage";
import {actionCreator as getValue} from "../../../actions/values/server/getValue";
import {actionCreator as getProgram} from "../../../actions/programs/server/getProgram";
import {actionCreator as getContact} from "../../../actions/contacts/server/getContact";
import {actionCreator as setPendingValueAction} from "../../../actions/values/ui/setPendingValueAction";
import {actionCreator as detachValueFromContact} from "../../../actions/values/server/detachValueFromContact";
import {actionCreator as attachValueToContact} from "../../../actions/values/server/attachValueToContact";
import {actionCreator as getTransactions} from "../../../actions/transactions/server/getTransactions";
import {getCurrency} from "lightrail-client/dist/currencies";
import {ValueServerState} from "../../../state/serverState/ValueServerState";
import {ProgramsServerState} from "../../../state/serverState/ProgramsServerState";
import {ContactsServerState} from "../../../state/serverState/ContactsServerState";
import {CurrencyServerState} from "../../../state/serverState/CurrencyServerState";

interface StateProps {
    valueStateServer: ValueServerState;
    programStateServer: ProgramsServerState;
    contactStateServer: ContactsServerState;
    currencyServerState: CurrencyServerState;
}

const mapStateToProps = (state: State): StateProps => ({
    valueStateServer: state.server.values,
    programStateServer: state.server.programs,
    contactStateServer: state.server.contacts,
    currencyServerState: state.server.currency,
});

const actionCreators = {
    getValue,
    getProgram,
    getContact,
    setPendingValueAction,
    attachValueToContact,
    detachValueFromContact,
    getTransactions,
    getCurrency
};

const connector = ReactRedux.connect<StateProps, typeof actionCreators, {}>(mapStateToProps, actionCreators);
export type ReduxProps = ReactRedux.ConnectedProps<typeof connector>;

export const ValueDetailPageConnected = connector(ValueDetailPage);