import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {CreateAccountRequest, CreateAccountResponse} from "../../../communication/dtos/account/CreateAccount";
import {createAccount} from "../../../communication/account";
import {ActionCreator} from "redux";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";

export const type = "createAccount";

export type CreateAccountActionResponse = ActionCreator<CreateAccountResponse>;

export const actionCreator = promisePattern.actionCreator(type,
    (requestParams: CreateAccountRequest) => createAccount(requestParams)
);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateAccountResponse> = (
    state: State, payload: CreateAccountResponse
) => sprout.assoc(state,
    ["server", "account", "accountInfo"], payload,
    ["server", "user", "testing"], true,
    ["server", "account", "fetching"], false
);

const onPending: promisePattern.PromisePendingHandler = (state: State) => sprout.assoc(state,
    ["server", "account", "fetching"], true
);

const rejectionReducer: RejectionReducer = {
    reducer: (state: State) => sprout.assoc(state,
        ["server", "account", "accountInfo"], null,
        ["server", "account", "fetching"], false
    )
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([rejectionReducer]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);