import * as React from "react";
import {formatRoute, Paths} from "../../../../util/routes/routes";
import {prefixIdWithDots} from "../../../../util/stringUtils";
import {Contact, Program, Value} from "lightrail-client/dist/model";
import {Avatar, Col, Divider, Row, Spin, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {getColorFromId} from "../../../../util/avatarColorHelper";
import {UsergroupAddOutlined, UserAddOutlined, UserOutlined} from "@ant-design/icons/lib";

export interface Props {
    value: Value;
    program: Program;
    contact: Contact;
    loadingContact?: boolean;

    attachContact: () => void;
    detachContact: (c: Contact) => void;
}

export class ValueDetailLinks extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onDetachContact = this.onDetachContact.bind(this);
    }

    onDetachContact(): void {
        this.props.detachContact(this.props.contact);
    }

    renderContactDetails(): JSX.Element {
        const value = this.props.value;
        const contact = this.props.contact;

        if (!!contact) {
            const contactTitle = (contact.email) ? contact.email : `Contact ${prefixIdWithDots(contact.id)}`;

            return (
                <div>
                    <div>
                        <Tooltip title={`Go to ${contactTitle}`}>
                            <Link
                                to={formatRoute(Paths.CONTACT_DETAIL, {contactId: value.contactId})}
                            >
                                {contactTitle}
                            </Link>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="Change Contact">
                            <a onClick={this.props.attachContact}>Change</a>
                        </Tooltip>
                        {" | "}
                        <Tooltip title="Remove Contact">
                            <a onClick={this.onDetachContact}>Remove</a>
                        </Tooltip>
                    </div>
                </div>
            );
        }

        if (value.isGenericCode) {
            return (
                <div>
                    <div>Contacts Listed Below</div>
                    <div>
                        <Tooltip title="Attach Contact">
                            <a onClick={this.props.attachContact}><UsergroupAddOutlined/> Attach Contact</a>
                        </Tooltip>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div>No Contact</div>
                <div>
                    <a onClick={this.props.attachContact}><UserAddOutlined/>Attach Contact</a>
                </div>
            </div>
        );
    }

    render(): JSX.Element {
        const value = this.props.value;

        if (!value.programId && (!value.contactId && !value.isGenericCode)) {
            return null;
        }

        const {program} = this.props;

        return (
            <div>
                <Row gutter={16} style={{width: "100%"}}>
                    {
                        (!!value.programId) &&
                        <Col span={12}>
                            {
                                (!!program)
                                    ? <div style={{display: "flex"}}>
                                        <Link
                                            to={formatRoute(Paths.PROGRAM_DETAIL, {programId: value.programId})}
                                        >
                                            <Avatar shape="square" size="large">P</Avatar>
                                        </Link>
                                        <div style={{marginLeft: 16}}>
                                            <Link
                                                to={formatRoute(Paths.PROGRAM_DETAIL, {programId: value.programId})}
                                            >
                                                {program.name}
                                            </Link>
                                            <br/>
                                            <p>Program</p>
                                        </div>
                                    </div>
                                    : <Spin/>
                            }
                        </Col>
                    }
                    {
                        (!value.isGenericCode) &&
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col>
                                    {
                                        (!!value.contactId) ?
                                            <Link
                                                to={formatRoute(Paths.CONTACT_DETAIL, {contactId: value.contactId})}
                                            >
                                                <Avatar shape="square"
                                                        size="large"
                                                        style={{backgroundColor: getColorFromId(value.contactId)}}
                                                >
                                                    <UserOutlined/>
                                                </Avatar>
                                            </Link>
                                            :
                                            <Avatar shape="square" size="large">
                                                <UserOutlined/>
                                            </Avatar>
                                    }
                                </Col>
                                <Col>
                                    {
                                        (this.props.loadingContact)
                                            ? <Spin/>
                                            : this.renderContactDetails()
                                    }
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                <Divider/>
            </div>
        );
    }
}