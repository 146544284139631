import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler} from "../../rejectionHandler";
import {State} from "../../../state/State";
import {mergeListObject} from "../../../util/dataUtils";
import {createWebhook} from "../../../communication/webhooks";
import {Webhook} from "../../../communication/dtos/webhooks/Webhook";
import {RestError} from "../../../communication/utils/errors/RestError";

export const type = "createWebhook";
export const actionCreator = promisePattern.actionCreator(type, createWebhook);

const onFulfilled: promisePattern.PromiseFulfilledHandler<Webhook> =
    (state, payload) => {
        const newWebhookList = mergeListObject(state.server.webhook.list, payload, "id");
        return sprout.assoc(state,
            ["server", "webhook", "list"], newWebhookList,
            ["server", "webhook", "processing"], false
        );
    };

const onRejected = rejectionHandler([
    {
        overrideGlobal: true,
        reducer: (state, error: RestError) => {
            return sprout.assoc(state,
                ["server", "webhook", "processing"], false,
                ["server", "webhook", "error"], error.message,
                ["server", "webhook", "status"], error.status
            );
        }
    }
]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc<State>(state,
        ["server", "webhook", "processing"], true
    );

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);