import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {onPendingProcessing} from "../../reducerHelpers";
import {
    DetachContactFromValueParams,
    DetachContactFromValueResponse
} from "lightrail-client/dist/params/contacts/DetachContactFromValueParams";
import {deleteListObject, mergeListObjectOrUnshift} from "../../../util/dataUtils";
import {detachContactFromValue} from "lightrail-client/dist/contacts";

export interface DetachValueFromContactActionParams {
    contactId: string;
    params: DetachContactFromValueParams;
    removeValue?: boolean;
    removeContact?: boolean;
}

//Include contactId with response
export interface DetachValueFromContactActionResponse {
    contactId: string;
    response: DetachContactFromValueResponse;
    removeValue?: boolean;
    removeContact?: boolean;
}

export const type = "detachValueFromContact";
export const actionCreator = promisePattern.actionCreator(type, (p: DetachValueFromContactActionParams) => detachContactFromValue(p.contactId, p.params).then(
    response => ({
        contactId: p.contactId,
        response,
        removeValue: p.removeValue,
        removeContact: p.removeContact
    }))
);

const onFulfilled: promisePattern.PromiseFulfilledHandler<DetachValueFromContactActionResponse> =
    (state, payload) => {
        const newContactList = (payload?.removeContact) ? deleteListObject(state.server.contacts.list, payload?.contactId, "id") : state.server.contacts.list;
        const newValueList = (payload?.removeValue) ? deleteListObject(state.server.values.list, payload?.response.body.id, "id") : mergeListObjectOrUnshift(state.server.values.list, payload?.response.body, "id");

        return sprout.assoc(state,
            ["server", "contacts", "list"], newContactList,
            ["server", "values", "list"], newValueList,
            ["server", "values", "processing"], false
        );
    };

const rejectionReducer: RejectionReducer = {
    overrideGlobal: true,
    reducer: (state, error) => sprout.assoc(state,
        ["server", "values", "processing"], false,
        ["server", "values", "error"], error.message
    )
};
const onRejected = rejectionHandler([rejectionReducer]);

const onPending: promisePattern.PromisePendingHandler = (state) => onPendingProcessing(state, ["server", "values"]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);